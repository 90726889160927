import React, { useEffect, useState, useRef } from "react";
import "../../App.css";
import { Link, useParams } from "react-router-dom";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { fetchBlogDetail } from '../../services/blogservice';
import { Helmet } from "react-helmet";
import HeaderComponent from '../HeaderComponent/HeaderComponent.js';
import FooterComponent from '../FooterComponent/FooterComponent.js';


function PrivacyPolicyComponent() {
  const [blogData, setBlogData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { id, title, description } = useParams();
  const [pageLoading, setPageLoading] = useState(false);
  const isLoadingRef = useRef(false);


 


  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    document.body.classList.add("home-bg");

    // Clean up the class when the component unmounts
    return () => {
      document.body.classList.remove("home-bg");
    };
  }, []);

  if (isLoading) {
    return (
      <section className="pad-top-20 pad-bot-80" id="loaderSection">
        <div className="container3">
          <div className="block-element m-b-30">
          </div>
          <div className="block-element loader-mobile-screen" style={{ marginTop: '80px' }}>
            <LoadingSpinner />
          </div></div>
      </section>
    );
  }



  return (

    <>
      <Helmet>
        <link href="/css/bootstrap.min.css" rel="stylesheet" />
      </Helmet>
      <HeaderComponent />
      <section className="pad-top-60 pad-bot-60">
        <div className="container2">
          <div className="block-element m-b-20">
            <Link to="/" className="back-btn1">
              <i className="fa fa-angle-left"> </i> Back
            </Link>
          </div>
          <div className="block-element m-b-10">
            <div className="textual-sec1">
              <h3 className="col-black">Privacy Policy</h3>
              <p className="col-black">Tikkets.com is committed to the safety and protection of your personal
                information and we respect your privacy. This Privacy Policy (together with
                our Terms of Use) describes how we collect, use, and disclose information
                when you use our mobile application ("App") and any related services,
                including our website. By using our App, you agree to the collection and use
                of your information as described in this Privacy Policy.
              </p>
            </div>
          </div>
          <div className="block-element m-b-10">
            <div className="textual-sec1">
              <h3 className="col-black">Information that may be collected</h3>
              <p className="col-black">We may collect personal information that you voluntarily provide when you
                use our Ticketing App or through our website, such as:
              </p>
              <ol>
                <li>Personal identification information, including your name, date of birth,
                  email address, telephone numbers, your address and gender;</li>
                <li>Your banking details, including your credit card and payment
                  information.</li>
              </ol>
              <p className="col-black">We will process or use your Personal Information only for the purpose(s) of
                providing to you the service(s) that you request that we provide to you, to
                comply with any legal requirements arising from regulatory obligations that
                arise from providing you the service(s) and our legitimate business interest.
                Based on our legal requirements and legitimate business interest, we may
                request other documents to verify your identity and the sources of your
                funds for the purposes of anti-money laundering and fraud prevention.
                Once we collect your Personal Information, we may use it for the following
                purposes:
              </p>
              <ol>
                <li>To allow you to open and use an Account on the Tikkets Platform;</li>
                <li>To enable you to complete Transactions on the Tikkets Platform;</li>
                <li>To reply to any of your queries;</li>
                <li>To contact you via email or calls;</li>
                <li>To ensure the security of your Account;</li>
                <li>To analyze the use and access of our website(s);</li>
                <li>To comply with any required regulatory purposes such as tax,
                  prevention of Money Laundering, prevention of Fraud, adherence to
                  Company statistical reporting obligations, etc.;</li>
                <li>To provide you with information about products and promotions that
                  may be of interest to you, from ourselves and third parties as specified
                  by you; and</li>
                <li>For data analytics and market research on an anonymous basis, unless
                  consented to by you otherwise.</li>
              </ol>
            </div>
          </div>
          <div className="block-element m-b-10">
            <div className="textual-sec1">
              <h3 className="col-black">Information that is Automatically Collect</h3>
              <p className="col-black">We may use cookies and other technology to automatically collect certain
                information about you and your interactions when you use our Tikkets
                Platform, including your device information (such as device type, operating
                system), usage data (such as pages visited, features used), and log
                information (such as IP address, access times).
              </p>
            </div>
          </div>
          <div className="block-element m-b-10">
            <div className="textual-sec1">
              <h3 className="col-black">How We Use Your Personal Information</h3>
              <p className="col-black">We may use your personal information we collect for various purposes,
                including providing and improving our services, personalizing your
                experience, processing transactions, communicating with you, analyzing
                usage trends and preferences, enforcing our terms and policies and
                protecting against misuse or unauthorized access.
              </p>
            </div>
          </div>
          <div className="block-element m-b-10">
            <div className="textual-sec1">
              <h3 className="col-black">Information Sharing and Disclosure</h3>
              <p className="col-black">We may share your information in the following circumstances:
              </p>
              <ul>
                <li>With your consent or as necessary to fulfill any purpose disclosed to
                  you</li>
                <li>With our service providers and partners who assist us in delivering
                  our services</li>
                <li>When required by law or to protect our rights, property, or safety, or
                  the rights, property, or safety of others</li>
              </ul>
              <h3 className="col-black mt-2">Your Rights</h3>
              <p className="col-black">You have the right to view and access your Personal Information and to
                make any correction, update any information and block or require the
                removal of any inaccurate or incorrect information by correcting and/or
                removing the information yourself or contacting us directly via email in the
                manner indicated in this Privacy Policy.  To make requests, or if you have
                questions concerning our Privacy Policy, please submit your communication
                to xxx.
              </p>
              <p className="col-black">We will make every effort to respond to requests; however, we reserve the
                right to reject any unreasonable, extremely impractical or
                disproportionately technical requests, or requests which might expose us to
                operational or legal risks.  We will also retain any and all Personal
                Information for the time period(s) required or mandated by applicable laws
                and regulations, to resolve disputes, when pursuing legitimate business
                interests, performing audits and to protect or enforce our rights.
              </p>
            </div>
          </div>
          <div className="block-element m-b-10">
            <div className="textual-sec1">
              <h3 className="col-black">Children's Privacy</h3>
              <p className="col-black">Our services are not directed to individuals under the age of 13, and
                Tikkets.com does not knowingly collect personal information from children
                under 13. If you are a parent or guardian and believe that your child has
                provided us with personal information, please contact us, and we will take
                steps to delete such information.
              </p>

              <h3 className="col-black mt-2">Personal Information Protection</h3>
              <p className="col-black">We take the protection and security of your Personal Information very
                seriously.  The Personal Information that you provide to us is stored
                securely on our internal and third-party servers.  We take steps to ensure
                that the policies, procedures, strategies, controls, and measures we have
                undertaken will keep your Personal Information and data safe and secure.
                We employ encryption technology and other protection technologies to
                safeguard our data. We ensure that your Personal Information is only
                accessible by trained, responsible personnel who have a specific need to
                carry out their responsibilities.
              </p>
              <h3 className="col-black mt-2">Amendments to this Privacy Policy</h3>

              <p className="col-black">We may amend, revise, modify, update or change in part or in whole this
                Privacy Policy from time to time, and we will notify you of any changes by
                posting the new Privacy Policy on this page. By using our website(s) or
                mobile application, you agree to accept the terms of this Privacy Policy as
                stated herein as at the time you use them.  We encourage you to review this
                Privacy Policy from time to time to stay up to date.
              </p>
              <h3 className="col-black mt-2">Contact Us</h3>

              <p className="col-black">If you have any questions or concerns about our Privacy Policy, please
                contact us at
                xxxx
              </p>
            </div>
          </div>
        </div>
      </section>
      <FooterComponent />
    </>
  );
}

export default PrivacyPolicyComponent;
