import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { login } from "../services/authService";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup"; // Import yupResolver from @hookform/resolvers
import * as yup from "yup";
import { Helmet } from "react-helmet";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";
import { fetchUserData } from "../services/profileService";
import { ProfileContext } from "../hooks/ProfileContext";

const schema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
  password: yup.string().required("Password is required"),
});

function Login() {
  const [loading, setLoading] = useState(false);
  const [Isloading, setloading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { setImagePath } = useContext(ProfileContext);

  const fetchUser = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const data = await fetchUserData(token);

      const fullImagePath = data.user.imagePath + data.user.image;
      setImagePath(fullImagePath);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setloading(false); // Change loading state to false after 2 seconds
    }, 2000); // 2 seconds delay

    return () => clearTimeout(timer); // Clear the timer if the component unmounts
    fetchUser();
  }, []);

  const handleLogin = async ({ email, password }) => {
    setLoading(true);
    setError("");

    try {
      const data = await login(email, password);
      localStorage.setItem("userID", data.user.user_id);
      localStorage.setItem("userName", data.user.name);
      setLoading(false);

      switch (data.role) {
        case "user":
          const checkOutNotAuth = localStorage.getItem("checkOutNotAuth");
          if (checkOutNotAuth == "1") {
            navigate("/cart");
            localStorage.removeItem("checkOutNotAuth");
          } else {
            window.location.href = "/";
          }

          break;

        default:
          alert("Invalid Role: " + data.role);
          break;
      }
    } catch (error) {
      console.error("Login failed:", error);
      if (error.response && error.response.status === 401) {
        Swal.fire("Unauthorized", "Invalid Credentials", "error");
        setLoading(false);
      } else {
        Swal.fire("Error", "Login failed. Please try again later.", "error");
        setLoading(false);
      }
    } finally {
      // setSubmitting(false);
      setLoading(false);
    }
  };
  return (
    <div>
      <Helmet>
        <link href="/styles/bootstrap.min.css" rel="stylesheet" />
        <link href="/styles/animate.css" rel="stylesheet" />
        <link href="/styles/custom.css" rel="stylesheet" />
        <link href="/styles/slick-theme.css" rel="stylesheet" />
        <link href="/styles/slick.css" rel="stylesheet" />
      </Helmet>
      {Isloading ? (
        <section className="pad-top-20 pad-bot-80">
          <div className="container3">
            <div className="block-element m-b-30"></div>
            <div
              className="block-element loader-mobile-screen"
              style={{ marginTop: "80px" }}
            >
              <LoadingSpinner />
            </div>
          </div>
        </section>
      ) : (
        <>
          <section
            className="all-layout w-full h-screen bg-cover bg-center bg-no-repeat"
            id="loginHeight"
          >
            <div className="d-flex align-items-center justify-content-center h-100">
              <div
                className="bg-contain bg-center p-4 p-md-5 h-80 mx-auto"
                id="loginSection"
              >
                <div className="d-flex justify-content-center align-items-center h-100 p-3 p-md-5">
                  <div className="bg-white p-4 p-md-5 rounded-6 shadow-lg formstyle">
                    <div className="text-center mb-4 mb-md-2">
                     
                     <a href="/">
                      <img src="/images/tikkets-dark-logo.png" alt="Logo" />
                     </a>
                      <h2 className="h4 font-weight-bold text-secondary">
                        Sign in
                      </h2>
                    </div>
                    <form onSubmit={handleSubmit(handleLogin)}>
                      <div className="form-group mb-3">
                        <label htmlFor="email" className="form-label">
                          E-Mail Address
                        </label>
                        <input
                          type="email"
                          {...register("email")}
                          placeholder="Email Address"
                          name="email"
                          id="email"
                          className={`form-control ${
                            errors.email ? "is-invalid" : ""
                          }`}
                        />
                        {errors.email && (
                          <div className="invalid-feedback">
                            {errors.email.message}
                          </div>
                        )}
                      </div>

                      <div className="form-group mb-3 position-relative">
                        <label htmlFor="password" className="form-label">
                          Password
                        </label>
                        <div className="position-relative">
                          <input
                            type={showPassword ? "text" : "password"}
                            {...register("password")}
                            placeholder="Password"
                            name="password"
                            id="password"
                            className={`form-control ps-4 pe-5 ${
                              errors.password ? "is-invalid" : ""
                            }`}
                          />
                          <div
                            className="position-absolute top-50 end-0 translate-middle-y pe-3"
                            onClick={togglePasswordVisibility}
                            style={{
                              cursor: "pointer",
                              top: "10px",
                              right: "10px",
                            }}
                          >
                            {showPassword ? (
                              <i
                                className="fa fa-eye-slash"
                                aria-hidden="true"
                              ></i>
                            ) : (
                              <i className="fa fa-eye" aria-hidden="true"></i>
                            )}
                          </div>
                        </div>
                        {errors.password && (
                          <div className="invalid-feedback">
                            {errors.password.message}
                          </div>
                        )}
                      </div>

                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="keepSignedIn"
                          />
                          <label
                            className="form-check-label qqtap"
                            htmlFor="keepSignedIn"
                          >
                            Keep me signed in
                          </label>
                        </div>
                       
                      </div>

                      <div className="mb-4">
                        <button
                          className="btn btn-primary w-100"
                          disabled={loading}
                        >
                          {loading ? (
                            <div
                              className="spinner-border text-light"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          ) : (
                            "Login"
                          )}
                        </button>
                      </div>

                      <div className="text-center">
                        <p className="mb-0">
                          Don't have an account? &nbsp;
                          <Link to="/user/register" className="text-primary ms-2">
                             Register
                          </Link>
                        </p>
                        <Link to="/user/reset-password" className="text-primary mt-3">
                          Forgot password?
                        </Link>
                        <p className="mb-0 mt-3">
                          <Link to="/" className="text-primary">
                            Back to Homepage
                          </Link>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </div>
  );
}

export default Login;
