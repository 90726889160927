import React, { useEffect, useState, useRef, useContext } from "react";

 
function CommingSoonComponent({currentTab,setCurrentTab,isMobile}) {
 
  const handleCloseClick = () => {
    setCurrentTab('eventsTab');
 
  };

  if(isMobile){

  }
   
 
  return (
    <>

<div
  className="modal fade show d-block modal-open  "
  id="staticBackdrop"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabIndex={-1}
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
  style={{display: 'block',visibility:'visible'}}
  
>
  
  <div className={`${isMobile ? 'onMOb' : 'modal-dialog'}`}>
    <div className="modal-content">
      <div className="modal-body p-0">
        <div className="d-block w-100 bg-white rounded-5 pb-3 pt-3">
          <div className="d-flex w-100 align-items-center position-relative justify-content-between px-3 pb-3">
            <div className="d-block w-100 text-center">
              <img
                src="https://tikkets.com/images/logo-black.png"
                height="40px"
                className="w-auto"
              />
            </div>
          </div>
          <div className="d-block w-100 py-2 px-3">
            <div className="d-block text-center mb-3">
              <div className="row justify-content-center">
                <div className="col-lg-7 col-md-9 col-sm-9 col-9">
                 
      {
        currentTab === "hotelsTab" ? (
          <img className="w-100" src="/images/book-everything.png" alt="Hotels" />
        ) : currentTab === "buses" ? (
          <img className="w-100" src="/images/bus.png" alt="Bus" />
        ) : currentTab === "flightTab" ? (
          <img className="w-100" src="/images/airplane.png" alt="Airplane" />
        ) : (
          ""
        )
      }
      


                 
                </div>
              </div>
            </div>
            <div className="d-block w-100 text-center mb-3">
              <p className="text-muted text-14 poppins-400 m-0">
                
              {
              currentTab === "hotelsTab" ? (
                <div>
                  <p>Hotels, Guest Houses, and Guest Rooms.</p>
                </div>
              ) : currentTab === "buses" ? (
                <div>
                  <p>Public Transportation and Tours.</p>
                </div>
              ) : currentTab === "flightTab" ? (
                <div>
                  <p>Book flights for your travel plans.</p>
                </div>
              ) : (
                <div>
                  <p>Select a tab to see details.</p>
                </div>
              )
            }

                <span className="d-block w-100 mt-1"> Coming soon! </span>
              </p>
            </div>
            <div className="d-block w-100 text-center border-0 px-4">
              <button
                data-bs-dismiss="modal"
                className="bg-blue3 border-0 rounded-3 px-4 w-100 py-2 text-white text-15 poppins-500"
                onClick={handleCloseClick}
              >
                <span className="d-inline-block px-5 mx-1 py-1"> Close </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

 
    </>
     
  );
}
export default CommingSoonComponent;
