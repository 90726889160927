import React, { useEffect, useState, useRef } from "react";
import { fetchBlogData } from '../../services/blogservice';
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { Link } from "react-router-dom";
import "../../App.css";
import { Helmet } from "react-helmet";
import HeaderComponent from '../HeaderComponent/HeaderComponent.js';
import FooterComponent from '../FooterComponent/FooterComponent.js';
import dayjs from 'dayjs';

function BlogComponent() {
  const [blogData, setBlogData] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const isLoadingRef = useRef(false); 
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = date.toLocaleString('en-US', { month: 'short' });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); // Change loading state to false after 2 seconds
    }, 2000); // 2 seconds delay

    return () => clearTimeout(timer); // Clear the timer if the component unmounts
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const blogArray = await fetchBlogData(currentPage,currentPage);
         setBlogData(blogArray.blogs.data);
         setTotalPages(blogArray.blogs.last_page);
      } catch (error) {
        // Handle error
      }
      finally {
        setIsLoading(false);
      }
    };

    if (!isLoadingRef.current && !pageLoading) {
      isLoadingRef.current = true;
      fetchData();
    } 
  }, [currentPage]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const renderedBlogs = blogData.map((blog, index) => {
    const formattedDate = dayjs(blogData.created_at).format('DD MMMM YYYY');
    return (
      <div className="col-md-4 col-lg-4 col-sm-6 col-12 blog-item-single" key={blog.id}>
        <div className="music-event-block">
          <Link
            to={`/blog-detail/${blog.id}/${blog.title
              .replace(/\s+/g, "-")
              .toLowerCase()}`}
            title={`${blog.title}`}
          >
            <img src={blog.imagePath + blog.image} alt={blog.image} />
            <div style={{ maxWidth: "100%" }}>
              <h4 className="text-dark blogTitle">{blog.title}</h4>
            </div>
            <h6 className="m-b-15">
              <div className="card-category-b col-yellow1">
                <span style={{ marginLeft: 8 }}>
                  <i className="fa fa-sitemap mt-6 float-left" />
                  {blog.category.name}
                </span>
                <span className="date-b">
                  <i className="fa fa-calendar" style={{ marginRight: 8 }} />
                  
                  {formatDate(blog.created_at)}
                </span>
              </div>
            </h6>
          </Link>
        </div>
      </div>
    );
  });
  return (
    <>
    <Helmet>
    <link href="/frontend/css/bootstrap.min.css" rel="stylesheet" />
  </Helmet>
  {loading ? (
        <section className="pad-top-20 pad-bot-80" id="loaderSection">
          <div className="container3 blog-mobile-container">
            <div className="block-element m-b-30">
            </div>
            <div className="block-element loader-mobile-screen" style={{ marginTop: '80px' }}>
              <LoadingSpinner />
            </div></div>
        </section>
      ) : (
        <>
     <HeaderComponent/>
    <section className="pad-top-60 pad-bot-60">
      <div className="container">
      <div className="  blog-mobile-container">
      <div className="block-element section-block1  bg-sec4">
        <div className="block-element m-b-30">
          <div style={{ display: "flex" }}>
            <h4 className="title-text7 text-dark mob-text-center"> Blogs </h4>
          </div>
        </div>
        <div className="block-element">
          <div>
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                <div className="row" id="blog-data">
                  {renderedBlogs}
                </div>
                {totalPages > 1 && (
                  <nav aria-label="Page navigation">
                    <ul className="pagination justify-content-center">
                      <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>&laquo; Previous</button>
                      </li>
                      {[...Array(totalPages).keys()].map((page) => (
                        <li key={page + 1} className={`page-item ${currentPage === page + 1 ? 'active' : ''}`}>
                          <button className="page-link" onClick={() => handlePageChange(page + 1)}>{page + 1}</button>
                        </li>
                      ))}
                      {currentPage < totalPages && (
                        <li className="page-item">
                          <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>Next &raquo;</button>
                        </li>
                      )}
                    </ul>
                  </nav>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
      </div>

  </section>
  <FooterComponent/>
  </>
      )}

  </>
  );
}

export default BlogComponent;
