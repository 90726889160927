import React, { useState, useEffect, useRef } from "react";
import NewHeader from '../HeaderComponent/NewHeader.js';
import FooterComponent from '../FooterComponent/FooterComponent.js';
import { Helmet } from "react-helmet";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import {
  transfer_ticket,
} from "../../services/mytikketservice";
function TransferringComponent() {
  const [loading, setLoading] = useState(true);
  const [showModal, setshowModal] = useState(false);
  const authToken = localStorage.getItem("authToken");
  const navigate = useNavigate();
  const [eventData, setEventData] = useState([]);
  const [TicketData, setTicketData] = useState([]);
  const transferEvent = localStorage.getItem("transfer_event");
  const transferTicket = localStorage.getItem("transfer_ticket");
  const [buttonloading, setbuttonloading] = useState(false);
  useEffect(() => {
    if (transferEvent) {
      const parsedEventData = JSON.parse(transferEvent);
      setEventData(parsedEventData);
    }

  }, [transferEvent]);

  useEffect(() => {

    if (transferTicket) {
      const parsedTicketData = JSON.parse(transferTicket);
      setTicketData(parsedTicketData);
    }
  }, [transferTicket]);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);
  const [user, setUser] = useState([]);
  useEffect(() => {

    const storedUser = localStorage.getItem('selectedUser');
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUser(parsedUser);
    } else {
      navigate('user/my-tickets');
    }
  }, [])

  const handleCloseModal = () => {
    setshowModal(false);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString(); // Format date as per your requirement
  };
  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    const options = { hour: '2-digit', minute: '2-digit', hour12: true };
    return date.toLocaleTimeString('en-US', options).toUpperCase();
};
  
  const handleCannel = () => {
    navigate('/user/my-tickets')
  }
  const handleConfirmTransfer = () => {
    // setshowModal(true);
    // return false;
    setbuttonloading(true);
    Swal.fire({ 
      title: 'Confirm Transfer',
      text: 'Are you sure you want to transfer the ticket?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Transfer it!',
      cancelButtonText: 'Cancel'
    }).then(async (result) => { // Make the callback function async
      if (result.isConfirmed) {
        const transfer_order_id = localStorage.getItem('transfer_order_id');
        const selectedTicket = JSON.parse(localStorage.getItem('selectedTicket'));

        console.log(transfer_order_id, selectedTicket);

        try {
          
          const resp = await transfer_ticket(
            authToken,
            transfer_order_id,
            selectedTicket,
            user.id
          );
          if (resp.success) {
            localStorage.removeItem("transfer_order_id");
            localStorage.removeItem("selectedTicket");
            localStorage.removeItem("selectedUser"); 
            localStorage.removeItem("transfer_ticket");
            localStorage.removeItem("transfer_event");
             
            setshowModal(true);
          }
          else {
          console.log(resp.msg,'resp.msg');
            Swal.fire({
              icon: 'error',
              title: 'Transfer Failed',
              text: resp.msg // Assuming the error message is provided in resp.msg
            });
          }
        } catch (error) {
          console.error(error);
        } finally {
          // Step 3: Set loading state to false after the transfer process completes
          setbuttonloading(false);
        }
      } else {
        // If the user cancels, also set loading state to false
        setbuttonloading(false);
      }
    });
  };



  return (
    <>

      <Helmet>
        <link href="/New-css/bootstrap.min.css" rel="stylesheet" />
        <link href="/New-css/tranferanimate.css" rel="stylesheet" />
        <link href="/New-css/tranfercustom.css" rel="stylesheet" />
        <link href="/New-css/tranferslick-theme.css" rel="stylesheet" />
        <link href="/New-css/tranferslick.css" rel="stylesheet" />
      </Helmet>


      {loading ? (
        <section className="pt-lg-5 pt-md-5 pt-3" id="loaderSection">
          <div className="container3">
            <div className="block-element m-b-30">
            </div>
            <div className="block-element loader-mobile-screen" style={{ marginTop: '80px' }}>
              <LoadingSpinner />
            </div></div>
        </section>
      ) : (
        <>
          <NewHeader />
          <div>
            <section className="pt-lg-5 pt-md-5 pt-3 bg-silver2">
              <div className="container">
                <div className="d-block w-100 py-2">
                  <h3 className="mt-0 mb-2 pt-0 pb-1 text-black text-29 mob-text-25 poppins-700"> Transfer Ticket </h3>
                  <h5 className="text-black text-15 d-flex align-items-center justify-content-start">
                  <Link to="/" id="breadcrumbs"> Home </Link>
                    <i className="fa fa-solid fa-circle text-6 text-muted ms-2 me-2" />
                    <Link to="/user/my-tickets" id="breadcrumbs"> My Tickets</Link>
                    <i className="fa fa-solid fa-circle text-6 text-muted ms-2 me-2" />
                    <Link to="/transfer-ticket" id="breadcrumbs"> Transfer Tickets </Link>
                  </h5>
                </div>
              </div>
            </section>
            
            <section className="pt-lg-5 pt-md-5 pt-2 pb-5 bg-silver2">
              <div className="container">
                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-4">    <div className="d-block w-100 position-relative">
                      <div className="d-block w-100 bg-white rounded-4 mb-lg-0 mb-md-0 mb-4 shadow-normal overflow-hidden">
                        <div className="d-block position-realtive">
                          
                          {eventData && eventData.imagePath && (
                            <img src={eventData.imagePath + (eventData.image || '')} className="w-100" id="TranferImG" />
                          )}
                          {!eventData && (
                            <img src="/images/event-1.png" className="w-100" />
                          )}
                        </div>
                        <div className="d-block w-100 px-3">
                          <div className="d-block w-100 px-3 pt-2 pb-2 border-top-0 border-start-0 border-end-0 border-bottom border-dashed border-color-silver">
                            <div className="d-flex align-items-center justify-content-between w-100">
                              <div className="d-inline-block">
                                <h3 className="text-black text-18 poppins-700"> {eventData && eventData.name || 'Event Name'}</h3>
                              </div>
                              <div className="d-inline-block">
                                <span className="text-muted text-15 poppins-500">#{eventData && eventData.id || 'id'}</span>
                              </div>
                            </div>
                            <div className="d-flex flex-wrap align-items-start justify-content-start">
                              <div className="d-flex align-items-center justify-content-start me-2 mb-1">
                                <img src="/images/date-icon1.png" className="me-1" />
                                <span className="text-muted text-14"> {eventData && eventData.start_time && formatDate(eventData.start_time)} - {eventData && eventData.end_time && formatDate(eventData.end_time)}</span>
                              </div>
                              <div className="d-flex align-items-center justify-content-start me-2 mb-1">
                                <img src="/images/clock-icon1.png" className="me-1" />
                                <span className="text-muted text-14">
                                  {eventData && eventData.start_time && formatTime(eventData.start_time)} - {eventData && eventData.end_time && formatTime(eventData.end_time)}
                                </span>
                              </div>
                              <div className="d-flex align-items-center justify-content-start me-2 mb-1">
                                <img src="/images/map-icon1.png" className="me-1" />
                                <span className="text-muted text-14"> {eventData && eventData.address || ''}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-block w-100 px-3">
                          <div className="d-block w-100 px-3 pt-2 pb-2 border-top-0 border-start-0 border-end-0 border-bottom border-dashed border-color-silver">
                            <div className="d-flex align-items-center justify-content-between w-100">
                              <div className="d-inline-block">
                                <h3 className="col-green text-22 poppins-700"> Transferring To</h3>
                              </div>
                              <div className="d-inline-block">
                                <span className="col-green text-15 poppins-500">#{TicketData && TicketData.id || 'id'}</span>
                              </div>
                            </div>
                            <div className="d-flex flex-wrap align-items-start justify-content-start">
                              <div className="d-flex align-items-center justify-content-start me-2 mb-1">
                                <img src="/images/date-icon1.png" className="me-1" />
                                <span className="text-muted text-14">{eventData && eventData.start_time && formatDate(eventData.start_time)} - {eventData && eventData.end_time && formatDate(eventData.end_time)}</span>
                              </div>
                              <div className="d-flex align-items-center justify-content-start me-2 mb-1">
                                <img src="/images/clock-icon1.png" className="me-1" />
                                <span className="text-muted text-14">                  {eventData && eventData.start_time && formatTime(eventData.start_time)} - {eventData && eventData.end_time && formatTime(eventData.end_time)}</span>
                              </div>
                              <div className="d-flex align-items-center justify-content-start me-2 mb-1">
                                <img src="/images/map-icon1.png" className="me-1" />
                                <span className="text-muted text-14">{eventData && eventData.address || ''}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-block w-100 px-3 py-3">
                          <div className="d-block w-100 text-center mb-4">
                            <img src={user.imagePath + user.image} className="custom-image5" />
                          </div>
                          <div className="d-block w-100 text-center mb-4">
                            <p className="mt-0 mb-2 col-blue1 poppins-500 text-15"> Processing... </p>
                            <h5 className="text-muted text-16 poppins-400 m-0"> Transferring To  <b className="poppins-700 ms-1 text-18 text-black">  {user.name}</b> </h5>
                          </div>
                          <div className="d-block w-100 px-4">
                            <button className="custom-btn3 rounded-3 mb-3 w-100" onClick={handleConfirmTransfer} disabled={buttonloading}>
                            {buttonloading ? 'Transferring...' : 'Confirm Transfer'}
                            </button>
                            <button className="custom-btn2 rounded-3 w-100"  onClick={handleCannel}>
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div></div>
                  <div className="col-md-4"></div>
                  {/* <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                    <div className="d-block w-100 position-relative">
                      <div className="d-block w-100 bg-white rounded-4 mb-lg-0 mb-md-0 mb-4 shadow-normal overflow-hidden">
                        <div className="d-block position-realtive">
                          
                          {eventData && eventData.imagePath && (
                            <img src={eventData.imagePath + (eventData.image || '')} className="w-100" id="TranferImG" />
                          )}
                          {!eventData && (
                            <img src="/images/event-1.png" className="w-100" />
                          )}
                        </div>
                        <div className="d-block w-100 px-3">
                          <div className="d-block w-100 px-3 pt-4 pb-2 border-top-0 border-start-0 border-end-0 border-bottom border-dashed border-color-silver">
                            <div className="d-flex align-items-center justify-content-between w-100">
                              <div className="d-inline-block">
                                <h3 className="text-black text-22 poppins-700"> {eventData && eventData.name || 'Event Name'}</h3>
                              </div>
                              <div className="d-inline-block">
                                <span className="text-muted text-15 poppins-500">#{eventData && eventData.id || 'id'}</span>
                              </div>
                            </div>
                            <div className="d-flex flex-wrap align-items-start justify-content-start">
                              <div className="d-flex align-items-center justify-content-start me-2 mb-1">
                                <img src="/images/date-icon1.png" className="me-1" />
                                <span className="text-muted text-14"> {eventData && eventData.start_time && formatDate(eventData.start_time)} - {eventData && eventData.end_time && formatDate(eventData.end_time)}</span>
                              </div>
                              <div className="d-flex align-items-center justify-content-start me-2 mb-1">
                                <img src="/images/clock-icon1.png" className="me-1" />
                                <span className="text-muted text-14">
                                  {eventData && eventData.start_time && formatTime(eventData.start_time)} - {eventData && eventData.end_time && formatTime(eventData.end_time)}
                                </span>
                              </div>
                              <div className="d-flex align-items-center justify-content-start me-2 mb-1">
                                <img src="/images/map-icon1.png" className="me-1" />
                                <span className="text-muted text-14"> {eventData && eventData.address || ''}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-block w-100 px-3">
                          <div className="d-block w-100 px-3 pt-4 pb-2 border-top-0 border-start-0 border-end-0 border-bottom border-dashed border-color-silver">
                            <div className="d-flex align-items-center justify-content-between w-100">
                              <div className="d-inline-block">
                                <h3 className="col-green text-22 poppins-700"> Transferring To</h3>
                              </div>
                              <div className="d-inline-block">
                                <span className="col-green text-15 poppins-500">#{TicketData && TicketData.id || 'id'}</span>
                              </div>
                            </div>
                            <div className="d-flex flex-wrap align-items-start justify-content-start">
                              <div className="d-flex align-items-center justify-content-start me-2 mb-1">
                                <img src="/images/date-icon1.png" className="me-1" />
                                <span className="text-muted text-14">{eventData && eventData.start_time && formatDate(eventData.start_time)} - {eventData && eventData.end_time && formatDate(eventData.end_time)}</span>
                              </div>
                              <div className="d-flex align-items-center justify-content-start me-2 mb-1">
                                <img src="/images/clock-icon1.png" className="me-1" />
                                <span className="text-muted text-14">                  {eventData && eventData.start_time && formatTime(eventData.start_time)} - {eventData && eventData.end_time && formatTime(eventData.end_time)}</span>
                              </div>
                              <div className="d-flex align-items-center justify-content-start me-2 mb-1">
                                <img src="/images/map-icon1.png" className="me-1" />
                                <span className="text-muted text-14">{eventData && eventData.address || ''}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-block w-100 px-3 py-3">
                          <div className="d-block w-100 text-center mb-4">
                            <img src={user.imagePath + user.image} className="custom-image5" />
                          </div>
                          <div className="d-block w-100 text-center mb-4">
                            <p className="mt-0 mb-2 col-blue1 poppins-500 text-15"> Processing... </p>
                            <h5 className="text-muted text-16 poppins-400 m-0"> Transferring To  <b className="poppins-700 ms-1 text-18 text-black">  {user.name}</b> </h5>
                          </div>
                          <div className="d-block w-100 px-4">
                            <button className="custom-btn3 rounded-3 mb-3 w-100" onClick={handleConfirmTransfer} disabled={buttonloading}>
                            {buttonloading ? 'Transferring...' : 'Confirm Transfer'}
                            </button>
                            <button className="custom-btn2 rounded-3 w-100"  onClick={handleCannel}>
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>


              {showModal && (
                <div
                  className={`modal fade modal-open  show ${showModal ? "d-block modal-open" : ""
                    }`}
                  id="staticBackdrop"
                  style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }} // Black backdrop
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabindex="-1"
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-normal">
                    <div className="modal-content">
                      <div className="modal-body p-0">
                        <div className="d-block w-100 position-relative">
                          <div className="d-block w-100 bg-white rounded-4 mb-lg-0 mb-md-0 mb-4 shadow-normal overflow-hidden">
                            <div className="d-block position-realtive">
                              <span className="px-5 py-2 text-16 bg-purple rounded-3 text-white poppins-700 position-absolute top-0 start-0 mt-3 ms-3">
                                LUXURY
                              </span>
                              {eventData && eventData.imagePath && (
                                <img src={eventData.imagePath + (eventData.image || '')} className="w-100" id="TranferImG" />
                              )}
                              {!eventData && (
                                <img src="/images/event-1.png" className="w-100" />
                              )}
                            </div>
                            <div className="d-flex align-items-center mt-3 justify-content-center w-100">
                              <span className="text-black poppins-500 text-14 d-flex w-100 align-items-center justify-content-center">
                                <img src="/images/ticket-icon2.png" className="me-2 d-none" />
                                {/* 72 Tickets Available */}
                              </span>
                            </div>
                            <div className="d-block w-100 px-3">
                              <div className="d-block w-100 px-3 pt-2 pb-2 border-top-0 border-start-0 border-end-0 border-bottom border-dashed border-color-silver">
                                <div className="d-flex align-items-center justify-content-between w-100">
                                  <div className="d-inline-block">
                                    <h3 className="text-black text-18 poppins-700">
                                      {eventData && eventData.name || 'Event Name'}
                                    </h3>
                                  </div>
                                  <div className="d-inline-block">
                                    <span className="text-muted text-14 poppins-500">
                                      #{eventData && eventData.id || 'id'}
                                    </span>
                                  </div>
                                </div>
                                <div className="d-flex flex-wrap align-items-start justify-content-start">
                                  <div className="d-flex align-items-center justify-content-start me-2 mb-1">
                                    <img src="/images/date-icon1.png" className="me-1" />
                                    <span className="text-muted text-14"> {eventData && eventData.start_time && formatDate(eventData.start_time)} - {eventData && eventData.end_time && formatDate(eventData.end_time)}</span>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-start me-2 mb-1">
                                    <img src="/images/clock-icon1.png" className="me-1" />
                                    <span className="text-muted text-14">
                                      {eventData && eventData.start_time && formatTime(eventData.start_time)} - {eventData && eventData.end_time && formatTime(eventData.end_time)}
                                    </span>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-start me-2 mb-1">
                                    <img src="/images/map-icon1.png" className="me-1" />
                                    <span className="text-muted text-14">
                                      {eventData && eventData.address || ''}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-block w-100 px-3">
                              <div className="d-block w-100 px-3 pt-2 pb-2 border-top-0 border-start-0 border-end-0 border-bottom border-dashed border-color-silver">
                                <div className="d-flex align-items-center justify-content-between w-100">
                                  <div className="d-inline-block">
                                    <h3 className="col-green text-22 poppins-700">

                                      Transferred To
                                    </h3>
                                  </div>
                                  <div className="d-inline-block">
                                    <span className="col-green text-15 poppins-500">

                                     #{TicketData && TicketData.id || 'id'}
                                    </span>
                                  </div>
                                </div>
                                <div className="d-flex flex-wrap align-items-start justify-content-start">
                                  <div className="d-flex align-items-center justify-content-start me-2 mb-1">
                                    <img src="/images/date-icon1.png" className="me-1" />
                                    <span className="text-muted text-14">{TicketData && TicketData.ticket_start_time && formatDate(TicketData.ticket_start_time)} - {TicketData && TicketData.ticket_end_time && formatDate(TicketData.ticket_end_time)}</span>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-start me-2 mb-1">
                                    <img src="/images/clock-icon1.png" className="me-1" />
                                    <span className="text-muted text-14">

                                    {TicketData && TicketData.ticket_start_time && formatTime(TicketData.ticket_start_time)} - {TicketData && TicketData.ticket_end_time && formatTime(TicketData.ticket_end_time)}

                                    </span>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-start me-2 mb-1">
                                    <img src="/images/map-icon1.png" className="me-1" />
                                    <span className="text-muted text-14">

                                    {eventData && eventData.address || ''}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-block w-100 ">
                              <div className="d-block w-100 text-center px-3 py-1">
                                <img
                                  src="/images/success-check-icon.png"
                                  className="custom-image5"
                                />
                              </div>
                              <div className="d-block w-100 text-center  px-3 py-1">
                                <p className="mt-0 mb-1 text-muted poppins-400 text-16">

                                  You have successfully transferred
                                </p>
                                <h5 className="text-muted text-18 poppins-400 m-0">

                                  Your Ticket To 
                                  <b className="ms-1 poppins-700 text-16 text-black">

                                   {user.name}
                                  </b>
                                </h5>
                              </div>
                              <div className="d-block w-100 position-relative">
                                <img
                                  src="images/line-1.png"
                                  className="position-relative z-index99"
                                />
                                <span className="border-top-0 border-start-0 border-end-0 border-bottom border-dashed border-color-silver w-100 d-block position-absolute top-0 start-0 mt-3 pt-1">

                                </span>
                              </div>
                              <div className="d-block w-100 px-3 py-1"> 
                                <div className="d-block w-100 px-4 pb-2">
                                  <Link className="custom-btn3 text-center rounded-3 w-100 " to="/user/my-tickets">
                                    Back to My Tickets
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}


            </section>
          </div>

          <FooterComponent />
        </>
      )}
    </>

  );
}
export default TransferringComponent
