import React, { useState, useEffect } from 'react';

function CategoryFilterComponent({ category, onChangeCate, selectedCategories,mainIndex }) {

  const [checked, setChecked] = useState(selectedCategories.includes(category.id));

  useEffect(() => {
    setChecked(selectedCategories.includes(category.id));
  }, [selectedCategories, category.id]);

  useEffect(() => {
    if (selectedCategories.length === 0) {
      onChangeCate([0]);
    }
  }, [selectedCategories, onChangeCate]);

  const handleChangeCate = (id) => {
    let updatedSelectedCategories;

    if (id === 0) {
      const isChecked = !checked;
      setChecked(isChecked);
      updatedSelectedCategories = isChecked ? [0] : [];
    } else {
 
      const isChecked = !selectedCategories.includes(id);
      if (isChecked) {

        updatedSelectedCategories = selectedCategories.includes(0)
          ? [id]
          : [...selectedCategories, id];
      } else {
  
        updatedSelectedCategories = selectedCategories.filter((categoryId) => categoryId !== id);
      }
    }

    onChangeCate(updatedSelectedCategories);
  };

  return (
    <div className="checkbox-style2 d-flex w-100 align-items-center justify-content-between mb-1"  >
      <label className="text-black text-14 me-2 w-100" htmlFor={category.name}>
        {category.name}
        <b className="poppins-600 text-14 text-muted ml-1">
          ({category.event_count})
        </b>
      </label>
      <input
        className="m-0"
        type="checkbox"
        id={category.name}
        name={category.name}
        checked={checked}
        onChange={() => handleChangeCate(category.id)}
      />
    </div>
  );
}

export default CategoryFilterComponent;
