import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";
import { registerUser } from "../services/authService";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Swal from "sweetalert2";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { getCountryCallingCode } from "react-phone-number-input/input";
import { useForm, Controller } from "react-hook-form";
import { Helmet } from "react-helmet";

function Register() {
  const schema = yup.object().shape({
    first_name: yup.string().required("First Name is required"),
    last_name: yup.string().required("Last Name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    phone: yup.string().required("Phone Number is required"),
    password: yup.string().required("Password is required"),
  });

  const [loading, setLoading] = useState(false);
  const [Loading, setloading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  

  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+1");
  const defaultCountry = "US";
  const handleCountryChange = (country) => {
    if (country) {
      const countryCallingCode = getCountryCallingCode(country);
      setCountryCode("+" + countryCallingCode);
    } else {
      setCountryCode("");
    }
  };

  const {
    control,
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setloading(false); // Change loading state to false after 2 seconds
    }, 2000); // 2 seconds delay

    return () => clearTimeout(timer); // Clear the timer if the component unmounts
  }, []);
  const [showPassword, setShowPassword] = useState(false);
  const [ConPassword, setConwPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibilityConpass = () => {
    setConwPassword(!ConPassword);
  };
  const onSubmit = async (formData) => {
    setLoading(true);
    setError("");

    try {
      const responseData = await registerUser(formData);
      setLoading(false);
      reset();
      setPhoneNumber([]);
      Swal.fire({
        title: "Success",
        text: "Registration successful!",
        icon: "success",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          // navigate('/user/login');
        }
      });
      return responseData;
    } catch (error) {
      setLoading(false);
      let errorMessage =
        "An error occurred while submitting the role. Please try again.";
      if (error.response && error.response.data && error.response.data.error) {
        const errorData = error.response.data.error;
        if (typeof errorData === "string") {
          errorMessage = errorData;
        } else if (typeof errorData === "object") {
          // Check if errorData.email is an array and join its elements if it is
          errorMessage = Array.isArray(errorData.email)
            ? errorData.email.join("\n")
            : "An unexpected error occurred.";
        }
      }

      Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#a23896",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
        showCloseButton: false, // Ensure the close button is shown
      });

      console.error("Registration failed:", error);
      if (error.response && error.response.status === 422) {
        // 422 Unprocessable Entity
        console.log("Error details:", error.response.data);
      } else {
        // Handle other errors
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          const errorsArray = Object.entries(error.response.data.error).flatMap(
            ([key, value]) => value.map((v) => `${key}: ${v}`)
          );
          console.log(errorsArray);
        } else {
          // Fallback error message
          console.log("An unexpected error occurred.");
        }
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Helmet>
        <link href="/styles/bootstrap.min.css" rel="stylesheet" />
        <link href="/styles/animate.css" rel="stylesheet" />
        <link href="/styles/custom.css" rel="stylesheet" />
        <link href="/styles/slick-theme.css" rel="stylesheet" />
        <link href="/styles/slick.css" rel="stylesheet" />
      </Helmet>
      {Loading ? (
        <section className="pad-top-20 pad-bot-80">
          <div className="container3">
            <div className="block-element m-b-30"></div>
            <div
              className="block-element loader-mobile-screen"
              style={{ marginTop: "80px" }}
            >
              <LoadingSpinner />
            </div>
          </div>
        </section>
      ) : (
        <>
          <div>
            <section
              className="all-layout w-full h-screen bg-cover bg-center bg-no-repeat"
              id="loginHeight"
            >
              <div className="d-flex align-items-center justify-content-center h-100">
                <div
                  className="bg-contain bg-center p-4 p-md-5 h-80 mx-auto"
                  id="loginSection"
                >
                  <div className="d-flex justify-content-center align-items-center h-100 p-3 p-md-5">
                    <div className="bg-white p-4 p-md-5 rounded-6 shadow-lg formstyleregister">
                      <div className="text-center mb-4 mb-md-2">
                        <a href="/"><img src="/images/tikkets-dark-logo.png" alt="Logo" /></a>
                        <h2 className="h4 font-weight-bold text-secondary">
                          Sign Up
                        </h2>
                      </div>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group mb-1">
                          <label htmlFor="first_name" className="form-label">
                            First name
                          </label>
                          <input
                            type="text"
                            {...register("first_name")}
                            placeholder="First name"
                            name="first_name"
                            className={`form-control ${
                              errors.first_name ? "is-invalid" : ""
                            }`}
                          />
                          {errors.first_name && (
                            <div className="invalid-feedback invlid-eroor">
                              {errors.first_name.message}
                            </div>
                          )}
                        </div>
                        <div className="form-group mb-1">
                          <label htmlFor="last_name" className="form-label">
                            Last name
                          </label>
                          <input
                            type="text"
                            {...register("last_name")}
                            placeholder="Last name"
                            name="last_name"
                            className={`form-control ${
                              errors.last_name ? "is-invalid" : ""
                            }`}
                          />
                          {errors.last_name && (
                            <div className="invalid-feedback invlid-eroor">
                              {errors.last_name.message}
                            </div>
                          )}
                        </div>
                        <div className="form-group mb-1">
                          <label htmlFor="email" className="form-label">
                          Email
                          </label>
                          <input
                            type="email"
                            {...register("email")}
                            placeholder="Email"
                            name="email"
                            className={`form-control ${
                              errors.email ? "is-invalid" : ""
                            }`}
                          />
                          {errors.email && (
                            <div className="invalid-feedback invlid-eroor">
                              {errors.email.message}
                            </div>
                          )}
                        </div>
                        <div className="form-group mb-1 position-relative">
                          <label htmlFor="password" className="form-label">
                            Phone
                          </label>
                          <div className="position-relative">
                            <Controller
                              name="phone"
                              control={control}
                              render={({ field }) => (
                                <PhoneInput
                                  {...field}
                                  className="form-control"
                                  international
                                  id="TelPhone"
                                  countryCallingCodeEditable={false}
                                  defaultCountry="US"
                                  value={field.value}
                                  onChange={field.onChange}
                                />
                              )}
                            />
                            {errors.phone && (
                              <div
                                className="invalid-feedback invlid-eroor"
                                style={{ display: "block" }}
                              >
                                {errors.phone.message}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="form-group mb-1 position-relative">
                          <label htmlFor="password" className="form-label">
                            Password
                          </label>
                          <div className="position-relative">
                            <input
                              type={showPassword ? "text" : "password"}
                              {...register("password")}
                              placeholder="Password"
                              name="password"
                              id="password"
                              className={`form-control ps-4 pe-5`}
                            />
                            <div
                              className="position-absolute top-50 end-0 translate-middle-y pe-3"
                              onClick={togglePasswordVisibility}
                              style={{
                                cursor: "pointer",
                                top: "10px",
                                right: "10px",
                              }}
                            >
                              {!showPassword ? (
                                <i
                                  className="fa fa-eye-slash"
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                <i className="fa fa-eye" aria-hidden="true"></i>
                              )}
                            </div>
                            {errors.password && (
                              <div className="invalid-feedback invlid-eroor">{errors.password.message}</div>
                            )}
                          </div>
                          
                        </div>
                        <div className="form-group mb-1 position-relative">
                          <label htmlFor="Confirm Password" className="form-label">
                          Confirm Password
                          </label>
                          <div className="position-relative">
                            <input
                              type={ConPassword ? "text" : "password"}
                              placeholder="Confirm Password"
                              name="Con_password"
                              id="Con_password"
                              className={`form-control ps-4 pe-5 `}
                            />
                            <div
                              className="position-absolute top-50 end-0 translate-middle-y pe-3"
                              onClick={togglePasswordVisibilityConpass}
                              style={{
                                cursor: "pointer",
                                top: "10px",
                                right: "10px",
                              }}
                            >
                              {!ConPassword ? (
                                <i
                                  className="fa fa-eye-slash"
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                <i className="fa fa-eye" aria-hidden="true"></i>
                              )}
                            </div>
                          </div>
                        </div>

                        {/* <div className="d-flex justify-content-between align-items-center mb-3">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="keepSignedIn"
                            />
                            <label
                              className="form-check-label qqtap"
                              htmlFor="keepSignedIn"
                            >
                              Keep me signed in
                            </label>
                          </div>
                          <Link
                            to="/user/reset-password"
                            className="text-primary"
                          >
                            Forgot password?
                          </Link>
                        </div> */}

                        <div className="mb-4">
                          <button
                            className="btn btn-primary w-100"
                            disabled={loading}
                          >
                            {loading ? (
                              <div
                                className="spinner-border text-light"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              "Register"
                            )}
                          </button>
                        </div>

                        <div className="text-center">
                          <p className="mb-0">
                            Don't have an account? &nbsp;
                            <Link to="/user/login" className="text-primary ms-2">
                               Sign-in
                            </Link>
                          </p>
                          <p className="mb-0 mt-3">
                            <Link to="/" className="text-primary">
                              Back to Homepage
                            </Link>
                          </p>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
}

export default Register;
