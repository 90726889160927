import React, { useEffect, useState, useRef } from "react";
import { fetchEvents } from '../../services/alleventService';
import { Link } from "react-router-dom";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { Helmet } from "react-helmet";
import HeaderComponent from '../HeaderComponent/HeaderComponent.js';
import FooterComponent from '../FooterComponent/FooterComponent.js';


function AllEventComponent() {
  const isLoadingRef = useRef(false);
  const isFetchingRef = useRef(false); // Ref to track if a request is currently being made
  const [eventData, setEventData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const categoriesFetchedRef = useRef(false);  
  const [pageLoading, setPageLoading] = useState(false);
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchData = async () => {
      if (!isFetchingRef.current) {
        isFetchingRef.current = true; // Set to true when starting a request
        try {
          setIsLoading(true);
          const response = await fetchEvents(currentPage, itemsPerPage);
          console.log(response);
          setEventData(response.data.data.events);
          setTotalPages(response.data.data.pagination.last_page);
        } catch (error) {
          console.error('Error fetching evt data:', error);
        } finally {
          setIsLoading(false);
          isFetchingRef.current = false; // Reset to false when the request is complete
        }
      }
    };

    fetchData();

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    document.body.classList.add('home-bg');

    return () => {
      document.body.classList.remove('home-bg');
    };
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
    <Helmet>
    <link href="/css/bootstrap.min.css" rel="stylesheet" />
  </Helmet> 
<HeaderComponent/>
    <section className="mt-15">
      <div className="container3">
        <div className="block-element content-block2">
          <div className="block-element featured-posts-title">
            <div className="sec-head2">
              <h3 className="col-black"> All Events</h3>
            </div>
          </div>
          <div className="block-element">
            <div>
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <div className="row arrows-1">
                  {eventData.map((event) => (
                    <div
                      className="col-md-4 col-lg-4 col-sm-6 col-12"
                      key={event.id}
                    >
                      <div className="music-event-block">
                        <div className="fav-section">
                          <button
                            style={{ position: "absolute", right: 25 }}
                            type="button"
                            className="btn p-0"
                          >
                            <Link to="/user/login">
                              <i className="fa fa-heart" style={{ fontSize: "20px" }}></i>
                            </Link>
                          </button>
                        </div>
                        <Link
                          to={`/event/${event.id}/${event.name.replace(/\s+/g, "-").toLowerCase()}`}
                        >
                          <div className="my-div">
                            <div className="image-container" style={{ height: 200, width: "100%", overflow: "hidden" }}>
                              <img
                                src={`${event.imagePath}${event.image}`}
                                style={{ objectFit: "cover", }}
                                alt={event.name}
                              />
                            </div>
                          </div>
                        </Link>
                        <div style={{ width: "100%" }}>
                          <Link
                            style={{ width: "100%" }}
                            to={`/event/${event.id}/${event.name.replace(/\s+/g, "-").toLowerCase()}`}
                          >
                            <h4 style={{ color: "black" }}>{event.name}</h4>
                            <p className="col-grey6 multi-line" style={{ color: "white", fontSize: '14px' }}>{event.description}</p>
                            <div className="event-detail">
                              <span className="archive-text1 tt">Category:</span>
                              <span className="archive-text1 col-pink1 ml-1">Events</span>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {totalPages > 1 && (
        <div className="pagination-links">
          <nav aria-label="Page navigation">
            <ul className="pagination justify-content-center">
              <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>&laquo; Previous</button>
              </li>
              {[...Array(totalPages).keys()].map((page) => (
                <li key={page + 1} className={`page-item ${currentPage === page + 1 ? 'active' : ''}`}>
                  <button className="page-link" onClick={() => handlePageChange(page + 1)}>{page + 1}</button>
                </li>
              ))}
              {currentPage < totalPages && (
                <li className="page-item">
                  <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>Next &raquo;</button>
                </li>
              )}
            </ul>
          </nav>
        </div>
      )}
    </section>
    <FooterComponent/>
    </>
  );
}

export default AllEventComponent;
