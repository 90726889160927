import axios from 'axios';
import { API_ENDPOINTS } from './api';

export const fetchEvents = async (page, itemsPerPage) => {
  try {
    const response = await axios.get(`${API_ENDPOINTS.ALL_EVENT}?page=${page}&itemsPerPage=${itemsPerPage}`);
    
    return response;
  } catch (error) {
    throw error;
  }
};
