import React, { useEffect, useState, useRef } from "react";
import "../../App.css";
import { Link, useParams } from "react-router-dom";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { Helmet } from "react-helmet";
import HeaderComponent from '../HeaderComponent/HeaderComponent.js';
import FooterComponent from '../FooterComponent/FooterComponent.js';
import {fetchPages_Single } from "../../services/homeService";
function PageComponent() {
  
  const [isLoading, setIsLoading] = useState(false);
  const { id, title, description } = useParams();
  const [pageLoading, setPageLoading] = useState(false);
  const isLoadingRef = useRef(false);

  const { slug } = useParams();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    document.body.classList.add("home-bg");

    // Clean up the class when the component unmounts
    return () => {
      document.body.classList.remove("home-bg");
    };
  }, []);

  const [pages, setPages] = useState([]);
  useEffect(() => {
     
    const fetchPagesSingle = async () => {
      setIsLoading(true);
      try {
         
        const data = await fetchPages_Single(slug);
       
        setPages(data.page);
         setIsLoading(false);
      } catch (error) {
        console.error("Error fetching home data:", error);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPagesSingle();
 
  }, [slug]);


  if (isLoading) {
    return (
      <section className="pad-top-20 pad-bot-80" id="loaderSection">
        <div className="container3">
          <div className="block-element m-b-30">
          </div>
          <div className="block-element loader-mobile-screen" style={{ marginTop: '80px' }}>
            <LoadingSpinner />
          </div></div>
      </section>
    );
  }



  return (

    <>
      <Helmet>
        <link href="/css/bootstrap.min.css" rel="stylesheet" />
      </Helmet>
      <HeaderComponent />
      <section className="pad-top-60 pad-bot-60" style={{background:"#f9fcff"}}>
        <div className="container2">
          <div className="block-element m-b-20">
            <Link to="/" className="back-btn1">
              <i className="fa fa-angle-left"> </i> Back
            </Link>
          </div>
          <div className="block-element m-b-10">
             
          {pages && (
              <div className="textual-sec1" dangerouslySetInnerHTML={{ __html: pages.description }} />
            )}
          </div>
          
        </div>
      </section>
      <FooterComponent />
    </>
  );
}

export default PageComponent;
