import { React, useEffect, useState } from "react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
export default function MyticketsSwiperDesktop({
  onChangeCate,
  setStatus,
  allCount,
  expiredCount,
  validCount,
  scannedCount,
}) {
 

  const [status, setagainStatus] = useState("valid");
  useEffect(() => {
    handleCategoryClick("valid");
  }, []);
  const handleCategoryClick = (id) => {
    onChangeCate(id);
    setStatus(id);
    setagainStatus(id);
  };

  return (
    <>
      <div className="d-flex slick-slider1 align-items-center justify-content-start swip mx-0">
          
            <button
              className={`px-2 py-1 text-14 d-flex align-items-center justify-content-start border-0 bg-transparent me-2 ${
                status === "valid" ? "active" : ""
              }`}
              onClick={() =>
                handleCategoryClick("valid")
              }
            >
              <b className="bg-green2 col-green2 text-13 px-2 py-1 me-2 rounded-2 text-center d-inline-block">
                {validCount}
              </b>
              Valid
            </button>
            
            <button
              className="px-2 py-1 text-14 d-flex align-items-center justify-content-start border-0 bg-transparent  me-2 d-none"
              onClick={() =>
                handleCategoryClick("transferred")
              }
            >
              <b className="bg-brown2 col-brown2 text-13 px-2 py-1 me-2 rounded-2 text-center d-inline-block">
                {" "}
                4{" "}
              </b>
              Transferred
            </button>
            <button
              className={`px-2 d-none py-1 text-14 d-flex align-items-center justify-content-start border-0 bg-transparent me-2 ${
                status === "scanned" ? "active" : ""
              }`}
              onClick={() =>
                handleCategoryClick("scanned")
              }
            >
              <b className="bg-blue2 col-blue2 text-13 px-2 py-1 me-2 rounded-2 text-center d-inline-block">
                {scannedCount}
              </b>
              Scanned
            </button>
            <button
              className={`px-2 py-1  text-14 d-flex align-items-center justify-content-start border-0 bg-transparent me-2 ${
                status === "expired" ? "active" : ""
              }`}
              onClick={() =>
                handleCategoryClick("expired")
              }
            >
              <b className="bg-red2 col-red2 text-13 px-2 py-1 me-2 rounded-2 text-center d-inline-block">
                {expiredCount}
              </b>
              Expired
            </button> 
            <button
              className={`px-2 py-1 text-14 d-flex align-items-center justify-content-start border-0 bg-transparent me-2 ${
                status === "all" ? "active" : ""
              }`}
              onClick={() => handleCategoryClick("all")}
            >
              <b className="bg-blue2 col-blue2 text-13 px-2 py-1 me-2 rounded-2 text-center d-inline-block">
                {allCount}
              </b>
              All
            </button>
    </div>
    </>
  );
}
