import React, { useState, useEffect } from "react";
import { Link, useNavigate,useLocation  } from "react-router-dom";
import { useOrders } from '../../hooks/SettingContext';
import { checkOutCard } from '../../services/checkoutCardService';
import Swal from 'sweetalert2';
import { Helmet } from "react-helmet";
import NewHeader from '../HeaderComponent/NewHeader.js';
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import FooterComponent from '../FooterComponent/FooterComponent.js';

function CartCheckout() {
  const [eventName, setEventName] = useState("");
  const [eventImage, setEventImage] = useState("");
  const [eventImagePath, setEventImagePath] = useState("");
  const [ticketQuantities, setTicketQuantities] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [ticketitems, setticketitems] = useState([]);
  const { ordersCount, updateOrdersCount } = useOrders();
  const savedDiscountAmount = localStorage.getItem('discountAmount');
  const couponId = localStorage.getItem('couponId');
  const discountAmount = parseFloat(savedDiscountAmount);
  const [Pageloading, setpageLoading] = useState(true);

  useEffect(() => {
    const availableItems = JSON.parse(localStorage.getItem("items"));
    setticketitems(availableItems);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setpageLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    // Retrieve values from localStorage
    const storedEventName = localStorage.getItem("eventName");
    const storedEventImage = localStorage.getItem("eventImage");
    const storedEventImagePath = localStorage.getItem("eventImagePath");

    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      // window.location.href = "/";

    }

    if (storedEventName) {
      setEventName(storedEventName);
    }
    if (storedEventImage) {
      setEventImage(storedEventImage);
    }
    if (storedEventImagePath) {
      setEventImagePath(storedEventImagePath);
    }
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); // Change loading state to false after 2 seconds
    }, 2000); // 2 seconds delay

    return () => clearTimeout(timer); // Clear the timer if the component unmounts
  }, []);
  useEffect(() => {


    window.scrollTo({ top: 0, behavior: "smooth" });
    document.body.classList.add("home-bg1");
    return () => {
      document.body.classList.remove("home-bg1");
    };


  }, []);
  const [tickets, setTickets] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  useEffect(() => {
    const savedTotalPrice = localStorage.getItem("total_Price");
    if (savedTotalPrice) {
      const parsedTotalPrice = parseFloat(savedTotalPrice);
      setTotalPrice(parsedTotalPrice);
    }
  }, []);
  useEffect(() => {
    const storedTicketQuantities = localStorage.getItem("ticketQuantities");
    if (storedTicketQuantities) {
      const parsedTicketQuantities = JSON.parse(storedTicketQuantities);
      setTicketQuantities(parsedTicketQuantities);
    }
  }, []);
  const [formData, setFormData] = useState({
    name: "",
    cardNumber: "",
    date: "",
    cvc: "",
    cart: '',
  });
  const goBack = () => {
    navigate(-1);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const savedDiscountAmount = localStorage.getItem('discountAmount');
    const couponId = localStorage.getItem('couponId');
    const discountAmount = parseFloat(savedDiscountAmount);
    const availableitems = JSON.parse(localStorage.getItem("items"));

    const ticketsJson = {};
    availableitems.forEach(ticket => {
      ticketsJson[ticket.id] = ticket;
    });


    const feee = totalPrice * 7 / 100;
    setFormData(formData => ({
      ...formData,
      [name]: value,
      cart: JSON.stringify(ticketsJson),
      payment: totalPrice + feee,
      coupon_discount: discountAmount,
      tax: 0,
      payment_type: 'STRIPE',
      coupon_id: couponId
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      // Handle the case where the token is missing
      setIsLoading(false);
      Swal.fire({
        title: 'Error!',
        text: 'You are not logged in. Please log in first.',
        icon: 'error',
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/', {
            state: { from: location.pathname }
          });
          localStorage.setItem("checkOutNotAuth", '1');
        }
      });
      return;
    }
   
 
    try {
      const response = await checkOutCard(authToken, formData);
    
      if (response && response.success) {
        localStorage.setItem('order_id', response.data.order_id);
        setIsLoading(true);
        updateOrdersCount(0);
        localStorage.removeItem("items");
        localStorage.removeItem("orders");
        localStorage.removeItem("ordersCount");
        navigate("/thank-you");
    } else {
        setIsLoading(false);
        if (response && response.msg) {
            Swal.fire({
                title: 'Error!',
                text: response.msg,
                icon: 'error',
                confirmButtonText: 'OK'
            });
        } else {
            Swal.fire({
                title: 'Error!',
                text: 'An error occurred. Please try again later.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    }
      
      // if (response.ok) {
      //   console.log(response,'response');
      //   setIsLoading(true);
      //   updateOrdersCount(0);
      //   localStorage.removeItem("items");
      //   localStorage.removeItem("orders");
      //   localStorage.removeItem("ordersCount");

      //   navigate("/thank-you");
      // } else {
      //   setIsLoading(false);
        
      // }
    } catch (error) {
 
      Swal.fire({
          title: 'Error!',
          text: 'Network error occurred.',
          icon: 'error',
          confirmButtonText: 'OK'
      });
    }
    setIsLoading(false);
  };
 
  
const  handleBack=() =>{
    navigate(-1);
}

  return (
   <div>
     <Helmet>
     <link href="/New-css/bootstrap.min.css" rel="stylesheet" />
        <link href="/New-css/animate.css" rel="stylesheet" />
        <link href="/New-css/custom.css" rel="stylesheet" />
        <link href="/New-css/slick-theme.css" rel="stylesheet" />
        <link href="/New-css/slick.css" rel="stylesheet" />
      </Helmet>
      {Pageloading ? (
         <section className="pad-top-20 pad-bot-80 bg-silver2"  id="loaderSection" style={{background:'#F1F4FF'}}>
         <div className="container3">
           <div className="block-element m-b-30">
           </div>
           <div className="block-element loader-mobile-screen" style={{ marginTop: '80px' }}>
             <LoadingSpinner />
           </div></div>
       </section>
      ) : (
        <>
      <NewHeader/>
  <section className="pt-lg-5 pt-md-5 pt-3 bg-silver2">
    <div className="container">
      <div className="d-block w-100 py-2">
        <h3 className="mt-0 mb-2 pt-0 pb-1 text-black text-29 mob-text-25 poppins-700"> Cart Payment </h3>
        <h5 className="text-black text-15 d-flex align-items-center justify-content-start"> 
        <Link to="/" style={{'color':'black'}}> <span> Home </span> </Link>
          <i className="fa fa-solid fa-circle text-6 text-muted ms-2 me-2" />
          <Link to="/cart" style={{'color':'black'}}> <span> Cart </span> </Link>
          <i className="fa fa-solid fa-circle text-6 text-muted ms-2 me-2" />
           
          <Link to="/checkout-card" style={{'color':'black'}}> <span> Payment </span> </Link>
        </h5>
      </div>
    </div>
  </section>
  {/* Breadcrumbs and Title Ends Here */}  
  {/* Cart Payment Content Starts Here */}
  <section className="py-5 bg-silver2">
    <div className="container">
      <div className="d-flex align-items-start justify-content-start flex-wrap">
        <div className="order-checkout-left pe-lg-4 pe-md-4 pe-0">
          <div className="d-block w-100 mb-4">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-8 col-sm-8 col-10">
                <div className="d-flex w-100 align-items-start justify-content-start">
                  <div className="d-inline-block text-center">
                  <i className="fa fa-circle text-10 mb-2 text-muted"></i>
                    <h5 className="text-black m-0 poppins-500 text-15"> Cart </h5>
                  </div>
                  <div className="w-100 pt-2 ps-2 pe-2">
                    <span className="bg-silver3 d-block w-100 line-2 overflow-hidden position-relative">
                      <b className="w-100 p-1 bg-green d-inline-block position-absolute top-0 start-0"> </b>
                    </span>
                  </div>
                  <div className="d-inline-block text-center">
                    <i className="fa fa-check text-24 mb-2 col-green"> </i>
                    <h5 className="text-muted m-0 poppins-500 text-15"> Payment </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-block w-100 mb-3 bg-white rounded-3">
            <div className="d-block w-100 px-3 py-4">
              <h3 className="text-black poppins-600 mt-0 mb-2 text-20"> Payment Options </h3>
            </div>
            <div className="d-block w-100 px-3 py-2">
              <div className="d-block w-100   rounded-2 border-color-silver p-2 mb-3">
                <div className="d-block w-100 pt-3 px-3">
                  <div className="d-block w-100 mb-4">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-inline-block">
                        <div className="d-flex align-items-center justify-content-start">
                          <div className="d-inline-block me-3">
                            <i className="fa fa-check text-white bg-green rounded-circle p-1 text-12"> </i>
                          </div>
                          <div className="d-inline-block">
                            <h4 className="m-0 p-0 text-15 poppins-600 text-black"> Credit / Debit Card </h4>
                            <p className="text-14 text-muted m-0"> We support Mastercard, Visa, Discover and Stripe. </p>
                          </div>
                        </div>
                      </div>
                      <div className="d-lg-inline-block d-md-inline-block d-none">
                        <img src="/images/visa-icon.png" />
                      </div>
                    </div>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 mb-3">
                        <div className="form-field1">
                          <input type="text" placeholder="Name"  value={formData.name}  onChange={handleInputChange} className="field-style1" name="name"    required />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 mb-3">
                        <div className="form-field1">
                          <input type="text" placeholder="Enter Your Card Number" className="field-style1" 
                           name="card_number"
                           onChange={handleInputChange}
                           pattern="[0-9]*"
                           required
                            />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-12 mb-3">
                          <div className="form-field1">
                            <input type="text" placeholder="MM" className="field-style1" 
                                  name="exp_month"
                                  min="1" max="12" // Specify min and max values for month
                                  value={formData.exp_month}
                                  onChange={handleInputChange}
                                  required />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-12 mb-3">
                          <div className="form-field1">
                            <input type="text" placeholder="YYYY" className="field-style1" 
                                  name="exp_year"
                                  min={new Date().getFullYear()} // Set minimum year to the current year
                                  value={formData.exp_year}
                                  onChange={handleInputChange}
                                  required />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-12 mb-3">
                          <div className="form-field1">
                            <input type="number" placeholder="CVV" className="field-style1 no-arrows-number"  
                                  name="cvc"
                                  value={formData.cvc}
                                  onChange={handleInputChange}
                                  required />
                          </div>
                        </div>
                    </div>
                  </form>
                  <div className="d-block w-100 text-end mb-2">
                    <button   
                     className={`custom-btn3 ${isLoading ? 'loading' : ''}`} onClick={handleSubmit}
                     id="payCheck"
                     disabled={isLoading} style={{width:'25%'}}> {isLoading ? (
                      <><i className="fa fa-spinner fa-spin" /> Loading...</>
                    ) : (
                      <>Pay ${totalPrice.toFixed(2)}</>
                    )}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-block w-100 mt-3">
            <button className="text-black border-0 bg-transparent text-14 poppins-600 px-3 py-2 d-flex align-items-center justify-content-start" onClick={handleBack}>
              <i className="fa fa-angle-left text-black text-15 me-2"> </i>
              Back
            </button>
          </div>
        </div>
        <div className="order-checkout-right ps-lg-3 ps-md-3 ps-0  pe-lg-3 pe-md-3 pe-0">
          <div className="d-block w-100 bg-white rounded-3 p-3   mb-3 ">
            <div className="d-block w-100 mb-3">
              <h3 className="text-black text-20 poppins-700 m-0"> Order Summary </h3>
            </div>
            <div className="d-block w-100">
              <table className="w-100">
                <tbody><tr>
                    <td className="text-black pt-2 pb-2 ps-0 pe-0 poppins-400 text-14"> Sub Total </td>
                    <td className="text-black pt-2 pb-2 ps-0 pe-0 poppins-400 text-14 text-end"> ${totalPrice.toFixed(2)} </td>
                  </tr>
                   <tr>
                    <td className="text-black pt-2 pb-2 ps-0 pe-0 poppins-400 text-14"> Discount </td>
                    <td className="col-green pt-2 pb-2 ps-0 pe-0 poppins-400 text-14 text-end"> ${discountAmount.toFixed(2)} </td>
                  </tr>
                 {/* <tr>
                    <td className="text-black pt-2 pb-2 ps-0 pe-0 poppins-400 text-14  border-bottom border-color-silver pb-2"> Tax </td>
                    <td className="text-black pt-2 pb-2 ps-0 pe-0 poppins-400 text-14 text-end  border-bottom border-color-silver pb-2"> $5.79 </td>
                  </tr> */}
                  <tr>
                    <td className="text-black pt-2 pb-2 ps-0 pe-0 poppins-600 text-15"> Total </td>
                    <td className="col-green pt-2 pb-2 ps-0 pe-0 poppins-600 text-15 text-end">${Math.abs(discountAmount-totalPrice).toFixed(2)} 
                      <span className="text-black text-13 d-block w-100 poppins-400 text-end font-italic"> (VAT included if applicable)</span>
                    </td>
                  </tr>
                </tbody></table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* Cart Payment Content Ends Here */}
  <FooterComponent/>
  </>
      )}
</div>

  );
}
export default CartCheckout;
