import React, { useState, useEffect, useRef } from "react";
import NewHeader from '../HeaderComponent/NewHeader.js';
import FooterComponent from '../FooterComponent/FooterComponent.js';
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import Swal from "sweetalert2";
import { Link } from 'react-router-dom';

import {
  User_Search,
} from "../../services/mytikketservice";
function TransferTicketComponent() {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [itemsPerPage, setitemsPerPage] = useState(5);
  const [eventData, setEventData] = useState([]);
  const [ticketData, setTicketData] = useState([]);
  const [currentPage, setCurrentPage] = useState("");
  const [notfount, setnotfound] = useState(0);
  const [userTrue, setuserTrue] = useState(0);
  const [page, setPage] = useState("");
  const [selectedUser, setSelectedUser] = useState([]);
  const authToken = localStorage.getItem("authToken");
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [prevPageUrl, setPrevPageUrl] = useState(null);
  const [from, setfrom] = useState("");
  const [to, setTo] = useState("");
  const [totalRecords, setTotal] = useState("");

  const transferEvent = localStorage.getItem("transfer_event");
  const transferTicket = localStorage.getItem("transfer_ticket");

  const navigate = useNavigate();



  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);





  useEffect(() => {
    if (transferEvent) {
      const parsedEventData = JSON.parse(transferEvent);
      setEventData(parsedEventData);
    }

  }, [transferEvent]);

  useEffect(() => {

    if (transferTicket) {
      const parsedTicketData = JSON.parse(transferTicket);
      setTicketData(parsedTicketData);
    }
  }, [transferTicket]);

  const [searchQuery, setSearchQuery] = useState('');

  // const handleSearchInputChange = (event) => {
  //   const { value } = event.target;
  //   if (value.length > 0) {
  //     setSearchQuery(value);

  //     userSearch(searchQuery);

  //   } else {
  //     setSearchQuery('');
  //   }
  // };

  const handleSearchInputChange = (event) => {
    const { value } = event.target;
   
    setSearchQuery(value);
   
    if (value.length > 0) {
      userSearch(value);
    }
  };

  const [tableLoading, setTableLoading] = useState(false);
  const userSearch = async (searchQuery) => {
    try {
      setTableLoading(true);

      await User_Search(
        authToken,
        searchQuery,
        setUsers,
        setfrom,
        setTo,
        setTotal,
        setPrevPageUrl,
        setNextPageUrl,
        setitemsPerPage,
        itemsPerPage,
        currentPage,
        page,



      );
      setTableLoading(false);

    } catch (error) {
      console.error(error);
    } finally {
      setTableLoading(false);

    }
  };


  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString(); // Format date as per your requirement
  };

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }); // Format time as per your requirement
  };
  const handleItemsPerPageChange = (event) => {
    const newValue = parseInt(event.target.value);
    setitemsPerPage(newValue);
    setCurrentPage("");
    setPage("");

  };


  const hitNext = () => {

    if (nextPageUrl !== null) {
      const regex = /[?&]page=(\d+)/;
      const match = nextPageUrl.match(regex);

      if (match) {
        const pageValue = match[1];

        if (currentPage !== pageValue) {
          setCurrentPage(pageValue);
          setPage(pageValue);
        }
      } else {
        console.log("Page parameter not found in URL.");
      }
    }
  };

  const hitPrev = () => { 
   
    if (prevPageUrl !== null) {
      const regex = /[?&]page=(\d+)/;
      const match = prevPageUrl.match(regex);

      if (match) {
        const pageValue = match[1];
        if (currentPage !== pageValue) {
          setCurrentPage(pageValue);
          setPage(pageValue);
        }
      } else {
        console.log("Page parameter not found in URL.");
      }
    }
  };
  const handleGoBack = () => {
    navigate('/user/my-tickets'); // This navigates back one step in the navigation stack
  };
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    if (!firstRender) {
      // Run your logic here
      userSearch(searchQuery);
    } else {
      // Set first render flag to false
      setFirstRender(false);
    }
  }, [itemsPerPage, currentPage]);

  const handleUserClick = (user) => {


    setSelectedUser(user);
    setuserTrue(1);
  };


  const handleSendTicket = () => {

    if (userTrue === 1) {
      localStorage.setItem('selectedUser', JSON.stringify(selectedUser));

      navigate('/ticket-transferring')
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please select a user first to transfer the ticket!',
      });
    }
  };



  return (
    <>

      <Helmet>
        <link href="/New-css/bootstrap.min.css" rel="stylesheet" />
        <link href="/New-css/tranferanimate.css" rel="stylesheet" />
        <link href="/New-css/tranfercustom.css" rel="stylesheet" />
        <link href="/New-css/tranferslick-theme.css" rel="stylesheet" />
        <link href="/New-css/tranferslick.css" rel="stylesheet" />
      </Helmet>


      {loading ? (
        <section className="pt-lg-5 pt-md-5 pt-3" id="loaderSection">
          <div className="container">
            <div className="block-element m-b-30">
            </div>
            <div className="block-element loader-mobile-screen" style={{ marginTop: '80px' }}>
              <LoadingSpinner />
            </div></div>
        </section>
      ) : (
        <>
          <NewHeader />
          <section className="pt-lg-5 pt-md-5 pt-3 bg-silver2">
            <div className="container">
              <div className="d-block w-100 py-2">
                <h3 className="mt-0 mb-2 pt-0 pb-1 text-black text-29 mob-text-25 poppins-700"> Transfer Ticket </h3>
                <h5 className="text-black text-15 d-flex align-items-center justify-content-start">
                  <Link to="/" id="breadcrumbs">Home</Link>
                  <i className="fa-solid fa-circle text-6 text-muted ms-2 me-2" />
                  <Link to="/user/my-tickets" id="breadcrumbs">My Tickets</Link>
                  <i className="fa-solid fa-circle text-6 text-muted ms-2 me-2" />
                  <Link to="/transfer-ticket" id="breadcrumbs">Transfer Ticket</Link>
                </h5>
              </div>
            </div>
          </section>

          <section className="pt-lg-5 pt-md-5 pt-2 pb-5 bg-silver2">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-lg-none d-md-none d-block mb-3">
                  <span className="text-black poppins-500 text-14 d-flex w-100 align-items-center justify-content-center">
                    <img src="/images/ticket-icon2.png" className="me-2" />
                    72 Tickets Available
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-5 col-md-5 col-sm-12 col-12">
                  <div className="d-block pe-lg-5 pe-md-5  position-relative">
                    <div className="d-block w-100 bg-white rounded-4 mb-lg-0 mb-md-0 mb-4 shadow-normal overflow-hidden">
                      <div className="d-block position-realtive">
                        {/* <span className="px-5 py-2 text-16 bg-purple rounded-3 text-white poppins-700 position-absolute top-0 start-0 mt-3 ms-3">
                          LUXURY
                        </span> */}
                        {eventData && eventData.imagePath && (
                          <img src={eventData.imagePath + (eventData.image || '')} className="w-100" id="TranferImG" />
                        )}
                        {!eventData && (
                          <img src="/images/event-1.png" className="w-100" id="TranferImG" />
                        )}
                      </div>
                      <div className="d-block w-100 px-3">
                        <div className="d-block w-100 px-3 pt-4 pb-2 border-top-0 border-start-0 border-end-0 border-bottom border-dashed border-color-silver">
                          <div className="d-flex align-items-center justify-content-between w-100">
                            <div className="d-inline-block">
                              <h3 className="text-black text-22 poppins-700">{eventData && eventData.name || 'Event Name'}</h3>
                            </div>
                            <div className="d-inline-block">
                              <span className="text-muted text-15 poppins-500">#{eventData && eventData.id || 'id'}</span>
                            </div>
                          </div>
                          <div className="d-flex flex-wrap align-items-start justify-content-start">
                            <div className="d-flex align-items-center justify-content-start me-2 mb-1">
                              <img src="/images/date-icon1.png" className="me-1" />
                              <span className="text-muted text-14">
                                {eventData && eventData.start_time && formatDate(eventData.start_time)} - {eventData && eventData.end_time && formatDate(eventData.end_time)}
                              </span>

                            </div>
                            <div className="d-flex align-items-center justify-content-start me-2 mb-1">
                              <img src="/images/clock-icon1.png" className="me-1" />

                              <span className="text-muted text-14">
                                {eventData && eventData.start_time && formatTime(eventData.start_time)} - {eventData && eventData.end_time && formatTime(eventData.end_time)}
                              </span>

                            </div>
                            <div className="d-flex align-items-center justify-content-start me-2 mb-1">
                              <img src="/images/map-icon1.png" className="me-1" />
                              <span className="text-muted text-14"> {eventData && eventData.address || ''}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-block w-100 px-3 py-3">
                        <button className="d-flex align-items-center justify-content-start border-0 bg-transparent">
                          <img src="/images/ticket-icon2.png" className="me-2" />
                          <span className="text-black text-15 poppins-600"> {ticketData && ticketData.name || ''}  </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 col-md-7 col-sm-12 col-12">
                  <div className="d-block w-100 bg-white rounded-4 shadow-normal">
                    <div className="d-lg-flex d-md-flex d-none w-100 align-items-center justify-content-start bg-silver4" id="Graysection">
                      <div className="nav nav-tabs px-2 border-0" id="nav-tab" role="tablist">
                        <button className="nav-link active nav-btn1 d-none" id="nav-all-tab" data-bs-toggle="tab" data-bs-target="#nav-all" type="button" role="tab" aria-controls="nav-all" aria-selected="true">
                          All
                        </button>
                        <button className="nav-link nav-btn1 d-none" id="nav-active-tab" data-bs-toggle="tab" data-bs-target="#nav-active" type="button" role="tab" aria-controls="nav-active" aria-selected="false">
                          Active
                        </button>
                        <button className="nav-link nav-btn1 d-none" id="nav-sent-tab" data-bs-toggle="tab" data-bs-target="#nav-sent" type="button" role="tab" aria-controls="nav-sent" aria-selected="false">
                          Sent
                        </button>
                        <button className="nav-link nav-btn1 d-none" id="nav-failed-tab" data-bs-toggle="tab" data-bs-target="#nav-failed" type="button" role="tab" aria-controls="nav-failed" aria-selected="false">
                          Failed
                        </button>
                      </div>
                    </div>
                    <div className="d-block w-100 px-3 align-items-center justify-content-start">
                      <div className="d-block w-100 py-3">
                        <form className="bg-white px-3 py-2 border rounded-2 overflow-hidden d-flex w-100 align-items-center justify-content-start">
                          <i className="fa fa-search me-2 text-16 text-muted"> </i>
                          <input
                            type="text"
                            placeholder="Enter the receiver's User ID"
                            className="w-100 lh-lg border-0 outline-0 text-black text-15 border-0" 
                            onChange={handleSearchInputChange}
                            name="search"
                          />
                        </form>
                      </div>
                      <div className="tab-content" id="nav-tabContent">
                        <div className="tab-pane fade active show" id="nav-all" role="tabpanel" aria-labelledby="nav-all-tab">
                          <div className="d-block w-100">
                            {tableLoading ? (
                              <tr>
                                <td className="text-center" colSpan="100%">
                                  <div className="w-100 d-flex justify-content-center align-items-center" id="loaderTableSearch">
                                    <LoadingSpinner />
                                  </div>
                                </td>
                              </tr>
                            ) : (
                              <div>
                              {users.length > 0 && (
                              <table className="w-100">
                                <thead>
                                  
                                    <tr>
                                      <th className="text-muted text-15 poppins-500 py-3 px-3 bg-silver4 d-lg-table-cell d-md-table-cell d-none"> User ID </th>
                                      <th className="text-muted text-15 poppins-500 py-3 px-3 bg-silver4 d-lg-table-cell d-md-table-cell d-none"> Name </th>
                                    </tr>
                                </thead>
                                <tbody>
                                  {users.length > 0 ? (
                                    users.map((user, index) => (
                                      <tr key={index} onClick={() => handleUserClick(user)} style={{ cursor: 'pointer' }} className={user.id === selectedUser.id ? 'orderActive' : ''}>
                                        <td className="px-3 py-2 d-lg-table-cell d-md-table-cell d-none" >
                                          <span className="text-16 text-black d-inline-block">#{user.user_id}</span>
                                        </td>
                                        <td className="px-3 py-2">
                                          <div className="d-flex w-100 align-items-center justify-content-start">
                                            <div className="d-lg-none d-md-none d-inline-block me-3">
                                              <input type="checkbox" name className="checkbox-style1" />
                                            </div>
                                            <div className="d-inline-block me-3">
                                              <img className="custom-image4 rounded-circle" src={user.imagePath + user.image} />
                                            </div>
                                            <div className="d-inline-block">
                                              <h5 className="text-black text-14 poppins-600 m-0">{user.name} {user.last_name}</h5>
                                              <p className="text-muted text-14 mt-1 mb-0 d-lg-none d-md-none d-block">{user.user_id}</p>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    notfount === 1 ? (
                                      <tr>
                                        <td className="text-center" colSpan="100%">
                                          <div className="w-100 d-flex justify-content-center align-items-center">
                                            <h6 className="mt-4">No User found!</h6>
                                          </div>
                                        </td>
                                      </tr>
                                    ) : null
                                  )}
                                </tbody>
                              </table>
                             
                            )}
                             </div>
                            )}

                          </div>
                          {users.length > 0 && (
                          <div className="d-block w-100 px-3 border-top py-3">
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="d-inline-block me-3">
                                <div className="d-flex align-items-center justify-content-start">
                                  <span className="d-inline-block text-14 text-black me-3 justify-content-start"> Rows per page: </span>
                                  <select
                                    value={itemsPerPage}
                                    className="border-0 bg-transparent text-14 text-black"
                                    onChange={handleItemsPerPageChange}
                                  >
                                    <option value={5}>5</option>
                                    <option value={10}>10</option>
                                    <option value={15}>15</option>
                                    <option value={20}>20</option>
                                    <option value={25}>25</option>
                                  </select>
                                </div>
                              </div>
                              <div className="d-inline-block me-3">
                                <span className="text-black text-14 d-inline-block"> {from}-{to} of {totalRecords} </span>
                              </div>
                              <div className="d-inline-block">
                                <div className="d-flex align-items-center justify-content-start">
                                  <button
                                    className="px-2 py-0 bg-transparent border-0"
                                    onClick={hitPrev}
                                  >
                                    <i className="fa fa-angle-left text-black text-14 m-0 p-0"></i>
                                  </button>
                                  <button
                                    className="px-2 py-0 bg-transparent border-0"
                                    onClick={hitNext}
                                  >
                                    <i className="fa fa-angle-right text-black text-14 m-0 p-0"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          )}
                        </div>
                        <div className="tab-pane fade" id="nav-active" role="tabpanel" aria-labelledby="nav-active-tab">
                          <div className="d-block w-100">
                            <table className="w-100">
                              <thead>
                                <tr>
                                  <th className="text-muted text-15 poppins-500 py-3 px-3 bg-silver4 d-lg-table-cell d-md-table-cell d-none"> User ID </th>
                                  <th className="text-muted text-15 poppins-500 py-3 px-3 bg-silver4 d-lg-table-cell d-md-table-cell d-none"> Name </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="px-3 py-2">
                                    <span className="text-16 text-black d-inline-block"> 12345 </span>
                                  </td>
                                  <td className="px-3 py-2">
                                    <div className="d-flex w-100 align-items-center justify-content-start">
                                      <div className="d-inline-block me-3">
                                        <img className="custom-image4 rounded-circle" src="/images/avatar-1.png" />
                                      </div>
                                      <div className="d-inline-block">
                                        <h5 className="text-black text-14 poppins-600 m-0"> Jayvion S*****</h5>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="px-3 py-2">
                                    <span className="text-16 text-black d-inline-block"> 12345 </span>
                                  </td>
                                  <td className="px-3 py-2">
                                    <div className="d-flex w-100 align-items-center justify-content-start">
                                      <div className="d-inline-block me-3">
                                        <img className="custom-image4 rounded-circle" src="/images/avatar-1.png" />
                                      </div>
                                      <div className="d-inline-block">
                                        <h5 className="text-black text-14 poppins-600 m-0"> Jayvion S*****</h5>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="px-3 py-2">
                                    <span className="text-16 text-black d-inline-block"> 12345 </span>
                                  </td>
                                  <td className="px-3 py-2">
                                    <div className="d-flex w-100 align-items-center justify-content-start">
                                      <div className="d-inline-block me-3">
                                        <img className="custom-image4 rounded-circle" src="/images/avatar-1.png" />
                                      </div>
                                      <div className="d-inline-block">
                                        <h5 className="text-black text-14 poppins-600 m-0"> Jayvion S*****</h5>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="d-block w-100 px-3 border-top py-3">
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="d-inline-block me-3">
                                <div className="d-flex align-items-center justify-content-start">
                                  <span className="d-inline-block text-14 text-black me-3 justify-content-start"> Rows per page: </span>
                                  <select className="border-0 bg-transparent text-14 text-black">
                                    <option> 5 </option>
                                    <option> 10 </option>
                                    <option> 15 </option>
                                    <option> 20 </option>
                                    <option> 25 </option>
                                  </select>
                                </div>
                              </div>
                              <div className="d-inline-block me-3">
                                <span className="text-black text-14 d-inline-block"> 6-10 of 11 </span>
                              </div>
                              <div className="d-inline-block">
                                <div className="d-flex align-items-center justify-content-start">
                                  <button className="px-2 py-0 bg-transparent border-0">
                                    <i className="fa fa-angle-left text-black text-14 m-0 p-0"> </i>
                                  </button>
                                  <button className="px-2 py-0 bg-transparent border-0">
                                    <i className="fa fa-angle-right text-black text-14 m-0 p-0"> </i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane fade" id="nav-sent" role="tabpanel" aria-labelledby="nav-sent-tab">
                          <div className="d-block w-100">
                            <table className="w-100">
                              <thead>
                                <tr>
                                  <th className="text-muted text-15 poppins-500 py-3 px-3 bg-silver4 d-lg-table-cell d-md-table-cell d-none"> User ID </th>
                                  <th className="text-muted text-15 poppins-500 py-3 px-3 bg-silver4 d-lg-table-cell d-md-table-cell d-none"> Name </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="px-3 py-2">
                                    <span className="text-16 text-black d-inline-block"> 12345 </span>
                                  </td>
                                  <td className="px-3 py-2">
                                    <div className="d-flex w-100 align-items-center justify-content-start">
                                      <div className="d-inline-block me-3">
                                        <img className="custom-image4 rounded-circle" src="/images/avatar-1.png" />
                                      </div>
                                      <div className="d-inline-block">
                                        <h5 className="text-black text-14 poppins-600 m-0"> Jayvion S*****</h5>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="px-3 py-2">
                                    <span className="text-16 text-black d-inline-block"> 12345 </span>
                                  </td>
                                  <td className="px-3 py-2">
                                    <div className="d-flex w-100 align-items-center justify-content-start">
                                      <div className="d-inline-block me-3">
                                        <img className="custom-image4 rounded-circle" src="/images/avatar-1.png" />
                                      </div>
                                      <div className="d-inline-block">
                                        <h5 className="text-black text-14 poppins-600 m-0"> Jayvion S*****</h5>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="d-block w-100 px-3 border-top py-3">
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="d-inline-block me-3">
                                <div className="d-flex align-items-center justify-content-start">
                                  <span className="d-inline-block text-14 text-black me-3 justify-content-start"> Rows per page: </span>
                                  <select className="border-0 bg-transparent text-14 text-black">
                                    <option> 5 </option>
                                    <option> 10 </option>
                                    <option> 15 </option>
                                    <option> 20 </option>
                                    <option> 25 </option>
                                  </select>
                                </div>
                              </div>
                              <div className="d-inline-block me-3">
                                <span className="text-black text-14 d-inline-block"> 6-10 of 11 </span>
                              </div>
                              <div className="d-inline-block">
                                <div className="d-flex align-items-center justify-content-start">
                                  <button className="px-2 py-0 bg-transparent border-0">
                                    <i className="fa fa-angle-left text-black text-14 m-0 p-0"> </i>
                                  </button>
                                  <button className="px-2 py-0 bg-transparent border-0">
                                    <i className="fa fa-angle-right text-black text-14 m-0 p-0"> </i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane fade" id="nav-failed" role="tabpanel" aria-labelledby="nav-failed-tab">
                          <div className="d-block w-100">
                            <table className="w-100">
                              <thead>
                                <tr>
                                  <th className="text-muted text-15 poppins-500 py-3 px-3 bg-silver4 d-lg-table-cell d-md-table-cell d-none"> User ID </th>
                                  <th className="text-muted text-15 poppins-500 py-3 px-3 bg-silver4 d-lg-table-cell d-md-table-cell d-none"> Name </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="px-3 py-2">
                                    <span className="text-16 text-black d-inline-block"> 12345 </span>
                                  </td>
                                  <td className="px-3 py-2">
                                    <div className="d-flex w-100 align-items-center justify-content-start">
                                      <div className="d-inline-block me-3">
                                        <img className="custom-image4 rounded-circle" src="/images/avatar-1.png" />
                                      </div>
                                      <div className="d-inline-block">
                                        <h5 className="text-black text-14 poppins-600 m-0"> Jayvion S*****</h5>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="d-block w-100 px-3 border-top py-3">
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="d-inline-block me-3">
                                <div className="d-flex align-items-center justify-content-start">
                                  <span className="d-inline-block text-14 text-black me-3 justify-content-start"> Rows per page: </span>
                                  <select className="border-0 bg-transparent text-14 text-black">
                                    <option> 5 </option>
                                    <option> 10 </option>
                                    <option> 15 </option>
                                    <option> 20 </option>
                                    <option> 25 </option>
                                  </select>
                                </div>
                              </div>
                              <div className="d-inline-block me-3">
                                <span className="text-black text-14 d-inline-block"> 6-10 of 11 </span>
                              </div>
                              <div className="d-inline-block">
                                <div className="d-flex align-items-center justify-content-start">
                                  <button className="px-2 py-0 bg-transparent border-0">
                                    <i className="fa fa-angle-left text-black text-14 m-0 p-0"> </i>
                                  </button>
                                  <button className="px-2 py-0 bg-transparent border-0">
                                    <i className="fa fa-angle-right text-black text-14 m-0 p-0"> </i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex w-100 align-items-center justify-content-center mt-4 mb-5">
                    <button
                      className="custom-btn3 px-lg-5 px-md-5 mob-w-100 tab-w-100 py-2 lh-lg rounded-3 text-15"
                      style={{ width: '40%' }}
                      onClick={handleSendTicket}
                    >
                      <span className="d-inline-block px-3">Send Ticket to user</span>
                    </button>

                  </div>
                </div>
              </div>
              <div className="d-block w-100 mt-3" onClick={handleGoBack}>
                <button className="text-black border-0 bg-transparent text-14 poppins-600 px-3 py-2 d-flex align-items-center justify-content-start">
                  <i className="fa fa-angle-left text-black text-15 me-2"> </i>
                  Back
                </button>
              </div>
            </div>

          </section>
          <FooterComponent />
        </>
      )}
    </>

  );
}
export default TransferTicketComponent
