import React, { useEffect, useState, useRef } from "react";
import "../../App.css";
import { Link, useParams } from "react-router-dom";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { fetchBlogDetail } from '../../services/blogservice';
import { Helmet } from "react-helmet";
import HeaderComponent from '../HeaderComponent/HeaderComponent.js';
import FooterComponent from '../FooterComponent/FooterComponent.js';


function Terms_and_Conditions_Component() {
  const [blogData, setBlogData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
 
 

  
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",  
    });
    document.body.classList.add("home-bg");

    // Clean up the class when the component unmounts
    return () => {
      document.body.classList.remove("home-bg");
    };
  }, []);

  if (isLoading) {
    return (
      <section className="pad-top-20 pad-bot-80" id="loaderSection">
          <div className="container3">
            <div className="block-element m-b-30">
            </div>
            <div className="block-element loader-mobile-screen" style={{ marginTop: '80px' }}>
        <LoadingSpinner />
        </div></div>
        </section>
    );
  }

   
 
  return (
  
    <>
    <Helmet>
    <link href="/css/bootstrap.min.css" rel="stylesheet" />
   </Helmet>
      <HeaderComponent/>
      <section className="pad-top-60 pad-bot-60">
        <div className="container2">
          <div className="block-element m-b-20">
            <Link to="/" className="back-btn1">
              <i className="fa fa-angle-left"> </i> Back
            </Link>
          </div>
        
           
          <div className="block-element m-b-10">
            <div className="textual-sec1">
              <h3 className="col-black">Terms and Conditions</h3>
              <p className="col-black">Thank you for using the services and products offered by Tikkets.com.
 
                  You agree that by registering on Tikkets.com, by using our mobile application or our website, you are entering into a legally binding agreement with Tikkets.com (‘we’, ‘us’ and ‘our’), having its principal office located at xxx, and these Terms and Conditions and the Tikkets.com Privacy Policy, which is incorporated herein by reference (collectively referred to as the ‘Agreement’) and that you become a Tikkets.com user (‘User’).

                  By using Tikkets.com, you agree to these Terms and Conditions. If you do not agree to these Terms and Conditions, you are not permitted to use Tikkets.com.

                  If you are using Tikkets.com on behalf of a company or other legal entity, you are individually bound by this Agreement even if your company has a separate agreement with us. If you do not want to register an account and become a Tikkets.com User, stop here, do NOT click ‘Continue’ and do not access, view, download or otherwise use any Tikkets.com application, webpage, information or services. By clicking ‘Continue,’ you acknowledge that you are at least 18 years of age and have read and understood the Terms and Conditions of this Agreement and that you agree to be bounded by all of its provisions. Please note that the Tikkets.com Terms & Conditions and Privacy Policy are also collectively referred to as Tikkets.com’s ‘User Agreement.’
                  </p>
            </div>
          </div>


          <div className="block-element m-b-10">
            <div className="textual-sec1">
              <h3 className="col-black">USER OBLIGATIONS</h3>
              <h4 className="col-black" style={{ fontWeight:'700' }}>Compliance</h4>
              <p className="col-black"> You must comply with all applicable laws, the Agreement may be amended from time to time with or without advance notice, and the policies and processes explained in the following sections:
                </p>
                <ul>
                 
                  <li>DO’s and DON’Ts;</li>
                  <li>Complaints Regarding Content Posted; and</li>
                  <li>Tikket.com’s Privacy Policy.</li>
                </ul>
            </div>
          </div>


          <div className="block-element m-b-10">
            <div className="textual-sec1">
              <h3 className="col-black">Information Provided to Tikkets.com</h3>
              
                <p className="col-black"> 
                You own the information you provide to Tikkets.com under this Agreement and may delete such content at any time, unless you have shared information or content with others and they have not deleted it, or it was copied or stored by other users. Additionally, you grant Tikkets.com a nonexclusive, irrevocable, worldwide, perpetual, unlimited, assignable, sublicensable, fully paid up and royalty-free right to us to copy, prepare derivative works of, improve, distribute, publish, remove, retain, add, process, analyze, use and commercialize, in any way now known or in the future discovered, any information you provide, directly or indirectly to Tikkets.com, including, but not limited to, any user-generated content, ideas, concepts, techniques or data to the services, you submit to Tikkets.com, without any further consent, notice and/or compensation to you or any third parties.

                By uploading information on our mobile application, or webpages, you represent and warrant that you are entitled to submit the information and that the data is accurate, not confidential, and not in violation of any contractual restrictions or other third-party rights. It is your responsibility to keep your Tikkets.com profile information accurate and updated.

                </p>
               
            </div>
          </div>


          <div className="block-element m-b-10">
            <div className="textual-sec1">
              <h3 className="col-black">Eligibility</h3>
              <p className="col-black"> 
              To be eligible to use the Service, you must meet the following criteria and represent and warrant that you: 
              </p>
                <ul className="col-black">
                 
                  <li>are 18 years of age or older; </li>
                  <li>are not currently restricted from the Services, or not otherwise prohibited from having a Tikkets.com account;
                  </li>
                  <li>are not a competitor of Tikkets.com or are not using the Services for reasons that are in competition with Tikkets.com; </li>
                  <li>have full power and authority to enter into this Agreement and doing so will not violate any other agreement to which you are a party;  </li>
                  <li>will not violate any of Tikkets.com’s rights, including intellectual property rights such as copyright or trademark rights.  </li>
                </ul>
               
            </div>
          </div>

          

          <div className="block-element m-b-10">
            <div className="textual-sec1">
              <h3 className="col-black">Log-In and Pin
              </h3>
                <p className="col-black"> 
                For you to use Tikkets.com, you are required to create an account with us. The account that you create with us is specific and personal to your personal use, and you are not permitted to lend, gift, transfer or otherwise permit any other person to access or use your account.

                When you create an account with us, you are asked for personal information, including but not limited to your name, age, gender, and address. The information you provide must be accurate and complete, and you are required to keep your information current. Tikkets.com reserves the right to change, suspend or terminate your Tikkets.com User account if you provide incomplete, untrue, or inaccurate information or you fail to complete or comply with the User account creation requirements.

                </p>
                <p className="col-black"> 
                You agree to:
                </p>
                <ul className="col-black">
                 
                  <li>keep your password and your Pin secure and confidential; ; </li>
                  <li>not permit others to use your account; 
                  </li>
                  <li>refrain from using other User’s accounts; </li>
                  <li>you are responsible for anything that happens on or through your account until you close down your account or show written proof that your account security was compromised due to no fault of your own.</li>
               
                </ul>
               
            </div>
          </div>

          
          <div className="block-element m-b-10">
            <div className="textual-sec1">
              <h3 className="col-black">Indemnification
              </h3>
                 
                <p className="col-black"> 
                You indemnify Tikkets.com and hold Tikkets.com harmless for all damages, losses and costs (including, but not limited to, reasonable attorney’s fees and costs) related to all third-party claims, charges, and investigations, caused by: 
                </p>
                <ul className="col-black">
                 
                  <li>your failure to comply with this Agreement, including, without limitation, your submission of content that violates third party rights or applicable laws;  </li>
                  <li>any content you submit to the Services; 
                  </li>
                  <li>any activity in which you engage on or through Tikkets.com.</li>
                  <li>you are responsible for anything that happens on or through your account until you close down your account or show written proof that your account security was compromised due to no fault of your own.</li>
               
                </ul>
               
            </div>
          </div>



          <div className="block-element m-b-10">
            <div className="textual-sec1">
              <h3 className="col-black">Tikkets.com Applications

              </h3>
                 
              <p className="col-black"> 
              Tikkets.com may offer the Services through applications built using Tikkets.com’s platform (‘Tikkets.com Applications’). Example of Tikkets.com Application include its smartphone applications (Tikkets.com for iPhone, Android). Tikkets.com Applications are distinct from third party Platform Applications, you agree that information about you and your use of the Services, including, but not limited to, your device, your mobile carrier, your internet access provider, your physical location, and/or web pages containing Tikkets.com plug-in that load in your browser may be communicated to us.

              You also agree that by importing any data through the Tikkets.com Application, you represent that you have authority to share the transferred data with your mobile carrier or other access providers. In the event you change or deactivate your mobile account, you must promptly update your Tikkets.com account information to ensure that your messages are not sent to the person that acquires your old number and failure to do so is your responsibility. 

              You acknowledge you are responsible for all charges and necessary permissions related to accessing Tikkets.com through your mobile access provider. Therefore, you should check with your provider to find out if the Services are available and the terms for these services for your specific mobile devices as well as any data usage fees.

              Finally, by using any downloadable application to enable your use of the Services, you are explicitly confirming your acceptance of the terms of the End User License Agreement associated with the application provided at download or installation, or as may be updated from time to time.

              </p>
              
               
            </div>
          </div>


          <div className="block-element m-b-10">
            <div className="textual-sec1">
              <h3 className="col-black">Privacy

              </h3>
                 
              <p className="col-black"> 
              Our Privacy Policy is incorporated herein and your acceptance to create an account as a Tikkets.com user is your agreement to such Privacy Policy and governs our treatment of any information, including personally identifiable information you submit to us. Note that certain information, statements, data, and content (such as photographs) which you may upload or submit to Tikkets.com are likely to reveal your gender, ethnic origin, nationality, age, and/or other personal information about you. You acknowledge that your submission of any information, statements, data, and content to us is voluntary on your part.
              </p>
              
               
            </div>
          </div>

          
          <div className="block-element m-b-10">
            <div className="textual-sec1">
              <h3 className="col-black">Your Rights

              </h3>
                 
              <p className="col-black"> 
              On the condition that you comply with all your obligations under this Agreement, including, but not limited to, the Do’s and Don’ts, we grant you a limited, revocable, nonexclusive, license and right to access the Services, through a generally available mobile device, web browser, or application (but not through automated tools such as: scraping, spidering, crawling or other technology or software used to access data without the express written consent of Tikkets.com or its Users), view information and use the Services that we provide on Tikkets.com’s application, and/or webpages and in accordance with this Agreement. Any other use of Tikkets.com contrary to our mission and purpose (such as seeking to connect to someone you do not know or trust, or to use information gathered from Tikkets.com commercially unless expressly authorized by Tikkets.com) is strictly prohibited and a violation of this Agreement. We reserve all rights not expressly granted in this Agreement, including, without limitation, title, ownership, intellectual property rights, and all other rights and interest in Tikkets.com and all related items, including any and all copies made of the Tikkets.com products.
             </p>
              
               
            </div>
          </div>


            
          <div className="block-element m-b-10">
            <div className="textual-sec1">
              <h3 className="col-black"> Our Rights and Obligations

              </h3>
                 
              <p className="col-black"> 
              For as long as Tikkets.com continues to offer its platform, Tikkets.com shall provide and seek to update, improve, and expand its services. As a result, we allow you to access Tikkets.com as it may exist and be available on any given day and have no other obligations, except as expressly stated in this Agreement. We may modify, replace, refuse access to, suspend or discontinue Tikkets.com, partially or entirely, or change and modify prices for all or part of the Services for you or all our users in our sole discretion. All of these changes shall be effective upon an application update or upon their posting on our sites or by direct communication to you unless otherwise noted. Tikkets.com further reserves the right to withhold, remove and or discard any content available as part of your account, with or without notice if deemed by Tikkets.com to be contrary to this Agreement. For the avoidance of doubt, Tikkets.com has no obligation to store, maintain, or provide you a copy of any content that you or other Users provide when using the Services.

              </p>
              
               
            </div>
          </div>

            
          <div className="block-element m-b-10">
            <div className="textual-sec1">
              <h3 className="col-black">Third-Party Sites

              </h3>
                 
              <p className="col-black"> 
              Tikkets.com may include links to third-party sites (‘Third-Party Sites’) on our mobile app or on Tikkets.com web pages (www.Tikkets.com). Tikkets.com also enables third-party developers (‘Platform Developers’) to create applications (‘Platform Applications’) that provide features and functionality using data and developer tools made available by Tikkets.com through its developer platform.

You are responsible for evaluating whether you want to access or use a Third-Party Site or Platform Application. You should review any applicable terms and/or privacy policy of a Third-Party Site or Platform Application before using it or sharing any information with it, because you may give the operator permission to use your information in ways we would not.
Tikkets.com is not responsible for and does not endorse, any features, content, advertising, products or other materials on or available from Third-Party Sites or Platform Applications. Tikkets.com also does not screen, audit, or endorse Platform Applications. Accordingly, if you decide to access Third-Party Sites or use Platform Applications, you do so at your own risk and agree that your use of any Platform Application is on an ‘as-is’ basis without any warranty as to the Platform Developer’s actions, and that this Agreement does not apply to your use of any Third-Party Site or Developer Application.

Please note: If you allow a Platform Application or Third-Party Site to authenticate to or connect with your Tikkets.com account, that application or website can access information on Tikkets.com related to you and your connections.


              </p>
              
               
            </div>
          </div>


          <div className="block-element m-b-10">
            <div className="textual-sec1">
              <h3 className="col-black">Disclosure of User Information

              </h3>
                 
              <p className="col-black"> 
              You acknowledge, consent and agree that we may access, preserve, and disclose your registration and any other information you provide if required to do so by law or in a good faith belief that such access preservation or disclosure is reasonably necessary in our opinion to:
(1) comply with a legal process, including, but not limited to, civil and criminal subpoenas, court orders or other compulsory disclosures; 
(2) enforce this Agreement; 
(3) respond to claims of a violation of the rights of third parties, whether or not the third party is a User, individual, or government agency; 
(4) respond to customer service inquiries; or 
(5) protect the rights, property, or personal safety of Tikkets.com, our Users or the public.


              </p>
              
               
            </div>
          </div>



          <div className="block-element m-b-10">
            <div className="textual-sec1">
              <h3 className="col-black">Connect, Trades, Interactions with Others
              </h3>
                 
              <p className="col-black"> 
              You are solely responsible for your interactions with other Users. Tikkets.com may limit the number of connections you may have to other Users and may, in certain circumstances, prohibit you from contacting other Users through use of the Services or otherwise limit your use of the Services. Tikkets.com reserves the right, but has no obligation, to monitor disputes between you and other members and to restrict, suspend, delete or close your account if Tikkets.com determines, in our sole discretion, that doing so is necessary to enforce this Agreement.

              </p>
              
               
            </div>
          </div>



          <div className="block-element m-b-10">
            <div className="textual-sec1">
              <h2 className="col-black">DISCLAIMER
              </h2>
                 
              <p className="col-black"> 
              SOME COUNTRIES AND JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OF IMPLIED TERMS IN CONTRACTS WITH CONSUMERS, AND AS A RESULT, THE CONTENTS OF THIS SECTION MAY NOT APPLY TO YOU.

DO NOT RELY ON TIKKETS.COM, ANY INFORMATION THEREIN, OR ITS CONTINUATION. WE PROVIDE THE PLATFORM FOR TIKKETS.COM AND ALL INFORMATION AND SERVICES ON AN ‘AS IS’ AND ‘AS AVAILABLE’ BASIS. TIKKETS.COM DOES NOT CONTROL OR VET USER GENERATED CONTENT FOR ACCURACY. WE DO NOT PROVIDE ANY EXPRESS WARRANTIES OR REPRESENTATIONS. 

TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, WE DISCLAIM ANY AND ALL IMPLIED WARRANTIES AND REPRESENTATIONS, INCLUDING, WITHOUT LIMITATION, ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, ACCURACY OF DATA, AND NONINFRINGEMENT. IF YOU ARE DISSATISFIED OR HARMED BY TIKKETS.COM OR ANYTHING RELATED TO TIKKETS.COM, YOU MAY CLOSE YOUR TIKKETS.COM ACCOUNT AND TERMINATE THIS AGREEMENT, AND SUCH TERMINATION SHALL BE YOUR SOLE AND EXCLUSIVE REMEDY.

TIKKETS.COM IS NOT RESPONSIBLE AND MAKES NO REPRESENTATIONS OR WARRANTIES FOR THE DELIVERY OF ANY MESSAGES SENT THROUGH TIKKETS.COM TO ANYONE. IN ADDITION, WE NEITHER WARRANT NOR REPRESENT THAT YOUR USE OF THE SERVICE WILL NOT INFRINGE THE RIGHTS OF THIRD PARTIES. ANY MATERIAL, SERVICE, OR TECHNOLOGY DESCRIBED OR USED ON THE WEBSITE MAY BE SUBJECT TO INTELLECTUAL PROPERTY RIGHTS OWNED BY THIRD PARTIES WHO HAVE LICENSED SUCH MATERIAL, SERVICE, OR TECHNOLOGY TO US.

TIKKETS.COM DOES NOT HAVE ANY OBLIGATION TO VERIFY THE IDENTITY OF THE PERSONS SUBSCRIBING TO ITS SERVICES, NOR DOES IT HAVE ANY OBLIGATION TO MONITOR THE USE OF ITS SERVICES BY OTHER USERS OF THE COMMUNITY; THEREFORE, TIKKETS.COM DISCLAIMS ALL LIABILITY FOR IDENTITY THEFT OR ANY OTHER MISUSE OF YOUR IDENTITY OR INFORMATION.

TIKKETS.COM DOES NOT GUARANTEE THAT THE SERVICES IT PROVIDES WILL FUNCTION WITHOUT INTERRUPTION OR ERRORS IN FUNCTIONING. IN PARTICULAR, THE OPERATION OF THE SERVICES MAY BE INTERRUPTED DUE TO MAINTENANCE, UPDATES, OR SYSTEM OR NETWORK FAILURES. TIKKETS.COM DISCLAIMS ALL LIABILITY FOR DAMAGES CAUSED BY ANY SUCH INTERRUPTION OR ERRORS IN FUNCTIONING. FURTHERMORE, TIKKETS.COM DISCLAIMS ALL LIABILITY FOR ANY MALFUNCTIONING, IMPOSSIBILITY OF ACCESS, OR POOR USE CONDITIONS OF THE TIKKETS.COM SITE DUE TO INAPPROPRIATE EQUIPMENT, DISTURBANCES RELATED TO INTERNET SERVICE PROVIDERS, TO THE SATURATION OF THE INTERNET NETWORK, AND FOR ANY OTHER REASON.

NOTWITHSTANDING ANY OTHER PROVISIONS OF THIS AGREEMENT, NOTHING IN THIS AGREEMENT LIMITS OR EXCLUDES ANY OF YOUR STATUTORY RIGHTS, TO THE EXTENT SUCH STATUTORY RIGHTS MAY NOT BE EXCLUDED OR WAIVED UNDER APPLICABLE LAWS AND REGULATIONS.

YOU AGREE THAT YOU, AND YOUR ORGANISATION, IF YOU ARE USING TIKKETS.COM ON BEHALF OF SUCH ORGANISATION) INDEMNIFY US AND OUR AFFILIATE COMPANIES FROM AND AGAINST ANY CLAIM, SUIT, ACTION, DEMAND, DAMAGE, DEBT, LOSS, EXPENSE, COST, (INCLUDING LITIGATION COSTS AND ATTORNEYS’ FEES) AND LIABILITY ARISING FROM: (A) YOUR USE OF TIKKETS.COM; OR (B) YOUR BREACH OF THIS AGREEMENT.

              </p>
              
               
            </div>
          </div>


          <div className="block-element m-b-10">
            <div className="textual-sec1">
              <h2 className="col-black">Termination
              </h2>
                 
              <p className="col-black"> 
              You may terminate this Agreement, for any or no reason, at any time, by deleting your account.

                Tikkets.com may terminate the Agreement and your account for any reason or no reason, at any time, with or without notice. This cancellation shall be effective immediately by an electronic notification that your account will be deleted.

                Tikkets.com may restrict, suspend, or terminate the account of any User who abuses or misuses the Services. Misuse of the Services includes abusing the Tikkets.com messaging services; creating false profiles; infringing any intellectual property rights, violating any of the Do’s and Don’ts listed in this Agreement, or any other behavior that Tikkets.com, in its sole discretion, deems contrary to its purpose.

              </p>
              
               
            </div>
          </div>


          <div className="block-element m-b-10">
            <div className="textual-sec1">
              <h2 className="col-black">Retention Of Your Information and Content
              </h2>
                 
              <p className="col-black"> 
              Following termination of this Agreement, we retain and use your information and content in accordance with this Agreement, and, if your information and content include Personal Information, then the Tikkets.com Privacy Policy will be followed. In accordance with the Tikkets.com Privacy Policy and applicable laws and regulations, where we suspend or terminate all or part of Tikkets.com, or where your access to Tikkets.com is terminated by you or us, we cannot guarantee that we will be able to return any of your information or content to you, and we may permanently delete your information or content without notice to you at any time after termination.

              </p>
              
               
            </div>
          </div>


          <div className="block-element m-b-10">
            <div className="textual-sec1">
              <h2 className="col-black">Dispute Resolution
              </h2>
                 
              <p className="col-black"> 
              This Agreement or any claim, cause of action or dispute (‘claim’) arising out of or related to this Agreement shall be governed by the laws of Jamaica regardless of your country of origin or where you access Tikkets.com, and notwithstanding any conflicts of law principles. You and Tikkets.com agree that all claims arising out of or related to this Agreement must be resolved exclusively by a court located in the Jamaica except as otherwise agreed by the parties or as described in the Arbitration Option paragraph below. You and Tikkets.com agree to submit to the personal jurisdiction of the courts located within the Jamaica for the purpose of litigating all such claims. Notwithstanding the above, you agree that Tikkets.com shall still be allowed to apply for injunctive remedies (or an equivalent type of urgent legal relief) in any jurisdiction.

              </p>
              
               
            </div>
          </div>

          <div className="block-element m-b-10">
            <div className="textual-sec1">
              <h2 className="col-black">Arbitration
              </h2>
                 
              <p className="col-black"> 
              For any claim (excluding claims for injunctive or other equitable relief) where the total amount of the award sought is less than $1.5 million JMD , the party requesting relief may elect to resolve the dispute cost-effectively through binding non-appearance-based arbitration. In the event a party elects’ arbitration, they shall initiate such arbitration through an established alternative dispute resolution (‘ADR’) provider mutually agreed upon by the parties. The ADR provider and the parties must comply with the following rules: (a) the arbitration shall be conducted by telephone, online and/or be solely based on written submissions, the specific manner shall be chosen by the party initiating the arbitration; (b) the arbitration shall not involve any personal appearance by the parties or witnesses unless otherwise mutually agreed by the parties; and (c) any arbitration proceedings will be conducted in English (d) any judgment on the award rendered by the arbitrator shall be final and may be entered in any court of competent jurisdiction.
              </p>
              
               
            </div>
          </div>



          <div className="block-element m-b-10">
            <div className="textual-sec1">
              <h2 className="col-black">Legal Notices
              </h2>
                 
              <p className="col-black"> 
              For legal notices, you may contact us at:
               </p>
              
               
            </div>
          </div>

          <div className="block-element m-b-10">
            <div className="textual-sec1">
              <h2 className="col-black">Entire Agreement
              </h2>
                 
              <p className="col-black"> 
              You agree that this Agreement constitutes the entire, complete and exclusive agreement between you and Tikkets.com regarding its application and services and supersedes all prior agreements and understandings, whether written or oral, or whether established by custom, practice, policy or precedent, with respect to the subject matter of this Agreement. You also acknowledge that you may be subject to additional terms and conditions that may apply when you use third-party content or third-party software.

               </p>
              
               
            </div>
          </div>

          <div className="block-element m-b-10">
            <div className="textual-sec1">
              <h2 className="col-black">Amendments
              </h2>
                 
              <p className="col-black"> 
              We reserve the right to modify, supplement, or replace the terms of the Agreement, effective upon electronic communication, via our mobile application or by posting at <Link to="/"> www.Tikkets.com</Link> or notifying you otherwise.
             </p>
              
               
            </div>
          </div>

          <div className="block-element m-b-10">
            <div className="textual-sec1">
              <h2 className="col-black">No informal waivers, agreements or representations

              </h2>
                 
              <p className="col-black"> 
              Our failure to act with respect to a breach of this Agreement by you or others does not waive our right to act with respect to that breach or subsequent similar or other breaches. Except as expressly and specifically contemplated by the Agreement, no representations, statements, consents, waivers or other acts or omissions by any Tikkets.com Affiliate shall be deemed legally binding on any Tikkets.com Affiliate, unless documented in a physical writing hand signed by a duly appointed officer of Tikkets.com.
             </p>
              
               
            </div>
          </div>


          <div className="block-element m-b-10">
            <div className="textual-sec1">
              <h2 className="col-black">No Injunctive Relief

              </h2>
                 
              <p className="col-black"> 
                
In no event shall you seek or be entitled to rescission, injunctive or other equitable relief, or to enjoin or restrain the operation of the Service, exploitation of any advertising or other materials issued in connection in addition to that, or exploitation of the Services or any content or other material used or displayed through the Services.

              </p>
              
               
            </div>
          </div>


          <div className="block-element m-b-10">
            <div className="textual-sec1">
              <h3 className="col-black">Tikkets.com User DO’S And DON’TS
              </h3>
                 
                <p className="col-black"> 
                As a condition to accessing Tikkets.com, you agree to this Agreement and to strictly adhere to the following:
DO:

                </p>
                <ul className="col-black">
                 
                  <li> Comply with all applicable laws, including, without limitation, privacy laws, intellectual property laws, export control laws, tax laws, and regulatory requirements;  </li>
                  <li> Be cautious when providing your information to others (Cards, Connect, Trade); 
                  </li>
                  <li>Protect your sensitive personal data;</li>
                  <li> Provide accurate information to us and update it as necessary;</li>
                  <li> Review and comply with our Privacy Policy;</li>
                  <li> Review and comply with notices sent by Tikkets.com concerning the Services; and</li>
                  <li> Use the Services in a polite and professional manner.</li>
 
               
                </ul>
               
            </div>
          </div>


          <div className="block-element m-b-10">
            <div className="textual-sec1">
              <h3 className="col-black">DON’T:
              </h3>
               
                <ul className="col-black">
                 
                  <li>  Act dishonestly or unprofessionally by engaging in unprofessional behavior by posting inappropriate, inaccurate, or objectionable content to Tikkets.com;  </li>
                  <li> Publish incorrect information in the designated fields on the profile form;
                  </li>
                  <li>Create a false identity on Tikkets.com;</li>
                  <li> Falsely state, impersonate or otherwise misrepresents your identity;</li>
                  <li>Use language or upload photos that are unlawful, libelous, abusive, obscene, discriminatory or otherwise objectionable</li>
                  <li> Include information that you do not have the right to disclose or make available under any law or under contractual or fiduciary relationships;
                  </li>
                  <li>Duplicate, license, sublicense, publish, broadcast, transmit, distribute, perform, display, sell, rebrand, or otherwise transfer information found on Tikkets.com (excluding content posted by you) except as permitted in this Agreement, Tikkets.com’s developer terms, and policies, or as expressly authorized by Tikkets.com;</li>
                  <li>Reverse engineer, decompile, disassemble, decipher or otherwise attempt to derive the source code for any underlying intellectual property used to provide the Services or any part thereof;</li>
                  <li>Utilize or copy information, content or any data you view on and/or obtain from Tikkets.com to offer any service that is competitive, in Tikkets.com’s sole discretion, with Tikkets.com;</li>
                  <li>Adapt, modify or create derivative works based on Tikkets.com or technology underlying the Services, or other User’s content, in whole or part, except as permitted under Tikkets.com’s developer program;</li>
                  <li>Infringe or use Tikkets.com’s brand, logos and/or trademarks, including, without limitation, using the word ‘Tikkets.com’ in any business name, email, or URL or including Tikkets.com’s trademarks and logos except as provided in the Brand Guidelines or as expressly permitted by Tikkets.com.</li>
                  
 
               
                </ul>
               
            </div>
          </div>






          <div className="block-element">
            <div className="event-detail-actions">
              {/* <Link to="" className="custom-btn2">
                ariana grande
              </Link> */}
            </div>
          </div>
        </div>
      </section>
      <FooterComponent/>
    </>
  );
}

export default Terms_and_Conditions_Component;
