import React, { useState, useEffect } from "react";
import format from "date-fns/format";
import Ticket from "./TicketComponent";
import TicketMOb from "./TicketMobComponent";
import { useOrders } from "../../hooks/SettingContext";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { eventCode } from "../../services/homeService";
import { ClipLoader } from 'react-spinners';
import { fetchEventData } from "../../services/EventTikketService";
// import Lightbox from 'yet-another-react-lightbox';
// import 'yet-another-react-lightbox/styles.css';
const EventComponent = ({
  event,
  selectedTickets,
  handleTicketSelection,
  handleAddToCart,
  mainIndex,
  keyboardId,
  setOpenSideModal,
  openSideModal,
  setEvtData,
  setAvailableTickets,
  setticketActiveTab,
  ticketactiveTab,
  setisticketLoading,
  isticketLoading,
   
 
 

  
}) => {

   

 
  const navigate = useNavigate();
  const wordLimit = 30;
  const truncateDescription = (description, wordLimit) => {
    const words = description.split(' ');
    if (words.length <= wordLimit) {
      return description;
    }
    return words.slice(0, wordLimit).join(' ') + '...';
  };

 
  const truncatedDescription = truncateDescription(event.description, wordLimit);
  const fullDescription = event.description;
  
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const wordCount = fullDescription.split(' ').length;


  const [isMobile, setIsMobile] = useState(false);
 
  const [activeTab, setActiveTab] = useState("description");
  const { ordersCount, updateOrdersCount } = useOrders();
  const [verifyEvent, setverifyEventt] = useState([]);
  const [isDisable, setisDisable] = useState(false);
 
 

  const [isReadMore, setIsReadMore] = useState(false);
  const [loginuserID, setloginuserID] = useState('');
  const [islogin, setislogin] = useState('no');
  const [unlockEventuser_ID, setunlockEvent_userID] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const galleryImages = event.gallery
    ? event.gallery.split(",").map((photo) => ({
        src: `http://api.tikkets.com/public/images/upload/${photo}`,
      }))
    : [];

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    setIsOpen(true);
  };

  useEffect(() => {
    if (isOpen) {
      // Automatically move to the next image after a delay
      const timer = setTimeout(() => {
        setSelectedImageIndex((selectedImageIndex + 1) % galleryImages.length);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isOpen, selectedImageIndex, galleryImages.length]);
 
 

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    
    if(event.security === 1 ){
      // console.log(event.name,event.security);
      setisDisable(true);
    }
 

  }, [event]);

  useEffect(() => {
    
    
    const storedEventId = localStorage.getItem("validatedEventIds") ? JSON.parse(localStorage.getItem("validatedEventIds")) : [];

    if (storedEventId) {
      setverifyEventt(storedEventId);
    }

          const verifyUserIds = localStorage.getItem("unlockEvent_userID") 
          ? JSON.parse(localStorage.getItem("unlockEvent_userID")) 
          : [];
        
        setunlockEvent_userID(verifyUserIds);

    
 

    const loginUser = localStorage.getItem('userID');
    if(loginUser){
      setloginuserID(loginUser);
      setislogin('yes');
    } 
   

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
    
  }, []);
   

  const handlebookNow = () => {
    const authToken = localStorage.getItem('authToken');
    
    if (!authToken) {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'You are not login please login first!',
        });
        return;
    }

    const itemsFromLocalStorage = JSON.parse(
      localStorage.getItem("items") || "[]"
    );

    // Check if any selected ticket already exists in localStorage items
    const alreadyExists = selectedTickets.some((ticket) =>
      itemsFromLocalStorage.some((item) => item.id === ticket.id)
    );

    if (alreadyExists) {
      navigate("/cart");
      return;
    }


    const updatedSelectedTickets = selectedTickets.map((ticket) => ({
      ...ticket,
      quantity: ticket.quantity + 1,
    }));

    if (updatedSelectedTickets.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `You have not selected any tickets.`,
      });
      
      return;
    }

    // Retrieve items from localStorage
     

    // Check if any selected ticket already exists in localStorage items
    // const alreadyExists = updatedSelectedTickets.some((ticket) =>
    //   itemsFromLocalStorage.some((item) => item.id === ticket.id)
    // );

    // if (alreadyExists) {
    //   Swal.fire({
    //     icon: "warning",
    //     title: "Ticket Already Added",
    //     text: `You have already added this ticket to your cart.`,
    //   });
    //   return;
    // }

    // Proceed with adding the tickets to localStorage items
    const updatedItems = itemsFromLocalStorage.map((item) => {
      const existingTicketIndex = updatedSelectedTickets.findIndex(
        (ticket) => ticket.id === item.id
      );
      if (existingTicketIndex !== -1) {
        const selectedTicket = updatedSelectedTickets[existingTicketIndex];
        return {
          ...item,
          quantity: item.quantity + selectedTicket.quantity,
          price:
            (item.quantity + selectedTicket.quantity) * item.per_ticket_price,
        };
      } else {
        return item;
      }
    });

    // Add new items to localStorage if they don't exist
    updatedSelectedTickets.forEach((ticket) => {
      const existingItemIndex = updatedItems.findIndex(
        (item) => item.id === ticket.id
      );
      if (existingItemIndex === -1) {
        const attributesObject = {
          eventName: ticket.eventName,
          event_id: ticket.event_id,
          image: ticket.image,
          ticket_per_order: ticket.ticket_per_order,
          user_id: ticket.user_id,
        };
        const newTicket = {
          id: ticket.id,
          name: ticket.name,
          description: ticket.description,
          type: ticket.type,
          per_ticket_price: ticket.price,
          price: ticket.price * ticket.quantity,
          quantity: ticket.quantity,
          attributes: attributesObject,
        };
        updatedItems.push(newTicket);
      }
    });

    // Update localStorage with the updated items
    localStorage.setItem("items", JSON.stringify(updatedItems));

    // Clear the selectedTickets array
    handleTicketSelection([], []);

    // Update ordersCount in localStorage
    const totalQuantity = updatedItems.reduce(
      (total, item) => total + item.quantity,
      0
    );
    localStorage.setItem("ordersCount", totalQuantity);
    updateOrdersCount(totalQuantity);

    // Navigate to cart page
    navigate("/cart");
  };


  // Function to check if a user has a specific event
function doesUserHaveEvent(userId, eventId) {
  // Initialize users object from local storage
  const users = initializeUsers();

  // Check if user exists
  if (!users[userId]) {
    return false;
  }

  // Check if the event exists for the user
  const userEvents = users[userId].events;
  return userEvents.some(event => event.eventId === eventId);
}

// Function to initialize users from local storage
function initializeUsers() {
  const usersFromStorage = localStorage.getItem('unlock_event_data');
  return usersFromStorage ? JSON.parse(usersFromStorage) : {};
}

// Initialize users object from local storage
let users = initializeUsers();

// Function to save users to local storage
function saveUsersToLocalStorage() {
  localStorage.setItem('unlock_event_data', JSON.stringify(users));
}

// Function to add a user
function addUser(userId) {
  if (!users[userId]) {
    users[userId] = { events: [] };
  }
  saveUsersToLocalStorage();
}

// Function to add an event to a user
function addEventToUser(userId, eventId, eventName, eventDate) {
  // Automatically add user if they don't exist
  if (!users[userId]) {
    addUser(userId);
  }
  // Add event to user
  users[userId].events.push({
    eventId: eventId,
    eventName: eventName,
    eventDate: eventDate
  });
  saveUsersToLocalStorage();
}

// Function to get the JSON string
function getJSONString() {
  return JSON.stringify({ users }, null, 2);
}



  const slidesToShow = event.ticket.length < 3 ? event.ticket.length : 3;

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  const formattedDate = format(
    new Date(event.start_time),
    "EEE, dd LLL yyyy, hh.mm a"
  );

  const [code, setCode] = useState(Array(4).fill(""));

  const handleChange = (keyboardId, eventId, index, value) => {
    if (eventId === event.id) {
      if (/^[0-9]$/.test(value) || value === "") {
        const newCode = [...code];
        newCode[index] = value;
        setCode(newCode);
  
        // Move focus to the next input if the current input is not empty
        if (value !== "" && index < code.length - 1) {
          document.getElementById(`input-${keyboardId}-${index + 1}`).focus();
        }
      }
    }
  };
  
  const handleKeyDown = (keyboardId, eventId, index, e) => {
    if (eventId === event.id) {
      if (e.key === "Backspace" && !code[index]) {
        if (index > 0) {
          document.getElementById(`input-${keyboardId}-${index - 1}`).focus();
        }
      }
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    const loginUser = localStorage.getItem('userID');  

    if (!loginUser) {
        Swal.fire({
            title: 'Please log in first',
            text: 'You need to login to access this feature.',
            icon: 'warning',
            confirmButtonText: 'OK'
        });
        return;
    }
    
    setIsLoading(true);
    const accessCode = code.join("");
    if (accessCode === "") {
      setIsLoading(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `Please enter an access code before attempting to verify.`,
      });
      return;
    }
    const formData = { code: accessCode, event_id: event.id };
  
    try {
      const response = await eventCode(formData);
  
      if (response.success) {
        setIsLoading(false);  
        setisDisable(false);
          


        addUser(loginUser);
        addEventToUser(loginUser, event.id, event.name, event.start_time);
       
        Swal.fire({
          title: "Success!",
          text: "Congratulations, you can now purchase tickets for this event.",
          icon: "success",
          confirmButtonText: "OK",
        });
      } else {
        setIsLoading(false);  
         setCode(Array(4).fill(""));
        Swal.fire({
          title: "Error!",
          text: response.msg ||  response.msg.code[0],
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      setIsLoading(false);  
      console.log(error, "error");
      Swal.fire({
        title: "Error!",
        text: "Network error occurred.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };
  

  // const handleChange = (eventId,index, value) => {


  //   if (eventId === event.id) {
  //     if (/^[0-9]$/.test(value) || value === "") {
  //       const newCode = [...code];
  //       newCode[index] = value;
  //       setCode(newCode);
  
  //       // Move focus to the next input if the current input is not empty
  //       if (value !== "" && index < code.length - 1) {
  //         document.getElementById(`input-${index + 1}`).focus();
  //       }
  //     }
  //   }  

    
  // };

  // const handleKeyDown = (eventId,index, e) => {
    
  //   if (eventId === event.id) {
  //     if (e.key === "Backspace" && !code[index]) {
  //       if (index > 0) {
  //         document.getElementById(`input-${index - 1}`).focus();
  //       }
  //     }
  //   }  

    
  // };
  // const [isLoading, setIsLoading] = useState(false);
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setIsLoading(true);
  //   const accessCode = code.join("");
  //  if(accessCode === ''){
  //   Swal.fire({
  //     icon: "error",
  //     title: "Oops...",
  //     text: `Please enter event access code first.`, 
  //   });
  //   setIsLoading(false);
  //   return ;
  //  }
  //   const formData = { code: accessCode, event_id: event.id };

  //   try {
  //     const response = await eventCode(formData);

  //     if (response.success) {
  //       setIsLoading(true);
  //       setisDisable(false);
  //       localStorage.setItem('validatedEventId', event.id);
  //       Swal.fire({
  //         title: "Success!",
  //         text: "Access Code Validated Successfully.",
  //         icon: "success",
  //         confirmButtonText: "OK",
  //       });
  //     } else {
  //       setIsLoading(false);
  //       Swal.fire({
  //         title: "Error!",
  //         text: response.msg.code[0],
  //         icon: "error",
  //         confirmButtonText: "OK",
  //       });
  //     }
  //   } catch (error) {
  //     setIsLoading(false);
  //     console.log(error, "error");
  //     Swal.fire({
  //       title: "Error!",
  //       text: "Network error occurred.",
  //       icon: "error",
  //       confirmButtonText: "OK",
  //     });
  //   }
  // };
  const handleBuyTicketsClick = (id,name,type) => {

        
       setEvtData([]);
       setAvailableTickets([]);


    setticketActiveTab(type);
    setOpenSideModal(true);
 
    // setTimeout(() => {
     
    // }, 1000);
    getHandletickets(id, name);

    const loginUser = localStorage.getItem("userID");
    const cartDataFromLocalStorage = JSON.parse(localStorage.getItem("CartData")) || {};
  
    if (cartDataFromLocalStorage[loginUser] && cartDataFromLocalStorage[loginUser].length > 0) {
      const itemsFromLocalStorage = cartDataFromLocalStorage[loginUser];

      // Retrieve or initialize FakeOrder object
      const fakeOrderFromLocalStorage = JSON.parse(localStorage.getItem('CartData')) || {};
  
      // Update the FakeOrder data for the logged-in user
      fakeOrderFromLocalStorage[loginUser] = itemsFromLocalStorage;
  
      // Save the updated FakeOrder object back to localStorage
      localStorage.setItem('FakeOrder', JSON.stringify(fakeOrderFromLocalStorage));
  
      // Update 'orders' in localStorage for compatibility
      localStorage.setItem("orders", JSON.stringify(itemsFromLocalStorage));
    } 
  };

  const getHandletickets = async (id, name) => {
    setisticketLoading(true);
    try {

      if(!isMobile){
        const div = document.getElementById("homeId");

        if (div) {
          div.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
     

      const ordersFromLocalStorage = JSON.parse(
        localStorage.getItem("items") || "[]"
      );
      localStorage.setItem("orders", JSON.stringify(ordersFromLocalStorage));
      const data = await fetchEventData(id, name);

      // setPageLoading(true);
      const ticketsWithQuantity = data.event.paid_ticket.map((ticket) => ({
        ...ticket,
        quantity: 0,
        selected_price: 0,
      }));

       
      setEvtData(data.event);
      setAvailableTickets(ticketsWithQuantity);
      setisticketLoading(false);
    } catch (error) {
      console.error("Error fetching event data:", error);
    } finally {
      setisticketLoading(false);
      // setisticketLoading(false);
      // setPageLoading(false);
    }
  };
 
  return (
  
    
    <div   key={event.id}>

      {event.security === 1 ? (
       
        <>
          <div key={event.id} className="d-block border-color-maroon border-5 border w-100 bg-white px-3 py-3 booking-event-single-row rounded-3 shadow-normal mb-4 d-none" id="BorderEvent">
            <div className="d-block w-100 premium-tag-wrapper">
              <span className="premium-tag rounded-3 text-18 poppins-600 px-3 py-1 m-0 d-inline-block text-white">
                Exclusive Event
              </span> 
            </div>
           
            <div className="d-block w-100"   
            onClick={
              isMobile && doesUserHaveEvent(localStorage.getItem('userID'), event.id)
                ? () => handleBuyTicketsClick(event.id, event.name,'tickets')
                : undefined
            }
            >
              <div className="d-flex align-items-center justify-content-start flex-wrap" >
                <div className="custom-image6 position-relative">
                <img
                              className="rounded flg position-absolute w-auto rounded-2 top-0 start-0 ms-2 mt-2"
                              id="FlagImages"
                              src={event.country_flag}
                              height="25px"
                            />
                  <img src={event.imagePath + event.image} className="homeimg event-image-exclusive" />
                </div>
                <div className="custom-detail6" >
                  <div className="row">
                    <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                      <div className="d-flex align-items-center justify-content-start mb-3">
                        <div className="d-inline-block">
                          <h6 className="poppins-600 m-0 d-flex align-items-center justify-content-start text-20 text-black" id="flat">
                             {event.name}
                          </h6>
                        </div>
                      </div>
                      <div className="d-flex w-100 align-items-center justify-content-start mb-2">
                        <div className="d-inline-block me-2">
                        {event.address ? 
                          <img src="images/map-icon3.png" height="16px" />
                        
                        : null}
                          
                        </div>
                        <div className="d-inline-block">
                        <h5 className="text-black text-14 mob-text-13 poppins-500 m-0 p-0">
                          {event.address ? (
                            <a
                            className="text-black"
                              href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(event.address)}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {event.address.includes(",")
                                ? event.address.substring(0, event.address.indexOf(","))
                                : event.address}
                            </a>
                          ) : null}
                        </h5>

                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-start mb-2">
                        <div className="d-inline-block">
                          <h6 className="text-black poppins-500 m-0 d-flex align-items-center justify-content-start text-13 ">
                            <img
                              className="me-2"
                              src="images/calendar-icon2.svg"
                              height="18px"
                            />
                            {formattedDate}
                          </h6>
                        </div>
                        {/* <div className="d-inline-block ps-3">
                          <span className="bg-green text-white px-2 py-1 rounded-3 text-14">
                            {event.cate_name}
                          </span>
                        </div> */}
                      </div>
                      <div className="d-flex align-items-center justify-content-start ">
                        <div className="d-inline-block">
                          <h6 className="text-black poppins-500 m-0 d-flex align-items-center justify-content-start text-13 ">
                            <img
                              className="me-2"
                              src="images/tag.png"
                              height="18px"
                            />
                            {event.cate_name}
                          </h6>
                        </div>
                        {/* <div className="d-inline-block ps-3">
                          <span className="bg-green text-white px-2 py-1 rounded-3 text-14">
                            {event.cate_name}
                          </span>
                        </div> */}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-12 mt-lg-0 mt-md-0 ps-lg-0 pe-lg-2">
                      <div className="d-block w-100 text-center mt-lg-0 mt-md-0 mt-3">
                        
                      <div className=" ">

                      <p className="mb-1 poppins-500 text-12"> Enter access code to purchase </p>

              {islogin  !== 'no' ? <>
               {event.security === 1 ? (  !doesUserHaveEvent(localStorage.getItem('userID'), event.id)   ? (
                            <form onSubmit={handleSubmit}  >
                               
                              {code.map((digit, index) => (
                                 
                                <input
                                key={index}
                                id={`input-${keyboardId}-${index}`}  
                                type="text"
                                maxLength="1"
                                className="small-input2 border border-2 rounded-3 mx-1 border-color-maroon text-black text-25 poppins-600 text-center outline-0"
                                value={digit}
                                onChange={(e) => handleChange(keyboardId, event.id, index, e.target.value)}  
                                onKeyDown={(e) => handleKeyDown(keyboardId, event.id, index, e)}  
                              />

                              ))}
                              <input type="hidden" name="event_id" value={event.id} />
                              
                             
                              <button type="submit" className={`w-100 text-13 custom-btn7 mt-2 d-block float-right ${isLoading ? 'disabled' : ''}`} disabled={isLoading}>
                                {isLoading ? (
                                   <ClipLoader color="#F9FCFF" size={30} />
                                ) : (
                                  "Validate Access Code"
                                )}
                              </button>
                            </form>
                          ) : (
                            
                            <>
                            {!isMobile ?  <>
                              <div className="d-block w-100 text-end mt-lg-0 mt-md-0">
                          <button className="rounded-5 text-14 mob-text-12 poppins-500 lh-lg custom-btn-showmore py-1 customMargin customPadding mb-3" onClick={() => handleBuyTicketsClick(event.id, event.name,'info')}> 
                            More Info <i className="ps-1 far fa-share-square" ></i> 
                          </button>
                          <p className="pb-0 col-blue3 poppins-600 mt-0 mb-2 p-0 customFS">  Starting from ${parseFloat(event.start_from).toFixed(2)}                          </p>
                          <button className="btn btn-primary text-13 text-start mob-text-10 poppins-600 customPadding2" onClick={() => handleBuyTicketsClick(event.id, event.name,'tickets')}>
                            Buy Tickets <i className="ps-1 fa fa-arrow-right"></i>
                          </button>
                        </div>
                         </> : '' }
                           
                          </>
                          )
                        ) : 
                        <>
                        <div className="d-inline-block d-flex flex-lg-column flex-md-column flex-row">
                             
                            </div>
                            </>
                            }</> :
                            
                             <>
                               <form onSubmit={handleSubmit}  >
                               
                               {code.map((digit, index) => (
                                  
                                 <input
                                 key={index}
                                 id={`input-${keyboardId}-${index}`}  
                                 type="text"
                                 maxLength="1"
                                 className="small-input2 border border-2 rounded-3 mx-1 border-color-maroon text-black text-25 poppins-600 text-center outline-0"
                                 value={digit}
                                 onChange={(e) => handleChange(keyboardId, event.id, index, e.target.value)}  
                                 onKeyDown={(e) => handleKeyDown(keyboardId, event.id, index, e)}  
                               />
 
                               ))}
                               <input type="hidden" name="event_id" value={event.id} />
                               
                              
                               <button type="submit" className={`custom-btn7 mt-2 ms-2 d-block ${isLoading ? 'disabled' : ''}`} disabled={isLoading}>
                                 {isLoading ? (
                                    <ClipLoader color="#F9FCFF" size={30} />
                                 ) : (
                                   "Validate Access Code"
                                 )}
                               </button>
                             </form>
                             </>
                            
                            
                            }

 
              </div>
                       

                        
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
              <div className="  w-100 mb-3  hidden-important" >
                <div className="d-block w-100">
                  <div
                    className="nav nav-tabs border-0 mb-3 custom-tabs3 hide-for-mobile"
                    id="nav-tab"
                    role="tablist"
                  >
                    <button
                      className={`nav-link ${
                        activeTab === "description" ? "active" : ""
                      }`}
                      onClick={() => handleTabChange("description")}
                    >
                      Description
                    </button>
                    <button
                      className={`nav-link ${
                        activeTab === "photos" ? "active" : ""
                      }`}
                      onClick={() => handleTabChange("photos")}
                    >
                      Photos
                    </button>
                  </div>
                </div>
                <div className="d-block w-100  ">
                  <div className="tab-content hide-for-mobile" id="nav-tabContent">
                  <div
                      className={`tab-pane fade ${
                        activeTab === "description" ? "show active" : ""
                      }`}
                      id="nav-description"
                      role="tabpanel"
                      aria-labelledby="nav-description-tab"
                    >
                      <div className="d-block w-100">
                      <p
                      className="text-14 poppins-400 m-0 p-0 text-muted distrptn"
                      dangerouslySetInnerHTML={{
                        __html: isReadMore ? fullDescription : truncatedDescription,
                      }}
                    />
                    {wordCount > wordLimit && (
                      <button onClick={toggleReadMore} className="btn btn-link p-0 mt-2">
                        {isReadMore ? "Read Less" : "Read More"}
                      </button>
                    )}
                       </div>
                    </div>
                    <div
                      className={`tab-pane fade ${
                        activeTab === "photos" ? "show active" : ""
                      }`}
                      id="nav-photos"
                      role="tabpanel"
                      aria-labelledby="nav-photos-tab"
                    >
                      <div className="row custom-gutters">
                      {event.gallery ? (
                        event.gallery.split(",").map((photo, index) => (
                          <div className="col-lg-3 col-md-4 col-sm-6 col-6" key={event.id}>
                            <div className="d-block w-100">
                              <img
                                src={`http://api.tikkets.com/public/images/upload/${photo}`}
                                className="photos-2 rounded-2 w-100 object-fit-cover"
                                alt={`Photo ${index + 1}`}
                       
                                // onClick={() => handleImageClick(index)}
                              />
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="col-12 text-center">
                          <p className="text-black poppins-500 m-0 justify-content-start text-13">
                            Gallery not found!
                          </p>
                        </div>
                      )}
                          {/* {isOpen && (
                          <Lightbox
                          slides={galleryImages}
                          open={isOpen}
                          close={() => setIsOpen(false)}
                          index={selectedImageIndex}
                          onIndexChange={setSelectedImageIndex}
                          autoPlay={3000}  
                        />
                            )} */}
                     </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-none">
              <h4 className="text-black poppins-600 mt-0 mb-1 text-15 mb-3">
                      {event.security === 1 ? (
                        islogin !== 'no' ? (
                          !doesUserHaveEvent(localStorage.getItem('userID'), event.id) ? (
                            'Enter Access Code'
                          ) : (
                            'Ticket Options'
                          )
                        ) : (
                          'Enter Access Code' // Handle case where event security is 1 but user is not logged in
                        )
                      ) : (
                        'Ticket Options' // Handle case where event security is not 1
                      )}
              </h4>

              {islogin  !== 'no' ? <>
               {event.security === 1 ? (  !doesUserHaveEvent(localStorage.getItem('userID'), event.id)   ? (
                            <form onSubmit={handleSubmit}  >
                               
                              {code.map((digit, index) => (
                                 
                                <input
                                key={index}
                                id={`input-${keyboardId}-${index}`}  
                                type="text"
                                maxLength="1"
                                className="small-input1 border border-2 rounded-3 mx-1 border-color-maroon text-black text-25 poppins-600 text-center outline-0"
                                value={digit}
                                onChange={(e) => handleChange(keyboardId, event.id, index, e.target.value)}  
                                onKeyDown={(e) => handleKeyDown(keyboardId, event.id, index, e)}  
                              />

                              ))}
                              <input type="hidden" name="event_id" value={event.id} />
                              
                             
                              <button type="submit" className={`custom-btn7 mt-2 ms-2 d-block ${isLoading ? 'disabled' : ''}`} disabled={isLoading}>
                                {isLoading ? (
                                   <ClipLoader color="#F9FCFF" size={30} />
                                ) : (
                                  "Validate Access Code"
                                )}
                              </button>
                            </form>
                          ) : (
                            
                            <>
                            {/* {isMobile && event.ticket.length > 1 ? (
                            <Slider {...settings}> 
                              {event.ticket.map((ticket) => (
                                <TicketMOb
                                mainIndex={ticket.id}
                                  ticket={ticket}
                                  selectedTickets={selectedTickets}
                                  handleTicketSelection={handleTicketSelection}
                                  event={event}
                                  isDisable={false}
                                  setisDisable={setisDisable}
                                  verifyEvent={verifyEvent}
                                />
                              ))}
                            </Slider>
                          ) : (
                            event.ticket.map((ticket) => (
                              <Ticket
                              mainIndex={ticket.id}
                                ticket={ticket}
                                selectedTickets={selectedTickets}
                                handleTicketSelection={handleTicketSelection}
                                event={event}
                                isDisable={false}
                                setisDisable={setisDisable}
                                verifyEvent={verifyEvent}
                              />
                            ))
                          )} */}
                          </>
                          )
                        ) : 
                        <>
                        <div className="d-inline-block d-flex flex-lg-column flex-md-column flex-row 111">
                             
                            </div>
                            </>
                            }</> :
                            
                             <>
                               <form onSubmit={handleSubmit}  >
                               
                               {code.map((digit, index) => (
                                  
                                 <input
                                 key={index}
                                 id={`input-${keyboardId}-${index}`}  
                                 type="text"
                                 maxLength="1"
                                 className="small-input1 border border-2 rounded-3 mx-1 border-color-maroon text-black text-25 poppins-600 text-center outline-0"
                                 value={digit}
                                 onChange={(e) => handleChange(keyboardId, event.id, index, e.target.value)}  
                                 onKeyDown={(e) => handleKeyDown(keyboardId, event.id, index, e)}  
                               />
 
                               ))}
                               <input type="hidden" name="event_id" value={event.id} />
                               
                              
                               <button type="submit" className={`custom-btn7 mt-2 ms-2 d-block ${isLoading ? 'disabled' : ''}`} disabled={isLoading}>
                                 {isLoading ? (
                                    <ClipLoader color="#F9FCFF" size={30} />
                                 ) : (
                                   "Validate Access Code"
                                 )}
                               </button>
                             </form>
                             </>
                            
                            
                            }

 
              </div>
            </div>
            <div className="d-block w-100"></div>
          </div>
        </>
      ) : (
        <>
        
        <div  key={event.id}  className="d-block w-100 bg-white px-3 py-3 booking-event-single-row rounded-3 shadow-normal mb-4" {...(isMobile && { onClick: () => handleBuyTicketsClick(event.id, event.name,'tickets') })}>            
          <div className="d-block w-100">
              <div className="d-flex align-items-center justify-content-start flex-wrap">
                <div className="custom-image6 position-relative">
                  <img
                    className="homeimg"
                    src={event.imagePath + event.image}
                    alt="Event"
                  />
                   <img
                              className="ms-2 mt-2 position-absolute top-0 rounded-2 start-0 flg"
                              id="FlagImages"
                              src={event.country_flag}
                              height="25px"
                              alt="Flag"
                            />
                </div>
                <div className="custom-detail6" >
                  <div className="row">
                    <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                      <div className="d-flex align-items-center justify-content-start mb-3">
                        <div className="d-inline-block">
                          <h6 className="poppins-600 m-0 d-flex align-items-center justify-content-start text-20 text-black mob-text-18">
                           
                            {event.name}
                          </h6>
                        </div>
                      </div>
                      <div className="d-flex w-100 align-items-center justify-content-start mb-2">
                        <div className="d-inline-block me-2">
                          {event.address ? 
                           <img
                           src="images/map-icon3.png"
                           height="16px"
                           alt="Map"
                         />
                        
                        : null}
                         
                        </div>
                        <div className="d-inline-block">
                          <h5 className="text-black text-14 mob-text-13 poppins-500 m-0 p-0">
                            

                              {event.address ? (event.address.includes(",")
                                  ? event.address.substring(0, event.address.indexOf(","))
                                  : event.address
                                )
                                : null
                              }

                          </h5>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-start mb-2">
                        <div className="d-inline-block">
                          <h6 className="text-black poppins-500 m-0 d-flex align-items-center justify-content-start text-13">
                            <img
                              className="me-2"
                              src="images/calendar-icon2.svg"
                              height="18px"
                              alt="Calendar"
                            />
                            {formattedDate}
                          </h6>
                        </div>
                        {/* <div className="d-inline-block ps-3">
                          <span className="bg-green text-white px-2 py-1 rounded-3 text-14">
                            {event.cate_name}
                          </span>
                        </div> */}
                      </div>
                      <div className="d-flex align-items-center justify-content-start ">
                        <div className="d-inline-block">
                          <h6 className="text-black poppins-500 m-0 d-flex align-items-center justify-content-start text-13 ">
                            <img
                              className="me-2"
                              src="images/tag.png"
                              height="18px"
                            />
                            {event.cate_name}
                          </h6>
                        </div>
                        {/* <div className="d-inline-block ps-3">
                          <span className="bg-green text-white px-2 py-1 rounded-3 text-14">
                            {event.cate_name}
                          </span>
                        </div> */}
                      </div>
                      
                    </div>
                    
                    <div className="col-lg-4 col-md-4 col-sm-4 col-4 mt-lg-0 mt-md-0 ps-0 d-mob-none">
                        <div className="d-block w-100 text-end mt-lg-0 mt-md-0">
                          <button className="rounded-5 text-14 mob-text-12 poppins-500 lh-lg custom-btn-showmore py-1 customMargin customPadding mb-3" onClick={() => handleBuyTicketsClick(event.id, event.name,'info')}> 
                            More Info <i className="ps-1 far fa-share-square" ></i> 
                          </button>
                          <p className="pb-0 col-blue3 poppins-600 mt-0 mb-2 p-0 customFS">  Starting from ${parseFloat(event.start_from).toFixed(2)}                          </p>
                          <button className="btn btn-primary text-13 text-start mob-text-10 poppins-600 customPadding2" onClick={() => handleBuyTicketsClick(event.id, event.name,'tickets')}>
                            Buy Tickets <i className="ps-1 fa fa-arrow-right"></i>
                          </button>
                        </div>
                        <div className="d-inline-block d-flex flex-lg-column flex-md-column flex-row">
                          {/* <button
                            className="custom-btn8 mb-2 me-lg-0 me-md-0 me-2"
                            onClick={() => handleAddToCart(event.id)}
                          >
                            Add to Cart
                            <i className="fa fa-arrow-right ms-2"></i>
                          </button>
                          <button
                            className="custom-btn9 mb-2 me-lg-0 me-md-0 me-2"
                            onClick={handlebookNow}
                          >
                            Book Now
                            <i className="fa fa-arrow-right ms-2"></i>
                          </button> */}
                        </div>



                        {/* start  */}
                         {/* {event.security === 1 ? (
                          isDisable && !verifyEvent.includes(event.id) ? (
                            <div className="d-inline-block d-flex flex-lg-column flex-md-column flex-row">
                            <form onSubmit={handleSubmit}>
                          
                              {code.map((digit, index) => (
                                
                                <input
                                key={index}
                                id={`input-${keyboardId}-${index}`}  
                                type="text"
                                maxLength="1"
                                className="small-input1 border border-2 rounded-3 mx-1 border-color-maroon text-black text-40 poppins-600 text-center outline-0"
                                value={digit}
                                onChange={(e) => handleChange(keyboardId, event.id, index, e.target.value)}  
                                onKeyDown={(e) => handleKeyDown(keyboardId, event.id, index, e)}  
                              />
                              ))}
                              <input type="hidden" name="event_id" value={event.id} />
                              
                              
                              <button type="submit" className="custom-btn7 mt-2" disabled={isLoading}>
                                {isLoading ? (
                               
                                  <span className="mt-2">
                                    <ClipLoader color="#F9FCFF " size={30} />
                                  </span>
                                ) : (
                                  "Validate Your Access Code"
                                )}
                              </button>
                            </form>
                            </div>
                          ) : (
                            <div className="d-flex justify-content-lg-end justify-content-md-end justify-content-start w-100 ps-lg-5 ps-md-5 ps-0">
                              <div className="d-inline-block d-flex flex-lg-column flex-md-column flex-row">
                                <button
                                  className="custom-btn8 mb-2 me-lg-0 me-md-0 me-2"
                                  onClick={() => handleAddToCart(event.id)}
                                >
                                  Add to Cart <i className="fa fa-arrow-right ms-1"></i>
                                </button>
                                <button
                                  className="custom-btn9 mb-2 me-lg-0 me-md-0 me-2"
                                  onClick={handlebookNow}
                                >
                                  Book Now <i className="fa fa-arrow-right ms-1"></i>
                                </button>
                              </div>
                              </div>
                          )
                        ) : 
                        <>
                        <div className="d-flex justify-content-lg-end justify-content-md-end justify-content-start w-100 ps-lg-5 ps-md-5 ps-0">
                        <div className="d-inline-block d-flex flex-lg-column flex-md-column flex-row">
                              <button
                                className="custom-btn8 mb-2 me-lg-0 me-md-0 me-2"
                                onClick={() => handleAddToCart(event.id)}
                              >
                                Add to Cart <i className="fa fa-arrow-right ms-1"></i>
                              </button>
                              <button
                                className="custom-btn9 mb-2 me-lg-0 me-md-0 me-2"
                                onClick={handlebookNow}
                              >
                                Book Now <i className="fa fa-arrow-right ms-1"></i>
                              </button>
                            </div>

                        </div>
                       
                            
                        </>
                        
                        } */}
                     
                    </div>
                  </div>
                </div>
              </div>
              <div className="  w-100 mb-3  hidden-important" >
                <div className="d-block w-100">
                  <div
                    className="nav nav-tabs border-0 mb-3 custom-tabs3"
                    id="nav-tab"
                    role="tablist"
                  >
                    <button
                      className={`nav-link ${
                        activeTab === "description" ? "active" : ""
                      }`}
                      onClick={() => handleTabChange("description")}
                    >
                      Description
                    </button>
                    <button
                      className={`nav-link ${
                        activeTab === "photos" ? "active" : ""
                      }`}
                      onClick={() => handleTabChange("photos")}
                    >
                      Photos
                    </button>
                  </div>
                </div>
                <div className="d-block w-100">
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className={`tab-pane fade ${
                        activeTab === "description" ? "show active" : ""
                      }`}
                      id="nav-description"
                      role="tabpanel"
                      aria-labelledby="nav-description-tab"
                    >
                      <div className="d-block w-100">
                      <p
                      className="text-14 poppins-400 m-0 p-0 text-muted distrptn"
                      dangerouslySetInnerHTML={{
                        __html: isReadMore ? fullDescription : truncatedDescription,
                      }}
                    />
                    {wordCount > wordLimit && (
                      <button onClick={toggleReadMore} className="btn btn-link p-0 mt-2">
                        {isReadMore ? "Read Less" : "Read More"}
                      </button>
                    )}
                      </div>
                    </div>
                    <div
                      className={`tab-pane fade ${
                        activeTab === "photos" ? "show active" : ""
                      }`}
                      id="nav-photos"
                      role="tabpanel"
                      aria-labelledby="nav-photos-tab"
                    >
                      <div className="row custom-gutters">
                        {event.gallery ? (
                          event.gallery.split(",").map((photo, index) => (
                            <div
                              className="col-lg-3 col-md-4 col-sm-6 col-6"
                              key={index}
                            >
                              <div className="d-block w-100">
                                <img
                                  src={`http://api.tikkets.com/public/images/upload/${photo}`}
                                  className="photos-2 rounded-2 w-100 object-fit-cover"
                                  alt={`Photo ${index + 1}`}
                                   
                                  onClick={() => handleImageClick(index)}
                                />
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="col-12 text-center">
                            <p className="text-black poppins-500 m-0  justify-content-start text-13">
                              Gallery not found!
                            </p>
                          </div>
                        )}
                             {isOpen && (
                             <Lightbox
                             slides={galleryImages}
                             open={isOpen}
                             close={() => setIsOpen(false)}
                             index={selectedImageIndex}
                             onIndexChange={setSelectedImageIndex}
                             autoPlay={3000} // automatically advances to the next slide after 3 seconds
                           />
                            )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="d-block w-100 slick-slider2">
                <div className="Ticket Options"> 

                  Ticket Options
                </div>
                
              </div>   */}
             
              <h4 className="text-black poppins-600 mt-0 mb-1 text-15 mb-3 hidden-important">Ticket Options</h4>
              <div className=" w-100 slick-slider2 hidden-important">
                
                {/* {isMobile && event.ticket.length > 1 ? (
                  <Slider {...settings}>
                    {event.ticket.map((ticket,index) => (
                      <TicketMOb
                        mainIndex={ticket.id}
                        ticket={ticket}
                        selectedTickets={selectedTickets}
                        handleTicketSelection={handleTicketSelection}
                        event={event}
                        isDisable={isDisable}
                        setisDisable={setisDisable}
                        verifyEvent={verifyEvent}
                      />
                    ))}
                  </Slider>
                ) : (
                  event.ticket.map((ticket,index) => (
                    <Ticket
                    mainIndex={ticket.id} 
                      ticket={ticket}
                      selectedTickets={selectedTickets}
                      handleTicketSelection={handleTicketSelection}
                      isDisable={isDisable}
                      setisDisable={setisDisable}
                      event={event}
                      verifyEvent={verifyEvent}
                    />
                  ))
               
                )} */}
              </div>
            </div>
            <div className="d-block w-100"></div>
          </div>
        </>
      )}
    </div>
  );
};

export default EventComponent;
