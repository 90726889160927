import { API_ENDPOINTS } from './api';


export async function fetchAllCategories() {
    try {
      const response = await fetch(`${API_ENDPOINTS.ALLCATEGORY_FETCH}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data.data;
    } catch (error) {
      console.error("Error fetching categories data:", error);
      throw error; // Re-throw the error so the caller can handle it
    }
  }
  export async function fetchEventsByIdAndName(id, name, page, itemsPerPage) {
    try {
      const response = await fetch(`${API_ENDPOINTS.EVENT_FETCH_BY_ID}${id}/${name}?page=${page}&itemsPerPage=${itemsPerPage}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching events data:", error);
      throw error; // Re-throw the error so the caller can handle it
    }
  }
  
  