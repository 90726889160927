// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    margin-left: -95px;
  }
  
  .loader {
    width: 50px;
    height: 50px;
    border: 4px solid transparent;
    border-top: 4px solid red; /* Change the color as needed */
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  .loader-inner {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 4px solid transparent;
    border-top: 4px solid #f3f3f3;  
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .mian .loader-container{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: auto;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/LoadingSpinner/LoadingSpinner.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,6BAA6B;IAC7B,yBAAyB,EAAE,+BAA+B;IAC1D,kBAAkB;IAClB,kCAAkC;EACpC;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,6BAA6B;IAC7B,6BAA6B;IAC7B,kCAAkC;EACpC;;EAEA;IACE,KAAK,uBAAuB,EAAE;IAC9B,OAAO,yBAAyB,EAAE;EACpC;EACA;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,YAAY;EACd","sourcesContent":[".loader-container {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    height: 50vh;\r\n    margin-left: -95px;\r\n  }\r\n  \r\n  .loader {\r\n    width: 50px;\r\n    height: 50px;\r\n    border: 4px solid transparent;\r\n    border-top: 4px solid red; /* Change the color as needed */\r\n    border-radius: 50%;\r\n    animation: spin 1s linear infinite;\r\n  }\r\n  \r\n  .loader-inner {\r\n    width: 100%;\r\n    height: 100%;\r\n    border-radius: 50%;\r\n    border: 4px solid transparent;\r\n    border-top: 4px solid #f3f3f3;  \r\n    animation: spin 2s linear infinite;\r\n  }\r\n  \r\n  @keyframes spin {\r\n    0% { transform: rotate(0deg); }\r\n    100% { transform: rotate(360deg); }\r\n  }\r\n  .mian .loader-container{\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    width: 100%;\r\n    height: 100%;\r\n    margin: auto;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
