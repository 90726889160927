import { API_ENDPOINTS } from './api';


export const fetchFaqData = async () => {
    try {
      const response = await fetch(API_ENDPOINTS.FaqData)
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching blog data:", error);
      throw error;
    }
  };
  