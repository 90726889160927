import React, { useEffect } from 'react';
import FrontRoutes from './routes/FrontRoutes';
import { ProfileProvider } from './hooks/ProfileContext';
// import FixedBottom from './components/FixedBottom' <FixedBottom />; 
import './App.css';

function App() { 
  

  return (
    <div>
      <ProfileProvider>
    <FrontRoutes />
    </ProfileProvider>

    </div>
  );
}

export default App;

