import React, { useState, useEffect,useContext } from "react";
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import HeaderComponent from '../HeaderComponent/HeaderComponent.js';
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { fetchUserData, editUserProfile } from "../../services/profileService";
import FooterComponent from '../FooterComponent/FooterComponent.js';
import { getCountryCallingCode } from 'react-phone-number-input/input';
import Swal from "sweetalert2";
import { ProfileContext } from '../../hooks/ProfileContext';
const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  phone: Yup.string().required('Phone number is required'),
});
 
const NewUserProfileComponent = () => {
  const { handleSubmit, control, setValue, register, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [DataLoad, setDataLoad] = useState(false);
  const [userData, setUserData] = useState({
    id: "",
    phone: "",
    bio: "",
    email: "",
    image: "",
    imagePath: "",
    country: "",
  });
  const { setImagePath } = useContext(ProfileContext);
  const [Countries, setCountries] = useState([]);
  const [defaultImagePath, setDefaultImagePath] = useState("");
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState('+1');
  const [buttonloading, setbuttonloading] = useState(false);
  const handleCountryChange = (country) => {
    if (country) {
      const countryCallingCode = getCountryCallingCode(country);
      setCountryCode('+' + countryCallingCode);
    } else {
      setCountryCode('');
    }
  };
 
  const fetchData = async () => {
    try {
      setDataLoad(true);
      const token = localStorage.getItem("authToken");
      const data = await fetchUserData(token);
      setCountries(data.countries);
      setUserData({
        ...data.user,
        country: data.user.countryData ? data.user.countryData.country : data.user.country
      });
      if (data.user.image) {
        const fullImagePath = data.user.imagePath + data.user.image;
        setImagePath(fullImagePath);
      } else {
        setImagePath('/images/notlogin.png');
      }
      setDefaultImagePath(data.user.imagePath + data.user.image); // Set the default image path
    } catch (error) {
      
    }
    setDataLoad(false);
  };
  const token = localStorage.getItem("authToken");
  useEffect(() => {
    if(token){
      fetchData();
    }
    
  }, [token]);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    document.body.classList.add("header-bgms");

    return () => {
      document.body.classList.remove("header-bgms");
    };
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); // Change loading state to false after 2 seconds
    }, 2000); // 2 seconds delay

    return () => clearTimeout(timer); // Clear the timer if the component unmounts
  }, []);
  useEffect(() => {
    setValue('bio', userData.bio || '');
    setValue('email', userData.email || '');
    setValue('phone', userData.phone || '');
    setValue('country', userData.location || '');
    setValue('image', userData.image || '/frontend/images/notlogin.png');
  }, [userData, setValue]);

  const onSubmit = (data) => {
    const token = localStorage.getItem("authToken");
    const formData = new FormData();

    formData.append('bio', data.bio);
    formData.append('email', data.email);
    formData.append('phone', data.phone);
    formData.append('country', data.country);

    if (data.image.length > 0) {
      formData.append('image', data.image[0]);
    } else {
      formData.append('image', userData.image);
    }
    setbuttonloading(true);
    editUserProfile(token, formData)
      .then(() => {
        fetchData();  
         
        setbuttonloading(false);
        Swal.fire({
          title: 'Success',
          text: 'Profile updated successfully!',
          icon: 'success',
          confirmButtonText: 'OK'
      });
      })
      .catch((error) => {
        setError(error.message);
        setbuttonloading(false);
      });
  };

  return (
    <div>
      <Helmet>
        <link href="/css/bootstrap.min.css" rel="stylesheet" />
      </Helmet>
      {loading ? (
        <section className="pt-lg-5 pt-md-5 pt-3" style={{ background: '#f9fcff' }}>
          <div className="container3">
            <div className="block-element m-b-30">
            </div>
            <div className="block-element loader-mobile-screen" style={{ marginTop: '80px' }}>
              <LoadingSpinner />
            </div></div>
        </section>
      ) : (
        <>
          <HeaderComponent />
          {DataLoad ? (
             <section className="pt-lg-5 pt-md-5 pt-3" style={{ background: '#f9fcff' }}>
             <div className="container3">
               <div className="block-element m-b-30">
               </div>
               <div className="block-element loader-mobile-screen" style={{ marginTop: '80px' }}>
                 <LoadingSpinner />
               </div></div>
           </section>
         ) : (
          <>
          <section className="pad-top-60 pad-bot-60" style={{ backgroundColor: "#f9fcff" }}>
            <div className="container profile-page">
                <div className="row profile-container">
                  <div className="col-md-4 mb-4">
                    <div className="profile-card profile-pic py-5">
                      <img src={userData.imagePath + userData.image || '/images/notlogin.png'} alt="Profile Picture" />
                      <small className="text-muted mt-2 text-center">Allowed *.jpeg, *.jpg, *.png, *.gif<br /></small>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="profile-card profile-details">
                      <h5 className="text-black poppins-600 text-20 mb-4 mt-0">About</h5> 
                      <p className="text-14 poppins-400 mb-4 p-0 text-muted">{userData.bio  ||''}</p>
                      <div>
                        <p className="text-14 poppins-600 mb-2 p-0 text-black"> 
                          <img src={userData.country_flag || '/images/flag.jpg'} alt="Flag" className="mr-2 w-7" />{userData.country ||" Trinidad Tobago" }
                        </p>
                        <p className="text-14 poppins-600 mb-2 p-0 text-black"><img src="/images/envelope-icon-14.png" alt="Flag" className="mr-2 w-7" />{userData.email ||'xxx@email.com' }</p>
                        <p className="text-14 poppins-600 mb-2 p-0 text-black"><img src="/images/pngimg.png" alt="Flag" className="mr-2 w-7" />{userData.phone || '+1 868 xxx xxx' }</p>
                      </div>

                        <div className="btn-container text-right desktop-only">
                          <Link className="btn btn-warning mr-2 poppins-600"  to='/user/change-password'>Reset Password</Link>
                          <Link className="btn btn-primary poppins-600" to='/user/edit-profile'>Edit Profile</Link> 
                        </div>
                    </div>
                  </div>
                  <div className="mobile-only w-100 mt-4">
                    <div className="px-3 btn-container text-right d-flex">
                      <Link className="btn btn-warning mr-2 poppins-600 w-100" to='/user/change-password'>Reset Password</Link>
                      <Link className="btn btn-primary poppins-600 w-100" to='/user/edit-profile'>Edit Profile</Link>
                    </div>
                  </div>
                  
                </div>

            </div>
          </section>
          </>
      )}
          <FooterComponent />
        </>
      )}
    </div>
  );
}

export default NewUserProfileComponent;
