import React, { useState, useEffect,useContext } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { forgotPassword, login } from '../services/authService';
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup"; // Import yupResolver from @hookform/resolvers
import * as yup from "yup";
import { Modal } from "react-bootstrap";
import "./Register.css"
import ForgotPassword from "./ForgotPassword";
import { fetchUserData } from "../services/profileService";
import { ProfileContext } from '../hooks/ProfileContext';


const schema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
  password: yup.string().required("Password is required")
});

const NewLogin = ({ show, handleClose, handleSwitchToRegister, handleSwitchToLoginModel }) => {

  const [loading, setLoading] = useState(false);
  const [Isloading, setloading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [forgotPasswordLoading, setForgotPasswordLoading] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");

  const { setImagePath } = useContext(ProfileContext);


 

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleForgotPasswordClick = () => {
    setShowForgotPasswordModal(true);
    handleClose();
  };
  const handleForgotPasswordClose = () => {
    setShowForgotPasswordModal(false);
  };
  
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setloading(false); // Change loading state to false after 2 seconds
    }, 2000); // 2 seconds delay

    return () => clearTimeout(timer); // Clear the timer if the component unmounts
  }, []);
  
  const handleLogin = async ({ email, password }) => {

    setLoading(true);
    setError("");

    try {

      const data = await login(email, password);

      localStorage.setItem("userID", data.user.user_id);
      setLoading(false);


      switch (data.role) {
      case "user":
        const checkOutNotAuth = localStorage.getItem('checkOutNotAuth');
        fetchUser();
        if (checkOutNotAuth == "1") {
          navigate("/checkout-card");
          localStorage.removeItem('checkOutNotAuth');
        } else {
          window.location.href = "/";
        }

        break;

      default:
        alert("Invalid Role: " + data.role);
        break;
      }
    } catch (error) {
      console.error("Login failed:", error);
      if (error.response && error.response.status === 401) {
        Swal.fire("Unauthorized", "Invalid Credentials", "error");
        setLoading(false);
      } else {
        Swal.fire("Error", "Login failed. Please try again later.", "error");
        setLoading(false);
      }
    } finally {
      // setSubmitting(false);
      setLoading(false);
    }
  };


  const fetchUser = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const data = await fetchUserData(token);
 
      const fullImagePath = data.user.imagePath + data.user.image;
      setImagePath(fullImagePath);
      
    } catch (error) {
      console.log(error);
    }
  };
 
  const handleSwitchToLoginAndClose = () => {
    setShowForgotPasswordModal(false);
    handleSwitchToLoginModel();
  };
  const handleForgotPasswordSubmit = async (event) => {
    event.preventDefault();
    setForgotPasswordLoading(true);

    const { success, message } = await forgotPassword(forgotPasswordEmail);
    if (success) {
      Swal.fire("Success", message, "success");
      setShowForgotPasswordModal(false);
    } else {
      Swal.fire("Error", message, "error");
    }

    setForgotPasswordLoading(false);
  };
  return (
    <>
      <Modal className="register" show={show} onHide={handleClose}>
      <Modal.Header id="modelHeaderDesgn" closeButton={false}>
    <button type="button" id="modelHeaderButton" className="custom-close" onClick={handleClose}>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-x"
      >
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
      </svg>
     </button>
  </Modal.Header>
      <Modal.Body>
      <form className="registration-form" onSubmit={handleSubmit(handleLogin)}>
          <h2>Sign in to <img className="auth-logo" src="/images/logo-black.png" /></h2>
          <p>User Account</p>
          <p>New user? <Link href="#" id="UserAccountAbc" onClick={handleSwitchToRegister} >Create an account</Link></p>

          <input type="email" {...register('email')} placeholder="Email address" className={`${errors.email ? 'is-invalid' : ''}`} />
          {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
          <div className="password-field">
            <input type={showPassword ? 'text' : 'password'} {...register('password')} className={`${errors.password ? 'is-invalid' : ''}`} placeholder="Password" />
            <span
              className="toggle-password"
              onClick={togglePasswordVisibility}>
             {!showPassword ? ' 👁' : <i class="fa fa-eye-slash fontSize" aria-hidden="true"></i> }
            </span>
           </div>
           {errors.password && <div className="invalid-feedback">{errors.password.message}</div>}
           <p className="forgot-password" id="forgot-password">
              <Link onClick={handleForgotPasswordClick}>Forgot Password?</Link>
            </p>
          
          <button disabled={loading}>
            {loading ? (
              <div className="spinner-border text-light" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              'Login'
            )}
          </button>
          <p className="terms">
            <span className="text-muted">
            By signing in, I agree to
            </span> <a href="#">Terms of Use</a> and 
            <a href="#">Privacy Policy</a>.
          </p>          <div className="by-signing-up-container1">
            Copyright © 2023 Made with ❤️
            <a
              className="by-kanoo"
              href="https://kanoopays.com/"
              target="_blank"
            >
              <span className="by-kanoo1">By Kanoo.</span>
            </a>
          </div>
        </form>
      </Modal.Body>
      </Modal>
      <ForgotPassword
        showForgotPasswordModal={showForgotPasswordModal}
        handleForgotPasswordClose={handleForgotPasswordClose}
        handleForgotPasswordSubmit={handleForgotPasswordSubmit}
        forgotPasswordLoading={forgotPasswordLoading}
        forgotPasswordEmail={forgotPasswordEmail}
        setForgotPasswordEmail={setForgotPasswordEmail}
        handleSwitchToLoginAndClose = {handleSwitchToLoginAndClose}
      />
    </>
  );
};

export default NewLogin;