import React, { useEffect, useState, useRef, useContext } from "react";
import HeaderNewComponent from "../HeaderComponent/HeaderNewComponent.js";
import FooterComponent from "../FooterComponent/FooterComponent.js";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { fetchHomeData, fetchHomeDataReset } from "../../services/homeService";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { fetchEventData } from "../../services/EventTikketService";
import Swal from "sweetalert2";
import { useOrders } from "../../hooks/SettingContext";
import { Helmet } from "react-helmet";
import { ProfileContext } from "../../hooks/ProfileContext";
import { fetchUserData } from "../../services/profileService"; 
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
  
function HotelComponent() {
  const [events, setEvents] = useState([]);
  const [blogs, setBlogs] = useState([]);

  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isSearchCompleted, setIsSearchCompleted] = useState(false);

  const navigate = useNavigate();

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState("");
  const [selectedCountry, setSelectedCountry] = useState([]);

  const [Country, setCountry] = useState([]);
  const [from, setfrom] = useState("");
  const [to, setTo] = useState("");

  const [totalRecords, setTotal] = useState("");
  const [availableTickets, setAvailableTickets] = useState([]);
  const [evtData, setEvtData] = useState(null);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [prevPageUrl, setPrevPageUrl] = useState(null);
  const [itemsPerPage, setitemsPerPage] = useState(5);
  const [orders, setOrders] = useState([]);
  const [selectedcounties, setselectedcounties] = useState([]);
  const [countriesCount, setcountriesCount] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [serviceFee, setServiceFee] = useState(0);
  const [tickets, setTickets] = useState([]);
  const [countryOptions, setcountryOptions] = useState([]);
  const [allCategories, setAllcategories] = useState([]);
  const [event_type, setType] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const { ordersCount, updateOrdersCount } = useOrders();
  const [ShowModal, setshowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isTicketVisible, setIsTicketVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const { setImagePath } = useContext(ProfileContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedTickets, setSelectedTickets] = useState([]);
  const [SearchCountry, setSearch] = useState(0);
  const [minPrice, setMinPrice] = useState(1);
  const [maxPrice, setMaxPrice] = useState(1000);
  const [selectedCountryValues, setSelectedCountryValues] = useState([0]);

   

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);


  useEffect(() => {
      
    const loginUser = localStorage.getItem("userID");
    const itemsJSON = localStorage.getItem("CartData");

    let totalQuantity = 0;
  
    if (itemsJSON) {
      const items = JSON.parse(itemsJSON);
  
      // Filter items by loginUser
      if (items[loginUser]) {
        items[loginUser].forEach((item) => {
          totalQuantity += item.quantity;
        });
      }
    }
  
    localStorage.setItem("ordersCount", totalQuantity);
    updateOrdersCount(totalQuantity); 

  }, []);
 

 
  const [Mob_filter, setMob_filter] = useState(false);
  const handleMobfilter = () => {
    setMob_filter(true);
  };
  const handleMobfilterOff = () => {
    setMob_filter(false);
  };
  const [showModal, setShowModal] = useState(false);
  const bodyStyle = showModal ? { backgroundColor: "rgba(0, 0, 0, 0.5)" } : {};

  const openModal = () => {
    if (orders && orders.length > 0) {
      setShowModal(true);

      setSelectedTickets([]);
      setModalVisible(false);
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `You have not selected any tickets.`,
      });
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const [startDate, setStartDate] = useState(null);  
  const startDateInputRef = useRef(null);

  const handleStartDateChange = (date) => {
    setStartDate(date);  
  };
  
  const [endDate, setEndDate] = useState(null);
  const endDateInputRef = useRef(null);
  
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const [clickCount, setClickCount] = useState(0);
  const handleItemsPerPageChange = () => {
    // const div = document.getElementById("thisThat");

    // if (div) {
    //   div.scrollIntoView({ behavior: "smooth", block: "start" });
    // }
    const newValue = clickCount === 0 ? 10 : 20;

    setitemsPerPage(newValue);
    setClickCount(clickCount + 1);
  };

  const handleSearch = async () => {
    if (
      selectedCountry.length === 0 &&
      startDate === "" &&
      endDate === "" &&
      searchText === ""
    ) {
      return;
    }

    try {
      setIsTableLoading(true);
      setIsSearchCompleted(false);

      const data = await fetchHomeData(
        currentPage,
        itemsPerPage,
        selectedCountry.join(","),
        startDate,
        endDate,
        searchText,
        cate,
        event_type,
        sortOrder,
        minPrice,
        maxPrice
      );
      setEvents(data.events.data);
      setTotalPages(data.events.last_page);
      setPrevPageUrl(data.events.prev_page_url);
      setNextPageUrl(data.events.next_page_url);
      setfrom(data.events.from);
      setTo(data.events.to);
      setTotal(data.events.total);
      setAllcategories(data.categories);
      setBlogs(data.blog);
      setIsSearchCompleted(true);
      setcountriesCount(data.events.data.length);
      setselectedcounties(data.selectedcounties);
    } catch (error) {
      console.error("Error fetching home data:", error);
    } finally {
      setIsTableLoading(false);
    }
  };

  const [searchText, setSearchText] = useState("");
  const [Text, setText] = useState("");
  const inputRef = useRef(null);
  const clearInput = async () => {
    if (inputRef.current) inputRef.current.value = "";
    if (startDateInputRef.current) startDateInputRef.current.value = "";
    if (endDateInputRef.current) endDateInputRef.current.value = "";
    if (inputRef.current) inputRef.current.value = "";

    setcountriesCount(0);
    // setMaxPrice(5000);
    // setitemsPerPage(5);

    setSelectedCountry([]);
    setselectedcounties([]);
    setCountry([]);
    setEndDate(null);
    setStartDate(null);
    // setType('');
    setSearchText("");

    setIsTableLoading(false);
    setIsSearchCompleted(false);
    setSearchText("");
    handleClearFunction();
  };

  const handleClearFunction = async () => {
    try {
      setIsTableLoading(true);
      setIsSearchCompleted(false);

      const data = await fetchHomeDataReset(currentPage, itemsPerPage);
      setEvents(data.events.data);
      setTotalPages(data.events.last_page);
      setPrevPageUrl(data.events.prev_page_url);
      setNextPageUrl(data.events.next_page_url);
      setfrom(data.events.from);
      setTo(data.events.to);
      setTotal(data.events.total);
      setAllcategories(data.categories);
      setBlogs(data.blog);
      setIsSearchCompleted(false);
      setIsTableLoading(true);
      setcountriesCount(data.events.data.length);
      setselectedcounties(data.selectedcounties);
    } catch (error) {
      console.error("Error fetching home data:", error);
    } finally {
      setIsTableLoading(false);
    }
  };

  const handleChangeInput = (e) => setSearchText(e.target.value);
  const [cate, setCate] = useState("");

  const [selectedCategories, setSelectedCategories] = useState([]);

 

  const [sortOrder, setSortOrder] = useState("");
 
 

  const [showFilters, setShowFilters] = useState(true);

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const [isFilterVisible, setIsFilterVisible] = useState(false);

  const toggle_Filter = () => {
    setIsFilterVisible(!isFilterVisible);
  };

  
 
  
  

  const [isOpen, setIsOpen] = useState(true);

  const toggleCountry = (value) => {
    if (value === "all") {
      if (selectedCountry.includes("all")) {
        setSelectedCountry([]);
      } else {
        setSelectedCountry(["all"]);
      }
      return;
    }

    const updatedSelectedCountry = selectedCountry.filter(
      (country) => country !== "all"
    );

    if (updatedSelectedCountry.includes(value)) {
      setSelectedCountry(
        updatedSelectedCountry.filter((country) => country !== value)
      );
    } else {
      setSelectedCountry([...updatedSelectedCountry, value]);
    }
  };

  const handleRemoveCountry = async (countryValue) => {
    console.log(selectedCountryValues);
    setSelectedCountry((prevSelectedCountry) => {
      const newSelectedOptions = prevSelectedCountry.filter(
        (option) => option !== countryValue
      );
      if (newSelectedOptions.length === 0) {
        setSearch(1);
        setIsSearchCompleted(true);
        setIsTableLoading(true);
        clearInput();
        return [];
      }
      return newSelectedOptions;
    });

    setSelectedCountryValues((prevSelectedCountry) => {
      const newSelectedOptions = prevSelectedCountry.filter(
        (option) => option !== countryValue
      );
      if (newSelectedOptions.length === 0) {
        return [];
      }
      return newSelectedOptions;
    });
    setSearch(1);
  };

  useEffect(() => {
    if (SearchCountry === 1) {
      handleSearch();
      setSearch(0);
    }
  }, [SearchCountry]);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  
  const [currentTab, setCurrentTab] = useState("eventsTab");
  const [checkedCountries, setCheckedCountries] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

 
 
 
  const renderTabContent = () => {
    
    switch (currentTab) {
      case "eventsTab":
        return (
          <div className="search-bar py-0">
            <div className="row px-2 py-1">
               
            <div className="col-lg-3 col-sm-12 px-custom-1 mb-2-sm  ">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    onChange={handleStartDateChange}
                    value={startDate}
                    renderInput={(params) => (
                      <TextField {...params} className="form-control" />
                    )}
                  />
                </LocalizationProvider>
              </div>

            <div className="col-lg-3 col-sm-12 px-custom-1 mb-2-sm  ">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker onChange={handleEndDateChange} 
                    renderInput={(params) => <TextField {...params} className="form-control" />}
                    value={endDate} />
                  </LocalizationProvider>
              </div>

            <div className="col-lg-5 col-sm-12 px-custom-1 mb-2-sm  ">
                  <input
                      id="form_name6"
                      className="form-control filter-5 border-0 float-input outline-0 shadow-none w-100 bg-white"
                      type="text"
                      required
                      onChange={handleChangeInput}
                      ref={inputRef}
                      placeholder=" Search Event Name..."
                    />
              </div>
           
              <div className="col-lg-1 col-sm-12 px-custom-1 ">
                  <div className="w-100">
              

                    {!isTableLoading && !isSearchCompleted && (
                        <button
                          className="btn btn btn btn-primary  "
                          style={{
                            display: "block",
                            backgroundColor: "#007bff",
                            color:"#fff",
                            padding: "12px 15px",
                            fontWeight: "600",
                            fontSize: "16px",
                            width:"100%"
                          }}
                          onClick={handleSearch}
                        >
                          Search
                        </button>
                      )}
                      {isTableLoading && (
                        <button
                          className="btn btn-primary"
                          style={{
                            display: "block",
                            backgroundColor: "#007bff",
                            color:"#fff",
                            padding: "12px 15px",
                            fontWeight: "600",
                            fontSize: "16px"
                          }}
                          disabled
                        >
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </button>
                      )}
                      {!isTableLoading && isSearchCompleted && (
                        <button
                        
                          className="btn btn-primary"
                          style={{
                            display: "block",
                            backgroundColor: "#007bff",
                            color:"#fff",
                            padding: "12px 15px",
                            fontWeight: "600",
                            fontSize: "16px"
                          }}
                          onClick={clearInput}
                        >
                          Reset
                        </button>
                      )}

                  </div>
              </div>
            </div>
          </div>



        );
      case "hotelsTab":
      case "transportTab":
      case "flightTab":
        return (
          <div className="d-block w-100 py-3 px-3">
            <h4 className="text-15 text-black m-0">
              Filters Coming Soon For {" "}
              {currentTab.charAt(0).toUpperCase() +
                currentTab.slice(1).replace("Tab", "")}
            </h4>
          </div>
        );
      default:
        return null;
    }
  };

  // return(

  //   <Vanta/>
  // );
  return (
    <>

      
      <Helmet>
        <link href="/todayCss/bootstrap.min.css" rel="stylesheet" />
        <link href="/todayCss/animate.css" rel="stylesheet" />
        <link href="/todayCss/custom.css" rel="stylesheet" />
        <link href="/filterCss/custom.css" rel="stylesheet" />
        <link href="/todayCss/slick-theme.css" rel="stylesheet" />
        <link href="/todayCss/slick.css" rel="stylesheet" />
        <link href="/todayCss/hotel.css" rel="stylesheet" />
      
         
      </Helmet>
      {loading ? (
        <section className="pad-top-20 pad-bot-80">
          <div className="container3">
            <div className="block-element m-b-30"></div>
            <div
              className="block-element loader-mobile-screen"
              style={{ marginTop: "80px" }}
            >
              <LoadingSpinner />
            </div>
          </div>
        </section>
      ) : (
        <>

  <HeaderNewComponent />
  
  <section className="header-custom">
    <div className="container">
      <nav className="navbar py-0">
        <div className="navbar-custom-booking">
          <ul className="navbar-nav-custom">
             
            <li  className="nav-item">
                    <a className={`nav-link  ${ currentTab === "eventsTab" ? "active" : "" }  `}  onClick={() => setCurrentTab("eventsTab")}>
                      <i className="fa fa-calendar  pr-2" aria-hidden="true" />
                      Events
                    </a>

            </li> 


            <li  className="nav-item">
               <a className={`nav-link  ${ currentTab === "hotelsTab" ? "active" : "" }  `}  onClick={() => setCurrentTab("hotelsTab")}>
                <i className="fa fa-bed pr-2" aria-hidden="true" />
                Accommodation
              </a>
            </li>
            <li className="nav-item">
                  <a className={`nav-link  ${ currentTab === "buses" ? "active" : "" }  `} onClick={() => setCurrentTab("buses")}>
                <i className="fa fa-car pr-2" aria-hidden="true"  />
                Buses
              </a>
            </li>
            <li className="nav-item">
              <a className={`nav-link  ${ currentTab === "flightTab" ? "active" : "" }  `} onClick={() => setCurrentTab("flightTab")}>
                <i className="fa fa-plane pr-2" aria-hidden="true" />
                Flights
              </a>
            </li>
           
           
          </ul>
        </div>
      </nav>
    </div>
  </section>
  <section className="bg-overlay">
    <div className="hero-section">
      <div className="container">
        <h1 className="bg-image-title">
        Tickets...for Everything
        </h1>
        <p className="herobanner-subtitile">
        Find tickets for your favourite events
        </p>
      
      </div>
    </div>
  </section>
  <section className="searchbox_cross_product__title " style={{display: 'none'}}>
  <h1 id="form_search_h1" className="b-no-tap-highlight">
    Tickets...for Everything
  </h1>
  <label className="search_hotels_subtext" htmlFor="input_destination">
    Find tickets for your favourite events
  </label>
</section>

  <section className=" bg-blue2 pt-3">
  <div className="container">
    
  {renderTabContent()}
    </div>
  </section>



          
       
          <div className="" style={bodyStyle}>
           

          <section className="pt-lg-5 pt-md-5 pt-2 pb-5 bg-blue2">
  <div className="container">
    <div className="w-100 mb-5 filter-mobile-bar6 d-mob-none texture-bg">
      <div className="row justify-content-center">
        {/* <div className="col-lg-9 col-md-10 col-sm-12 col-12">
          <div className="d-block w-100">
            <div
              className="nav nav-tabs border-0 custom-tabs4"
              id="nav-tab2"
              role="tablist"
            >
              <button
                aria-controls="nav-events"
                aria-selected="true"
                className="nav-link active bg-white text-black text-14"
                data-bs-target="#nav-events"
                data-bs-toggle="tab"
                id="nav-events-tab"
                role="tab"
                type="button"
              >
                <i className="fa fa-calendar-days text-16 me-2">
                  {' '}
                </i>
                Events
              </button>
              <button
                aria-controls="nav-hotels"
                aria-selected="false"
                className="nav-link bg-white text-black text-14"
                data-bs-target="#nav-hotels"
                data-bs-toggle="tab"
                id="nav-hotels-tab"
                role="tab"
                type="button"
              >
                <i className="fa fa-bed text-16 me-2">
                  {' '}
                </i>
                Hotels
              </button>
              <button
                aria-controls="nav-transport"
                aria-selected="false"
                className="nav-link bg-white text-black text-14"
                data-bs-target="#nav-transport"
                data-bs-toggle="tab"
                id="nav-transport-tab"
                role="tab"
                type="button"
              >
                <i className="fa fa-bus text-16 me-2">
                  {' '}
                </i>
                Public Transportation
              </button>
              <button
                aria-controls="nav-flight"
                aria-selected="false"
                className="nav-link bg-white text-black text-14"
                data-bs-target="#nav-flight"
                data-bs-toggle="tab"
                id="nav-flight-tab"
                role="tab"
                type="button"
              >
                <i className="fa fa-plane text-16 me-2">
                  {' '}
                </i>
                Flight
              </button>
            </div>
          </div>
          <div className="bg-white px-2 pb-2 pt-3 shadow-normal rounded-3">
            <div
              className="tab-content"
              id="nav-tabContent"
            >
              <div
                aria-labelledby="nav-events"
                className="tab-pane fade active show"
                id="nav-events"
                role="tabpanel"
              >
              </div>
              <div
                aria-labelledby="nav-hotels"
                className="tab-pane fade"
                id="nav-hotels"
                role="tabpanel"
              >
                <div className="d-flex  flex-wrap align-items-center justify-content-lg-center justify-content-md-center justify-content-start">
                  <div className="form-group form-filter-grey form-filter-search form-filter-width9 me-2 position-relative d-flex align-items-center justify-content-start border border-color-silver rounded-4 mb-2">
                    <input
                      className="form-control filter-5 border-0 float-input outline-0 shadow-none"
                      id="form_name6"
                      placeholder=""
                      required
                      type="text"
                    />
                    <label
                      className="float-label "
                      htmlFor="form_name6"
                    >
                      {' '}Search Country{' '}
                      <span className="gl-form-asterisk" />
                    </label>
                  </div>
                  <div className="form-group form-filter-grey form-filter-width2 position-relative me-2 border border-color-silver rounded-4 mb-2">
                    <input
                      className="form-control filter-2 border-0 float-input outline-0 shadow-none"
                      id="hotel_startdate"
                      placeholder=""
                      required
                      type="date"
                    />
                    <label
                      className="float-label"
                      htmlFor="hotel_startdate"
                    >
                      Start Date
                      <span className="gl-form-asterisk" />
                    </label>
                  </div>
                  <div className="form-group form-filter-grey form-filter-width3 position-relative me-2 border border-color-silver rounded-4 mb-2">
                    <input
                      className="form-control filter-3 border-0 float-input outline-0 shadow-none"
                      id="hotel_enddate"
                      placeholder=""
                      required
                      type="date"
                    />
                    <label
                      className="float-label"
                      htmlFor="hotel_enddate"
                    >
                      End Date
                      <span className="gl-form-asterisk" />
                    </label>
                  </div>
                  <div className="form-group form-filter-grey select-width-1 position-relative me-2 border border-color-silver overflow-hidden rounded-4 mb-2">
                    <img
                      className="position-absolute top-0 start-0 ms-2"
                      src="/images/hotel-adult-icon.png"
                      style={{
                        marginTop: '10px'
                      }}
                    />
                    <select
                      className="w-100 border border-1 border-transparent ps-4 select-width-1 py-2 bg-silver4  text-13 outline-0"
                      id="hotel_room"
                    >
                      <option className="text-13 text-grey">
                        {' '}1 Adult{' '}
                      </option>
                      <option className="text-13 text-grey">
                        {' '}2 Adult{' '}
                      </option>
                      <option className="text-13 text-grey">
                        {' '}3 Adult{' '}
                      </option>
                    </select>
                  </div>
                  <div className="form-group form-filter-grey select-width-1 position-relative me-2 border border-color-silver overflow-hidden rounded-4 mb-2">
                    <img
                      className="position-absolute top-0 start-0 ms-1"
                      src="/images/hotel-room-icon.png"
                      style={{
                        marginTop: '10px'
                      }}
                    />
                    <select
                      className="w-100 border border-1 border-transparent ps-4 py-2 bg-silver4  0 text-13 outline-0"
                      id="hotel_room"
                    >
                      <option className="text-13 text-grey">
                        {' '}1 Room{' '}
                      </option>
                      <option className="text-13 text-grey">
                        {' '}2 Room{' '}
                      </option>
                      <option className="text-13 text-grey">
                        {' '}3 Room{' '}
                      </option>
                    </select>
                  </div>
                  <div className="d-inline-block mb-2">
                    <button className="custom-btn11 fw-normal text-13 px-lg-4 px-md-4 px-sm-4 px-3">
                      Search
                    </button>
                  </div>
                </div>
              </div>
              <div
                aria-labelledby="nav-transport"
                className="tab-pane fade"
                id="nav-transport"
                role="tabpanel"
              >
                <div className="d-block w-100 py-3 px-3">
                  <h4 className="text-15 text-black m-0">
                    {' '}No Filter For Public Transportation
                  </h4>
                </div>
              </div>
              <div
                aria-labelledby="nav-flight"
                className="tab-pane fade"
                id="nav-flight"
                role="tabpanel"
              >
                <div className="d-block w-100 py-3 px-3">
                  <h4 className="text-15 text-black m-0">
                    {' '}No Filter For Flights
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
    <div className="d-flex align-items-start justify-content-start flex-wrap">
      <div className="booking-search-checkout-left pe-lg-4 pe-md-4 pe-0 ">
        <div className="d-block w-100">
          <div className="d-lg-none d-md-none w-100 bg-white rounded-2 py-2 px-2  d-block w-100 shadow-sm mb-4">
            <div className="d-flex w-100 align-items-center justify-content-between">
              <div className="d-inline-block">
                <button
                  className="d-flex align-items-center justify-content-start bg-white border-0"
                  id="filter-mobile-toggle"
                >
                  <img
                    className="me-1"
                    src="/images/filter-icon2.png"
                  />
                  <span className="text-16 text-black poppins-500">
                    {' '}Filter{' '}
                  </span>
                </button>
              </div>
              <div className="d-inline-block">
                <div className="dropdown">
                  <button
                    aria-expanded="false"
                    className="dropdown-toggle d-flex align-items-center justify-content-start bg-white border-0"
                    data-bs-toggle="dropdown"
                    id="dropdownMenuButton1"
                    type="button"
                  >
                    <img
                      className="me-1"
                      src="/images/sort-icon1.png"
                    />
                    <span className="text-16 text-black poppins-500">
                      {' '}Sort{' '}
                    </span>
                  </button>
                  <ul
                    aria-labelledby="dropdownMenuButton1"
                    className="dropdown-menu"
                  >
                    <li>
                      <a
                        className="dropdown-item text-14"
                        href="#"
                      >
                        Prices: Low to High
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item text-14"
                        href="#"
                      >
                        Prices: High to Low
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item text-14"
                        href="#"
                      >
                        New to Old
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item text-14"
                        href="#"
                      >
                        Old to New
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="d-block w-100 filter-mobile-bar">
            <div className="d-block w-100 filter-mobile-bar-wrapper">
              <div className="d-lg-none d-md-none w-100 mb-3">
                <div className="d-flex w-100 align-items-center justify-content-between">
                  <h4 className="m-0 p-0 col-blue3 poppins-600 text-20">
                    {' '}
                    <img src="/images/filter-icon3.png" />
                    Filters
                  </h4>
                  <button
                    className="border-0 bg-transparent text-18"
                    id="filter-mobile-toggle-close"
                  >
                    <i className="fa fa-times">
                      {' '}
                    </i>
                  </button>
                </div>
              </div>
              <div className="d-block bg-white rounded-2 py-3 px-2  d-block w-100 shadow-sm mb-4">
                <div className="d-flex w-100 align-items-center justify-content-between">
                  <div className="d-inline-block">
                    <h5 className="m-0 text-14 poppins-600 ">
                      {' '}Show Map{' '}
                    </h5>
                  </div>
                  <div className="d-inline-block">
                    <div className="form-check form-switch custom-form-switch">
                      <input
                        className="form-check-input"
                        id="ShowMapCheckbox"
                        type="checkbox"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-block w-100 mb-4">
                <div className="d-block w-100 mb-3">
                  <h4 className="text-black text-18 m-0 poppins-600">
                    {' '}Deals{' '}
                  </h4>
                </div>
                <div className="d-block w-100 bg-white px-3 py-3 rounded-2 shadow-normal">
                  <div className="d-block w-100">
                    <div className="d-block w-100 mb-3">
                      <h4 className="text-black text-14 m-0 poppins-600">
                        {' '}Heading Text{' '}
                      </h4>
                    </div>
                    <div className="d-flex w-100 align-items-center justify-content-between">
                      <div className="d-inline-block">
                        <h5 className="m-0 text-14 poppins-500 ">
                          {' '}Top Secret Hotels{' '}
                        </h5>
                      </div>
                      <div className="d-inline-block">
                        <div className="form-check form-switch custom-form-switch">
                          <input
                            className="form-check-input"
                            id="ShowSecreHotelsCheckbox"
                            type="checkbox"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-block w-100 mb-4">
                <div className="d-block w-100 mb-3">
                  <h4 className="text-black text-18 m-0 poppins-600">
                    {' '}Booking Options{' '}
                  </h4>
                </div>
                <div className="d-block w-100 bg-white px-3 py-3 rounded-2 shadow-normal">
                  <div className="d-block w-100">
                    <div className="d-flex w-100 align-items-center justify-content-between">
                      <div className="d-inline-block">
                        <h5 className="m-0 text-14 poppins-500 ">
                          {' '}Free Cancellation{' '}
                        </h5>
                      </div>
                      <div className="d-inline-block">
                        <div className="form-check form-switch custom-form-switch">
                          <input
                            className="form-check-input"
                            id="ShowSecreHotelsCheckbox"
                            type="checkbox"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-block w-100 mb-4">
                <div className="d-block w-100 mb-3">
                  <h4 className="text-black text-18 m-0 poppins-600">
                    {' '}Price of Ticket{' '}
                  </h4>
                </div>
                <div className="d-block w-100 bg-white px-3 py-3 rounded-2 shadow-normal">
                  <div className="d-block w-100 mb-4">
                    <div className="d-flex w-100 align-items-center justify-content-between mb-3">
                      <div className="d-inline-block min-width-clear">
                        <h4 className="text-black text-15 m-0 poppins-600">
                          Per Person{' '}
                        </h4>
                      </div>
                      <div className="d-flex align-items-center justify-content-end price-input">
                        <div className="field">
                          <input
                            className="input-min border-0 p-0 text-16 poppins-600 col-blue1 bg-transparent"
                            defaultValue="2500"
                            type="number"
                          />
                        </div>
                        <span className="text-16 d-inline-block mx-1">
                          {' '}-
                        </span>
                        <div className="field">
                          <input
                            className="input-max text-16 poppins-600 border-0 p-0 col-blue1 bg-transparent"
                            defaultValue="7500"
                            type="number"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="wrapper">
                      <div className="slider">
                        <div className="progress" />
                      </div>
                      <div className="range-input">
                        <input
                          className="range-min"
                          defaultValue="2500"
                          max="10000"
                          min="0"
                          step="100"
                          type="range"
                        />
                        <input
                          className="range-max"
                          defaultValue="7500"
                          max="10000"
                          min="0"
                          step="100"
                          type="range"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-block w-100 mb-2">
                    <h4 className="text-black poppins-600 mt-0 mb-1 text-15">
                      {' '}Tickets{' '}
                    </h4>
                  </div>
                  <div className="d-block w-100">
                    <div className="checkbox-style2 d-flex w-100 align-items-center justify-content-between mb-1">
                      <label
                        className="text-black text-14 me-2 w-100"
                        htmlFor="public"
                      >
                        {' '}Public
                      </label>
                      <input
                        className="m-0"
                        defaultValue="None"
                        id="public"
                        name="check"
                        type="checkbox"
                      />
                    </div>
                    <div className="checkbox-style2 d-flex w-100 align-items-center justify-content-between mb-1">
                      <label
                        className="text-black text-14 me-2 w-100"
                        htmlFor="public"
                      >
                        {' '}Private
                      </label>
                      <input
                        className="m-0"
                        defaultValue="None"
                        id="private"
                        name="check"
                        type="checkbox"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-block w-100 mb-4">
                <div className="d-block w-100 mb-3">
                  <h4 className="text-black text-18 m-0 poppins-600">
                    {' '}Property Type{' '}
                  </h4>
                </div>
                <div className="d-block w-100 bg-white px-3 py-3 rounded-2 shadow-normal">
                  <div className="d-block w-100 mb-2">
                    <h4 className="text-black poppins-600 mt-0 mb-1 text-15">
                      {' '}Event Categories{' '}
                    </h4>
                    <p className="text-muted poppins-400 text-12 m-0">
                      {' '}Scarborough - Port Of Spain{' '}
                    </p>
                  </div>
                  <div className="d-block w-100">
                    <div className="checkbox-style2 d-flex w-100 align-items-center justify-content-between mb-1">
                      <label
                        className="text-black d-flex w-100 align-items-center justify-content-between text-14 me-2 w-100"
                        htmlFor="music"
                      >
                        {' '}Apart Hotel{' '}
                        <b className="poppins-600 text-14 text-muted">
                          {' '}(12){' '}
                        </b>
                        {' '}
                      </label>
                      <input
                        className="m-0"
                        defaultValue="None"
                        id="music"
                        name="check"
                        type="checkbox"
                      />
                    </div>
                    <div className="checkbox-style2 d-flex w-100 align-items-center justify-content-between mb-1">
                      <label
                        className="text-black d-flex w-100 align-items-center justify-content-between text-14 me-2 w-100"
                        htmlFor="music"
                      >
                        {' '}Apartment{' '}
                        <b className="poppins-600 text-14 text-muted">
                          {' '}(12){' '}
                        </b>
                        {' '}
                      </label>
                      <input
                        className="m-0"
                        defaultValue="None"
                        id="music"
                        name="check"
                        type="checkbox"
                      />
                    </div>
                    <div className="checkbox-style2 d-flex w-100 align-items-center justify-content-between mb-1">
                      <label
                        className="text-black d-flex w-100 align-items-center justify-content-between text-14 me-2 w-100"
                        htmlFor="music"
                      >
                        {' '}Bed & Breakfast{' '}
                        <b className="poppins-600 text-14 text-muted">
                          {' '}(12){' '}
                        </b>
                        {' '}
                      </label>
                      <input
                        className="m-0"
                        defaultValue="None"
                        id="music"
                        name="check"
                        type="checkbox"
                      />
                    </div>
                    <div className="checkbox-style2 d-flex w-100 align-items-center justify-content-between mb-1">
                      <label
                        className="text-black d-flex w-100 align-items-center justify-content-between text-14 me-2 w-100"
                        htmlFor="music"
                      >
                        {' '}Guest House{' '}
                        <b className="poppins-600 text-14 text-muted">
                          {' '}(12){' '}
                        </b>
                        {' '}
                      </label>
                      <input
                        className="m-0"
                        defaultValue="None"
                        id="music"
                        name="check"
                        type="checkbox"
                      />
                    </div>
                    <div className="checkbox-style2 d-flex w-100 align-items-center justify-content-between mb-1">
                      <label
                        className="text-black d-flex w-100 align-items-center justify-content-between text-14 me-2 w-100"
                        htmlFor="music"
                      >
                        {' '}Hostel{' '}
                        <b className="poppins-600 text-14 text-muted">
                          {' '}(12){' '}
                        </b>
                        {' '}
                      </label>
                      <input
                        className="m-0"
                        defaultValue="None"
                        id="music"
                        name="check"
                        type="checkbox"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-block w-100 mb-4">
                <div className="d-block w-100 mb-3">
                  <h4 className="text-black text-18 m-0 poppins-600">
                    {' '}Property Name{' '}
                  </h4>
                </div>
                <div className="d-block w-100 bg-white px-3 py-3 rounded-2 shadow-normal">
                  <div className="d-block w-100 mb-2">
                    <h4 className="text-black poppins-600 mt-0 mb-1 text-15">
                      {' '}Event Categories{' '}
                    </h4>
                  </div>
                  <div className="d-block w-100">
                    <form className="bg-silver4 px-2 py-1 border rounded-2 overflow-hidden d-flex w-100 align-items-center justify-content-start">
                      <i className="fa fa-search me-2 text-14 text-muted">
                        {' '}
                      </i>
                      <input
                        className="w-100 bg-silver4 lh-lg border-0 outline-0 text-black text-14 border-0"
                        name=""
                        placeholder="Search..."
                        type="text"
                      />
                    </form>
                  </div>
                </div>
              </div>
              <div className="d-block w-100 mb-4">
                <div className="d-block w-100 mb-3">
                  <h4 className="text-black text-18 m-0 poppins-600">
                    {' '}Meal Plan{' '}
                  </h4>
                </div>
                <div className="d-block w-100 bg-white px-3 pt-3 rounded-2 shadow-normal">
                  <div className="d-block w-100 mb-2">
                    <h4 className="text-black poppins-600 mt-0 mb-1 text-15">
                      {' '}Event Categories{' '}
                    </h4>
                  </div>
                  <div className="d-block w-100">
                    <div className="d-flex w-100 flex-wrap align-items-start justify-content-start">
                      <div className="d-inline-block">
                        <label className="d-block w-100 text-center meal-box-selector meal-box-selector-active">
                          <input
                            name=""
                            type="checkbox"
                          />
                          <img src="/images/meal-icon1.svg" />
                          <span className="text-12 text-muted poppins-400 d-block w-100 m-0">
                            {' '}Non-Veg (22){' '}
                          </span>
                        </label>
                      </div>
                      <div className="d-inline-block">
                        <label className="d-block w-100 text-center meal-box-selector">
                          <input
                            name=""
                            type="checkbox"
                          />
                          <img src="/images/meal-icon2.svg" />
                          <span className="text-12 text-muted poppins-400 d-block w-100 m-0">
                            {' '}Veg (4){' '}
                          </span>
                        </label>
                      </div>
                      <div className="d-inline-block">
                        <label className="d-block w-100 text-center meal-box-selector">
                          <input
                            name=""
                            type="checkbox"
                          />
                          <img src="/images/meal-icon3.svg" />
                          <span className="text-12 text-muted poppins-400 d-block w-100 m-0">
                            {' '}Full Meal (21){' '}
                          </span>
                        </label>
                      </div>
                      <div className="d-inline-block">
                        <label className="d-block w-100 text-center meal-box-selector">
                          <input
                            name=""
                            type="checkbox"
                          />
                          <img src="/images/meal-icon4.svg" />
                          <span className="text-12 text-muted poppins-400 d-block w-100 m-0">
                            {' '}Non-Veg (22){' '}
                          </span>
                        </label>
                      </div>
                      <div className="d-inline-block">
                        <label className="d-block w-100 text-center meal-box-selector">
                          <input
                            name=""
                            type="checkbox"
                          />
                          <img src="/images/meal-icon5.svg" />
                          <span className="text-12 text-muted poppins-400 d-block w-100 m-0">
                            {' '}No Meal (13){' '}
                          </span>
                        </label>
                      </div>
                      <div className="d-inline-block">
                        <label className="d-block w-100 text-center meal-box-selector">
                          <input
                            name=""
                            type="checkbox"
                          />
                          <img src="/images/meal-icon1.svg" />
                          <span className="text-12 text-muted poppins-400 d-block w-100 m-0">
                            {' '}Non-Veg (22){' '}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-block w-100 mb-4">
                <div className="d-block w-100 mb-3">
                  <h4 className="text-black text-18 m-0 poppins-600">
                    {' '}Rating{' '}
                  </h4>
                </div>
                <div className="d-block w-100 bg-white px-3 py-3 rounded-2 shadow-normal">
                  <div className="d-block w-100">
                    <div className="checkbox-style2 d-flex w-100 align-items-center justify-content-between mb-1">
                      <label
                        className="text-black d-flex w-100 align-items-center justify-content-between text-14 me-2 w-100"
                        htmlFor="music"
                      >
                        <span className="d-flex align-items-center text-black text-14 poppins-500">
                          <i className="fa fa-star color-rating text-16 mob-text-14 me-1">
                            {' '}
                          </i>
                          <i className="fa fa-star color-rating text-16 mob-text-14 me-1">
                            {' '}
                          </i>
                          <i className="fa fa-star color-rating text-16 mob-text-14 me-1">
                            {' '}
                          </i>
                          <i className="fa fa-star color-rating text-16 mob-text-14 me-1">
                            {' '}
                          </i>
                          <i className="fa fa-star color-rating text-16 mob-text-14   me-2">
                            {' '}
                          </i>
                          & Up
                        </span>
                        <b className="poppins-600 text-14 text-muted">
                          {' '}(12){' '}
                        </b>
                        {' '}
                      </label>
                      <input
                        className="m-0"
                        defaultValue="None"
                        id="music"
                        name="check"
                        type="checkbox"
                      />
                    </div>
                    <div className="checkbox-style2 d-flex w-100 align-items-center justify-content-between mb-1">
                      <label
                        className="text-black d-flex w-100 align-items-center justify-content-between text-14 me-2 w-100"
                        htmlFor="music"
                      >
                        <span className="d-flex align-items-center text-black text-14 poppins-500">
                          <i className="fa fa-star color-rating text-16 mob-text-14 me-1">
                            {' '}
                          </i>
                          <i className="fa fa-star color-rating text-16 mob-text-14 me-1">
                            {' '}
                          </i>
                          <i className="fa fa-star color-rating text-16 mob-text-14 me-1">
                            {' '}
                          </i>
                          <i className="fa fa-star color-rating text-16 mob-text-14 me-1">
                            {' '}
                          </i>
                          <i className="fa fa-star   text-16 mob-text-14 text-muted me-2">
                            {' '}
                          </i>
                          & Up
                        </span>
                        <b className="poppins-600 text-14 text-muted">
                          {' '}(12){' '}
                        </b>
                        {' '}
                      </label>
                      <input
                        className="m-0"
                        defaultValue="None"
                        id="music"
                        name="check"
                        type="checkbox"
                      />
                    </div>
                    <div className="checkbox-style2 d-flex w-100 align-items-center justify-content-between mb-1">
                      <label
                        className="text-black d-flex w-100 align-items-center justify-content-between text-14 me-2 w-100"
                        htmlFor="music"
                      >
                        <span className="d-flex align-items-center text-black text-14 poppins-500">
                          <i className="fa fa-star color-rating text-16 mob-text-14 me-1">
                            {' '}
                          </i>
                          <i className="fa fa-star color-rating text-16 mob-text-14 me-1">
                            {' '}
                          </i>
                          <i className="fa fa-star color-rating text-16 mob-text-14 me-1">
                            {' '}
                          </i>
                          <i className="fa fa-star text-muted text-16 mob-text-14 me-1">
                            {' '}
                          </i>
                          <i className="fa fa-star   text-16 mob-text-14 text-muted me-2">
                            {' '}
                          </i>
                          & Up
                        </span>
                        <b className="poppins-600 text-14 text-muted">
                          {' '}(12){' '}
                        </b>
                        {' '}
                      </label>
                      <input
                        className="m-0"
                        defaultValue="None"
                        id="music"
                        name="check"
                        type="checkbox"
                      />
                    </div>
                    <div className="checkbox-style2 d-flex w-100 align-items-center justify-content-between mb-1">
                      <label
                        className="text-black d-flex w-100 align-items-center justify-content-between text-14 me-2 w-100"
                        htmlFor="music"
                      >
                        <span className="d-flex align-items-center text-black text-14 poppins-500">
                          <i className="fa fa-star color-rating text-16 mob-text-14 me-1">
                            {' '}
                          </i>
                          <i className="fa fa-star color-rating text-16 mob-text-14 me-1">
                            {' '}
                          </i>
                          <i className="fa fa-star text-muted text-16 mob-text-14 me-1">
                            {' '}
                          </i>
                          <i className="fa fa-star text-muted text-16 mob-text-14 me-1">
                            {' '}
                          </i>
                          <i className="fa fa-star   text-16 mob-text-14 text-muted me-2">
                            {' '}
                          </i>
                          & Up
                        </span>
                        <b className="poppins-600 text-14 text-muted">
                          {' '}(12){' '}
                        </b>
                        {' '}
                      </label>
                      <input
                        className="m-0"
                        defaultValue="None"
                        id="music"
                        name="check"
                        type="checkbox"
                      />
                    </div>
                    <div className="checkbox-style2 d-flex w-100 align-items-center justify-content-between mb-1">
                      <label
                        className="text-black d-flex w-100 align-items-center justify-content-between text-14 me-2 w-100"
                        htmlFor="music"
                      >
                        <span className="d-flex align-items-center text-black text-14 poppins-500">
                          <i className="fa fa-star color-rating text-16 mob-text-14 me-1">
                            {' '}
                          </i>
                          <i className="fa fa-star text-muted text-16 mob-text-14 me-1">
                            {' '}
                          </i>
                          <i className="fa fa-star text-muted text-16 mob-text-14 me-1">
                            {' '}
                          </i>
                          <i className="fa fa-star text-muted text-16 mob-text-14 me-1">
                            {' '}
                          </i>
                          <i className="fa fa-star text-16 mob-text-14 text-muted me-2">
                            {' '}
                          </i>
                          & Up
                        </span>
                        <b className="poppins-600 text-14 text-muted">
                          {' '}(12){' '}
                        </b>
                        {' '}
                      </label>
                      <input
                        className="m-0"
                        defaultValue="None"
                        id="music"
                        name="check"
                        type="checkbox"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-lg-none d-md-none d-block">
                <div className="d-flex w-100 align-items-center justify-content-between">
                  <button className="custom-btn10 me-2 w-100">
                    Reset
                  </button>
                  <button className="custom-btn11 ms-2 w-100">
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="booking-search-checkout-right ps-lg-3 ps-md-3 ps-0  pe-lg-3 pe-md-3 pe-0 mt-0">
        <div className="d-block w-100">
          <div className="d-block w-100 mb-3">
            <div className="row">
              <div className="col-lg-9 col-md-9 col-sm-12 col-12">
                <h3 className="text-black poppins-600 text-24 m-0 mob-text-16">
                  {' '}All Countries  : 2,582 search results found{' '}
                </h3>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                <div className="d-lg-block d-md-block d-none  mt-lg-0 mt-md-0 mt-3">
                  <div className="form-group position-relative me-2 border border-color-silver rounded-4 mb-2">
                    <select
                      className="form-control text-14 border-0 float-input outline-0 shadow-none"
                      id="form_name2"
                    >
                      <option>
                        {' '}Featured{' '}
                      </option>
                      <option>
                        {' '}Prices: Low to High{' '}
                      </option>
                      <option>
                        {' '}Prices: High to Low{' '}
                      </option>
                      <option>
                        {' '}New to Old{' '}
                      </option>
                      <option>
                        {' '}Old to New{' '}
                      </option>
                    </select>
                    <label
                      className="float-label"
                      htmlFor="form_name2"
                    >
                      Sort By
                      <span className="gl-form-asterisk" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-block w-100 mb-3">
            <h3 className="text-black poppins-600 text-20 m-0">
              {' '}Popular Events
            </h3>
          </div>
          <div className="d-block w-100 bg-white px-3 py-3 booking-event-single-row rounded-3 shadow-normal mb-4">
            <div className="d-block w-100">
              <div className="d-flex align-items-center justify-content-start flex-wrap position-relative">
                <div className="custom-image6">
                  <img
                    className="hotel-book-image"
                    src="/images/photos-1.png"
                  />
                </div>
                <div className="custom-detail6">
                  <div className="row">
                    <div className="col-lg-7 col-md-7 col-sm-12 col-12">
                      <div className="d-flex align-items-center justify-content-start mb-2">
                        <div className="d-inline-block">
                          <h6 className="poppins-600 m-0 d-flex align-items-center justify-content-start text-20 mob-text-16 text-black">
                            FabHotel Prime Urban Cliff
                          </h6>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-start mb-2">
                        <div className="d-flex align-items-center justify-content-start me-3">
                          <i className="fa fa-star color-rating text-16 mob-text-14 me-1">
                            {' '}
                          </i>
                          <i className="fa fa-star color-rating text-16 mob-text-14 me-1">
                            {' '}
                          </i>
                          <i className="fa fa-star color-rating text-16 mob-text-14 me-1">
                            {' '}
                          </i>
                          <i className="fa fa-star color-rating text-16 mob-text-14 me-1">
                            {' '}
                          </i>
                          <i className="fa fa-star text-16 mob-text-14 text-muted">
                            {' '}
                          </i>
                        </div>
                        <span className="text-grey text-13 mt-1 me-3 d-inline-block text-muted">
                          4.0
                        </span>
                        <span className="text-grey text-13 mt-1 me-2 d-inline-block text-muted">
                          7 Reviews
                        </span>
                        <span className="d-inline-block mt-1 bg-dark-green px-1 py-1 text-white rounded-2 text-13">
                          Featured
                        </span>
                      </div>
                      <div className="d-flex w-100 align-items-center justify-content-start mb-2">
                        <div className="d-inline-block me-2">
                          <img
                            height="16px"
                            src="/images/map-icon3.png"
                          />
                        </div>
                        <div className="d-inline-block">
                          <h5 className="text-black text-14 mob-text-13 poppins-500 m-0 p-0">
                            {' '}Bypass , Park Circus, Kolkata, 700039, India{' '}
                          </h5>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-start ">
                        <div className="d-inline-block">
                          <h6 className="text-black poppins-500 m-0 d-flex align-items-center justify-content-start text-13 ">
                            <img
                              className="me-2"
                              height="22px"
                              src="/images/hotel-room-icon2.png"
                            />
                            4-6 guests · Entire Home · 5 beds · 3 bath
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-5 col-sm-12 col-12 mt-lg-0 mt-md-0 mt-3">
                      <div className="d-flex justify-content-lg-end justify-content-md-end justify-content-start w-100 ps-lg-5 ps-md-5 ps-0">
                        <div className="d-inline-block d-flex flex-lg-column flex-md-column flex-row align-items-end">
                          <span className="mb-2 bg-dark-green px-1 py-2 rounded-2 text-white d-inline-block hotel-sale-tag text-13">
                            20% SALE
                          </span>
                          <button className="mb-2 custom-btn9 mb-2 me-lg-0 me-md-0 me-2">
                            Book Now
                            <i className="fa fa-arrow-right">
                              {' '}
                            </i>
                          </button>
                          <h5 className="d-lg-block d-md-block d-sm-none d-none col-blue3 m-0 text-32 poppins-700">
                            $325
                            <span className="col-blue3 text-14 align-middle mt-1 poppins-500 d-inline-block">
                              {' '}/night{' '}
                            </span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex w-100 align-items-center justify-content-center">
            <button className="custom-btn10">
              Load more Results
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
            
 

             
          </div>
          <FooterComponent />
        </>
      )}
    </>
  );
}
export default HotelComponent;
