import React, { useEffect, useState, useRef, useContext } from "react";
import HeaderNewComponent from "../HeaderComponent/HeaderNewComponent.js";
// import HeaderNewComponent from "../HeaderComponent/HeaderComponent.js";
import FooterComponent from "../FooterComponent/FooterComponent.js";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { fetchHomeData, fetchHomeDataReset } from "../../services/homeService";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { fetchEventData } from "../../services/EventTikketService";
import Swal from "sweetalert2";
import { useOrders } from "../../hooks/SettingContext";
import { Helmet } from "react-helmet";
import { ProfileContext } from "../../hooks/ProfileContext";
import { fetchUserData } from "../../services/profileService";
import EventComponent from "./EventComponent";
import TicketModelComponent from "./TicketModelComponent";
import TicketSideBar from "./TicketSideBar";
import CategoryFilterComponent from "./CategoryFilterComponent";
import PriceFilterComponent from "./PriceFilterComponent";
import CountriesList from "./CountriesList";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';

import Vanta from './Vanta';

 
  
function FilterLatest_HomeComponent() {
  const [events, setEvents] = useState([]);
  const [blogs, setBlogs] = useState([]);

  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isSearchCompleted, setIsSearchCompleted] = useState(false);

  const navigate = useNavigate();

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState("");
  const [selectedCountry, setSelectedCountry] = useState([]);

  const [Country, setCountry] = useState([]);
  const [from, setfrom] = useState("");
  const [to, setTo] = useState("");
  const [isticketLoading, setisticketLoading] = useState(false);
  const [totalRecords, setTotal] = useState("");
  const [availableTickets, setAvailableTickets] = useState([]);
  const [evtData, setEvtData] = useState(null);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [prevPageUrl, setPrevPageUrl] = useState(null);
  const [itemsPerPage, setitemsPerPage] = useState(5);
  const [orders, setOrders] = useState([]);
  const [selectedcounties, setselectedcounties] = useState([]);
  const [countriesCount, setcountriesCount] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [serviceFee, setServiceFee] = useState(0);
  const [tickets, setTickets] = useState([]);
  const [countryOptions, setcountryOptions] = useState([]);
  const [allCategories, setAllcategories] = useState([]);
  
  const [event_type, setType] = useState("");
  const [searchText, setSearchText] = useState("");
   const [totalPrice, setTotalPrice] = useState(0);
  const { ordersCount, updateOrdersCount } = useOrders();
  const [ShowModal, setshowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isTicketVisible, setIsTicketVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const { setImagePath } = useContext(ProfileContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedTickets, setSelectedTickets] = useState([]);
  const [SearchCountry, setSearch] = useState(0);
  const [minPrice, setMinPrice] = useState(1);
  const [maxPrice, setMaxPrice] = useState(1000);
  const [selectedCountryValues, setSelectedCountryValues] = useState([0]);
  
  const [openSideModal, setOpenSideModal] = useState(false);

  const [ticketactiveTab, setticketActiveTab] = useState("tickets");
   
  const stickyDivRef = useRef(null);
  const wrapperRef = useRef(null);
  const nextDivRef = useRef(null);
  const startDateInputRef = useRef(null);
  const endDateInputRef = useRef(null);
  const inputRef = useRef(null);
    const [startDate, setStartDate] = useState(null);  
  const [endDate, setEndDate] = useState(null);

    const [Selected_start_Date, setSelected_start_Date] = useState(null);  
  const [Selected_end_Date, setSelected_end_Date] = useState(null);
  // const handleScroll = () => {
  //   const stickyDiv = stickyDivRef.current;
  //   const wrapper = wrapperRef.current;
  
  //   if (stickyDiv && wrapper) {
  //     const wrapperRect = wrapper.getBoundingClientRect();
  
  //     if (wrapperRect.top <= 0 && wrapperRect.bottom >= 0) {
  //       stickyDiv.classList.add('in-view');
  //       wrapper.classList.add('in-view-wrapper');
  //     } else {
  //       stickyDiv.classList.remove('in-view');
  //       wrapper.classList.remove('in-view-wrapper');
  //     }
  //   }
  // };
 
const handleScroll = () => {
  const stickyDiv = stickyDivRef.current;
  const wrapper = wrapperRef.current;

  if (stickyDiv && wrapper) {
    const wrapperRect = wrapper.getBoundingClientRect();
    const stickyHeight = stickyDiv.offsetHeight;

    // Check if the top of the wrapper is above or at the top of the viewport
    if (wrapperRect.top <= 0) {
      stickyDiv.classList.add('in-view');
      wrapper.classList.add('in-view-wrapper');
    } else {
      stickyDiv.classList.remove('in-view');
      wrapper.classList.remove('in-view-wrapper');
    }
  }
};

  const handleTicketSelection = (ticket, event) => {
    const ticketWithEventInfo = {
      ...ticket,
      eventName: event.name,
      image: event.imagePath + event.image,
      user_id: event.user_id,
      flag: event.country_flag,
      event_id: event.id,
      ticket_per_order: ticket.ticket_per_order,
      cateName: event.cate_name,
      quantity: 0,
      selected_price: 0,
    };

    setSelectedTickets((prevSelectedTickets) => {
      let updatedTickets;
      if (prevSelectedTickets.some((t) => t.id === ticket.id)) {
        updatedTickets = prevSelectedTickets.filter((t) => t.id !== ticket.id);
      } else {
        updatedTickets = [...prevSelectedTickets, ticketWithEventInfo];
      }

      // setTimeout(() => {
      //   console.log('setTimeout Run');
      //   console.log(updatedTickets);

      // }, 1000);

      if (updatedTickets.length > 0) {
        handleAddToCart(event.id, updatedTickets);
      }

      return updatedTickets;
    });
  };

  const handleAddToCart = (id, updatedTickets) => {
    const authToken = localStorage.getItem("authToken");

    if (!authToken) {

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please login before purchasing tickets",
        showCancelButton: true,
        confirmButtonText: "Login Now",
        cancelButtonText: "Close",
      }).then((result) => {
        if (result.isConfirmed) {
          // Navigate to the login page
          navigate('/user/login');
        }
      });
      return;
    }

    const isTicketSelected = updatedTickets.some(
      (ticket) => ticket.event_id === id
    );

    if (!isTicketSelected) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You have not selected tickets for this event.",
      });
      return;
    }

    // if (updatedTickets.length > 0) {
    //   setModalVisible(true);
    //   const itemsFromLocalStorage = JSON.parse(
    //     localStorage.getItem("items") || "[]"
    //   );
    //   if (itemsFromLocalStorage.length > 0) {
    //     localStorage.setItem("orders", JSON.stringify(itemsFromLocalStorage));
    //   }
    // } else {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Oops...",
    //     text: `You have not selected any tickets.`,
    //   });
    // }


    if (updatedTickets.length > 0) {
      setModalVisible(true);
  
      const loginUser = localStorage.getItem("userID");
      const cartDataFromLocalStorage = JSON.parse(localStorage.getItem("CartData")) || {};
    
      if (cartDataFromLocalStorage[loginUser] && cartDataFromLocalStorage[loginUser].length > 0) {
        const itemsFromLocalStorage = cartDataFromLocalStorage[loginUser];
  
        // Retrieve or initialize FakeOrder object
        const fakeOrderFromLocalStorage = JSON.parse(localStorage.getItem('CartData')) || {};
    
        // Update the FakeOrder data for the logged-in user
        fakeOrderFromLocalStorage[loginUser] = itemsFromLocalStorage;
    
        // Save the updated FakeOrder object back to localStorage
        localStorage.setItem('FakeOrder', JSON.stringify(fakeOrderFromLocalStorage));
    
        // Update 'orders' in localStorage for compatibility
        localStorage.setItem("orders", JSON.stringify(itemsFromLocalStorage));
      } 
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `You have not selected any tickets.`,
      });
    }


   

  };

  const searchFilters = localStorage.getItem("searchFilters");

  useEffect(() => {

       
    if (searchFilters && events.length > 0) {
      const savedFilters = JSON.parse(searchFilters);
    
       
      // setEndDate(savedFilters.endDate);

      if (savedFilters.startDate) {
        const storedStartDate = savedFilters.startDate;
   
        const parsedDate = dayjs(storedStartDate);
        if (parsedDate.isValid()) {
          setStartDate(parsedDate);
        }
      }

      
      

      if (savedFilters.endDate) {
        const storedStartDate = savedFilters.endDate;
 
        const parsedDate = dayjs(storedStartDate);
        if (parsedDate.isValid()) {
          setEndDate(parsedDate);
        }
      }  
       
      setSearchText(savedFilters.searchText);
      
      
      if (savedFilters.selectedCountry) {
        const storedSelectedCountry = savedFilters.selectedCountry;
        const selectedCountryArray = storedSelectedCountry.split(',').map(Number);
        setSelectedCountryValues(selectedCountryArray);
     
      }
      setIsTableLoading(true);
      setIsSearchCompleted(false);
      
      //handleSearch(); 
    }
  }, [searchFilters]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = searchText;
    }
  }, [searchText]);

   
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('scroll', handleScroll);

    handleResize();
    window.addEventListener("resize", handleResize);

    return () =>  {
      window.removeEventListener("resize", handleResize); 
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);


  useEffect(() => {
      
    const loginUser = localStorage.getItem("userID");
    const itemsJSON = localStorage.getItem("CartData");

    let totalQuantity = 0;
  
    if (itemsJSON) {
      const items = JSON.parse(itemsJSON);
  
      // Filter items by loginUser
      if (items[loginUser]) {
        items[loginUser].forEach((item) => {
          totalQuantity += item.quantity;
        });
      }
    }
  
    localStorage.setItem("ordersCount", totalQuantity);
    updateOrdersCount(totalQuantity); 

  }, []);
  const handleTicketClick = (ticket) => {
    setSelectedTickets(ticket);
    setshowModal(true);
  };

  const handleCloseModal = () => {
    setshowModal(false);
    setModalVisible(false);
    localStorage.removeItem("orders");
    localStorage.removeItem("FakeOrder");

    setSelectedTickets([]);
  };

  const [Mob_filter, setMob_filter] = useState(false);
  const handleMobfilter = () => {
    setMob_filter(true);
  };
  const handleMobfilterOff = () => {
    setMob_filter(false);
  };
  const [showModal, setShowModal] = useState(false);
 
  const bodyStyle = showModal ? { backgroundColor: "rgba(0, 0, 0, 0.5)" } : {};

  const openModal = () => {

    const loginUser = localStorage.getItem("userID");

       

          if (!loginUser) {
              Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "User is not logged in ",
              });
              return;
          }
           
    if (orders && orders.length > 0) {
      setShowModal(true);
      setOpenSideModal(false);

      setSelectedTickets([]);
      setModalVisible(false);
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `You have not selected any tickets.`,
      });
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };



  const handleStartDateChange = (date) => {
    const formattedDate = dayjs(date).format('MM/DD/YYYY');
    
    setStartDate(date);
  };
   
  
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const [clickCount, setClickCount] = useState(0);
  const handleItemsPerPageChange = () => {
    // const div = document.getElementById("thisThat");

    // if (div) {
    //   div.scrollIntoView({ behavior: "smooth", block: "start" });
    // }
    const newValue = clickCount === 0 ? 10 : 20;

    setitemsPerPage(newValue);
    setClickCount(clickCount + 1);
  };

 


  const handleSearch = async () => {
    if (
      selectedCountry.length === 0 &&
      startDate === "" &&
      endDate === "" &&
      searchText === ""
    ) {
      console.log('returning from line 406');
      return;
    }

    try {
      setIsTableLoading(true);
      setIsSearchCompleted(false);

      const data = await fetchHomeData(
        currentPage,
        itemsPerPage,
        selectedCountry.join(","),
        startDate,
        endDate,
        searchText,
        cate,
        event_type,
        sortOrder,
        minPrice,
        maxPrice
      );
 
    
   
      setLocalSearch();
      setEvents(data.events.data);
      setTotalPages(data.events.last_page);
      setPrevPageUrl(data.events.prev_page_url);
      setNextPageUrl(data.events.next_page_url);
      setfrom(data.events.from);
      setTo(data.events.to);
      setTotal(data.events.total);
      setAllcategories(data.categories);
      setBlogs(data.blog);
      setIsSearchCompleted(true); 
      setcountriesCount(data.events.data.length);
      setselectedcounties(data.selectedcounties);

    } catch (error) {
      console.error("Error fetching home data:", error);
    } finally {
      setIsTableLoading(false);
    }
  };

  const setLocalSearch = () =>{
    
    let stateData = {
       
      selectedCountry: selectedCountry.join(","),
      startDate: startDate,
      endDate: endDate,
      searchText: searchText,
      cate: cate,
      event_type: event_type,
      sortOrder: sortOrder,
      minPrice: minPrice,
      maxPrice: maxPrice,
      
       
      
  };
   
  let stateDataString = JSON.stringify(stateData);
  setIsSearchCompleted(false);
  setIsTableLoading(true);
 
  localStorage.setItem("searchFilters", stateDataString);
  
  }
  const [Text, setText] = useState("");

  const clearInput = async () => {
    if (inputRef.current) inputRef.current.value = "";
    if (startDateInputRef.current) startDateInputRef.current.value = "";
    if (endDateInputRef.current) endDateInputRef.current.value = "";
    if (inputRef.current) inputRef.current.value = "";
    
    localStorage.removeItem("searchFilters");
    setcountriesCount(0);
    // setMaxPrice(5000);
    // setitemsPerPage(5);
    setSelected_start_Date(null);
    setSelected_end_Date(null);
    setSelectedCountry([]);
    setselectedcounties([]);
    setCountry([]);
    setEndDate(null);
    setStartDate(null);
    // setType('');
    setSearchText("");
    setIsTableLoading(true);
    setIsSearchCompleted(false);
    
    setSearchText("");
    handleClearFunction();
  };

  const handleClearFunction = async () => {
    try {
      setIsTableLoading(true);
      setIsSearchCompleted(false);

      const data = await fetchHomeDataReset(currentPage, itemsPerPage);
      setEvents(data.events.data);
      setTotalPages(data.events.last_page);
      setPrevPageUrl(data.events.prev_page_url);
      setNextPageUrl(data.events.next_page_url);
      setfrom(data.events.from);
      setTo(data.events.to);
      setTotal(data.events.total);
      setAllcategories(data.categories);
      setBlogs(data.blog);
      setIsSearchCompleted(false);
      setIsTableLoading(true);
      setcountriesCount(data.events.data.length);
      setselectedcounties(data.selectedcounties);
    } catch (error) {
      console.error("Error fetching home data:", error);
    } finally {
      setIsTableLoading(true);
    }
  };

  const handleChangeInput = (e) => setSearchText(e.target.value);
  const [cate, setCate] = useState("");

  const [selectedCategories, setSelectedCategories] = useState([]);

  const handleChangeCate = (selectedCategoryIds) => {
    setSelectedCategories(selectedCategoryIds);
    const selectedCategoriesString = selectedCategoryIds.join(",");

    setCate(selectedCategoriesString);
  };

  const [sortOrder, setSortOrder] = useState("");

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setSortOrder(selectedValue);
    setIsTableLoading(false);
  };
  const [show, setShow] = useState("");
  const dropdownRef = useRef(null);
  const handleChangeMobile = (event) => {
  
    const selectedValue = event;
    setSortOrder(selectedValue);
    setIsTableLoading(false);
    setShow("");
      
  };
  const handleToggle = () => {
        if (show === "show") {
            setShow("");
            document.removeEventListener("mousedown", handleClickOutside);
        } else {
            setShow("show");
            document.addEventListener("mousedown", handleClickOutside);
        }
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShow("");
            document.removeEventListener("mousedown", handleClickOutside);
        }
    };

  const fetchDataHome = async () => {
    setMob_filter(false);
    if (isMobile) {
      setIsTicketVisible(false);
    }
    try {
        setIsTableLoading(true);
      const data = await fetchHomeData(
        currentPage,
        itemsPerPage,
        selectedCountry,
        startDate,
        endDate,
        searchText,
        cate,
        event_type,
        sortOrder,
        minPrice,
        maxPrice
      );
      setIsTableLoading(false);
      setEvents(data.events.data);
      setTotalPages(data.events.last_page);
      setcountriesCount(data.events.data.length);
      setPrevPageUrl(data.events.prev_page_url);
      setNextPageUrl(data.events.next_page_url);
      setfrom(data.events.from);
      setTo(data.events.to);
      setTotal(data.events.total);
      setcountriesCount(data.events.data.length);
      setselectedcounties(data.selectedcounties);
      localStorage.removeItem("orders");
      localStorage.removeItem("FakeOrder");
      setBlogs(data.blog);
      fetchData();
      
    
      const countriesOptions = [
       
        ...data.currencies.map((currency) => ({
          value: currency.id,
          label: currency.country,
          country_flag: currency.country_flag,
          event_count: currency.event_count,
        })),
      ];

      setcountryOptions(countriesOptions);

      setAllcategories(data.categories);
    } catch (error) {
      console.error("Error fetching home data:", error);
    } finally {
      setIsTableLoading(false);
    }
  };


  useEffect(() => {
    
    fetchDataHome();
  }, [
    itemsPerPage,
    sortOrder,
    cate,
    event_type,
    minPrice,
    maxPrice,
    selectedCountry

  ]);

 
 


  const fetchData = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const data = await fetchUserData(token);

      if (data.user.image) {
        const fullImagePath = data.user.imagePath + data.user.image;
        setImagePath(fullImagePath);
      } else {
        setImagePath("/images/notlogin.png");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // Calculate the subtotal (price without service fee)
    const subtotal = availableTickets.reduce(
      (accumulator, t) => accumulator + t.price * t.quantity,
      0
    );
    setSubtotal(subtotal);

    const fee = 0;
    setServiceFee(fee);

    const totalPrice = subtotal + fee;
    setTotalPrice(totalPrice);

    const availableitems =
      JSON.parse(localStorage.getItem("items")) || availableTickets;

    const quantities = availableitems.reduce((data, ticket) => {
      data[ticket.id] = ticket.quantity;
      return data;
    }, {});
    localStorage.setItem("ticketQuantities", JSON.stringify(quantities));
  }, [availableTickets]);
  useEffect(() => {
    localStorage.setItem("availableTickets", JSON.stringify(availableTickets));
    localStorage.setItem("totalPrice", totalPrice.toString());
  }, [availableTickets, totalPrice]);
  useEffect(() => {
    const savedTickets =
      JSON.parse(localStorage.getItem("availableTickets")) || availableTickets;
    const savedTotalPrice = parseFloat(
      localStorage.getItem("totalPrice") || totalPrice
    );
    setTickets(savedTickets);
    setTotalPrice(savedTotalPrice);
  }, []);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("items"));
    if (items && items.length > 0) {
      const itemsToUpdate = items.filter((item) => {
        const matchingTicket = availableTickets.find(
          (ticket) => ticket.id === item.id
        );
        return matchingTicket && matchingTicket.quantity !== item.quantity;
      });

      if (itemsToUpdate.length > 0) {
        setAvailableTickets((prevTickets) => {
          return prevTickets.map((ticket) => {
            const matchingItem = itemsToUpdate.find(
              (item) => item.id === ticket.id
            );
            if (matchingItem) {
              return { ...ticket, quantity: matchingItem.quantity };
            } else {
              return ticket;
            }
          });
        });
      }
    }
  }, []);

 

  const [showFilters, setShowFilters] = useState(true);

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const [isFilterVisible, setIsFilterVisible] = useState(false);

  const toggle_Filter = () => {
    setIsFilterVisible(!isFilterVisible);
  };

  

  const handleContinue = () => {
    // if (locationStorage) {
    const loginUser = localStorage.getItem("userID");
    if (orders && orders.length > 0) {
      const ordersFromLocalStorage = JSON.parse(
        localStorage.getItem("orders") || "[]"
      );
 
      const allCartData = JSON.parse(localStorage.getItem('CartData')) || {};
      allCartData[localStorage.getItem("userID")] = ordersFromLocalStorage;
      localStorage.setItem('CartData', JSON.stringify(allCartData));
       
      localStorage.setItem("items", JSON.stringify(ordersFromLocalStorage));
      // const itemsJSON = localStorage.getItem("CartData");

      // let items = [];
      // if (itemsJSON) {
      //   items = JSON.parse(itemsJSON);
      // }

      // let totalQuantity = 0;

      // items.forEach((item) => {
      //   totalQuantity += item.quantity;
      // });

      // localStorage.setItem("ordersCount", totalQuantity);
      // updateOrdersCount(totalQuantity);
    
      const itemsJSON = localStorage.getItem("CartData");
  
      let totalQuantity = 0;
    
      if (itemsJSON) {
        const items = JSON.parse(itemsJSON);
    
        // Filter items by loginUser
        if (items[loginUser]) {
          items[loginUser].forEach((item) => {
            totalQuantity += item.quantity;
          });
        }
      }
    
      localStorage.setItem("ordersCount", totalQuantity);
      updateOrdersCount(totalQuantity); 


      setShowModal(false);
    }
  };
  const handleContinue_checkout = () => {
     
    const loginUser = localStorage.getItem("userID");

       

          if (!loginUser) {
              Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "User is not logged in.",
              });
              return;
          }
           
         

        if (orders && orders.length > 0) {
          const ordersFromLocalStorage = JSON.parse(
            localStorage.getItem("orders") || "[]"
          );
          const allCartData = JSON.parse(localStorage.getItem('CartData')) || {};
          allCartData[localStorage.getItem("userID")] = ordersFromLocalStorage;
          localStorage.setItem('CartData', JSON.stringify(allCartData));
           
          localStorage.setItem("items", JSON.stringify(ordersFromLocalStorage));
           
        
          const itemsJSON = localStorage.getItem("CartData");
      
          let totalQuantity = 0;
        
          if (itemsJSON) {
            const items = JSON.parse(itemsJSON);
        
            // Filter items by loginUser
            if (items[loginUser]) {
              items[loginUser].forEach((item) => {
                totalQuantity += item.quantity;
              });
            }
          }
        
          localStorage.setItem("ordersCount", totalQuantity);
          updateOrdersCount(totalQuantity); 
          navigate("/cart");
      };
  };
  // const handleContinue_checkout = () => {
  //   // if (locationStorage) {

  //   if (orders && orders.length > 0) {
  //     const ordersFromLocalStorage = JSON.parse(
  //       localStorage.getItem("orders") || "[]"
  //     );
  //     localStorage.setItem("items", JSON.stringify(ordersFromLocalStorage));

  //     // Retrieve orders from localStorage
  //     const loginUser = localStorage.getItem("userID");
  //     const itemsJSON = localStorage.getItem("CartData");
  
  //     let totalQuantity = 0;
    
  //     if (itemsJSON) {
  //       const items = JSON.parse(itemsJSON);
    
  //       // Filter items by loginUser
  //       if (items[loginUser]) {
  //         items[loginUser].forEach((item) => {
  //           totalQuantity += item.quantity;
  //         });
  //       }
  //     }
    
  //     localStorage.setItem("ordersCount", totalQuantity);
  //     updateOrdersCount(totalQuantity); 

  //     setShowModal(false);
  //     navigate("/cart");
  //   } else {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Oops...",
  //       text: `You have not selected any tickets.`,
  //     });
  //   }
  // };
  

  const [isOpen, setIsOpen] = useState(true);

  const toggleCountry = (value) => {
    if (value === "all") {
      if (selectedCountry.includes("all")) {
        setSelectedCountry([]);
      } else {
        setSelectedCountry(["all"]);
      }
      return;
    }

    const updatedSelectedCountry = selectedCountry.filter(
      (country) => country !== "all"
    );

    if (updatedSelectedCountry.includes(value)) {
      setSelectedCountry(
        updatedSelectedCountry.filter((country) => country !== value)
      );
    } else {
      setSelectedCountry([...updatedSelectedCountry, value]);
    }
  };

  const handleRemoveCountry = async (countryValue) => {
    
    setSelectedCountry((prevSelectedCountry) => {
      const newSelectedOptions = prevSelectedCountry.filter(
        (option) => option !== countryValue
      );
      if (newSelectedOptions.length === 0) {
        setSearch(1);
        setIsSearchCompleted(true);
        setIsTableLoading(true);
        clearInput();
        return [];
      }
      return newSelectedOptions;
    });

    setSelectedCountryValues((prevSelectedCountry) => {
      const newSelectedOptions = prevSelectedCountry.filter(
        (option) => option !== countryValue
      );
      if (newSelectedOptions.length === 0) {
        return [];
      }
      return newSelectedOptions;
    });
    setSearch(1);
  };

  useEffect(() => {
    if (SearchCountry === 1) {
      handleSearch();
      setSearch(0);
    }
  }, [SearchCountry]);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const UpcomingEvents = events.map((event, index) => {
    return ( 
      <>
        <EventComponent
          key={event.id}
          event={event}
          selectedTickets={selectedTickets}
          handleTicketSelection={handleTicketSelection}
          handleAddToCart={handleAddToCart}
          mainIndex={index}
          keyboardId={`keyboard-${index}`}
          setOpenSideModal={setOpenSideModal}
          openSideModal={openSideModal}
          setEvtData={setEvtData}
          setAvailableTickets={setAvailableTickets}
          setticketActiveTab={setticketActiveTab}
          ticketactiveTab={ticketactiveTab}
          setisticketLoading={setisticketLoading}
          isticketLoading={isticketLoading}
 
      
          // setisDisable={setisDisable}
          // isDisable={isDisable}
        />
      </>
    );
  });

  
  const [currentTab, setCurrentTab] = useState("eventsTab");
  const [checkedCountries, setCheckedCountries] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleCheckedCountry = (value) => {
    setCheckedCountries((prev) =>
      prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value]
    );
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
 
  const renderTabContent = () => {
    
    switch (currentTab) {
      case "eventsTab":
        return (
          <div className="search-bar py-0">
            <div className="row px-2 py-1">
               
            <div className="col-lg-3 col-sm-12 px-custom-1 mb-2-sm  ">
           
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    onChange={handleStartDateChange}
                    value={startDate}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="form-control"
                        onFocus={() => {  params.inputProps.onFocus(); }}
                        onClick={() => {  params.inputProps.onFocus(); }}
                      />
                    )}
                  />
                </LocalizationProvider>
    
              </div>

            <div className="col-lg-3 col-sm-12 px-custom-1 mb-2-sm  ">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        onChange={handleEndDateChange}
                        value={endDate} 
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="form-control"
                            onFocus={() => {  params.inputProps.onFocus(); }}
                            onClick={() => {
                              params.inputProps.onFocus();
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
              </div>

            <div className="col-lg-5 col-sm-12 px-custom-1 mb-2-sm  ">
                  <input
                      id="form_name6"
                      className="form-control filter-5 border-0 float-input outline-0 shadow-none w-100 bg-white"
                      type="text"
                      required
                      onChange={handleChangeInput}
                      // ref={inputRef}
                      value={searchText}
                      placeholder=" Search Event Name..."
                    />
              </div>
           
              <div className="col-lg-1 col-sm-12 px-custom-1 ">
                  <div className="w-100">
              

                    {!isTableLoading && !isSearchCompleted && (
                        <button
                          className="btn btn btn btn-search"
                          onClick={handleSearch}
                        >
                          Search
                        </button>
                      )}
                      {isTableLoading && (
                        <button
                          className="btn btn-search"
                          
                          disabled
                        >
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </button>
                      )}
                      {!isTableLoading && isSearchCompleted && (
                        <button
                        
                          className="btn btn-search"
                          
                          onClick={clearInput}
                        >
                          Reset
                        </button>
                      )}

                  </div>
              </div>
            </div>
          </div>



        );
      case "hotelsTab":
      case "transportTab":
      case "flightTab":
        return (
          <div className="d-block w-100 py-3 px-3">
            <h4 className="text-15 text-black m-0">
              Filters Coming Soon For {" "}
              {currentTab.charAt(0).toUpperCase() +
                currentTab.slice(1).replace("Tab", "")}
            </h4>
          </div>
        );
      default:
        return null;
    }
  };

  // return(

  //   <Vanta/>
  // );
  return (
    <>

      
      <Helmet>
        <link href="/homeCss/bootstrap.min.css" rel="stylesheet" />
        <link href="/homeCss/animate.css" rel="stylesheet" />
        <link href="/homeCss/custom.css" rel="stylesheet" />
        <link href="/filterCss/custom.css" rel="stylesheet" />
        <link href="/homeCss/slick-theme.css" rel="stylesheet" />
        <link href="/homeCss/slick.css" rel="stylesheet" />
      
         
      </Helmet>
      {loading ? (
        <section className="pad-top-20 pad-bot-80">
          <div className="container3">
            <div className="block-element m-b-30"></div>
            <div
              className="block-element loader-mobile-screen"
              style={{ marginTop: "80px" }}
            >
              <LoadingSpinner />
            </div>
          </div>
        </section>
      ) : (
        <>

  <HeaderNewComponent />
  
  <section className="header-custom">
    <div className="container">
      <nav className="navbar py-0">
        <div className="navbar-custom-booking">
          <ul className="navbar-nav-custom">
             
            <li  className="nav-item">
                    <a className={`nav-link  ${ currentTab === "eventsTab" ? "active" : "" }  `}  onClick={() => setCurrentTab("eventsTab")}>
                      <i className="fa fa-calendar  pr-2" aria-hidden="true" />
                      Events
                    </a>

            </li> 


            <li  className="nav-item">
               <a className={`nav-link  ${ currentTab === "hotelsTab" ? "active" : "" }  `}  onClick={() => setCurrentTab("hotelsTab")}>
                <i className="fa fa-bed pr-2" aria-hidden="true" />
                Accommodation
              </a>
            </li>
            <li className="nav-item">
                  <a className={`nav-link  ${ currentTab === "buses" ? "active" : "" }  `} onClick={() => setCurrentTab("buses")}>
                <i className="fa fa-car pr-2" aria-hidden="true"  />
                Buses
              </a>
            </li>
            <li className="nav-item">
              <a className={`nav-link  ${ currentTab === "flightTab" ? "active" : "" }  `} onClick={() => setCurrentTab("flightTab")}>
                <i className="fa fa-plane pr-2" aria-hidden="true" />
                Flights
              </a>
            </li>
           
           
          </ul>
        </div>
      </nav>
    </div>
  </section>
  <section className="bg-overlay">
    <div className="hero-section">
      <div className="container">
        <h1 className="bg-image-title">
        Tickets...for Everything
        </h1>
        <p className="herobanner-subtitile">
        Find tickets for your favourite events
        </p>
      
      </div>
    </div>
  </section>
  <section className="searchbox_cross_product__title " style={{display: 'none'}}>
  <h1 id="form_search_h1" className="b-no-tap-highlight">
    Tickets...for Everything
  </h1>
  <label className="search_hotels_subtext" htmlFor="input_destination">
    Find tickets for your favourite events
  </label>
</section>

  <section className=" bg-silver2 pt-3">
  <div className="container">
    
  {renderTabContent()}
    </div>
  </section>



          
       
          <div className="" style={bodyStyle}>
           

            <section className=" bg-silver2">
              <div className="container bg-silver2">

               
                <div ref={wrapperRef}
                  className="d-flex align-items-start justify-content-start flex-wrap mt-4"
                  id="thisThat"
                >
                  <div className="booking-search-checkout-left sort-filter-sticked-mobile pe-lg-4 pe-md-4 pe-0 "  ref={stickyDivRef}>
                    <div className="d-block w-100">
                      <div className="d-lg-none d-md-none d-block w-100 " >
                        <div className="d-block w-100 shadow-sm bottom-0 z-index-9999 w-100 start-0">
                          <div className="d-flex align-items-center rounded-2 py-3 border border-color-grey px-2 bg-white justify-content-between">
                          <div className="d-inline-block">
                            <button
                              id="filter-mobile-toggle"
                              className="d-flex align-items-center justify-content-start bg-white border-0"
                              onClick={handleMobfilter}
                            >
                              <img
                                src="images/filter-icon2.png"
                                className="me-1"
                              />
                              <span className="text-16 text-black poppins-500">
                                Filter
                              </span>
                            </button>
                          </div>
                          <div className="d-inline-block">
                             <div className="dropdown">
                              <button onClick={handleToggle} className="ddd dropdown-toggle d-flex align-items-center justify-content-start bg-white border-0" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                              <img src="images/sort-icon1.png" className="me-1"  />
                              <span className="text-16 text-black poppins-500"> Sort </span>
                              </button>
                              
                              <ul className={`dropdown-menu ddddddddd ${show ? 'show' : ''}`} aria-labelledby="dropdownMenuButton1" ref={dropdownRef}>

                                <li>
                                  <a className="dropdown-item text-14" onClick={() => handleChangeMobile('all')}>
                                    All Events
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item text-14" onClick={() => handleChangeMobile('featured')}>
                                  Featured
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item text-14" onClick={() => handleChangeMobile('newtoold')}>
                                    New to Old
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item text-14" onClick={() => handleChangeMobile('oldtonew')}> 
                                    Old to New
                                  </a>
                                </li>
                              </ul>
                              </div>
                          </div>

                          </div>
                        </div>
                      </div>
                      <div
                        className={`d-block w-100 filter-mobile-bar ${
                          Mob_filter ? "open-filter-mobile" : ""
                        }`}
                      >
                        <div className="d-block w-100 filter-mobile-bar-wrapper">
                          <div className="d-lg-none d-md-none w-100 mb-3">
                            <div className="w-100 align-items-center justify-content-between filter-inner-wrp">
                              <h4 className="m-0 p-0 col-blue3 poppins-600 text-20">
                                <img src="images/filter-icon3.png" />
                                Filters
                              </h4>
                              <button
                                id="filter-mobile-toggle-close"
                                className="border-0 bg-transparent text-18"
                                onClick={handleMobfilterOff}
                              >
                                <i className="fa fa-times"> </i>
                              </button>
                            </div>
                          </div>
                          {/* <PriceFilterComponent
                            totalRecords={totalRecords}
                            to={to}
                            from={from}
                            setEvents={setEvents}
                            setType={setType}
                            setMinPrice={setMinPrice}
                            setMaxPrice={setMaxPrice}
                          /> */}

                          <CountriesList
                            countries={countryOptions}
                            setSelectedCountry={setSelectedCountry}
                            selectedCountry={selectedCountry}
                            totalRecords={totalRecords}
                            setSelectedCountryValues={setSelectedCountryValues}
                            selectedCountryValues={selectedCountryValues}
                          />
                          <div className="d-block w-100 mb-4">
                            <div className="d-block w-100 mb-3"> 
                              <h4 className="text-black text-18 m-0 poppins-600">
                                Categories
                              </h4>
                            </div>
                            <div className="d-block w-100 bg-white px-3 py-3 rounded-2 shadow-normal">
                              <div className="d-block w-100 mb-2">
                                <h4 className="text-black poppins-600 mt-0 mb-1 text-15">
                                  Event Categories
                                </h4>
                              </div>
                              <div className="d-block w-100">
                                {allCategories.map((category) => (
                                  <CategoryFilterComponent
                                    category={category}
                                    onChangeCate={handleChangeCate}
                                    selectedCategories={selectedCategories}
                                  />
                                ))}
                              </div>
                            </div>
                          </div>
                          <PriceFilterComponent
                            totalRecords={totalRecords}
                            to={to}
                            from={from}
                            setEvents={setEvents}
                            setType={setType}
                            setMinPrice={setMinPrice}
                            setMaxPrice={setMaxPrice}
                          />
                           
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div className="booking-search-checkout-right ps-lg-3 ps-md-3 ps-0  pe-lg-3 pe-md-3 pe-0 mt-0" ref={nextDivRef}>
                    <div className="d-block w-100">
                      <div className="d-block w-100 mb-lg-3">
                        <h3 className="text-black poppins-600 text-24 m-0 mob-text-16">
                          {/* {selectedcounties && selectedcounties.length > 0 ? `${selectedcounties.length} Countries` : 'All Countries'} : {totalRecords} search results found */}
                        </h3>
                      </div>
                      <div className="d-block w-100 mb-3 mt-3">
                        <div className="row">
                          <div className="col-lg-9 col-md-9 col-sm-12 col-12">
                            <div className="d-flex w-100 align-items-center justify-content-start flex-wrap">
                              {selectedcounties &&
                                selectedcounties.length > 0 &&
                                selectedcounties.map((country, index) => (
                                  <div
                                    key={index}
                                    className="d-flex align-items-center justify-content-start mb-1 me-2 px-2 py-2 border border-color-blue rounded-2"
                                  >
                                    <img
                                      src={country.flag}
                                      alt={`${country.name} flag`}
                                      id="FlagImages"
                                      className="w-auto flg"
                                    />
                                    <span className="col-blue3 mx-2 d-inline-block">
                                      {country.name}
                                    </span>
                                    <button
                                      className="p-0 border-0 bg-transparent text-14 poppins-500 text-muted awann"
                                      onClick={() =>
                                        handleRemoveCountry(country.id)
                                      }
                                    >
                                      <i className="fa fa-times"> </i>
                                    </button>
                                  </div>
                                ))}
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                            <div className="d-lg-block d-md-block d-none  mt-lg-0 mt-md-0 mt-3">
                              <div className="form-group position-relative me-2 border border-color-silver rounded-4 mb-2">
                              <span className="sort_label_bg"></span>
                                <select
                                  id="form_name2"
                                  className="form-control text-14 border-0 float-input outline-0 shadow-none"
                                  name="sortOrder"
                                  onChange={handleChange}
                                >
                                  <option value="all">All Events</option>
                                  <option value="featured">Featured</option>
                                  {/* <option value="lowToHigh">Prices: Low to High</option>
                                  <option value="highToLow">Prices: High to Low</option> */}
                                  <option value="newtoold">New to Old</option>
                                  <option value="oldtonew">Old to New</option>
                                </select>
                                <label
                                  for="form_name2"
                                  className="float-label"
                                  id="SortfloatHome"
                                >
                                  Sort By
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-block w-100 mb-3">
                        <h3 className="text-black poppins-600 text-20 m-0 ">
                          Popular Events
                        </h3>
                      </div>

                      {isTableLoading ? (
                        <LoadingSpinner />
                      ) : (
                        <>
                          {UpcomingEvents.length > 0 ? (
                            <>
                              {UpcomingEvents}

                              {totalRecords !== itemsPerPage &&
                                to !== totalRecords && (
                                  <div className="d-flex w-100 align-items-center justify-content-center mb-3">
                                    <button
                                      className="custom-btn10"
                                      onClick={handleItemsPerPageChange}
                                    >
                                      Load more Results
                                    </button>
                                  </div>
                                )}
                            </>
                          ) : (
                            <>
                              <div>
                                
                                  <img
                                    className="d-block m-auto "
                                    src="/images/eventnotfound.png"
                                  />
                                </div>
 
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <TicketSideBar 
              
              events={events}
              selectedTickets={selectedTickets}
              handleClose={handleCloseModal}
              evtData={evtData}
              setOrders={setOrders}
              setAvailableTickets={setAvailableTickets}
              ordersCount={updateOrdersCount}
              updateOrdersCount={updateOrdersCount}
              availableTickets={availableTickets}
              orders={orders}
              setSelectedTickets={setSelectedTickets}
              setOpenSideModal={setOpenSideModal}
              openSideModal={openSideModal}
              openModal={openModal}
              handleAddToCart={handleAddToCart}
              setticketActiveTab={setticketActiveTab}
              ticketactiveTab={ticketactiveTab} 
              isticketLoading={isticketLoading} 
              />
            </section>
            

            <TicketModelComponent
              modalVisible={modalVisible}
              selectedTickets={selectedTickets}
              handleClose={handleCloseModal}
              evtData={evtData}
              setOrders={setOrders}
              setAvailableTickets={setAvailableTickets}
              ordersCount={updateOrdersCount}
              updateOrdersCount={updateOrdersCount}
              availableTickets={availableTickets}
              orders={orders}
              setSelectedTickets={setSelectedTickets}
              openModal={openModal}
            />


            

            {/* <TikketsBlog isLoading={isLoading} blogrender={blogrender} /> */}

            {showModal && (
              <div
                className={`modal fade modal-open  show ${
                  showModal ? "d-block modal-open" : ""
                }`}
                id="staticBackdrop"
                style={{
                  display: "block",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                }} // Black backdrop
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabindex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog  ">
                  <div className="modal-content">
                    <div className="modal-body p-0">
                      <div className="d-block w-100 bg-white rounded-3 pb-3 pt-3">
                        <div className="row justify-content-center position-relative m-0">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-12 p-0 ">
                            <div className="d-block w-100 text-center mb-4  ps-3 pe-3">
                              <h3 className="text-black text-26 poppins-700 mob-text-20">
                                Great Job!
                              </h3>
                            </div>
                            <div className="d-block w-100 text-center mb-4  ps-3 pe-3">
                              <img
                                className="custom-image2 popupimg"
                                src="/images/thankyou-graphics.png"
                              />
                            </div>
                            <div className="d-block w-100 text-center  ps-3 pe-3">
                              <h5 className="text-black poppins-500 text-15 mb-3">
                                Thanks for placing your order.
                              </h5>
                              <h3 className="text-black poppins-600 text-17 mb-3 mob-text-17">
                                Your Items are now in your Cart.
                              </h3>
                              <h5 className="col-green poppins-500 text-15 mb-2 mt-0 d-none">
                                01dc1370-3df6-11eb-b378-0242ac130002
                              </h5>
                              <h5 className="text-black poppins-500 text-15 mb-3 mt-0 max-w-90">
                                For questions or queries, contact us or please see our <Link className="text-black" to="/faq">FAQ page</Link>.
                              </h5>
                            </div>
                            <div className="d-block w-100 mt-4 mb-3 pb-2 position-relative">
                              <img
                                src="/images/line-1.png"
                                className="position-relative z-index99 line-img1"
                              />
                              <span className="d-block w-100 line-1 position-absolute top-0 start-0 mt-3"></span>
                            </div>
                            <div className=" align-items-center justify-content-between flex-wrap  pl-3 pr-3 container">
                              <button
                                className="custom-btn2 w-100 mb-3"
                                onClick={handleContinue}
                              >
                                <span onClick={handleContinue}>
                                  Continue Shopping
                                </span>
                              </button>
                              <button
                                className="custom-btn3 w-100"
                                onClick={handleContinue_checkout}
                              >
                                Checkout
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <FooterComponent />
        </>
      )}
    </>
  );
}
export default FilterLatest_HomeComponent;
