import React, { useEffect, useState, useRef } from "react";
import "../../App.css";
import { Link, useParams } from "react-router-dom";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { fetchBlogDetail } from '../../services/blogservice';
import { Helmet } from "react-helmet";
import HeaderComponent from '../HeaderComponent/HeaderComponent.js';
import FooterComponent from '../FooterComponent/FooterComponent.js';


function RefundPolicyComponent({ idOrTitle }) {
  const [blogData, setBlogData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { id, title, description } = useParams();
  const [pageLoading, setPageLoading] = useState(false);
  const isLoadingRef = useRef(false);


  useEffect(() => {
    if (!isLoadingRef.current) {
      isLoadingRef.current = true;
      fetchBlogDetail(id, title, setBlogData, (isLoading) => {
        setIsLoading(isLoading);
        isLoadingRef.current = false;
      });
    }
  }, [id, title, setBlogData, setIsLoading]);


  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    document.body.classList.add("home-bg");

    // Clean up the class when the component unmounts
    return () => {
      document.body.classList.remove("home-bg");
    };
  }, []);

  if (isLoading) {
    return (
      <section className="pad-top-20 pad-bot-80" id="loaderSection">
        <div className="container3">
          <div className="block-element m-b-30">
          </div>
          <div className="block-element loader-mobile-screen" style={{ marginTop: '80px' }}>
            <LoadingSpinner />
          </div></div>
      </section>
    );
  }



  return (

    <>
      <Helmet>
        <link href="/css/bootstrap.min.css" rel="stylesheet" />
      </Helmet>
      <HeaderComponent />
      <section className="pad-top-60 pad-bot-60">
        <div className="container2">
          <div className="block-element m-b-20">
            <Link to="/" className="back-btn1">
              <i className="fa fa-angle-left"> </i> Back
            </Link>
          </div>
          <div className="block-element m-b-10">
            <div className="textual-sec1">
              <div class="mb-6">
                <p className="col-black"><strong>Document Title:</strong> Tikkets Refund Policy</p>
                <p className="col-black"><strong>Document Number:</strong> Ref-Pol-01</p>
                <p className="col-black"><strong>Version Number:</strong> 1.2</p>
                <p className="col-black"><strong>Effective Date:</strong> 30 June, 2024</p>
                <p className="col-black"><strong>Last Review Date:</strong> 27 June, 2024</p>
                <p className="col-black"><strong>Next Review Date:</strong> 27 September, 2024</p>
                <p className="col-black"><strong>Approved By:</strong></p>
                <p className="col-black"><strong>Document Owner:</strong> Narda Ventura, Operations Officer – Kanoo Jamaica</p>
              </div>

              <h3 className="col-black">Tikkets Refund Policy</h3>
              <p className="col-black">At Tikkets.com, we strive to provide our users with exceptional experiences and services. We understand that circumstances may arise requiring you to request a refund for a ticket purchased through our app. Please review the following refund policy to understand our guidelines and procedures:</p>

              <h3 className="col-black">1. Eligibility for Refund</h3>
              <p className="col-black">Refunds may be requested under the following circumstances:</p>
              <ul>
                <li><strong>a. Significant Change in Event Details</strong>
                  <ul>
                    <li> <strong>Cancellation:</strong> If the event for which you purchased a ticket is cancelled, you may be eligible for a refund.</li>
                    <li> <strong>Rescheduling:</strong> If the event time or date is significantly altered.</li>
                  </ul>
                </li>
                <li><strong>b. Ticketing Errors</strong>
                  <ul>
                    <li> <strong>Duplicate Charges:</strong> If you are charged multiple times for the same ticket.</li>
                    <li> <strong>Incorrect Tickets:</strong> If the tickets received do not match the ones purchased (e.g., wrong seats or sections).</li>
                  </ul>
                </li>
                <li><strong>c. Personal Circumstances</strong>
                  <ul>
                    <li> <strong>Medical Emergencies:</strong> If you or an immediate family member falls seriously ill or is injured.</li>
                    <li> <strong>Bereavement:</strong> In the event of a death in your immediate family.</li>
                  </ul>
                </li>
              </ul>

              <h3 className="col-black">2. Refund Requests</h3>
              <p className="col-black">To request a refund, please follow these steps:</p>
              <ul>
                <li><strong>a. Contact Customer Support:</strong> Reach out to our customer support team through the app or via email at [support email].</li>
                <li><strong>b. Submit Your Request:</strong> Refund requests must be submitted within 3-5 days of the event cancellation or rescheduling, or within a reasonable timeframe for other exceptional circumstances.</li>
                <li><strong>c. Provide Necessary Details:</strong> Include your order number and details of the event when submitting a refund request to expedite the process.</li>
              </ul>

              <h3 className="col-black">3. Refund Process</h3>
              <p className="col-black">Once we receive your refund request, we will follow these steps:</p>
              <ul>
                <li><strong>a. Review Request:</strong> We will review your request based on our eligibility criteria.</li>
                <li><strong>b. Process Refund:</strong> If your request meets our refund policy guidelines, we will process the refund to the original payment method used for the purchase.</li>
                <li><strong>c. Processing Time:</strong> Please allow 3-10 business days for the refund to reflect in your account, depending on your financial institution's processing time.</li>
              </ul>

              <h3 className="col-black">4. Non-Refundable Tickets</h3>
              <ul>
                <li><strong>a. Specific Terms:</strong> Some tickets may be non-refundable as per the terms and conditions set by the event organizer.</li>
                <li><strong>b. Labeling:</strong> If a ticket is labeled as non-refundable at the time of purchase, it is ineligible for a refund except in cases where required by applicable law.</li>
              </ul>

              <h3 className="col-black">5. How to Prove Eligibility for a Refund</h3>
              <p className="col-black">To prove eligibility for a refund, please provide the following documentation:</p>
              <ul>
                <li><strong>a. Documentation and Communication</strong>
                  <ul>
                    <li> <strong>Official Announcements:</strong> Save and provide any official announcements from the event organizers regarding cancellations, rescheduling, or changes.</li>
                    <li> <strong>Receipts and Tickets:</strong> Keep all receipts, confirmation emails, and tickets as proof of purchase.</li>
                    <li> <strong>Communication Records:</strong> Maintain records of any communication with the ticketing service or event organizers, including emails or messages where the refund policy is discussed.</li>
                  </ul>
                </li>
                <li><strong>b. Specific Circumstances Documentation</strong>
                  <ul>
                    <li> <strong>Medical Certificates:</strong> If claiming a refund due to a medical emergency, provide a doctor’s note or hospital records.</li>
                    <li> <strong>Obituaries or Death Certificates:</strong> In the case of bereavement, provide an obituary or death certificate.</li>
                    <li> <strong>Government Orders:</strong> For events canceled due to government restrictions, provide copies of relevant government orders or advisories.</li>
                  </ul>
                </li>
                <li><strong>c. Error Proofs</strong>
                  <ul>
                    <li> <strong>Bank Statements:</strong> If disputing duplicate charges, provide bank statements showing the multiple charges.</li>
                    <li> <strong>Photos of Tickets:</strong> If incorrect tickets were issued, provide photos of the tickets received versus the ones ordered.</li>
                  </ul>
                </li>
              </ul>

              <h3 className="col-black">6. Changes to Refund Policy</h3>
              <p className="col-black">We reserve the right to modify or update this refund policy at any time without prior notice. Any changes will be effective immediately upon posting the updated policy on our app.</p>
              <p className="col-black">It is your responsibility to review this refund policy periodically for any changes. By using Tikkets.com, you agree to abide by the terms and conditions outlined in this refund policy. If you have any questions or concerns regarding this policy, please contact us at [support email].</p>
            </div>
          </div>
        </div>
      </section>
      <FooterComponent />
    </>
  );
}

export default RefundPolicyComponent;
