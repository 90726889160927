import React from "react";
import "./LoadingSpinner.css"; // Import your CSS for styling

function LoadingSpinner() {
  return (
    <div className="loader-container">
      <div className="loader">
        <div className="loader-inner"></div>
      </div>
    </div>
  );
}

export default LoadingSpinner;
