import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { fetchEventData, fetchFavoriteState, fetchLoggedInUser } from "../../services/EventTikketService";
import Swal from 'sweetalert2';
import { useOrders } from '../../hooks/SettingContext';
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import HomeHeader from '../HeaderComponent/HomeHeader';
import FooterComponent from '../FooterComponent/FooterComponent.js';

function EventTikketComponent() {
  const [userFavrt, setUserFavrt] = useState(null);
  const { id, name, address } = useParams();
  const locationStorage = localStorage.getItem("authToken");
  const [evtData, setEvtData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [Pageloading, setpageloading] = useState(true);
  const [tickets, setTickets] = useState([]);
  const [FrvtText, setText] = useState([]);
  const [availableTickets, setAvailableTickets] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [error, setError] = useState(null);
  const storedFavoriteState = localStorage.getItem('favorite');
  const [isLoading, setIsLoading] = useState(true);
  const [isFavorited, setIsFavorited] = useState(false);
  const [favoriteText, setFavoriteText] = useState('');
  const [isIdMatching, setIsIdMatching] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const isLoadingRef = useRef(false);
  const isFetchingRef = useRef(false);
  const isFavStateFetchingRef = useRef(false);
  const [isFavStateFetching, setIsFavStateFetching] = useState(false);

 console.log(favoriteText,'favoriteText');
  useEffect(() => {
    

    const fetchData = async () => {
      try {
        if (!isFetchingRef.current) {
          isFetchingRef.current = true;
          localStorage.removeItem("orders");
          const ordersFromLocalStorage = JSON.parse(localStorage.getItem('items') || '[]');
          localStorage.setItem('orders', JSON.stringify(ordersFromLocalStorage));
          const data = await fetchEventData(id, name);
          const ticketsWithQuantity = data.event.paid_ticket.map((ticket) => ({
            ...ticket,
            quantity: 0,
          }));



          setPageLoading(true);
          setEvtData(data.event);
          setAvailableTickets(ticketsWithQuantity);
          localStorage.setItem("eventName", data.event.name);
          localStorage.setItem("eventImagePath", data.event.imagePath);
          localStorage.setItem("eventImage", data.event.image);

        }
      } catch (error) {
        console.error("Error fetching event data:", error);
      } finally {
        setPageLoading(false);
        setIsLoading(false);
        isFetchingRef.current = false;

      }
    };

    if (id && name && !evtData) { // Only fetch if id, name, and evtData are available
      fetchData();
    }
    return () => {
     
    };
  }, [id, name, evtData, setAvailableTickets]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // You can use 'auto' for instant scroll or 'smooth' for a smooth scroll animation
    });
    document.body.classList.add("home-bg");
    // Clean up the class when the component unmounts
    return () => {
      document.body.classList.remove("home-bg");
    };
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    document.body.classList.add("home-bgms");

    return () => {
      document.body.classList.remove("home-bgms");
    };
  }, []);
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Check if data is already loaded
        if (!isLoadingRef.current) {
          isLoadingRef.current = true; // Set loading state to true

          const response = await fetchLoggedInUser();

          if (response.status === 200) {
            const favrt = await response.json();
            setUserFavrt(favrt);
          } else {
            console.error('Error fetching user data. Status:', response.status);
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, []);
  // const storedFavoriteState = localStorage.getItem('favorite');
  // useEffect(() => {
  //   setText(storedFavoriteState);
  // }, [storedFavoriteState]);

 
  const fetchFavState = async () => {
    if (!isFavStateFetching) {
      setIsFavStateFetching(true);

      try {
        const favorite = await fetchFavoriteState(id);
        setIsFavorited(favorite === 1);
        setFavoriteText(favorite === 1 ? 'Event Added to Favorites' : 'Event Removed from Favorites');
        localStorage.setItem('favoriteState', JSON.stringify({ isFavorited: favorite === 1, favoriteText }));
        // if (favorite === 1) {
        //   Swal.fire({
        //     icon: 'success',
        //     title: 'Event Added to Favorites',
        //     text: 'This event has been added to your favorites',
        //     showCancelButton: false,
        //     confirmButtonText: 'OK',
        //   });
        // } else {
        //   Swal.fire({
        //     icon: 'success',
        //     title: 'Event Removed from Favorites',
        //     text: 'This event has been removed from your favorites',
        //     showCancelButton: false,
        //     confirmButtonText: 'OK',
        //   });
        // }
      } catch (error) {
        console.error('Error fetching favorite state:', error);
      } finally {
        setIsFavStateFetching(false);
      }
    }
  };

  useEffect(() => {
    // Fetch initial favorite state when component mounts
    fetchFavState();
  }, [id]); //
  useEffect(() => {
    const timer = setTimeout(() => {
      setpageloading(false); // Change loading state to false after 2 seconds
    }, 2000); // 2 seconds delay

    return () => clearTimeout(timer); // Clear the timer if the component unmounts
  }, []);


  useEffect(() => {
    const commaSeparatedString = userFavrt || '';
    const idToMatch = id || '';

    const idArray = commaSeparatedString.split(',');
    const isMatching = idArray.includes(idToMatch);

    setIsIdMatching(isMatching);
    if (isMatching) {
      setIsFavorited(true);
      setFavoriteText("UnFavorite");
    } else {
      setIsFavorited(false);
      setFavoriteText("Favorite");
    }
  }, [userFavrt, id]);

  if (error) {
    return <div>Error: {error}</div>;
  }
  if (isLoading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  if (!evtData) {
    return <div><LoadingSpinner /></div>;
  }

 
  return (
    <>
      <Helmet>
        <link href="/css/bootstrap.min.css" rel="stylesheet" />
      </Helmet>
      {Pageloading ? (
        // <section className="eventInfo" style={{backgroundColor:'#9f7bbc',objectFit:'cover',height:'100%'}}>
        <section className="eventInfo" style={{ height: '100vh' }}>
          <div className="container3">
            <div className="block-element m-b-30">
            </div>
            <div className="block-element loader-mobile-screen" style={{ marginTop: '80px' }}>
              <LoadingSpinner />
            </div></div>
        </section>
      ) : (
        <>
          <HomeHeader />
          <section className="eventInfo">
            <div className="container3">
              <div style={{ marginBottom: "2rem", display: "flex" }}>

                <img
                  src="/images/upload/64cad375e9829.png"
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    borderRadius: 10,
                  }}
                  alt="64cad375e9829" />
              </div>
              <div className="row center-row1 mb-6" style={{ marginBottom: '60px' }}>
                <div className="col-md-5 col-lg-5 col-sm-12 col-12 order-lg-1 order-md-1 order-2 order-sm-2">
                  <div className="textual-sec8">
                    <h3 className="col-white"> {evtData.name}</h3>

                    <div style={{ display: "flex" }}>
                      <img
                        src="/images/calendar-icon2.png"
                        style={{ width: 28, height: 20, paddingRight: "0.5rem" }}
                        alt="calendar-icon2" />
                      <p className="col-white"> {evtData.start_time} </p>
                    </div>
                    <div style={{ display: "flex" }}>
                      <img
                        src="/images/map-icon2.png"
                        style={{ width: 30, height: 20, paddingRight: "0.5rem" }}
                        alt="icon2" />
                      <p className="col-yellow1" style={{ textAlign: "left" }}>

                        {evtData.address}
                      </p>
                    </div>
                    <div className="block-element2 m-b-25"></div>
                    <div className="fav-section">
                    <button className="interest-btn" id="like-dislike" onClick={fetchFavState} disabled={isFavStateFetching}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill={isFavorited ? "red" : "white"}
                      >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" fill={isFavorited ? "red" : "white"} />
                      </svg>
                      {isFavorited ? "Interested" : "Not Interested"}
                    </button>
                      {locationStorage ? (
                        <>
                          <Link to={`/view-organizers-profile/${evtData.organization.id}`} id="like-organizers" className="interest-btn ">
                            <i className="fa fa-eye" /> View Organizer
                          </Link>
                        </>
                      ) : (
                        <>
                          <Link to="/user/login" className="interest-btn ">
                            <i className="fa fa-eye" id="eyes_icon_style" /> View Organizer
                          </Link>
                        </>
                      )}

                    </div>
                  </div>
                </div>
                <div className="col-md-7 col-lg-7 col-sm-12 col-12 order-lg-2 order-md-2 order-1 order-sm-1">
                  <div className="custom-image11">
                    <img src={evtData.imagePath + evtData.image} alt={evtData.image} />
                  </div>
                </div>
              </div>
              <h2 className="text-white">Description</h2>
              <div className="description text-white" dangerouslySetInnerHTML={{ __html: evtData.description }}>
              </div>
            </div>
          </section>
          <FooterComponent />
 
        </>
      )}
    </>

  );
}

export default EventTikketComponent;
