import React, { useState,useEffect } from 'react';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';

function PriceFilterComponent({ from, to, totalRecords, setType,setMinPrice,setMaxPrice }) {
  const [checkedType, setCheckedType] = useState(null);
 

  const handleCheckboxClick = (type) => {
    setCheckedType(prevCheckedType => {
      const newCheckedType = prevCheckedType === type ? null : type;
      setType(newCheckedType === null ? 'all' : type);
      return newCheckedType;
    });
  }; 


  const [selectedRange, setSelectedRange] = useState([1, 1000]);

  const handleRangeChange = (newRange) => {
 
    setSelectedRange(newRange);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setMinPrice(selectedRange[0]);
      setMaxPrice(selectedRange[1]);
    }, 1000);

    // Cleanup timeout if selectedRange changes before the timeout completes
    return () => clearTimeout(timeoutId);
  }, [selectedRange, setMinPrice, setMaxPrice]); 

 
  return (
    <>
      {/* <div className="d-block bg-white rounded-2 py-2 px-2 d-block w-100 shadow-sm mb-4 displayNone" style={{'display':'none !important'}}>
        <h5 className="text-14 text-center text-muted m-0 fw-normal lh-lg  " style={{'display':'none !important'}}>  
          <b className="col-blue1 d-inline-block me-1">{from}</b> of {to} results of {totalRecords}
        </h5>
      </div> */}
      <div className="d-block w-100 mb-4">
        <div className="d-block w-100 mb-3">
        <h4 className="text-black text-18 m-0 poppins-600"> Price of Ticket </h4>
          
        </div>
        <div className="d-block w-100 bg-white px-3 py-3 rounded-2 shadow-normal customBorder">
          <div className="d-block w-100 mb-4">
            <div className="d-flex w-100 align-items-center justify-content-between mb-3">
             <div className="d-inline-block min-width-clear">
             <h4 className="text-black text-15 m-0 poppins-600">Per Person </h4>
              </div>  
             <div className="d-flex align-items-center justify-content-end price-input">
 
                 <div className="field">
                   
                  <span className="  border-0 p-0 text-16 poppins-600 col-blue1 bg-transparent disabled priceRange"   >${selectedRange[0]}</span>
                  
                </div>  
                <span className="text-16 d-inline-block mx-1"> -</span>
                <div className="field"> 
                   
                   <span  className="  text-16 poppins-600 border-0 p-0 col-blue1 bg-transparent disabled priceRange" >${selectedRange[1]}</span>
                </div>    
              </div>  
            </div>
            <div className="wrapper">
    
              
            <RangeSlider
              value={selectedRange} // Bind the value to the state
              onInput={handleRangeChange} // Use onInput to handle the range change
              min={1} // Set the minimum value for the range
              max={1000} // Set the maximum value for the range
              step={10} // Set the step value for the slider
            />
             {/* <div className="range-input">
              
                <input
                  type="range"
                  className="range-min"
                  min={0}
                  max={10000}
                  defaultValue={2500}
                  step={100}
                />
                <input
                  type="range"
                  className="range-max"
                  min={0}
                  max={10000}
                  defaultValue={7500}
                  step={100}
                />
              </div>   */}
            </div>
          </div>
          <div className="d-block w-100 mb-2">
            <h4 className="text-black poppins-600 mt-0 mb-1 text-15">Tickets</h4>
            
          </div>
          <div className="d-block w-100">
            <div className="checkbox-style2 d-flex w-100 align-items-center justify-content-between mb-1">
              <label className="text-black text-14 me-2 w-100" htmlFor="public">
                Public
              </label>
              <input
                className="m-0"
                type="checkbox"
                id="public"
                name="check"
                checked={checkedType === 0}
                onChange={() => handleCheckboxClick(0)}
                />
            </div>
            <div className="checkbox-style2 d-flex w-100 align-items-center justify-content-between mb-1">
              <label className="text-black text-14 me-2 w-100" htmlFor="private">
                Private
              </label>
              <input
                className="m-0"
                type="checkbox"
                id="private"
                name="check"
                checked={checkedType === 1}
                onChange={() => handleCheckboxClick(1)}
                />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PriceFilterComponent;
