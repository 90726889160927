import { API_ENDPOINTS } from './api';
import axios from 'axios'; 


export async function fetchHomeData(page, itemsPerPage,selectedCountry,startDate, endDate, searchText,cate,event_type,sortOrder,minPrice,maxPrice) {
    try {

      //&sortOrder=${sortOrder}&minPrice=${minPrice}&maxPrice=${maxPrice}
      const response = await fetch(`${API_ENDPOINTS.HOME_DATA}?page=${page}&itemsPerPage=${itemsPerPage}&country=${selectedCountry}&startDate=${startDate}&endDate=${endDate}&name=${searchText}&category=${cate}&type=${event_type}&sortOrder=${sortOrder}&minPrice=${minPrice}&maxPrice=${maxPrice}`);
      if (!response.ok) { 
        throw new Error("Network response was not ok");  
      }
      const data = await response.json();
      
      return data.data;
    } catch (error) {
      console.error("Error fetching home data:", error);   
      throw error;
    }
  }
export async function fetchHomeDataReset(page, itemsPerPage ) {
    try {

      //&sortOrder=${sortOrder}&minPrice=${minPrice}&maxPrice=${maxPrice}
      const response = await fetch(`${API_ENDPOINTS.HOME_DATA}?page=${page}&itemsPerPage=${itemsPerPage}`);
      if (!response.ok) { 
        throw new Error("Network response was not ok");  
      }
      const data = await response.json();
      return data.data;
    } catch (error) {
      console.error("Error fetching home data:", error);   
      throw error;
    }
  }
export async function fetchHome_Banner() {
    try {
      const response = await fetch(`${API_ENDPOINTS.fetchBanner} `);

      if (!response.ok) {
        throw new Error("Network response was not ok");  
      }
      const data = await response.json();
      
      return data;
    } catch (error) {
      console.error("Error fetching home data:", error);   
      throw error;
    }
  }

export async function fetch_Page() {
    try {
      const response = await fetch(`${API_ENDPOINTS.fetchPages} `); 

      if (!response.ok) {
        throw new Error("Network response was not ok");  
      }
      const data = await response.json();
      
      return data;
    } catch (error) {
      console.error("Error fetching home data:", error);   
      throw error;
    }
  }
export async function fetchPages_Single(slug) {
    try {
      const response = await fetch(`${API_ENDPOINTS.fetchPagesSingle+'/'+slug} `); 

      if (!response.ok) {
        throw new Error("Network response was not ok");  
      }
      const data = await response.json();
      
      return data;
    } catch (error) {
      console.error("Error fetching home data:", error);   
      throw error;
    }
  }
 
  export const eventCode = async (formData) => {
    try {
        const response = await fetch(`${API_ENDPOINTS.CheckeventCode}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
        });

        // Convert the JSON response to an array
      const responseData = await response.json();
      console.log(responseData,'--------------');
      // Now you have the JSON data as an array
      return responseData;

     
   
    } catch (error) {
        throw new Error("Network error occurred.");
    }
};
  
  