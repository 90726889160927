import React from 'react';
import { Link } from 'react-router-dom';

const ResetCartModal = ({ setResetCardModal,isMobile,handleResetCart}) => {
  const handleClose = () => {
    setResetCardModal(false);
  };

  

  return (
    <>
      <div
  className="modal fade modal fade show d-block modal modal-open modall-standard-design"
  id="exampleModal"
  tabIndex={-1}
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
<div className={`modal-dialog  modal-dialog-centered ${isMobile ? "mx-3" : ""}`}>

    <div className={`modal-content notlogin mx-auto  ${isMobile ? "mx-3" : ""}`} style={{ borderRadius: 25 }}>
       
      <div className="modal-body">
  <img className="mt-1 ms-2" src="/images/3.png" alt="" />
  <div className="text-center pt-sm-0 pt-2">
    <img src="/images/4.png" alt="" />
    <h4 className="Regular pt-2" style={{ fontSize: 20, fontWeight: 700 }}>
    Are you sure to delete your cart
    </h4>
     
    <div className="pt-3 px-4 pb-4 d-flex">
  <button
    className="shoppings w-50 me-2"
    style={{
      background: "none",
      fontSize: "16.34px",
      border: "1px solid #0978DC",
      fontWeight: 600,
      color: "#0978DC",
      borderRadius: 7,
      padding: 11,
    }}
    data-bs-dismiss="modal"
    onClick={handleClose}
  >
    Cancel
  </button>
  <button
    className="Checkouts w-50 ms-2"
    style={{
      background: "#0B5ED7",
      fontSize: "16.34px",
      border: "none",
      fontWeight: 400,
      color: "#fff",
      borderRadius: 7,
      padding: 11,
    }}
    onClick={handleResetCart}
  >
    Confirm
  </button>
</div>

  </div>
</div>

 

    </div>
  </div>
</div>
</>

  

 
  );
};

export default ResetCartModal;
