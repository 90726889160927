import { API_ENDPOINTS } from './api';
import Swal from 'sweetalert2';
import axios from 'axios';
export const fetchOrganizerProfile = async (id) => {
    try {
      const response = await fetch(
        `${API_ENDPOINTS.PROFILE_PAGE_API}/${id}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      const userEvents = data.events;
      let userOrg = [];
      if (Array.isArray(data.organizer)) {
        userOrg = data.organizer;
      } else if (typeof data.organizer === "object") {
        userOrg = [data.organizer];
      }
      return { userEvents, userOrg };
    } catch (error) {
      throw new Error(`Error fetching data: ${error.message}`);
    }
  };
  export const fetchFavoriteState = async (id, token) => {
    try {
      const response = await fetch(`${API_ENDPOINTS.FETCH_STATE}${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch favorite state from the API');
      }
  
      return await response.json();
    } catch (error) {
      throw new Error('Error fetching favorite state:', error);
    }
  };

  export const fetchUserData = async (token) => {
    try {
      const response = await fetch(`${API_ENDPOINTS.FETCH_PROFILE_USER}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error("Error fetching user data: " + error.message);
    }
  };
  
  export const editUserProfile = async (token, formData) => {
    try {
       
        const response = await fetch(`${API_ENDPOINTS.EDIT_PROFILE_USER}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error("Error editing user profile: " + error.message);
    }
  };
  export const resetPasswordForm = async (formData) => {
    const authToken = localStorage.getItem('authToken');
    const response = await axios.post(`${API_ENDPOINTS.Change_password_PROFILE_USER}`, formData, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
  
    if (response.status === 200) {
      return response.data;  
    } else {
      throw new Error("Network response was not ok");
    }
  };