import axios from 'axios';
import { API_ENDPOINTS } from './api';
 

export const searchEvents = async (query) => {
  try {
    const response = await axios.post(`${API_ENDPOINTS.SEARCH_DATA}?q=${query}`);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching search results:', error);
  }
};

 
