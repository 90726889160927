import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "./Register.css";
import { Link } from "react-router-dom";
import { registerUser } from "../services/authService";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Swal from "sweetalert2";

import PhoneInput from "react-phone-number-input";
import { getCountryCallingCode } from "react-phone-number-input/input";
import { useForm, Controller } from "react-hook-form";
 
const NewRegister = ({ show, handleClose, handleSwitchToLogin }) => {
  const schema = yup.object().shape({
    first_name: yup.string().required("First Name is required"),
    last_name: yup.string().required("Last Name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    phone: yup.string().required("Phone Number is required"),
    password: yup.string().required("Password is required")
  });

  const [loading, setLoading] = useState(false);
 
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });

  

  
   
  
  const {
    control,
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });
 
  const [showPassword, setShowPassword] = useState(false);
  const [ConPassword, setConwPassword] = useState(false);

  
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibilityConpass = () => {
    setConwPassword(!ConPassword);
  };
  const onSubmit = async (formData) => {
    setLoading(true);
    

    try {
      const responseData = await registerUser(formData);
      setLoading(false);
      Swal.fire({
        title: "Success",
        text: "Registration successful!",
        icon: "success",
        confirmButtonText: "OK"
      }).then((result) => {
        if (result.isConfirmed) {
          reset();
          handleClose();
        }
      });
      return responseData;
    } catch (error) {
      setLoading(false);
      let errorMessage = "An error occurred while submitting the role. Please try again.";
      if (error.response && error.response.data && error.response.data.error) {
        const errorData = error.response.data.error;
        if (typeof errorData === "string") {
          errorMessage = errorData;
        } else if (typeof errorData === "object") {
          // Check if errorData.email is an array and join its elements if it is
          errorMessage = Array.isArray(errorData.email)
            ? errorData.email.join("\n")
            : "An unexpected error occurred.";
        }
      }

      Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#a23896",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
        showCloseButton: false // Ensure the close button is shown
      });

      console.error("Registration failed:", error);
      if (error.response && error.response.status === 422) {
        // 422 Unprocessable Entity
        console.log("Error details:", error.response.data);
      } else {
        // Handle other errors
        if (error.response && error.response.data && error.response.data.error) {
          const errorsArray = Object.entries(error.response.data.error).flatMap(([key, value]) =>
            value.map((v) => `${key}: ${v}`)
          );
          console.log(errorsArray);
        } else {
          // Fallback error message
          console.log("An unexpected error occurred.");
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} className="register">
    <Modal.Header id="modelHeaderDesgn" closeButton={false}>
    <button type="button" id="modelHeaderButton" className="custom-close" onClick={handleClose}>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-x"
      >
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
      </svg>
     </button>
  </Modal.Header>
      <Modal.Body>
        <form className="registration-form" onSubmit={handleSubmit(onSubmit)}>
          <h2>
            Register for{" "}
            <span className="authlogo">
              {" "}
              <Link to="/">
                <img src="/images/tikkets-dark-logo.png" />
              </Link>
            </span>
          </h2>
          <p>User Account</p>
          <p>
            Already have an account? <Link onClick={handleSwitchToLogin} id="UserAccountAbc">Sign in</Link>
          </p>
          <div className="name-fields">
            <span className="w-100">
              <input
                type="text"
                {...register("first_name")}
                placeholder="First name"
                name="first_name"
                className={` ${errors.first_name ? "is-invalid" : ""}`}
              />
              {errors.first_name && (
                <div className="invalid-feedback">{errors.first_name.message}</div>
              )}
            </span>
            <span className="w-100">
              <input
                type="text"
                placeholder="Last name"
                name="last_name"
                {...register("last_name")}
                className={`  ${errors.last_name ? "is-invalid" : ""}`}
              />
              {errors.last_name && (
                <div className="invalid-feedback">{errors.last_name.message}</div>
              )}
            </span>
          </div>

          <input
            type="email"
            {...register("email")}
            placeholder="Email Address"
            name="email"
            className={`  ${errors.email ? "is-invalid" : ""}`}
          />
          {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}

              <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <PhoneInput
                  {...field}
                  className="field-style1 no-arrows-number"
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry="US"
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            {errors.phone && <p>{errors.phone.message}</p>}

  
          <div className="password-field">
            <input
              type={showPassword ? "text" : "password"}
              {...register("password")}
              placeholder="Password"
              name="password"
              className={`field-style1 ${errors.password ? "is-invalid" : ""}`}
            />

            <span className="toggle-password" onClick={togglePasswordVisibility}>
              {!showPassword ? (
                " 👁"
              ) : (
                <i className="fa fa-eye-slash fontSize" aria-hidden="true"></i>
              )}
            </span>

            {errors.password && <div className="invalid-feedback">{errors.password.message}</div>}
          </div>
          <div className="password-field">
            <input
              type={ConPassword ? "text" : "password"}
              placeholder="Confirm Password"
              name="Con_password"
              className="field-style1"
            />
            <span className="toggle-password" onClick={togglePasswordVisibilityConpass}>
              {!ConPassword ? (
                " 👁"
              ) : (
                <i className="fa fa-eye-slash fontSize" aria-hidden="true"></i>
              )}
            </span>
          </div>

          <button type="submit" disabled={loading}>
            {loading ? (
              <div className="spinner-border text-light" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              "Sign Up"
            )}
          </button>
          <p className="terms">
            <span className="text-muted">By signing up, I agree to </span>{" "}
            <a href="#">Terms of Use </a> and <a href="#">Privacy Policy</a>.
          </p>
          <div className="by-signing-up-container1">
            Copyright © 2023 Made with ❤️
            <a className="by-kanoo" href="https://kanoopays.com/" target="_blank" rel="noreferrer">
              <span className="by-kanoo1">By Kanoo.</span>
            </a>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default NewRegister;
