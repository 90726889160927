import React, { useEffect, useState } from 'react';
import '../../App.css';
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { Helmet } from "react-helmet";
import HeaderComponent from '../HeaderComponent/HeaderComponent.js';
import FooterComponent from '../FooterComponent/FooterComponent.js';

function ContactComponent() {
    
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false); 
    }, 2000); 

    return () => clearTimeout(timer); // Clear the timer if the component unmounts
  }, []);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', 
    });
    document.body.classList.add('home-bg');
    return () => {
      document.body.classList.remove('home-bg');
    };
  }, []);

  return (
    <>
    <Helmet>
    <link href="//css/bootstrap.min.css" rel="stylesheet" />
  </Helmet>
  
  {isLoading ? (
            <section className="pad-top-20 pad-bot-80" id="loaderSection">
            <div className="container3">
              <div className="block-element m-b-30">
              </div>
              <div className="block-element loader-mobile-screen" style={{ marginTop: '80px' }}>
                <LoadingSpinner />
              </div></div>
          </section>
          ) : ( 
            <> 
            <HeaderComponent />
    <section className="pad-top-60 pad-bot-60 about-container">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          
              <div className="col-md-6">
                <img src="/images/contact_graphic.png" alt='contact_graphic' />
              </div>
              <div className="col-md-6 section-md-t3" style={{ textAlign: 'center' }}>
                <div className="icon-box section-b2">
                  <div className="icon-box-icon">
                    <span className="ion-ios-paper-plane" />
                  </div>
                  <div className="icon-box-content table-cell">
                    <div className="icon-box-title">
                      <h4 className="icon-title">Contact Us</h4>
                    </div>
                    <div className="icon-box-content">
                      <p className="mt-3">
                        <img src="/images/logo-black.png" width="200px" height="70px" alt='logo-black' />
                      </p>
                      <p className="mb-1">Email:
                        <span className="color-a">admin@tikkets.com</span>
                      </p>
                      <p className="mb-1"><span>Contact Number:</span><span style={{ marginLeft:"1%" }}><img className='ms-2' src="/images/whatsapp.png" height="20" width="20" /></span>
                        <span className="color-a">  +1 (868) 766-6289</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="icon-box">
                  <div className="icon-box-icon">
                    <span className="ion-ios-redo" />
                  </div>
                  <div className="icon-box-content table-cell">
                    <div className="icon-box-title">
                      <h4 className="icon-title">Social networks</h4>
                    </div>
                    <div className="icon-box-content">
                      <div className="socials-footer">
                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <a href="#" className="link-one">
                              <img style={{ height: 23 }} src="/images/upload/facebook.png" alt='facebook' />
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a href="#" className="link-one">
                              <img style={{ height: 23 }} src="/images/twitter-icon.png" alt='twitter-icon' />
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a href="#" className="link-one">
                              <img style={{ height: 23 }} src="/images/instagram.png" alt='instagram' />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
         
        </div>
      </div>
    </section>
    <FooterComponent />
    </>
          )}
           
  </>
  )
}

export default ContactComponent