import React, {useState} from 'react';

const TicketComponent = ({ ticket, selectedTickets, handleTicketSelection,event,isDisable,verifyEvent,setisDisable,loginuserID,unlockEvent_userID,mainIndex   }) => {
    const radioChecked = selectedTickets.some(t => t.id === ticket.id);

    const handleRadioChange = () => {
        handleTicketSelection(ticket, event);
      };

     

      if(verifyEvent.length > 0 && verifyEvent.includes(event.id) ){
       
        setisDisable(false);
      } 
     
    
  return (
    <>
    
    <div  id='ticketDiv' className="d-inline-block border border-2 border-color-blue blue rounded-4 cursor-pointer me-2 ticketDiv " key={mainIndex} >
    
      <label className="d-flex align-items-center px-2 py-2 checkbox_inbox pointer">
      <input 
          type="checkbox" 
          checked={radioChecked} 
          onChange={handleRadioChange} 
          disabled={isDisable}
        />
      
        <div className="d-inline-block  ">
          <h5 className="text-center text-14 col-blue3 poppins-500 mt-0 mb-2 p-0"> 
             
            {ticket.name}
          </h5>
          <div className="d-flex w-100 align-items-center justify-content-between">
            {/* <span className="col-blue3 poppins-400 text-14 me-4">
            {ticket.ticket_number}
            </span> */}
            <span className="col-blue3 poppins-600 text-16">
              ${ticket.price.toFixed(2)}
            </span>
          </div>
        </div>
      </label>
      {/* {isDisable && 
        <div className='text-center text-danger' style={{ fontSize: '12px' }}>
          Enter event access code first
        </div>
      } */}
    </div>
    
    </>
    
  );
};

export default TicketComponent;
