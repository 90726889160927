import { API_ENDPOINTS } from './api';
 
import Swal from 'sweetalert2';


export const fetchEventData = async (id, name) => {
    try {
      const apiUrl = `${API_ENDPOINTS.FETCH_EVENT_TIKKETS}/${id}/${name}`;
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error(`Error fetching event data: ${error.message}`);
    }
  };
  
  export const fetchFavoriteState = async (id) => {
    const token = localStorage.getItem("authToken");
  
    try {
      const response = await fetch(`${API_ENDPOINTS.ADD_FAVRT}/${id}/event`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch favorite state from the API');
      }
  
      const data = await response.json();
      return data.favorite;
    } catch (error) {
      console.error('Error fetching favorite state:', error);
      throw error;
    }
  };
  
  export const fetchLoggedInUser = async () => {
    try {
      const response = await fetch(`${API_ENDPOINTS.ORG_CHECK_LOGIN}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch user data');
      }
  
      const data = await response.json();
      return data.user.favorite;
    } catch (error) {
      throw new Error(`Error fetching user data: ${error.message}`);
    }
  };
  