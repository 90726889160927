import React, { createContext, useState, useEffect,useCallback  } from 'react';
import { fetchUserData } from "../services/profileService";
 
export const ProfileContext = createContext();
 
export const ProfileProvider = ({ children }) => {
    const [imagePath, setImagePath] = useState('');
    const token = localStorage.getItem("authToken");

    // Memoize fetchData to prevent unnecessary re-creations
    const fetchData = useCallback(async () => {
        try {
            const token = localStorage.getItem("authToken");
            if (!token || imagePath) return; // Avoid fetching if imagePath is already set
            
            const data = await fetchUserData(token);
            if (data.user.image) {
                const fullImagePath = data.user.imagePath + data.user.image;
                setImagePath(fullImagePath);
            } else {
                setImagePath('/images/notlogin.png');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [imagePath]);

    useEffect(() => {
        if (token && !imagePath) {
            fetchData();
        }
    }, [token,imagePath]); 

    return (
        <ProfileContext.Provider value={{ imagePath, setImagePath }}>
            {children}
        </ProfileContext.Provider>
    );
};