import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import {
  FetchChildTicketData,
  handleSubmitFunction,
  getMyTicketsDATA,
  SearchMyTicketsDATA,
  FetchChild_tranfer_TicketData,
  getMyTicketsDATAReset,
} from "../../services/mytikketservice";
import QRCode from "qrcode.react";
import { enUS, tr } from 'date-fns/locale';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { format } from "date-fns";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import MyticketsSwiperMobile from "./MyticketsSwiper";
import MyticketsSwiperDesktop from "./MyticketsSwiperDesktop";
import { Helmet } from "react-helmet";
import NewHeader from "../HeaderComponent/NewHeader.js";
import HeaderComponent from "../HeaderComponent/NewHeader.js";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import FooterComponent from "../FooterComponent/FooterComponent.js";

function MyticketsmainComp() {
  const [checkboxValues, setCheckboxValues] = useState([]);
  const [events, setEvents] = useState([]);
  const [Country, setCountry] = useState([]);

  const [endDate, setEndDate] = useState(null);
  const endDateInputRef = useRef(null);
  const [cate, setCate] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [allCategories, setAllcategories] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [UpcomingTickets, setUpcomingTickets] = useState([]);
  const inputRef = useRef(null);
  const [UpcomingTicketsChild, setUpcomingTicketsChild] = useState([]);
  const [UpcomingTicketsOnlyChild, setUpcomingTicketsOnlyChild] = useState();
  const [UpcomingTicketsGet, setUpcomingTicketsGet] = useState([]);
   const [loading, setLoading] = useState(true);
  const [functionRun, setFunctionRun] = useState(false);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [prevPageUrl, setPrevPageUrl] = useState(null);
  const [PastTickets, setPastTickets] = useState([]);
  const [transferEvent, setTransferEvent] = useState([]);
  const [transferTicket, setTransferTicket] = useState([]);
  const [itemsPerPage, setitemsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [eventLoading, setEventLoading] = useState(true);
  const [isChildLoading, setIsChildLoading] = useState(true);
  const [from, setfrom] = useState("");
  const [to, setTo] = useState("");
  const [totalRecords, setTotal] = useState("");
  const [all_count, setAll_count] = useState("");
  const [expired, setExpired] = useState("");
  const [valid, setValid] = useState("");
  const [scanned, setScanned] = useState("");
  const authToken = localStorage.getItem("authToken");
  const navigate = useNavigate();
  const [LoadFullpage, setLoadFullpage] = useState(true);
  const [Isdata, setIsdata] = useState();
  const [clickedDivId, setClickedDivId] = useState(null);
  const [items, setItems] = useState([]);
  const [orderID, setorderID] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const roleStr = localStorage.getItem('login_user');
  const User_Login = JSON.parse(roleStr);
  const [loginUser, setLoginUser] = useState(false);
  const [disable, setDisable] = useState(true);
  const [isSearchCompleted, setIsSearchCompleted] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [countriesCount, setcountriesCount] = useState(0);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [selectedCountryValues, setSelectedCountryValues] = useState(["all"]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [sort_Order, setSort_Order] = useState("purchase_date_desc");
  const [startDate, setStartDate] = useState(null);
  const startDateInputRef = useRef(null);

  const [status, setStatus] = useState("valid"); 
  const [isTicketVisible, setIsTicketVisible] = useState(false);
  const [dataForButtons, setDataForButtons] = useState([]);
 
  const [downloadButtonDisplay, setDownloadButtonDisplay] = useState(false);
  const [transferButtonDisplay, setTransferButtonDisplay] = useState(false);
  const [currentTab, setCurrentTab] = useState("eventsTab");
  const [showFilters, setShowFilters] = useState(true);


  const handleShort = (event) => {
    const selectedValue = event.target.value;
    setSort_Order(selectedValue);
    
  };
 
  useEffect(() => {

    setLoginUser(User_Login.id);

  }, [User_Login]);


  useEffect(() => {

    SearchShort(sort_Order);

  }, [sort_Order]);

  const SearchShort = async () => {
    
    try {
      setIsTableLoading(true);
       
      const addOneDay = (date) => {
        if (!date) return '';
        const dateObj = new Date(date);
        dateObj.setDate(dateObj.getDate() + 1);
        return dateObj.toISOString().split('T')[0];
      };
        const adjustedStartDate = addOneDay(startDate);
      const adjustedEndDate = addOneDay(endDate);
      const data = await SearchMyTicketsDATA(
        authToken,
        setItems,
        setorderID,
        setIsLoading,
        setUpcomingTickets,
        setIsdata,
        setUpcomingTicketsChild,
        setPastTickets,
        itemsPerPage,
        1,
        setPrevPageUrl,
        setNextPageUrl,
        setfrom,
        setTo,
        setTotal,
        setEventLoading,
        setAll_count,
        setExpired,
        setValid,
        setScanned,
        setUpcomingTicketsOnlyChild,
        selectedCountry,
        adjustedStartDate,
        adjustedEndDate,
        searchText,
        cate,
        sort_Order,
        status
 

      );
       
      setEvents(data.events.data);
      setTotalPages(data.events.last_page);
      setPrevPageUrl(data.events.prev_page_url);
      setNextPageUrl(data.events.next_page_url);
      setfrom(data.events.from);
      setTo(data.events.to);
      setTotal(data.events.total);
      setAllcategories(data.categories);
      setBlogs(data.blog);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsTableLoading(false);
    }
  };

  useEffect(() => {
    
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); 
    };
 
    handleResize();
    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    document.body.classList.add("header-bgms");
    document.body.classList.add("page-12");

    return () => {
      document.body.classList.remove("page-12");
      document.body.classList.remove("header-bgms");
    };
  }, []);
  
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadFullpage(false);  
    }, 2000);  
    return () => clearTimeout(timer); 
  }, []);

  const handleChange = (event) => {
    if (event.target.checked) {
      setItems([...items, event.target.value]);

      const dataAttribute = event.target.getAttribute("attriData");
      setorderID(dataAttribute);
    } else {
      const updatedItems = [...items];
      updatedItems.indexOf(event.target.value);

      const index = updatedItems.indexOf(event.target.value);
      if (index > -1) {
        updatedItems.splice(index, 1);
      }

      setItems(updatedItems);
      const dataAttribute = event.target.getAttribute("attriData");
      setorderID(dataAttribute);
    }
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

 
  const handleChangeInput = (e) => setSearchText(e.target.value);
  
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleClick = (id) => {
    setClickedDivId(id);
    fetchChildTicketData(id);
    setIsChildLoading(true);
    // window.scrollTo({ top: 0, behavior: "smooth" });  
    const targetElement = document.getElementById('HeadingTEA2');
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start', // You can adjust this as needed
      });
    }
  };

  const clearInput = async () => {
    try {
      if (inputRef.current) inputRef.current.value = "";
      if (startDateInputRef.current) startDateInputRef.current.value = "";
      if (endDateInputRef.current) endDateInputRef.current.value = ""; 
      setEndDate(null);
      setStartDate(null);
      setSearchText('');
      setIsTableLoading(false);
      setIsSearchCompleted(false);
      handleClearFunction();
    } catch (error) {
      console.error('Error during clearing input:', error);
    }
  };
  
  

  async function fetchChildTicketData(id) {
    var checkboxes = document.getElementsByClassName("ticket_input");
    for (var i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = false;
    }

    setItems([]);
    setorderID("");
    await FetchChildTicketData(
      id,
      authToken,
      setItems,
      setorderID,
      setUpcomingTicketsOnlyChild,
      setIsdata,
      setIsChildLoading
    );
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (items.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select the tickets you want to download.",
        confirmButtonText: "OK",
      }).then((result) => {
        // Handle the user's response if needed
        if (result.isConfirmed) {
          // User clicked OK, you can add any action here
          // For example, close the dialog
          Swal.close();
        }
      });
    } else {
      await handleSubmitFunction(orderID, items, authToken, navigate, isMobile);
    }
  };


 

  const handleClearFunction = async () => {
    try {
      setIsTableLoading(true);
      setIsSearchCompleted(false);

      const data = await getMyTicketsDATAReset(currentPage,status, itemsPerPage,setUpcomingTickets,setPrevPageUrl,setNextPageUrl,setfrom,setTo,setTotal,setAll_count,setValid,setExpired,setSort_Order);
      setEvents(data.events.data);
      setTotalPages(data.events.last_page);
      setPrevPageUrl(data.events.prev_page_url);
      setNextPageUrl(data.events.next_page_url);
      setfrom(data.events.from);
      setTo(data.events.to);
      setTotal(data.events.total);
      setAllcategories(data.categories);
      setBlogs(data.blog);
      setIsSearchCompleted(false);
      setIsTableLoading(true);
       
    } catch (error) {
      console.error("Error fetching home data:", error);
    } finally {
      setIsTableLoading(false);
    }
  };

  

 

  const handleSearch = async () => {
    if (
      startDate === "" &&
      endDate === "" &&
      searchText === ""
    ) {
      return;
    }

    try {
      setIsTableLoading(true);
      setIsSearchCompleted(false);
   
      const addOneDay = (date) => {
        if (!date) return '';
        const dateObj = new Date(date);
        dateObj.setDate(dateObj.getDate() + 1);
        return dateObj.toISOString().split('T')[0];
      };
        const adjustedStartDate = addOneDay(startDate);
      const adjustedEndDate = addOneDay(endDate);
      const data = await SearchMyTicketsDATA(
        authToken,
        setItems,
        setorderID,
        setIsLoading,
        setUpcomingTickets,
        setIsdata,
        setUpcomingTicketsChild,
        setPastTickets,
        itemsPerPage,
        1,
        setPrevPageUrl,
        setNextPageUrl,
        setfrom,
        setTo,
        setTotal,
        setEventLoading,
        setAll_count,
        setExpired,
        setValid,
        setScanned,
        setUpcomingTicketsOnlyChild,
        selectedCountry,
        adjustedStartDate,
        adjustedEndDate,
        searchText,
        cate,
        sort_Order,
        status,
 

      );
      setIsSearchCompleted(true);
      setEvents(data.events.data);
      setTotalPages(data.events.last_page);
      setPrevPageUrl(data.events.prev_page_url);
      setNextPageUrl(data.events.next_page_url);
      setfrom(data.events.from);
      setTo(data.events.to);
      setTotal(data.events.total);
      setAllcategories(data.categories);
      setBlogs(data.blog);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsTableLoading(false);
    }
  };
  const handleSubmitTransfare = async (event) => {
    event.preventDefault();
    localStorage.removeItem("transfer_order_id");
    localStorage.removeItem("selectedTicket");
    localStorage.removeItem("selectedUser");
    localStorage.removeItem("transfer_ticket");
    localStorage.removeItem("transfer_event");

    if (items.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select the tickets you want to transfer.",
        confirmButtonText: "OK",
      }).then((result) => {
        // Handle the user's response if needed
        if (result.isConfirmed) {
          // User clicked OK, you can add any action here
          // For example, close the dialog
          Swal.close();
        }
      });
    } else {
      // Store items and orderID in local storage
      localStorage.setItem("selectedTicket", JSON.stringify(items));
      localStorage.setItem("transfer_order_id", orderID);
      fetchChildTransferTicketData(orderID);

      //  if(functionRun){
      navigate("/transfer-ticket");
      //  }
    }
  };

  async function fetchChildTransferTicketData(id) {
    var checkboxes = document.getElementsByClassName("ticket_input");
    for (var i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = false;
    }

    setItems([]);
    setorderID("");
    await FetchChild_tranfer_TicketData(
      id,
      authToken,
      setTransferEvent,
      setTransferTicket,
      setFunctionRun
    );
  }



  const handleCategoryClick = (status) => {
    
    setCurrentPage("");
    setitemsPerPage("");
    setStatus(status.target.value);
    // fetchMyTicketsData(status);
  };

  const handleItemsPerPageChange = (event) => {
    const newValue = parseInt(event.target.value);
    setitemsPerPage(newValue);
    setCurrentPage("");
    setEventLoading(true);
  };

  const hitNext = () => {
    if (nextPageUrl !== null) {
      const regex = /[?&]page=(\d+)/;
      const match = nextPageUrl.match(regex);

      if (match) {
        const pageValue = match[1];

        if (currentPage !== pageValue) {
          setCurrentPage(pageValue);
        }
      } else {
        console.log("Page parameter not found in URL.");
      }
    }
  };

  const hitPrev = () => {
    if (prevPageUrl !== null) {
      const regex = /[?&]page=(\d+)/;
      const match = prevPageUrl.match(regex);

      if (match) {
        const pageValue = match[1];
        if (currentPage !== pageValue) {
          setCurrentPage(pageValue);
        }
      } else {
        console.log("Page parameter not found in URL.");
      }
    }
  };

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = date.toLocaleString('en-US', { month: 'short' });
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;  // Convert to 12-hour format
    return `${day} ${month} ${year} ${hours}:${minutes} ${ampm}`;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = date.toLocaleString('en-US', { month: 'short' });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
  };

  const isFetchingRef = useRef(false);

  const fetchMyTicketsData = async () => {
    await getMyTicketsDATA(
      authToken,
      setItems,
      setorderID,
      setIsLoading,
      setUpcomingTickets,
      setIsdata,
      setUpcomingTicketsChild,
      setPastTickets,
      status,
      itemsPerPage,
      currentPage,
      setPrevPageUrl,
      setNextPageUrl,
      setfrom,
      setTo,
      setTotal,
      setEventLoading,
      setAll_count,
      setExpired,
      setValid,
      setScanned,
      setUpcomingTicketsOnlyChild,
      selectedCountry,
      cate,
       
   
    );
    setLoading(false);
    isFetchingRef.current = false;
  };

  useEffect(() => {
    if (!isFetchingRef.current) {
      isFetchingRef.current = true;
      setLoading(true);
      fetchMyTicketsData();
    }
  }, [itemsPerPage,currentPage,status,selectedCountry,cate]);

 
 
  const toggleTicket = (id) => {
    if (isMobile) {
      setIsTicketVisible(!isTicketVisible);
      setClickedDivId(id);
      fetchChildTicketData(id);
      setIsChildLoading(true);
      
      const targetElement = document.getElementById('HideHeading');
      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',  
        });
      }
      // window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const toggleTicket_2 = () => {
    setIsTicketVisible(!isTicketVisible);
    // window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const truncateText = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  };



  const renderedUpcomingTickets = UpcomingTickets.map((item, index) => {

 
    if (item.order_child.length > 0) {
      const quantity =
        item.order_child.length + " tickets fo" + item.ticket.ticket_number;
      const eventStartTime = new Date(item.event.start_time);
      const formattedDate = format(eventStartTime, "EEE, dd MMM yyyy", { locale: enUS }).toUpperCase();
      const formattedDateTimeStart = format(eventStartTime, "h.mm a", { locale: enUS });

      return (
        <div className="d-block w-100 mb-3 bg-white rounded-5 shadow-normal overflow-hidden">
          <div className="event-row-block p-3" id="event-single-block">
            <div className="event-block-img">
              <img
                src={item.event.imagePath + item.event.image}
                className="w-100 rounded-5"
                id="TicketIMageTab"
                onClick={() => toggleTicket(item.order_child[0].order_id)}
              />
            </div>
            <div className="event-block-detail ps-3 pe-3">
              <div className="d-block w-100 mb-2">
                <div className="d-flex justify-content-start">
                  <div className="d-inline-block">
                    <h6 className="poppins-600 m-0 d-flex align-items-center justify-content-start text-13 text-uppercase" id="DateTExt">
                      <img
                        className="d-lg-block d-md-block d-none me-2"
                        src="/images/calendar-icon2.svg"
                        height="18px"
                      />
                      {formattedDate}, {formattedDateTimeStart}
                    </h6>
                  </div>
                  <div className="d-inline-block ps-3">
                    <img src={item.country_flag} id="FlagSTyle" height="18px" style={{ borderRadius: '4px' }} />
                  </div>
                </div>
              </div>
              <div className="d-block w-100 mb-2">
                <h3
                  className="text-black poppins-600 text-16 mob-text-14 m-0 p-0"
                  onClick={() => toggleTicket(item.order_child[0].order_id)}
                >
                  {isMobile ? (
                    item.name.length > 22 ? item.name.slice(0, 22) + '...' : item.name
                  ) : (
                    item.name
                  )}
                </h3>
              </div>
              <div className="d-block w-100">
                <div className="d-flex w-100 align-items-center justify-content-start">
                  <div className="d-inline-block me-2">
                    <img src="/images/map-icon3.png" height="18px" />
                  </div>
                  <div className="d-inline-block">
                  <h5 className="col-grey1 text-14 mob-text-13 poppins-400 m-0 p-0">
  {item.address ? (item.address.includes(',') ? item.address.substring(0, item.address.indexOf(',')) : item.address) : null}
</h5>

                  </div>
                </div>
              </div>
            </div>
            <div className="event-block-actions d-lg-flex d-md-flex d-none">
              <div className="d-block w-100">
                <button className="custom-btn4 px-0 py-1 mb-2 rounded-5 text-14 w-100 lh-lg">
                  <img src="/images/glass-icon1.png" />
                  {item.category}
                </button>
                <button
                  className="custom-btn3 px-0 py-1 rounded-5 text-14 w-100 lh-lg buttonwww text-white"
                  onClick={() => handleClick(item.order_child[0].order_id)}
                  id={item.order_id}
                >
                  View TICKETS
                </button>
              </div>
            </div>
          </div>
        </div>


      );
    }
  });

  const i = 1;
  const renderTabContentMobile = () => {
    switch (currentTab) {
      case "eventsTab":
        return (
          <div className="moble">
            <div className="form-group form-filter-grey form-filter-search form-filter-width4 position-relative d-flex align-items-center justify-content-start   rounded-4 mb-3">
            <input
                id="form_name6"
                className="form-control filter-5 border-0 float-input outline-0 shadow-none w-100"
                type="text"
                required
                onChange={handleChangeInput}
                ref={inputRef}
                value={searchText}
              />
              <label htmlFor="form_name6" className="float-label">
                Search Event Name...

              </label>
            </div>
            
            <div className="d-flex w-100">
              <div className="form-group form-filter-grey  position-relative me-2  rounded-4 w-100">

                <LocalizationProvider dateAdapter={AdapterDayjs}
                    >
                  <DatePicker onChange={handleStartDateChange}
                     label="Start Date"
                    renderInput={(params) => <TextField {...params} />}
                    value={startDate} />
                </LocalizationProvider>




                {/* <input
                  id="form_name3"
                  className="form-control filter-2 border-0 float-input outline-0 shadow-none w-100 border-none"
                  type="date"
                  placeholder=""
                  onChange={handleStartDateChange}
                  ref={startDateInputRef}
                  required
                /> 
             
                <label htmlFor="form_name3" className="float-label">
                  Start Date
                </label> */}
              </div>
              <div className="form-group form-filter-grey  position-relative rounded-4 w-100 end_date">


                <LocalizationProvider dateAdapter={AdapterDayjs}
               >
                  <DatePicker onChange={handleEndDateChange}
                   label="End Date"
                    renderInput={(params) => <TextField {...params} />}
                    value={endDate} />
                </LocalizationProvider>

                {/* <input
                  id="form_name4"
                  className="form-control filter-3 border-0 float-input outline-0 shadow-none w-100 border-none"
                  type="date"
                  placeholder=""
                  required
                  onChange={handleEndDateChange}
                  ref={endDateInputRef}
                />
                <label htmlFor="form_name4" className="float-label">
                  End Date
                   
                </label> */}
              </div>
            </div>


            <div className="mb-2">
            {
                !isTableLoading && !isSearchCompleted && (
                    <button
                        className="custom-btn11 fw-normal text-13 px-lg-5 px-md-5 px-sm-4 px-4"
                        onClick={handleSearch}
                        id="buttonWidthsch"
                    >
                        Search
                    </button>
                )
            }
            {
                isTableLoading && (
                    <button
                        className="custom-btn11 fw-normal text-13 px-lg-5 px-md-5 px-sm-4 px-4"
                        disabled
                        id="buttonWidthsch"
                    >
                        <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                        ></span>
                    </button>
                )
            }
            {
                !isTableLoading && isSearchCompleted   && (
                    <button
                        className="custom-btn11 fw-normal text-16 w-100 d-block"
                        onClick={clearInput}
                        id="buttonWidthsch"
                    >
                        Reset
                    </button>
                )
            }

            </div>
          </div>
        );
      case "hotelsTab":
      case "transportTab":
      case "flightTab":
        return (
          <div className="d-block w-100 py-3 px-3">
            <h4 className="text-15 text-black m-0">
              Filters Coming Soon For {" "}
              {currentTab.charAt(0).toUpperCase() +
                currentTab.slice(1).replace("Tab", "")}
            </h4>
          </div>
        );
      default:
        return null;
    }
  };
  const renderTabContent = () => {
    switch (currentTab) {
      case "eventsTab":
        return (
          <div className="d-flex flex-wrap align-items-center  justify-content-start">
            {/* <div className="form-group form-filter-grey form-filter-width1 position-relative   rounded-4 me-2 mb-2">
          

                <div  className="dropdown countries-select">
                    <button data-mdb-button-init data-mdb-ripple-init data-mdb-dropdown-init  className="form-control filter-6 float-input outline-0 shadow-none dropdown-toggle" type="button" id="dropdownMenuButton12"
                    data-mdb-toggle="dropdown"  aria-haspopup="true" 
                    aria-expanded={true} 
                    onClick={handleToggleDropdown} >
                    Select Countries  
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton12">
                      {countryOptions.map(country => (
                        <li key={country.value}>
                          <a className="dropdown-item" href="#">
                            <div className="form-check d-flex justify-content-between align-items-center">
                              <div>
                                <input 
                                  className="form-check-input mt-2" 
                                  type="checkbox" 
                                  value={country.value} 
                                  id={country.value} 
                                  checked={selectedCountry.includes(country.value)} 
                                  onChange={() => toggleCountry(country.value)} 
                                />
                                <label className="form-check-label pt-1" htmlFor={country.value}>
                                  {country.label}
                                </label>
                              </div>
                              {country.country_flag && (
                                <img className="flg mt-2" src={country.country_flag} alt={`${country.label} flag`} style={{ marginLeft: '10px', width: '20px', height: '15px' }} />
                              )}
                            </div>
                          </a>
                        </li>
                      ))}
                    </ul>
                </div>


           

          </div>   */}
            <div style={{ width: '340px !important' }} id="search_inputsssss" className=" form-group form-filter-grey form-filter-search form-filter-width4 me-2 position-relative d-flex align-items-center justify-content-start   rounded-4 mb-0">
              <input
                id="form_name6"
                className="form-control filter-5 border-0 float-input outline-0 shadow-none w-100"
                type="text"
                required
                onChange={handleChangeInput}
                ref={inputRef}
              />
              <label htmlFor="form_name6" className="float-label">
                Search Event Name...

              </label>
            </div>
            <div className="form-group form-filter-grey form-filter-width2 position-relative me-2  rounded-4 mb-0 destopDate" id="DatepickerWidth">
              {/* <input
                id="form_name3"
                className="form-control filter-2 border-0 float-input outline-0 shadow-none w-100"
                type="date"
                placeholder=""
                onChange={handleStartDateChange}
                ref={startDateInputRef}
                required
              />
              <label htmlFor="form_name3" className="float-label">
                Start Date
                 
              </label> */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    value={startDate}
                    onChange={handleStartDateChange}
                     label="Start Date"
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder="Start Date" // Placeholder text
                        InputLabelProps={{
                        ...params.InputLabelProps,
                        shrink: false, // Prevent the label from shrinking (floating)
                        }}
                    />
                    )}
                />
                </LocalizationProvider> 
              
              
            </div>
            <div className="form-group form-filter-grey form-filter-width3 position-relative me-2   rounded-4 mb-0 destopDate" id="DatepickerWidth">

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker onChange={handleEndDateChange}
                label="End Date"
                  renderInput={(params) => <TextField {...params} />}
                  value={endDate} />
              </LocalizationProvider>
              {/* <input
                id="form_name4"
                className="form-control filter-3 border-0 float-input outline-0 shadow-none w-100"
                type="date"
                placeholder=""
                required
                onChange={handleEndDateChange}
                ref={endDateInputRef}
              />
              <label htmlFor="form_name4" className="float-label">
                End Date
                 
              </label> */}
            </div>

            <div className="d-inline-block mb-0">
            {!isTableLoading && !isSearchCompleted && (
                <button
                  className="custom-btn11 fw-normal text-16 w-100 d-block"
                  onClick={handleSearch}
                  id="ButtonWIDTH"
                >
                  Search
                </button>
              )}
              {isTableLoading && (
                <button
                  className="custom-btn11 fw-normal text-16  w-100 d-block"
                  disabled
                  id="ButtonWIDTH"
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </button>
              )}
              {!isTableLoading && isSearchCompleted && (
                <button
                  className="custom-btn11 fw-normal text-16  w-100 d-block"
                  onClick={clearInput}
                  id="ButtonWIDTH"
                >
                  Reset
                </button>
              )}
            </div>
          </div>
        );
      case "hotelsTab":
      case "transportTab":
      case "flightTab":
        return (
          <div className="d-block w-100 py-3 px-3">
            <h4 className="text-15 text-black m-0">
              Filters Coming Soon For {" "}
              {currentTab.charAt(0).toUpperCase() +
                currentTab.slice(1).replace("Tab", "")}
            </h4>
          </div>
        );
      default:
        return null;
    }
  };




  useEffect(() => {
    const evaluateTickets = (tickets) => {
      let showDownloadButton = false;
      let showTransferButton = false;

      tickets.forEach(ticket => {
        const isNormalTicket = !ticket.transfer_from_user_name && !ticket.transfer_to_user_name;
        const isTransferredToUser = !!ticket.transfer_to_user_name;
        const isTransferredFromUser = !!ticket.transfer_from_user_name;

        if (isNormalTicket || isTransferredFromUser) {
          showDownloadButton = true;
        }

        if (isNormalTicket) {
          showTransferButton = true;
        }

        if (ticket.status === 2) {
          showTransferButton = false;
          showDownloadButton = false;
        }
      });

      return { showDownloadButton, showTransferButton };
    };

    const { showDownloadButton, showTransferButton } = evaluateTickets(dataForButtons);

    setDownloadButtonDisplay(showDownloadButton);
    setTransferButtonDisplay(showTransferButton);
  }, [dataForButtons]);


  useEffect(() => {

    if (typeof UpcomingTicketsOnlyChild != 'undefined' ) {
      setDataForButtons(UpcomingTicketsOnlyChild.order_child);
    } else if (UpcomingTicketsChild && UpcomingTicketsChild.length > 0 && UpcomingTicketsChild[0]?.order_child && typeof UpcomingTicketsOnlyChild === 'undefined') {
      setDataForButtons(UpcomingTicketsChild[0]?.order_child);
    } else {
      setDataForButtons([]);
    }
    
    // console.log('================================');
    // console.log(UpcomingTicketsChild);
    // console.log(UpcomingTicketsOnlyChild);
    // console.log('================================');



  },[UpcomingTicketsChild, UpcomingTicketsOnlyChild]); 
  

  const ticket_child_loop = UpcomingTicketsChild.map((item, index) => {
    if (index === 0) {
      if (UpcomingTicketsOnlyChild) {
        
        return UpcomingTicketsOnlyChild.order_child.map((Childitem, indexChild) => {
          const isLastChild = indexChild === UpcomingTicketsOnlyChild.order_child.length - 1;
          const shouldShow = Childitem.transfer_to_user_id !== '' || Childitem.transfer_from_user_id !== '' || Childitem.customer_id === loginUser;
        const shouldHaveBorderBottom = !isLastChild && UpcomingTicketsOnlyChild.order_child.length > 1;


          return isChildLoading ? (
            <div className="spinner-container" key={indexChild}>
              <LoadingSpinner />
            </div>
          ) : (
            <div key={indexChild}>
              {(Childitem.transfer_to_user_id === '' &&
                Childitem.transfer_from_user_id === '' &&
                Childitem.customer_id !== loginUser) ? null : (
                // <div
                //   className="d-flex align-items-start justify-content-between border-bottom border-dashed border-0 border-color-silver pb-2 mb-3"
                //   data-tickt_child_id={Childitem.id}
                // >
                  <div
                className={`d-flex align-items-start justify-content-between  border-dashed border-0 border-color-silver pb-2 mb-3 ${shouldHaveBorderBottom ? 'border-bottom' : ''}`}
                data-tickt_child_id={Childitem.id}
              >
                  <div className="d-flex align-items-start justify-content-start w-80">
                    {/* <div className="d-inline-block pe-3">
                      <input
                        type="hidden"
                        name="orderID"
                        id="orderID"
                        value={item.order_id}
                      />
                      {!(
                        
                        Childitem.scanned_at !== null ||
                        UpcomingTicketsOnlyChild.event.event_valid === "expire" ||
                        ( Childitem.transfer_to_user_name !== "")
                      ) ? (
                        <input
                          type="checkbox"
                          name="ticket_id"
                          value={Childitem.id}
                          className="ticket_input cart-ticket-name rashid"
                          attriData={Childitem.order_id}
                          onChange={handleChange}
                        />
                      ) : (
                        <input
                          type="checkbox"
                          name="ticket_id"
                          value={Childitem.id}
                          className="ticket_input cart-ticket-name rashid"
                          attriData={Childitem.order_id}
                          disabled={true}
                          style={{ opacity: '0' }}
                        />
                      )}
                      <input
                        type="hidden"
                        name="free_ticket"
                        className="free_ticket"
                      />
                    </div> */}
                        {!(
                    Childitem.scanned_at !== null ||
                    UpcomingTicketsOnlyChild.event.event_valid === "expire" ||
                    Childitem.transfer_to_user_name !== ""
                  ) ? (
                    <div className="d-inline-block pe-3">
                      <input
                        type="hidden"
                        name="orderID"
                        id="orderID"
                        value={item.order_id}
                      />
                      <input
                        type="checkbox"
                        name="ticket_id"
                        value={Childitem.id}
                        className="ticket_input cart-ticket-name rashid"
                        attriData={Childitem.order_id}
                        onChange={handleChange}
                      />
                      <input
                        type="hidden"
                        name="free_ticket"
                        className="free_ticket"
                      />
                    </div>
                  ) : null}


                    <div className="d-inline-block">
                      <h5 className="text-15 mt-0 mb-2 poppins-400 text-15 text-black">
                        <img src="/images/ticket-icon2.png" style={{ height: '16px' }} />
                        <span className="ms-1 text-14">
                          {UpcomingTicketsOnlyChild.ticket.name}
                        </span>
                        <span className="d-inline-block px-2 ms-1 py-1 text-13 bg-green text-white rounded-3 d-none">
                          Valid
                        </span>
                      </h5>
                      <h5 className="text-15 mt-0 mb-2 poppins-400 text-15 text-black">
                        <i className="fa fa-calendar" aria-hidden="true"></i>
                        <span className="ms-2 text-14">
                          {formatDate(UpcomingTicketsOnlyChild.ticket.ticket_start_time)}
                        </span>
                      </h5>
                      <h5 className="text-15 mt-0 mb-2 poppins-400 text-15 text-black">
                        <i className="fa fa-clock" aria-hidden="true"></i>
                        <span className="ms-2 text-14">
                          {formatTime(UpcomingTicketsOnlyChild.ticket.ticket_end_time)}
                        </span>
                      </h5>
                      <h5 className="text-15 mt-0 mb-2 poppins-400 text-15 text-black">
                        <img src="/images/doller.svg" style={{ height: '16px' }} />
                        <span className="ms-2">
                          ${UpcomingTicketsOnlyChild.ticket.price}
                        </span>
                      </h5>
                    </div>
                  </div>
                  <div className="d-inline-block">
                    {Childitem.transfare_id !== null && Childitem.transfare_id !== "" ? (
                      Childitem.transfer_to_user_name !== "" ? (
                        <>
                          <span
                            className="custom-btn1 text-12 poppins-300 fw-normal ps-2 pe-2 rounded-2 text-white min-width-clear pt-1 pb-1"
                            style={{ backgroundColor: 'yellow !important' }}
                          >
                            Transferred To
                          </span>
                          <span
                            style={{
                              fontSize: "12px",
                              textAlign: "start",
                              display: "block",
                              marginTop: "6px",
                            }}
                            className="dni"
                          >
                            <span>{truncateText(Childitem.transfer_to_user_name, 2)}</span>
                          </span>
                          <span
                            style={{
                              fontSize: "12px",
                              textAlign: "start",
                              display: "block",
                              marginTop: "4px",
                            }}
                          >
                            User ID #{Childitem.transfer_to_user_id}
                          </span>
                        </>
                      ) : (
                        <>

                          <span
                            className="custom-btn1 text-10 poppins-300 fw-normal ps-2 pe-2 rounded-2 text-white min-width-clear pt-1 pb-1"
                            style={{ backgroundColor: 'yellow !important' }}
                          >
                            Transferred From
                          </span>
                          <span
                            style={{
                              textAlign: "start",
                              display: "block",
                              marginTop: "6px",
                            }}
                            className="dni"
                          >
                            <span>{truncateText(Childitem.transfer_from_user_name, 2)}</span>
                          </span>
                          <span
                            style={{
                              textAlign: "start",
                              display: "block",
                              marginTop: "2px",
                            }}
                          >
                            User Id  #{Childitem.transfer_from_user_id}
                          </span>
                          <QRCode
                            style={{
                              textAlign: "start",
                              display: "block",
                              margin: "4px auto",
                            }}
                            value={UpcomingTicketsOnlyChild.ticket.id}
                          />
                        </>
                      )
                    ) : Childitem.scanned_at !== null && UpcomingTicketsOnlyChild.event.end_time > Childitem.scanned_at ? (
                      <span
                        style={{
                          fontSize: "12px",
                          textAlign: "end",
                          display: "block",
                          marginTop: "39px",
                        }}
                      >
                        Ticket is scanned
                      </span>
                    ) : UpcomingTicketsOnlyChild.event.event_valid === "expire" ? (
                      <span
                        style={{
                          fontSize: "12px",
                          textAlign: "end",
                          display: "block",
                          marginTop: "13%",
                        }}
                      >
                        <img src="/images/expired.png" height="80" />
                      </span>
                    ) : (
                      <QRCode value={UpcomingTicketsOnlyChild.ticket.id} />
                    )}
                  </div>
                </div>
              )}
            </div>
          );
        });
      } else {
        return item.order_child.map((Childitem, indexChild) => {
 
          
          return (
            <div key={Childitem.id}>
              {/* <div
                className="d-flex align-items-start justify-content-between border-bottom border-dashed border-0 border-color-silver pb-2 mb-3"
                data-tickt_child_id={Childitem.id}
              > */}
                <div
                className={`d-flex align-items-start justify-content-between  border-dashed border-0 border-color-silver pb-2 mb-3  ${item.order_child.length > 1 ? 'border-bottom' : ''}`}
                data-tickt_child_id={Childitem.id}
              >
                <div className="d-flex align-items-start justify-content-start w-80">
                  {/* <div className="d-inline-block pe-3">
                    <input
                      type="hidden"
                      name="orderID"
                      id="orderID"
                      value={item.order_id}
                    />
                    {!(
                      Childitem.scanned_at !== null ||
                      item.event_valid === "expire" ||
                      (Childitem.transfare_id !== null && Childitem.transfare_id !== "")
                    ) ? (
                      <input
                        type="checkbox"
                        name="ticket_id"
                        value={Childitem.id}
                        className="ticket_input cart-ticket-name ashraf"
                        attriData={Childitem.order_id}
                        onChange={handleChange}
                      />
                    ) : (
                      <input
                        type="checkbox"
                        name="ticket_id"
                        value={Childitem.id}
                        className="ticket_input cart-ticket-name ashraf"
                        attriData={Childitem.order_id}
                        disabled={true}
                        style={{ opacity: '0' }}
                      />
                    )}
                    <input
                      type="hidden"
                      name="free_ticket"
                      className="free_ticket"
                    />
                  </div> */}
                  {!(
                    Childitem.scanned_at !== null ||
                    item.event_valid === "expire" ||
                    (Childitem.transfare_id !== null && Childitem.transfare_id !== "")
                  ) ? (
                    <div className="d-inline-block pe-3">
                      <input
                        type="hidden"
                        name="orderID"
                        id="orderID"
                        value={item.order_id}
                      />
                      <input
                        type="checkbox"
                        name="ticket_id"
                        value={Childitem.id}
                        className="ticket_input cart-ticket-name ashraf"
                        attriData={Childitem.order_id}
                        onChange={handleChange}
                      />
                      <input
                        type="hidden"
                        name="free_ticket"
                        className="free_ticket"
                      />
                    </div>
                  ) : null}

                  <div className="d-inline-block">
                    <h5 className="text-15 mt-0 mb-2 poppins-400 text-15 text-black">
                      <img src="/images/ticket-icon2.png" style={{ height: '16px' }} />
                      <span className="ms-1 text-14"> {item.ticket.name}  </span>
                      <span className="d-inline-block px-2 ms-1 py-1 text-13 bg-green text-white rounded-3 d-none">
                        Valid
                      </span>
                    </h5>
                    <h5 className="text-15 mt-0 mb-2 poppins-400 text-15 text-black hhhh">
                      <i className="fa fa-calendar" aria-hidden="true"></i>
                      <span className="ms-2 text-14">
                        {formatDate(item.event.start_time)}
                      </span>
                    </h5>
                    <h5 className="text-15 mt-0 mb-2 poppins-400 text-15 text-black">
                      <i className="fa fa-clock" aria-hidden="true"></i>
                      <span className="ms-2 text-14">
                        {formatTime(item.event.start_time)}
                      </span>
                    </h5>
                    <h5 className="text-15 mt-0 mb-2 poppins-400 text-15 text-black">
                      <img src="/images/doller.svg" style={{ height: '16px' }} />
                      <span className="ms-2">
                        ${item.ticket.price.toFixed(2)}
                      </span>
                    </h5>
                  </div>
                </div>
                <div className="d-inline-block text-center">
                  {Childitem.transfare_id && Childitem.transfare_id !== "" ? (
                    Childitem.transfer_to_user_name !== "" ? (
                      <>
                        <span
                          className="custom-btn1 text-12 poppins-300 fw-normal ps-2 pe-2 rounded-2 text-white min-width-clear pt-1 pb-1"
                          style={{ backgroundColor: 'yellow !important' }}
                        >
                          Transferred To
                        </span>
                        <span
                          style={{
                            fontSize: "12px",
                            textAlign: "start",
                            display: "block",
                            marginTop: "6px",
                          }}
                          className="dni"
                        >
                          <span>{truncateText(Childitem.transfer_to_user_name, 2)}</span>
                        </span>
                        <span
                          style={{
                            fontSize: "12px",
                            textAlign: "start",
                            display: "block",
                            marginTop: "4px",
                          }}
                        >
                          User ID #{Childitem.transfer_to_user_id}
                        </span>
                      </>
                    ) : (
                      <>
                        <span
                          className="custom-btn1 text-10 poppins-300 fw-normal ps-2 pe-2 rounded-2 text-white min-width-clear pt-1 pb-1"
                          style={{ backgroundColor: 'yellow !important' }}
                        >
                          Transferred From
                        </span>
                        <span
                          style={{
                            textAlign: "start",
                            display: "block",
                            marginTop: "6px",
                          }}
                          className="dni"
                        >
                          <span>{truncateText(Childitem.transfer_from_user_name, 2)}</span>
                        </span>
                        <span
                          style={{
                            textAlign: "start",
                            display: "block",
                            marginTop: "4px",
                          }}
                        >
                          User ID {Childitem.transfer_from_user_id}
                        </span>
                      </>
                    )
                  ) : Childitem.scanned_at !== null && item.event.end_time > Childitem.scanned_at ? (
                    <span
                      style={{
                        fontSize: "18px",
                        textAlign: "start",
                        display: "block",
                        marginTop: "39pxpx",
                      }}
                    >
                      Ticket is scanned
                    </span>
                  ) : item.event_valid === "expire" ? (
                    <span
                      style={{
                        fontSize: "18px",
                        textAlign: "start",
                        display: "block",
                        marginTop: "13%",
                      }}
                    >
                      <img src="/images/expired.png" height="80" />
                    </span>
                  ) : (
                    <QRCode value={item.ticket.id} />
                  )}
                </div>
              </div>
            </div>
          );
        });
      }
    }
  });
  

 
  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };


   
 
  
  if (isLoading) {
    return (
      <>
        <LoadingSpinner />
      </>
    );
  } else {
    if (Isdata == "yes") {
      return (
        <>
          <Helmet>
            <link href="/New-css/bootstrap.min.css" rel="stylesheet" />
            <link href="/New-css/animate.css" rel="stylesheet" />
            <link href="/New-css/custom.css" rel="stylesheet" />
            <link href="/New-css/slick-theme.css" rel="stylesheet" />
            <link href="/New-css/slick.css" rel="stylesheet" />
          </Helmet>
          {LoadFullpage ? (
            <section className="pad-top-20 pad-bot-80">
              <div className="container3">
                <div className="block-element m-b-30"></div>
                <div
                  className="block-element loader-mobile-screen mian"
                
                >
                  <LoadingSpinner />
                </div>
              </div>
            </section>
          ) : (
            <>

              <div>
                <HeaderComponent />
                <section  className="pt-lg-5 pt-md-5 pt-3 bg-silver2">
                  <div  className="container">
                    <div  className="d-block w-100 py-2">
                      <div  className="d-flex align-items-center justify-content-between">
                        <div className="d-inline-block">
                          <h3 className="mt-0 mb-2 pt-0 pb-1 text-black text-29 mob-text-25 poppins-700">

                            My Tickets
                          </h3>
                          <h5 className="text-black text-15 d-flex align-items-center justify-content-start">
                            <Link className='text-black' to='/'> Home </Link>
                            <svg
                              className="svg-inline--fa fa-circle text-6 text-muted ms-2 me-2"
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="circle"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                              data-fa-i2svg=""
                              style={{ width: "9px" }}
                            >
                              <path
                                fill="currentColor"
                                d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512z"
                              />
                            </svg>
                            {/* <i  className="fa-solid fa-circle text-6 text-muted ms-2 me-2"></i> Font Awesome fontawesome.com */}
                            <span> My Tickets </span>
                          </h5>
                          
                        </div>

                         
                          
                  
                      </div>
                    </div>
                  </div>
                </section>
                <section className="pt-lg-5 pt-md-5 pt-2 pb-5 bg-silver2">
                  <div className="container">
                  <div className="d-lg-none d-md-none d-inline-block" style={{float:'right',marginBottom:'8px'}}>
                      <button
                        id="toggle-filter"
                        className="bg-green2 col-green poppins-600 border-0 px-3 py-1 rounded-3"
                        onClick={toggleFilters}
                      >
                        Filter
                      </button>
                      
                    </div>
                    <div className="d-lg-none d-md-none d-flex align-items-center justify-content-center w-100 text-center">
                      {!isTicketVisible ? <>
                        
                        <button className="d-flex align-items-center justify-content-center text-black text-15 mb-3 bg-transparent border-0">
                          <img src="/images/tap-icon1.png" className="me-2" />
                          Click on event Image to view ticket
                        </button>
                      </> : null}

                    </div>
                    
                    <div className="d-block w-100 py-2 bg-white rounded  border-bottom myticker-swiper shadow-sm" id="TOpSection">
                      {isMobile ? (
                        <>
                        {/* <div className="d-block w-100 top-menu-bar">
                            <div
                              className="nav nav-tabs border-0 custom-tabs4"
                              id="nav-tab2"
                              role="tablist"
                            >
                              <button
                                className={`nav-link  ${currentTab === "eventsTab" ? "active" : ""
                                  } bg-white text-black text-11 pl-2 pr-1 pt-3 me-1`}
                                onClick={() => setCurrentTab("eventsTab")}
                                id="TicketButton"
                                style={{

                                  borderTopLeftRadius: '10px', // Adjust the value as needed
                                  borderTopRightRadius: '0',
                                  borderBottomRightRadius: '0',
                                  borderBottomLeftRadius: '0'

                                }}
                              >
                                <i className="fa fa-calendar text-14 me-1">

                                </i>
                                Events
                              </button>
                              <button
                                className={`nav-link rounded-0  ${currentTab === "hotelsTab" ? "active" : ""
                                  } bg-white text-black text-11 pl-2 pr-1 pt-3 me-1`}
                                onClick={() => setCurrentTab("hotelsTab")}
                                id="TicketButton"
                              >
                                <i className="fa fa-bed text-14 me-1"> </i> Hotels
                              </button>
                              <button
                                className={`nav-link  rounded-0  ${currentTab === "transportTab" ? "active" : ""
                                  } bg-white text-black text-11 pl-2 pr-1 pt-3 me-1`}
                                onClick={() => setCurrentTab("transportTab")}
                                id="TicketButton"
                              >
                                <i className="fa fa-bus text-14 me-1"> </i> Buses
                              </button>
                              <button
                                className={`nav-link  ${currentTab === "flightTab" ? "active" : ""
                                  } bg-white text-black text-11 pl-2 pr-1 pt-3 me-0`}
                                onClick={() => setCurrentTab("flightTab")}
                                id="TicketButton"
                                style={{

                                  borderTopLeftRadius: '0', // Adjust the value as needed
                                  borderTopRightRadius: '10px',
                                  borderBottomRightRadius: '0',
                                  borderBottomLeftRadius: '0'

                                }}
                              >
                                <i className="fa fa-plane text-14 me-1"> </i> Flights
                              </button>
                            </div>
                          </div> */}
                        {/* <MyticketsSwiperMobile
                          allCount={all_count}
                          expiredCount={expired}
                          validCount={valid}
                          scannedCount={scanned}
                          onChangeCate={handleCategoryClick}
                          setStatus={setStatus}
                        /> */}
                         
                           
                              <div
                                className={` px-2 pb-2 pt-3 d-md-none d-lg-none ${showFilters ? 'd-block' : 'd-none'}`}
                              >
                                <div className="tab-content" id="nav-tabContent">
                                  {renderTabContentMobile()}
                                </div>
                    
                      </div>
                        </>
                      ) : (
                        <>
                          {/* <div className="d-block w-100 top-menu-bar">
                            <div
                              className="nav nav-tabs border-0 custom-tabs4"
                              id="nav-tab2"
                              role="tablist"
                            >
                              <button
                                className={`nav-link  ${currentTab === "eventsTab" ? "active" : ""
                                  } bg-white text-black text-11 pl-2 pr-1 pt-3 me-1`}
                                onClick={() => setCurrentTab("eventsTab")}
                                id="TicketButton"
                                style={{

                                  borderTopLeftRadius: '10px', // Adjust the value as needed
                                  borderTopRightRadius: '0',
                                  borderBottomRightRadius: '0',
                                  borderBottomLeftRadius: '0'

                                }}
                              >
                                <i className="fa fa-calendar text-14 me-1">

                                </i>
                                Events
                              </button>
                              <button
                                className={`nav-link rounded-0  ${currentTab === "hotelsTab" ? "active" : ""
                                  } bg-white text-black text-11 pl-2 pr-1 pt-3 me-1`}
                                onClick={() => setCurrentTab("hotelsTab")}
                                id="TicketButton"
                              >
                                <i className="fa fa-bed text-14 me-1"> </i> Hotels
                              </button>
                              <button
                                className={`nav-link  rounded-0  ${currentTab === "transportTab" ? "active" : ""
                                  } bg-white text-black text-11 pl-2 pr-1 pt-3 me-1`}
                                onClick={() => setCurrentTab("transportTab")}
                                id="TicketButton"
                              >
                                <i className="fa fa-bus text-14 me-1"> </i> Buses
                              </button>
                              <button
                                className={`nav-link  ${currentTab === "flightTab" ? "active" : ""
                                  } bg-white text-black text-11 pl-2 pr-1 pt-3 me-0`}
                                onClick={() => setCurrentTab("flightTab")}
                                id="TicketButton"
                                style={{

                                  borderTopLeftRadius: '0', // Adjust the value as needed
                                  borderTopRightRadius: '10px',
                                  borderBottomRightRadius: '0',
                                  borderBottomLeftRadius: '0'

                                }}
                              >
                                <i className="fa fa-plane text-14 me-1"> </i> Flights
                              </button>
                            </div>
                          </div> */}

                          {/* <MyticketsSwiperDesktop
                            allCount={all_count}
                            expiredCount={expired}
                            validCount={valid}
                            scannedCount={scanned}
                            onChangeCate={handleCategoryClick}
                            setStatus={setStatus}
                          /> */}
                          
                        </>
                      )}
                      <div className="d-mob-none w-100 bg-white all-filters2">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 px-2">


                          {isMobile ? (
                            <div
                              className=" px-2 pb-2 pt-3 d-md-none d-lg-none "
                              style={{
                                borderTopLeftRadius: '0', // Adjust the value as needed
                                borderTopRightRadius: '0',
                                borderBottomRightRadius: '10px',
                                borderBottomLeftRadius: '10px',
                              }}
                            >
                              <div
                                className={` px-2 pb-2 pt-3 d-md-none d-lg-none ${showFilters ? 'd-block' : 'd-none'}`}
                              >
                                <div className="tab-content" id="nav-tabContent">
                                  {renderTabContentMobile()}
                                </div>
                              </div>
                            </div>
                          ) : (
                            //  className="bg-white px-2 pb-2 pt-3 d-sm-none d-md-block d-lg-block shadow-normal"


                            <div
                              className="bg-white d-sm-none d-md-block d-lg-block"
                              style={{

                                borderTopLeftRadius: '0', // Adjust the value as needed
                                borderTopRightRadius: '10px',
                                borderBottomRightRadius: '10px',
                                borderBottomLeftRadius: '10px'

                              }}
                            >
                              <div className="tab-content" id="nav-tabContent">
                                {renderTabContent()}
                              </div>
                            </div>

                          )}

                        </div>
                      </div>
                    </div>
                    <>
                    <div className="row">
                    <div 
                      className={`d-lg-block d-md-block mt-lg-0 mt-md-0 mt-3 w-20 shorting ${!isMobile ? 'mr-2' : ''}`} 
                      >  
                              <div className="form-group position-relative me-2 border border-color-silver rounded-4 mb-2">
                              <span className="sort_label_bg"></span>
                            
                                <select
                                  id="form_name2"
                                  className="form-control text-14 border-0 float-input outline-0 shadow-none rounded-4 "
                                  name="sortOrder"
                                  value={sort_Order}
                                  onChange={handleShort}
                                >
              
                                <option value="start_time_asc" selected={sort_Order === 'start_time_asc'}>Start Time Newest</option>
                                <option value="start_time_desc" selected={sort_Order === 'start_time_desc'}>Start Time Oldest</option>
                                <option value="purchase_date_desc" selected={sort_Order === 'purchase_date_desc'}>Purchase Date Newest</option>
                                <option value="purchase_date_asc" selected={sort_Order === 'purchase_date_asc'}>Purchase Date Oldest</option>
                                </select>
                                <label
                                  htmlFor="form_name2"
                                  className="float-label"
                                  id="SortfloatHome"
                                >
                                  Sort By
                                </label>
                              </div>
                            </div>

                            <div 
                              className={`d-lg-block d-md-block mt-lg-0 mt-md-0 mt-3 w-20 shorting ml-auto  ${!isMobile ? 'mr-2' : ''}`}
                              >  
                              <div className="form-group position-relative me-2 border border-color-silver rounded-4 mb-2">
                              <span className="sort_label_bg"></span>
                            
                                <select
                                  id="form_name3"
                                  className="form-control text-14 border-0 float-input outline-0 shadow-none rounded-4 "
                                  name="sortOrder"
                                  value={status}
                                  onChange={handleCategoryClick}
                                >
              
                                <option value="all" selected={status === 'all'}>All</option>
                                <option value="valid" selected={status === 'valid'}>Valid</option>
                                {/* <option value="transferred" selected={status === 'transferred'}>Transferred</option>
                                <option value="scanned" selected={status === 'scanned'}>Scanned</option> */} 
                                <option value="expired" selected={status === 'expired'}>Expired</option>
                                </select>
                                <label
                                  htmlFor="form_name3"
                                  className="float-label"
                                  id="SortfloatHome"
                                >
                                Type
                                </label>
                              </div>
                            </div>

                    </div>
                    
                     </>
                    <h2  className="text-black poppins-600 mt-0 mb-1 text-22 mb-3 mt-4" id="HeadingTEA" style={{float:'right',marginRight:'266px'}}>Tickets</h2>
                    
                    <h2  className="text-black poppins-600 mt-0 mb-1 text-22 mb-3 mt-4" id="HeadingTEA2">My Ticket List</h2>
                    <div className="d-flex align-items-start justify-content-start flex-wrap">
                      <div
                        className="order-checkout-left pe-lg-4 pe-md-4 pe-0 order-lg-1 order-md-1 order-2 all-tickets-div"
                        style={{ display: isTicketVisible ? "none" : "" }}
                      >
                        <div className="d-block w-100 mb-3 rounded-5">
                          <div className="d-block w-100">



                            {eventLoading ? (
                              <div className="d-block w-100">
                                    <LoadingSpinner />
                                    </div>
                            ) : (
                              <>
                                {renderedUpcomingTickets.length > 0 ? (
                                  renderedUpcomingTickets
                                ) : (
                                   
                                      <div className="w-100 d-flex justify-content-center align-items-center">
                                        <h6  className="mt-4">
                                          No Events found!
                                        </h6>
                                      </div>
                                     
                                )}
                              </>
                            )}

                          </div>
                          <div className="d-block w-100 px-3 border-top py-3">
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="d-inline-block me-3">
                                <div className="d-flex align-items-center justify-content-start">
                                  <span className="d-inline-block text-14 text-black me-3 justify-content-start">
                                    Rows per page:
                                  </span>
                                  <select
                                    value={itemsPerPage}
                                    className="border-0 bg-transparent text-14 text-black"
                                    onChange={handleItemsPerPageChange}
                                  >
                                    <option value={5}>5</option>
                                    <option value={10}>10</option>
                                    <option value={15}>15</option>
                                    <option value={20}>20</option>
                                    <option value={25}>25</option>
                                  </select>
                                </div>
                              </div>
                              <div className="d-inline-block me-3">
                                <span className="text-black text-14 d-inline-block">
                                  {from}-{to} of {totalRecords}
                                </span>
                              </div>
                              <div className="d-inline-block">
                                <div className="d-flex align-items-center justify-content-start">
                                  <button
                                    className="px-2 py-0 bg-transparent border-0"
                                    onClick={hitPrev}
                                  >
                                    <i className="fa fa-angle-left text-black text-14 m-0 p-0"></i>
                                  </button>
                                  <button
                                    className="px-2 py-0 bg-transparent border-0"
                                    onClick={hitNext}
                                  >
                                    <i className="fa fa-angle-right text-black text-14 m-0 p-0"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="order-checkout-right ps-lg-3 ps-md-3 ps-0  pe-lg-3 pe-md-3 pe-0 mt-0 order-lg-2 order-md-2 order-1 "
                        style={{ display: isTicketVisible ? "block" : "" }}
                      >
                        <h2  className="text-black poppins-600 mt-0 mb-1 text-22 mb-3 mt-4" id="HideHeading">{isTicketVisible ? "Tickets" : "My Ticket List"}</h2>
                        <div
                          className="d-lg-block d-md-block w-100 d-mob-none ticket-detail-div px-3 py-3 rounded-5 shadow-normal bg-white"
                          style={{ display: isTicketVisible ? "block" : "" }}
                        >
                          
                          <div className="d-lg-none d-md-none w-100 mb-3">
                            <button
                              id="backToggle"
                              className="border-0 bg-blue2 px-3 py-2 rounded-3 d-flex align-items-center justify-content-start text-14 text-black"
                              onClick={toggleTicket_2}
                            >
                              <i className="fa fa-angle-left text-black text-14 m-0 p-0"></i>
                              <span className="ms-2"> Back to All Tickets</span>
                            </button>
                          </div>

                          {UpcomingTicketsOnlyChild ? (
                            <React.Fragment>
                              <div className="d-block w-100">
                                <h3 className="mt-0 mb-3 p-0 text-black poppins-600 text-18 ">
                                  {UpcomingTicketsOnlyChild.event.name}
                                </h3>
                              </div>

                              <div className="d-block w-100 mb-3">
                                <img
                                  className="w-100 rounded-3 card-img event_img"
                                  src={
                                    UpcomingTicketsOnlyChild.event.imagePath +
                                    UpcomingTicketsOnlyChild.event.image
                                  }
                                />
                              </div>

                              <div className="d-block ">

                                {/* <div className="d-inline-block">
                                    <span className="bg-blue3 d-inline-block text-white px-2 py-1 rounded-3 text-13" style={{'justifyContent':'end'}}>
                                      {UpcomingTicketsOnlyChild.order_id}
                                    </span>
                                  </div> */}
                                <div className="d-flex justify-content-end">
                                  <div className="d-inline-block">
                                    <span className="bg-blue3 d-inline-block text-white px-2 py-1 rounded-3 text-13">
                                      {UpcomingTicketsOnlyChild.order_id}
                                    </span>
                                  </div>
                                </div>



                                <div className="d-flex align-items-center justify-content-between mt-2">

                                  <div className="d-inline-block">
                                    <h5 className="col-green1 mt-0 mb-1 poppins-600 p-0 text-14" >

                                      {formatDateTime(UpcomingTicketsOnlyChild.event.start_time)} - {formatDateTime(UpcomingTicketsOnlyChild.event.end_time)}
                                    </h5>
                                    <h4 className="text-uppercase col-blue1 poppins-600 m-0 p-0 text-14">
                                    {UpcomingTicketsOnlyChild.event.address ? (
                                      <a
                                        href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(UpcomingTicketsOnlyChild.event.address)}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {UpcomingTicketsOnlyChild.event.address}
                                      </a>
                                    ) : null}
 


                                      {/* {UpcomingTicketsOnlyChild.event.address
                                            ? UpcomingTicketsOnlyChild.event.address
                                                .split(" ")
                                                .map((word, index) => {
                                                  return index > 0 &&
                                                    index % 10 === 0 ? (
                                                    <React.Fragment key={index}>
                                                      {word}
                                                      <br />
                                                    </React.Fragment>
                                                  ) : (
                                                    word
                                                  );
                                                })
                                                .slice(0, 18)
                                                .join(" ")
                                            : "Address not available"} */}


                                    </h4>
                                  </div>
                                  {/* <div className="d-inline-block">
                                    <span className="bg-blue3 d-inline-block text-white px-2 py-1 rounded-3 text-13">
                                      {UpcomingTicketsOnlyChild.order_id}
                                    </span>
                                  </div> */}
                                </div>

                              </div>
                            </React.Fragment>
                          ) : (
                            UpcomingTickets.map((item, index) =>
                              index === 0 ? (
                                <>
                                
                                <React.Fragment key={index}>
                                  <div className="d-block w-100">
                                    <h3 className="mt-0 mb-3 p-0 text-black poppins-600 text-18 ">
                                      {item.event.name}
                                    </h3>
                                  </div>

                                  <div className="d-block w-100 mb-3 ">
                                    <img
                                      className="w-100 rounded-3 card-img event_img  "
                                      src={
                                        item.event.imagePath + item.event.image
                                      }
                                    />
                                  </div>

                                  <div className="d-block w-100 mb-4">
                                    {/* <div className="d-inline-block">
                                        <span className="bg-blue3 d-inline-block text-white px-2 py-1 rounded-3 text-13">
                                          {item.order_id}
                                        </span>
                                    </div> */}

                                    <div className="d-flex  " style={{ 'justifyContent': 'end' }}>
                                      <div className="d-inline-block">
                                        <span className="bg-blue3 d-inline-block text-white px-2 py-1 rounded-3 text-13">
                                          {item.order_id}
                                        </span>
                                      </div>
                                    </div>


                                    <div className="d-flex align-items-center justify-content-between mt-2">
                                      <div className="d-inline-block">
                                        <h5 className=" mt-0 mb-1 poppins-600 p-0 text-14" id="DateTExt">

                                          {formatDateTime(item.event.start_time)} - {formatDateTime(item.event.end_time)}
                                        </h5>
                                        <h4 className="text-uppercase col-blue1 poppins-600 m-0 p-0 text-14">
                                        {item.event.address ? (
                                                <a
                                                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(item.event.address)}`}
                                                  target="_blank"
                                                  className="col-blue1"
                                                  rel="noopener noreferrer"
                                                >
                                                  {item.event.address}
                                                </a>
                                              ) : null}
                                          {/* {item.event.address
                                            ? item.event.address
                                                .split(" ")
                                                .map((word, index) => {
                                                  return index > 0 &&
                                                    index % 10 === 0 ? (
                                                    <React.Fragment key={index}>
                                                      {word}
                                                      <br />
                                                    </React.Fragment>
                                                  ) : (
                                                    word
                                                  );
                                                })
                                                .slice(0, 18)
                                                .join(" ")
                                            : "Address not available"} */}
                                        </h4>
                                      </div>
                                      {/* <div className="d-inline-block">
                                        <span className="bg-blue3 d-inline-block text-white px-2 py-1 rounded-3 text-13">
                                          {item.order_id}
                                        </span>
                                      </div> */}
                                    </div>

                                  </div>
                                </React.Fragment>
                                </>
                              ) : null
                            )
                          )}

                          <div className="d-block w-100 mb-3 mt-3">
                            {ticket_child_loop.length > 0 ? (
                              <>
                                {ticket_child_loop}
                                <div className="d-block">
                                  <form
                                    method="post"
                                    className="event_print"
                                    id="download_pdf_form"
                                    onSubmit={handleSubmit}
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="F3K9ib4m3SzPvE6PnvM4CjNciPxfgOpRNo4z7fu2"
                                    />
                                    <input
                                      type="hidden"
                                      name="ticket_child_id"
                                      className="event_child_id"
                                      value={items}
                                    />
                                    <input
                                      type="hidden"
                                      name="orderId"
                                      id="orderIDD"
                                      value={orderID}
                                    />

                                    <button
                                      type="submit"
                                      className="custom-btn3 w-100 mb-3 download_pdf_btn buttonwww text-white"
                                        style={{ display: downloadButtonDisplay ? 'block' : 'none' }}
                                    // aria-disabled={items.length === 0 ? "true" : "false"}
                                    >
                                      Download Tickets
                                    </button>
                                  </form>

                                  <form
                                    method="post"
                                    className="event_print"
                                    id="transfer_form"
                                    onSubmit={handleSubmitTransfare}
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="F3K9ib4m3SzPvE6PnvM4CjNciPxfgOpRNo4z7fu2"
                                    />
                                    <input
                                      type="hidden"
                                      name="ticket_child_id"
                                      className="event_child_id"
                                      value={items}
                                    />
                                    <input
                                      type="hidden"
                                      name="orderId"
                                      id="orderIDD"
                                      value={orderID}
                                    />

                                    <button 
                                      type="submit"
                                      id="ticket_transfer_btn"
                                      className="custom-btn2 w-100"
                                        style={{ display: transferButtonDisplay ? 'block' : 'none' }}
                                       
                                    // aria-disabled={items.length === 0 ? "true" : "false"}
                                    >
                                      Transfer Tickets
                                    </button>
                                  </form>
                                </div>
                              </>
                            ) : (
                              <div className="w-100 d-flex justify-content-center align-items-center">
                                <h6 className="mt-4">No Tickets Found!</h6>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <FooterComponent />
            </>
          )}
        </>
      );
    } else {
      return (
        <div>
          <NewHeader />
          <section  className="pt-lg-5 pt-md-5 pt-3 bg-silver2">
            <div  className="container">
              <div  className="d-block w-100 py-2">
                <div  className="d-flex align-items-center justify-content-between">
                  <div className="d-inline-block">
                    <h3 className="mt-0 mb-2 pt-0 pb-1 text-black text-29 mob-text-25 poppins-700">

                      My Tickets
                    </h3>
                    
                    <h5 className="text-black text-15 d-flex align-items-center justify-content-start">
                      <span> Tickets </span>
                      <svg
                        className="svg-inline--fa fa-circle text-6 text-muted ms-2 me-2"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="circle"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        data-fa-i2svg=""
                        style={{ width: "9px" }}
                      >
                        <path
                          fill="currentColor"
                          d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512z"
                        />
                      </svg>
                      {/* <i  className="fa-solid fa-circle text-6 text-muted ms-2 me-2"></i> Font Awesome fontawesome.com */}
                      <span> My Tickets </span>
                    </h5>
                    
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="pt-lg-5 pt-md-5 pt-2 pb-5 bg-silver2">
            <div className="container">
              <div className="d-lg-none d-md-none d-flex align-items-center justify-content-center w-100 text-center">
                <button className="d-flex align-items-center justify-content-center text-black text-15 mb-3 bg-transparent border-0">
                  <img src="/images/tap-icon1.png" className="me-2" />
                  Click on event Image to view ticket
                </button>
                
              </div>
              <div className="d-flex align-items-center justify-content-center flex-wrap">
                <div
                  className="  pe-lg-4 pe-md-4 pe-0 order-lg-1 order-md-1 order-2 all-tickets-div"
                  style={{ display: isTicketVisible ? "none" : "" }}
                >
                  <div className="d-block w-100   ">
                    <div
                       className=" "
                      style={{ padding: "230px 0px !important" }}
                    >
                      <div className="empty-state">
                        <img src="/images/empty.png" />
                        <h4 className="mt-4" style={{ textAlign: "center" }}>No Tickets found!</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <FooterComponent />
        </div>
        // <section className="pad-top-40 pad-bot-40 bg-white2">
        //   <div
        //      className="col-lg-12 text-center"
        //     style={{ padding: "230px 0px !important" }}
        //   >
        //     <div  className="empty-state">
        //       <img src="/images/empty.png" />
        //       <h6  className="mt-4"> No Tickets found!</h6>
        //     </div>
        //   </div>
        // </section>
      );
    }
  }
}

export default MyticketsmainComp;
