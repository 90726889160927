import React, { useState, useEffect,useContext } from "react";
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Helmet } from "react-helmet";
import HeaderComponent from '../HeaderComponent/HeaderComponent.js';
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import FooterComponent from '../FooterComponent/FooterComponent.js';
import { ProfileContext } from '../../hooks/ProfileContext';
import Swal from "sweetalert2";
import { fetchUserData, resetPasswordForm } from "../../services/profileService";

 
const validationSchema = Yup.object().shape({
  password: Yup.string().required('Password is required'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
});

const ChangePasswordComponent = () => {
  const { handleSubmit, control, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema)
  });
const { setImagePath } = useContext(ProfileContext);
 
const [loading,setLoading] =useState(false);
const [userData, setUserData] = useState({
  image: "",
  imagePath: "",
});
const fetchData = async () => {
  try {
    const token = localStorage.getItem("authToken");
    const data = await fetchUserData(token);
    setUserData(data.user);
    if (data.user.image) {
      const fullImagePath = data.user.imagePath + data.user.image;
      setImagePath(fullImagePath);
    } else {
      setImagePath('/images/notlogin.png');
    }
  
  } catch (error) {
   
  }
};

useEffect(() => {
  fetchData();
}, []);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    document.body.classList.add("header-bgms");

    return () => {
      document.body.classList.remove("header-bgms");
    };
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); 
    }, 2000); 

    return () => clearTimeout(timer); 
  }, []);
 
const onSubmit = async (formData) => {
  console.log(formData, 'formData');
  try {
    const result = await resetPasswordForm(formData);
    console.log("User profile updated successfully", result.msg);
    if (result.success) {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: result.msg,
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: result.msg || "Request failed with no message",
      });
    }
  } catch (error) {
    console.error("Error updating user profile:", error.msg);
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error.msg,
    });
  }
};
  
  return (
    <div>
      <Helmet>
        <link href="/css/bootstrap.min.css" rel="stylesheet" />
      </Helmet>
      {loading ? (
        <section className="pt-lg-5 pt-md-5 pt-3" style={{ background: '#f9fcff' }}>
          <div className="container3">
            <div className="block-element m-b-30">
            </div>
            <div className="block-element loader-mobile-screen" style={{ marginTop: '80px' }}>
              <LoadingSpinner />
            </div></div>
        </section>
      ) : (
        <>
          <HeaderComponent />
          <section className="pad-top-60 pad-bot-60" style={{ backgroundColor: "#f9fcff" }}>
            <div className="container profile-page">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row profile-container">
                <div className="col-md-4 mb-4">
                <div className="profile-card profile-pic py-5">
                      <img src={userData.imagePath + userData.image || '/images/notlogin.png' } alt="Profile Picture" />
                      <small className="text-muted mt-2 text-center">Allowed *.jpeg, *.jpg, *.png, *.gif<br />Max size of 3.1 MB</small>
                    </div>
                </div>
                <div className="col-md-8">
                  <div className="profile-card profile-details pt-5">
                  
                    <div>
                      <div className="row">
                        <div className="col-md-12 mb-2">
                          <div className="form-group">
                            <label htmlFor="password" className="poppins-400">Password</label>
                            <Controller
                              name="password"
                              control={control}
                              defaultValue=""
                              render={({ field }) => (
                                <input
                                  {...field}
                                  type="password"
                                  className="form-control poppins-400"
                                  id="password"
                                  placeholder="Password"
                                />
                              )}
                            />
                            {errors.password && <p className="error">{errors.password.message}</p>}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 mb-2">
                          <div className="form-group">
                            <label htmlFor="confirm_password" className="poppins-400">Confirm Password</label>
                            <Controller
                              name="confirm_password"
                              control={control}
                              defaultValue=""
                              render={({ field }) => (
                                <input
                                  {...field}
                                  type="password"
                                  className="form-control poppins-400"
                                  id="confirm_password"
                                  placeholder="Confirm Password"
                                />
                              )}
                            />
                            {errors.confirm_password && <p className="error">{errors.confirm_password.message}</p>}
                          </div>
                        </div>
                      </div>
                      <div className="btn-container desktop-only">
                        <button type="submit" className="btn btn-primary mr-2 poppins-600 w-100">Save changes</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mobile-only w-100 mt-4">
                  <div className="px-3 btn-container">
                    <button type="submit" className="btn btn-primary mr-2 poppins-600 w-100">Save changes</button>
                  </div>
                </div>
              </div>
            </form>
            </div>
          </section>
          <FooterComponent />
        </>
      )}
    </div>
  );
}

export default ChangePasswordComponent;
