import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
 import HomeHeader from '../HeaderComponent/HomeHeader';
 import FooterComponent from '../FooterComponent/FooterComponent.js';

const SearchDataComponent = () => {
  const location = useLocation();
  const searchResults = location.state.results.data;
  const query = location.state.query;
  const [loading, setLoading] = useState(true);



  useEffect(() => {
    document.body.classList.add('bg-black-color');

    return () => {
      document.body.classList.remove('bg-black-color');
    };
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); // Change loading state to false after 2 seconds
    }, 2000); // 2 seconds delay

    return () => clearTimeout(timer); // Clear the timer if the component unmounts
  }, []);
  if (!Array.isArray(searchResults)) {
    return <div>No search results found</div>;
  }

  const filteredResults = searchResults.filter(result => result.name.toLowerCase().includes(query.toLowerCase()));

  return (
    <>
      <Helmet>
        <link href="/css/bootstrap.min.css" rel="stylesheet" />
      </Helmet>
      {loading ? (
        <section className="pad-top-20 pad-bot-80">
          <div className="container3">
            <div className="block-element m-b-30">
            </div>
            <div className="block-element" style={{ marginTop: '80px' }}>
              <LoadingSpinner />
            </div></div>
        </section>
      ) : (
        <>
          <HomeHeader />
          <section className="pad-top-20 pad-bot-80">
            <div className="container3">
              <div className="block-element m-b-30">
                <h4 className="title-text7 col-white mob-text-center">Search Result</h4>
              </div>
              <div className="block-element">
                {filteredResults.length === 0 ? (
                  <div className="text-white">No search results found</div>
                ) : (
                  <div className="row">
                    <br />
                    {filteredResults.map((event) => (
                      <div className="col-md-4 mb-5" key={event.id}>
                        <div className="fav-section">
                          {/* <button
                    style={{ position: "absolute", right: 25 }}
                    type="button"
                    className="btn p-0"
                  >
                     
                    <Link href="/user/login">
                      <i className="fa fa-heart" />
                    </Link>
                  </button> */}
                        </div>
                        <Link
                          to={`/event/${event.id}/${event.name
                            .replace(/\s+/g, "-")
                            .toLowerCase()}`}
                        >
                          <div className="image-container" style={{ height: 200, width: "100%", overflow: "hidden" }}>
                            <img src={event.imagePath + event.image} alt={event.image} style={{ objectFit: "cover", }} />
                          </div>
                        </Link>
                        <Link
                          to={`/event/${event.id}/${event.name
                            .replace(/\s+/g, "-")
                            .toLowerCase()}`}
                        >
                          <h4 className="col-white"> {event.name} </h4>
                          <div style={{ maxWidth: "100%" }}>
                            <div className="col-grey6 text-white eventDescription" style={{ fontSize: "14px", color: "white", ...event.description.includes("<") ? {} : { display: "none" } }} dangerouslySetInnerHTML={{ __html: event.description.replace(/<h2>/g, '<h2 style="font-size: 14px;color:white;">').split(' ').slice(0, 20).join(' ') }}></div>
                          </div>
                          <h6 className="m-b-15">

                            <span className="col-yellow1"> Date: </span>
                            <span className="col-yellow1" style={{ borderRight: "none" }}>
                              {(() => {
                                const startDate = new Date(event.start_time);
                                const options = {
                                  weekday: 'long',
                                  year: 'numeric',
                                  month: 'long',
                                  day: 'numeric',
                                };
                                return startDate.toLocaleDateString('en-US', options);
                              })()}
                            </span>
                          </h6>
                        </Link>
                        <br />
                      </div>
                    ))}
                    <div className="pagination-links"></div>
                  </div>
                )}
              </div>
            </div>
          </section>
          <FooterComponent />
        </>
      )}
    </>

  );
}

export default SearchDataComponent;
