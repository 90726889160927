import React, {useEffect} from 'react';
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
const TicketModelComponent = ({ modalVisible, selectedTickets,setSelectedTickets,openModal, handleClose,evtData,setOrders,setAvailableTickets,ordersCount,updateOrdersCount,availableTickets,orders }) => {
    const navigate = useNavigate();

    useEffect(() => {
        const body = document.querySelector('body');
        if (modalVisible) {
            body.classList.add('modal-open');
        } else {
            body.classList.remove('modal-open');
        }
        return () => {
            body.classList.remove('modal-open');
        };
    }, [modalVisible]);
    

    const getUserCart = (userId) => {
     
  
      if (!userId) {
          console.error("User is not logged in.");
          return null;
      }
  
      const cart = JSON.parse(localStorage.getItem("cart") || "{}");

      return cart[userId] || { userId: userId, items: [] };
  };

  

//   const handleContinue_checkout = () => {
//     const userId = localStorage.getItem('userID');

//     if (!userId) {
//         Swal.fire({
//             icon: "error",
//             title: "Oops...",
//             text: "User is not logged in.",
//         });
//         return;
//     }

//     if (orders && orders.length > 0) {
//         const ordersFromLocalStorage = JSON.parse(localStorage.getItem("orders") || "[]");
//         console.log(ordersFromLocalStorage);

//         let cart = JSON.parse(localStorage.getItem("cart") || "{}");

//         // Initialize the cart if it doesn't exist for this user
//         if (!cart[userId]) {
//             cart[userId] = {
//                 userId: userId,
//                 items: []
//             };
//         }

//         // Add orders to the user's cart items
//         cart[userId].items = [...cart[userId].items, ...ordersFromLocalStorage];

//         localStorage.setItem("cart", JSON.stringify(cart));

//         let totalQuantity = 0;
//         cart[userId].items.forEach((item) => {
//             totalQuantity += item.quantity;
//         });

//         localStorage.setItem(`ordersCount_${userId}`, totalQuantity);
//         updateOrdersCount(totalQuantity);
//         getUserCart(userId);
//         // setShowModal(false); // Uncomment this if you need to hide a modal
//         navigate("/cart");
//     } else {
//         Swal.fire({
//             icon: "error",
//             title: "Oops...",
//             text: `You have not selected any tickets.`,
//         });
//     }
// };

  
   

  
    
      const handleContinue_checkout = () => {
        // if (locationStorage) {
    
        const loginUser = localStorage.getItem("userID");

       

          if (!loginUser) {
              Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "User is not logged in.",
              });
              return;
          }
           
          

        if (orders && orders.length > 0) {
          const ordersFromLocalStorage = JSON.parse(
            localStorage.getItem("orders") || "[]"
          );
          const allCartData = JSON.parse(localStorage.getItem('CartData')) || {};
          allCartData[localStorage.getItem("userID")] = ordersFromLocalStorage;
          localStorage.setItem('CartData', JSON.stringify(allCartData));
           
          localStorage.setItem("items", JSON.stringify(ordersFromLocalStorage));
           
        
          const itemsJSON = localStorage.getItem("CartData");
      
          let totalQuantity = 0;
        
          if (itemsJSON) {
            const items = JSON.parse(itemsJSON);
        
            // Filter items by loginUser
            if (items[loginUser]) {
              items[loginUser].forEach((item) => {
                totalQuantity += item.quantity;
              });
            }
          }
        
          localStorage.setItem("ordersCount", totalQuantity);
          updateOrdersCount(totalQuantity); 
          navigate("/cart");
      }else{
          Swal.fire({
              icon: "error",
              title: "Oops...",
              text: `You have not selected any tickets.`,
          });
      };

    }


      const handleIncrementQuantity = (ticket, ticketPerOrder) => {
        console.log(ticket);
        const loginUser = localStorage.getItem("userID");
    
        // Retrieve the entire FakeOrder object and then get the orders for the logged-in user
        const allCartDataByUser = JSON.parse(localStorage.getItem('FakeOrder')) || {};
        const ordersFromLocalStorage = allCartDataByUser[loginUser] || [];
    
        // Find if the ticket ID already exists in the user's orders
        const existingTicketIndex = ordersFromLocalStorage.findIndex(
            (order) => order.id === ticket.id
        );
    
        if (existingTicketIndex !== -1) {
            // If the ticket exists, update the quantity and price
            const updatedOrders = [...ordersFromLocalStorage];
            const existingOrder = updatedOrders[existingTicketIndex];
    
            if (existingOrder.quantity === ticketPerOrder) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: `You can only order up to ${ticketPerOrder} tickets per order.`,
                });
                return ticket.quantity;
            }
    
            existingOrder.quantity++;
            existingOrder.price = existingOrder.quantity * existingOrder.per_ticket_price;
            setOrders(updatedOrders);
    
            // Update the FakeOrder object in localStorage with the new orders for the logged-in user
            allCartDataByUser[loginUser] = updatedOrders;
            localStorage.setItem('FakeOrder', JSON.stringify(allCartDataByUser));
    
            // Also update the generic 'orders' in localStorage for compatibility
            localStorage.setItem("orders", JSON.stringify(updatedOrders));
        } else {
            // If the ticket does not exist, add a new order to the user's orders
            const attributesObject = {
                eventName: ticket.eventName,
                event_id: ticket.event_id,
                image: ticket.image,
                ticket_per_order: ticket.ticket_per_order,
                user_id: ticket.user_id,
            };
    
            const ticketObject = {
                id: ticket.id,
                name: ticket.name,
                description: ticket.description,
                type: ticket.type,
                per_ticket_price: ticket.price,
                price: ticket.price * (ticket.quantity + 1),
                quantity: ticket.quantity + 1,
                attributes: attributesObject,
            };
    
            const newOrders = [...ordersFromLocalStorage, ticketObject];
            setOrders(newOrders);
    
            // Update the FakeOrder object in localStorage with the new orders for the logged-in user
            allCartDataByUser[loginUser] = newOrders;
            localStorage.setItem('FakeOrder', JSON.stringify(allCartDataByUser));
    
            // Also update the generic 'orders' in localStorage for compatibility
            localStorage.setItem("orders", JSON.stringify(newOrders));
        }
    
        // Update the availableTickets array with the new quantity and price
        const updatedTickets = availableTickets.map((t) => {
            if (t.id === ticket.id) {
                const updatedQuantity = t.quantity + 1;
                const updatedPrice = updatedQuantity * t.price;
                return {
                    ...t,
                    quantity: updatedQuantity,
                    selected_price: updatedPrice,
                };
            }
            return t;
        });
    
        setAvailableTickets(updatedTickets);
    
        // Update the selectedTickets array with the new quantity and price
        const updatedSelectedTickets = selectedTickets.map((t) => {
            if (t.id === ticket.id) {
                const updatedQuantity = t.quantity + 1;
                const updatedTotalPrice = updatedQuantity * t.price;
                return {
                    ...t,
                    quantity: updatedQuantity,
                    selected_price: updatedTotalPrice,
                };
            }
            return t;
        });
    
        setSelectedTickets(updatedSelectedTickets);
    };



    //   const handleIncrementQuantity = (ticket, ticketPerOrder) => {
          
    //     const ordersFromLocalStorage = JSON.parse(
    //       localStorage.getItem("orders") || "[]"
    //     );
        
 
    //     const existingTicketIndex = ordersFromLocalStorage.findIndex(
    //       (order) => order.id === ticket.id
    //     );
       
    //     if (existingTicketIndex !== -1) {


    //       const updatedOrders = [...ordersFromLocalStorage];
    
    //       const existingOrder = ordersFromLocalStorage[existingTicketIndex];

    //       console.log(existingOrder);
    //       if (existingOrder.quantity === ticketPerOrder) {
    //         Swal.fire({
    //           icon: "error",
    //           title: "Oops...",
    //           text: `You can only order up to ${ticketPerOrder} tickets per order.`,
    //         });
    //         return ticket.quantity;
    //       }
    //       const existingTicket = updatedOrders[existingTicketIndex];
    //       existingTicket.quantity++;  
    //       existingTicket.price =
    //         existingTicket.quantity * existingTicket.per_ticket_price;  
    //       setOrders(updatedOrders);
 

    //       const allCartDataByUser = JSON.parse(localStorage.getItem('FakeOrder')) || {};
    //       allCartDataByUser[localStorage.getItem("userID")] = updatedOrders;
    //       localStorage.setItem('FakeOrder', JSON.stringify(allCartDataByUser));

 

    //       localStorage.setItem("orders", JSON.stringify(updatedOrders));
    //     } else {
      
    //       const attributesObject = {
    //         eventName: ticket.eventName,
    //         event_id: ticket.event_id,
    //         image: ticket.image,
    //         ticket_per_order: ticket.ticket_per_order,
    //         user_id: ticket.user_id,
    //       };
    
        
    //       const ticketObject = {
    //         id: ticket.id,
    //         name: ticket.name,
    //         description: ticket.description,
    //         type: ticket.type,
    //         per_ticket_price: ticket.price,
    //         price: ticket.price * (ticket.quantity + 1),
    //         quantity: ticket.quantity + 1,
    //         attributes: attributesObject,
    //       };

    
    
    //       const newOrders = [...ordersFromLocalStorage, ticketObject];  
    //       setOrders(newOrders);

    //       const allCartDataByUser = JSON.parse(localStorage.getItem('FakeOrder')) || {};
    //       allCartDataByUser[localStorage.getItem("userID")] = newOrders;
    //       localStorage.setItem('FakeOrder', JSON.stringify(allCartDataByUser));


    //       localStorage.setItem("orders", JSON.stringify(newOrders));
    //     }
    
    //     if (ticket.quantity >= ticketPerOrder) {
    //       Swal.fire({
    //         icon: "error",
    //         title: "Oops...",
    //         text: `You can only order up to ${ticketPerOrder} tickets per order.`,
    //       });
    //       return ticket.quantity;
    //     }
 
    //     const updatedTickets = availableTickets.map((t) => {
    //       if (t.id === ticket.id) {
    //         const updatedQuantity = t.quantity + 1;
    //         const updatedPrice = updatedQuantity * t.price;
    //         return {
    //           ...t,
    //           quantity: updatedQuantity,
    //           selected_price: updatedPrice,
    //         };
    //       }
    //       return t;
    //     });
    
    //     setAvailableTickets(updatedTickets);

     
    // const updatedSelectedTickets = selectedTickets.map((t) => {
    //     if (t.id === ticket.id) {
    //         const updatedQuantity = t.quantity + 1;
    //         const updatedTotalPrice = updatedQuantity * t.price;
    //         return {
    //             ...t,
    //             quantity: updatedQuantity,
    //             selected_price: updatedTotalPrice,
    //         };
    //     }
    //     return t;
    // });
    // console.log(updatedSelectedTickets);
    //  setSelectedTickets(updatedSelectedTickets); 
    //   };

      const handleDecrementQuantity = (ticket) => {

        if (ticket.quantity > 0) {
          console.log(selectedTickets);
          const updatedTickets = selectedTickets.map((t) => {
            if (t.id === ticket.id) {
              const updatedQuantity = t.quantity - 1;
              const updatedPrice = updatedQuantity * t.price;
              return {
                ...t,
                quantity: updatedQuantity,
                selected_price: updatedPrice,
              };
            }
            return t;
          });
          setSelectedTickets(updatedTickets);
    
       
          const ordersFromLocalStorage = JSON.parse(
            localStorage.getItem("orders") || "[]"
          );
     
          const updatedOrders = ordersFromLocalStorage
            .map((order) => {
              if (order.id === ticket.id && ticket.quantity === 1) {
           
                return null;
              } else if (order.id === ticket.id) {
                
                const updatedQuantity = order.quantity - 1;
                const updatedPrice = ticket.price * updatedQuantity;  
                return { ...order, quantity: updatedQuantity, price: updatedPrice };
              } else {
                return order;
              }
            })
            .filter(Boolean);  
     
          setOrders(updatedOrders);
     
          localStorage.setItem("orders", JSON.stringify(updatedOrders));
        }
      };
 
  return (
    modalVisible && (
      <div
        className={`modal fade modal-open show ${modalVisible ? "d-block modal-open" : ""}`}
        id="staticBackdrop"
        style={{
          display: "block",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}  
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
 
        <div className="modal-dialog modal-dialog-centered" >
          <div className="modal-content">
            <div className="modal-body p-0">
              <div className="d-block w-100 bg-white rounded-3 pb-3 pt-3">
                <div className="d-flex w-100 align-items-center justify-content-between px-3">
                  <div className="d-inline-block">
                    <span className="text-white px-2 py-2 rounded-3 text-14 bg-danger">
                    {selectedTickets.length > 0 ? selectedTickets[0].cateName : ''}
                    </span>
                  </div>
                  <div className="d-inline-block">
                    <button
                      data-bs-dismiss="modal"
                      className="border-0 bg-transparent text-black text-18"
                      onClick={handleClose}
                    >
                      <i className="fa fa-times"></i>
                    </button>
                  </div>
                </div>
            
                  <div className="d-block w-100 py-3 px-3">
                    <div className="d-flex align-items-center justify-content-start mb-3">
                      <div className="d-inline-block">
                        <h6 className="poppins-600 m-0 d-flex align-items-center justify-content-start text-20 text-black">
                          <img className="me-2 rounded" 
                          src={selectedTickets.length > 0 ? selectedTickets[0].flag : 'images/flag-1.png'}
                          height="20px"
                          id="FlagImages"
                           alt="flag" 
                           />
                         
                          {selectedTickets.length > 0 ? selectedTickets[0].eventName : ''}
                        </h6>
                      </div>
                    </div>
                    {selectedTickets.map(ticket => (
                      <>
                    <div className="d-flex align-items-center justify-content-start mb-3" key={ticket.id} >
                      <h6 className="poppins-600 m-0 d-flex align-items-center justify-content-start text-18 text-uppercase text-black">
                        TICKET TYPE: {ticket.name.toUpperCase()}
                      </h6>
                    </div>
                    <div className="d-flex align-items-center justify-content-start mb-2">
                      <h6 className="poppins-600 m-0 d-flex align-items-center justify-content-start text-15 text-black">
                        Description
                      </h6>
                    </div>
                    <div className="d-flex w-100 align-items-center justify-content-start">
                      
                      <span className="text-muted text-12">
                        This ticket grants you access to all areas marked general
                      </span>
                    </div>
                    <div className="d-block w-100 text-end border-bottom border-color-silver pb-3 mb-3">
                      <h5 className="text-18 poppins-600 m-0 p-0"> ${ticket.price.toFixed(2)}</h5>
                    </div>
                    <div className="d-flex w-100 align-items-center justify-content-between">
                      <div className="d-inline-block">
                        <span className="text-black d-inline-block poppins-600 text-16">
                          Quantity
                        </span>
                      </div>
                      <div className="quantity-container d-inline-block border rounded-4 border-color-silver overflow-hidden">
                        <button
                          data-decrease=""
                          className="text-black bg-transparent d-inline-block py-1 px-2 text-18 poppins-500 border-0"
                          onClick={() =>
                            handleDecrementQuantity(ticket)
                          }
                        >
                          -
                        </button>
                        <span className="input-number border-0 text-center outline-0 text-black bg-transparent d-inline-block p-1 text-16 poppins-500">{ticket.quantity}</span>
                         
                        <button
                          data-increase=""
                          className="border-0 text-black bg-transparent d-inline-block p-1 text-18 poppins-500"
                          onClick={() =>
                            handleIncrementQuantity(
                              ticket,
                              ticket.ticket_per_order
                            )
                          }
                        >
                          +
                        </button>
                      </div>
                    </div>
                    <div className="d-flex w-100 align-items-center justify-content-between mt-3">
                      <div className="d-inline-block">
                        <span className="text-black d-inline-block poppins-600 text-18">
                          Total
                        </span>
                      </div>
                      <div className="d-inline-block">
                        <h5 className="text-18 poppins-700 m-0 p-0"> ${ticket.selected_price.toFixed(2)}</h5>
                      </div>
                    </div>
                    </>
                  ))}
                    <div className="d-flex w-100 align-items-center justify-content-between mt-3">
                      <button className="custom-btn8 w-100 me-1 lh-lg"      onClick={openModal}>
                        Add to Cart
                      </button>
                      <button className="custom-btn9 w-100 ms-1 lh-lg"  onClick={handleContinue_checkout}>Checkout</button>
                    </div>
                  </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default TicketModelComponent;
