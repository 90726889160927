import React, { useState, useEffect, useContext } from "react";
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';
// import Footerpage from '../../pages/Footerpages/footerpage.js';
import HeaderComponent from '../HeaderComponent/HeaderComponent.js';
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { fetchUserData, editUserProfile } from "../../services/profileService";
import FooterComponent from '../FooterComponent/FooterComponent.js';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { getCountryCallingCode } from 'react-phone-number-input/input';
import Swal from "sweetalert2";
import { ProfileContext } from '../../hooks/ProfileContext';
const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  phone: Yup.string().required('Phone number is required'),
   
});

const EditUserProfileComponent = () => {
  const { handleSubmit, control, setValue, register, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState({
    id: "",
    phone: "",
    bio: "",
    email: "",
    image: "",
    imagePath: "",
    country: "",
  });
  const { setImagePath } = useContext(ProfileContext);
  const [Countries, setCountries] = useState([]);
  const [defaultImagePath, setDefaultImagePath] = useState("");
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState('+1');
  const [buttonloading, setbuttonloading] = useState(false);
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const handleCountryChange = (country) => {
    if (country) {
      const countryCallingCode = getCountryCallingCode(country);
      setCountryCode('+' + countryCallingCode);
    } else {
      setCountryCode('');
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    document.body.classList.add("header-bgms");

    return () => {
      document.body.classList.remove("header-bgms");
    };
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); 
    }, 2000);

    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    setValue('bio', userData.bio || '');
    setValue('email', userData.email || '');
    setValue('phone', userData.phone || '');
    setValue('country', userData.location || '');
    setValue('image', userData.image || '/frontend/images/notlogin.png');
  }, [userData, setValue]);

   const fetchData = async () => {
    try {
       
      const token = localStorage.getItem("authToken");
      const data = await fetchUserData(token);
      setCountries(data.countries);
      setUserData(data.user);
      if (data.user.image) {
        const fullImagePath = data.user.imagePath + data.user.image;
     
        setImagePath(fullImagePath);
      } else {
        setImagePath('/images/notlogin.png');
      }
      setDefaultImagePath(data.user.imagePath + data.user.image);
    } catch (error) {

    }
   
  };

  useEffect(() => {
    fetchData();
  }, []);
  const dataURLtoBlob = (dataURL) => {
    const parts = dataURL.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
  };
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setSelectedImage(reader.result);  
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const onSubmit = (data) => {
    const token = localStorage.getItem("authToken");
    const formData = new FormData();
  
    formData.append('bio', data.bio);
    formData.append('email', data.email);
    formData.append('phone', data.phone);
    formData.append('country', data.country);
  
    
    if (selectedImage) {
      const blob = dataURLtoBlob(selectedImage);
      formData.append('image', blob, 'image.jpg');
    }
  
     
    if (!selectedImage && data.image) {
      formData.append('image', data.image[0]);
    } else {
      formData.append('image', userData.image);
    }
  
    setbuttonloading(true);
    editUserProfile(token, formData)
      .then(() => {
        setbuttonloading(false);
        Swal.fire({
          title: 'Success',
          text: 'Profile updated successfully!',
          icon: 'success',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/user/profile');
          }
        });
      })
      .catch((error) => {
        setError(error.message);
        setbuttonloading(false);
      });
  };
 
  return (
    <div>
      <Helmet>
        <link href="/css/bootstrap.min.css" rel="stylesheet" />
      </Helmet>
      {loading ? (
        <section className="pt-lg-5 pt-md-5 pt-3" style={{ background: '#f9fcff' }}>
          <div className="container3">
            <div className="block-element m-b-30">
            </div>
            <div className="block-element loader-mobile-screen" style={{ marginTop: '80px' }}>
              <LoadingSpinner />
            </div></div>
        </section>
      ) : (
        <>
          <HeaderComponent />
          <section className="pad-top-60 pad-bot-60" style={{ backgroundColor: "#f9fcff" }}>
            <div className="container profile-page">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row profile-container">

                  <div className="col-md-4 mb-4">
                    <div className="profile-card profile-pic py-5">
                      <div className="upload-container">
                      <img id="preview" src={selectedImage || (userData.imagePath + userData.image) || '/images/notlogin.png'} alt="Image Preview" />
                      <input type="file" id="fileInput" {...register('image')} onChange={handleFileInputChange} accept=".jpeg,.jpg,.png,.gif" />
                        <label htmlFor="fileInput">Update photo</label>
                        <small className="text-muted mt-2 text-center">Allowed *.jpeg, *.jpg, *.png, *.gif<br />Max size of 3.1 MB</small>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="profile-card profile-details">
                      <div>
                        <div className="row">
                          <div className="col-md-12 mb-2">
                            <div className="form-group">
                              <label htmlFor="bio" className="poppins-400">Bio</label>
                              <Controller
                                name="bio"
                                control={control}
                                defaultValue={userData.bio}
                                render={({ field }) => (
                                  <textarea className="form-control poppins-400" id="bio" {...field} rows={3} placeholder="Bio" defaultValue={""} />

                                )}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">

                          <div className="col-md-6 mb-2">
                            <div className="form-group">
                              <label htmlFor="emailAddress" className="poppins-400">Phone Number</label>
                              <Controller
                              name="phone"
                              control={control}
                              render={({ field }) => (
                                <PhoneInput
                                  {...field}
                                  className="field-style1 no-arrows-number"
                                  international
                                  countryCallingCodeEditable={false}
                                  defaultCountry="US"
                                  value={field.value}
                                  onChange={field.onChange}
                                />
                              )}
                            />

                            </div>
                          </div>

                          <div className="col-md-6 mb-2">
                            <div className="form-group">
                              <label htmlFor="emailAddress" className="poppins-400">Email address</label>
                              <Controller
                                name="email"
                                control={control}
                                defaultValue={userData.email}
                                render={({ field }) => (
                                  <input type="email" className="form-control poppins-400" id="emailAddress" placeholder="Email"    {...field}
                                  />
                                )}
                              />
                            </div>

                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 mb-2">
                            <div className="form-group">
                              <label htmlFor="country" className="poppins-400">Country</label>

                              <Controller
                                name="country"
                                control={control}
                                render={({ field }) => (
                                  <select className="form-control poppins-400" id="country" {...field}>
                                    <option>select country</option>
                                    {Countries.map(country => (
                                      <option key={country.id} value={country.id}>
                                        {country.country}
                                      </option>
                                    ))}
                                  </select>
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="btn-container desktop-only">
                          <button type="submit" className="btn btn-primary mr-2 poppins-600 w-100" disabled={buttonloading}>{buttonloading ? 'Updating...' : 'Save changes'}</button>


                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="mobile-only w-100 mt-4">
                    <div className="px-3 btn-container">
                      <button type="submit" className="btn btn-primary mr-2 poppins-600 w-100" disabled={buttonloading}>{buttonloading ? 'Updating...' : 'Save changes'}</button>
                    </div>
                  </div>


                </div>
              </form>
            </div>
          </section>
          <FooterComponent />
        </>
      )}
    </div>
  );
}

export default EditUserProfileComponent;
