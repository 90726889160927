import React, { useEffect, useState, useRef } from "react";
import "../../App.css";
import { Link, useParams } from "react-router-dom";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { fetchBlogDetail } from '../../services/blogservice';
import { Helmet } from "react-helmet";
import HeaderComponent from '../HeaderComponent/HeaderComponent.js';
import FooterComponent from '../FooterComponent/FooterComponent.js';


function BLogDetailComponent({ idOrTitle }) {
  const [blogData, setBlogData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { id, title, description } = useParams();
  const [pageLoading, setPageLoading] = useState(false);
  const isLoadingRef = useRef(false); 

  
  useEffect(() => {
    if (!isLoadingRef.current) {
      isLoadingRef.current = true;
      fetchBlogDetail(id, title, setBlogData, (isLoading) => {
        setIsLoading(isLoading);
        isLoadingRef.current = false;
      });
    }
  }, [id, title, setBlogData, setIsLoading]);

  
  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",  
  //   });
  //   document.body.classList.add("home-bg");

 
  //   return () => {
  //     document.body.classList.remove("home-bg");
  //   };
  // }, []);

  if (isLoading) {
    return (
      <section className="pad-top-20 pad-bot-80" id="loaderSection">
          <div className="container3">
            <div className="block-element m-b-30">
            </div>
            <div className="block-element loader-mobile-screen" style={{ marginTop: '80px' }}>
        <LoadingSpinner />
        </div></div>
        </section>
    );
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = date.toLocaleString('en-US', { month: 'short' });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };
  
  if (!blogData) {
    return <div>Blog not found</div>;
  }
 
  return (
  
    <>
    <Helmet>
    <link href="/css/bootstrap.min.css" rel="stylesheet" />
   </Helmet>
      <HeaderComponent/>
      <section className="pad-top-60 pad-bot-60">
        <div className="container2">
          <div className="block-element m-b-20">
            <Link to="/all-blogs" className="back-btn1">
              <i className="fa fa-angle-left"> </i> Back
            </Link>
          </div>
          <div className="block-element m-b-20">
            <div className="custom-image1" >
              
              <img className="rounded mx-auto d-block" id="BLOGBANNER" src={blogData.imagePath + blogData.image} alt={blogData.image}/>
            </div>
          </div>
          <div className="block-element m-b-30">
            <div className="event-detail">
              <span className="archive-text1 col-pink1">Category:</span>
              <span
                className="date-text1 col-black1"
                style={{ marginLeft: 10 }}
              >
                {blogData.category.name}
              </span>
              <span
                className="archive-text1 col-pink1"
                style={{ marginLeft: 10 }}
              >
                Date:
              </span>
              <span
                className="date-text1 col-black1"
                style={{ marginLeft: 17 }}
              >
               {formatDate(blogData.created_at)}
              </span>
            </div>
          </div>
          <div className="block-element m-b-10">
            <div className="textual-sec1">
              <h2 className="col-black">{blogData.title}</h2>
              <div className="text-black" dangerouslySetInnerHTML={{ __html: blogData.description }} />

            </div>
          </div>
          <div className="block-element">
            <div className="event-detail-actions">
              {/* <Link to="" className="custom-btn2">
                ariana grande
              </Link> */}
            </div>
          </div>
        </div>
      </section>
      <FooterComponent/>
    </>
  );
}

export default BLogDetailComponent;
