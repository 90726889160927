import React, {useEffect, useState,useRef} from "react";
import { Link } from "react-router-dom";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {fetch_Page } from "../../services/homeService";
function FooterComponent() {

  const [isMobile, setIsMobile] = useState(false);
  const stickyDivRef = useRef(null);
  const wrapperRef = useRef(null);
 
  const handleScroll = () => {
    const stickyDiv = stickyDivRef.current;
    const wrapper = wrapperRef.current;
  
    if (stickyDiv && wrapper) {
      const wrapperRect = wrapper.getBoundingClientRect();
      const stickyHeight = stickyDiv.offsetHeight;
  
      // Check if the top of the wrapper is above or at the top of the viewport
      if (wrapperRect.top <= 0) {
        stickyDiv.classList.add('in-view');
        wrapper.classList.add('in-view-wrapper');
      } else {
        stickyDiv.classList.remove('in-view');
        wrapper.classList.remove('in-view-wrapper');
      }
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('scroll', handleScroll);

    handleResize();
    window.addEventListener("resize", handleResize);

    return () =>  {
      window.removeEventListener("resize", handleResize); 
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Jamaica
    </Tooltip>
  );
  const renderTooltipUsa = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      United States
    </Tooltip>
  );
 
    const [pages, setPages] = useState([]);
  useEffect(() => {
    

    const fetchPages = async () => {
       
      try {
         
        const data = await fetch_Page();
      
        setPages(data.pages);
   
      } catch (error) {
        console.error("Error fetching home data:", error);
      } finally {
        
      }
    };

    fetchPages();

    
  }, []);
  return (
    <>
    {isMobile ? 
    <> 
      <footer className="bg-color-101828" style={{marginTop: "6rem"}}>
  <div className="d-block w-100 text-lg-start text-md-start text-sm-start text-center py-4">
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="d-lg-none d-md-none d-sm-none d-block">
            <div className="d-block w-100 text-center mb-2">
              <img
                src="https://tikkets.com/images/logo-white.png"
                height="40px"
                className="w-auto"
              />
            </div>
            <div className="d-block w-100 text-center mb-4">
              <p className="text-white text-14 m-0 fw-normal text-center">
                
                Tickets for every event 
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-8 col-md-8 col-sm-12 col-12">
          <div className="d-block mb-4 text-center ddddddddd">
            <h5 className="m-0 text-muted text-14 text-uppercase text-center">
              
              By the way 
            </h5>
          </div>
          <div className="d-block w-100 mb-4 text-center">
            <h3 className="text-white text-32 mob-text-22 fw-normal  m-0 text-center">
              
              Subscribe for our <br /> newsletters 
            </h3>
          </div>
          <div className="d-block w-100 mb-4">
            <form className="subscribe-footer-form border-color-silver d-flex border  rounded-6 align-items-center justify-content-start">
              <input
                type="email"
                placeholder="Email"
                name=""
                className="w-100 px-3 py-2 text-white text-15 bg-transparent border-0 emailinput"
              />
              <button className="bg-transparent border-0 shadow-none pe-3">
                <i className="fa fa-arrow-right text-15 text-white"> </i>
              </button>
            </form>
          </div>
          <div className="d-lg-none d-md-none d-sm-none d-block mb-4">
            <div className="d-block w-100 text-lg-center text-md-center text-sm-center text-center mb-3 text-center">
              <img src="/images/kanoo-logo-red.png" />
            </div>
            <div className="d-block w-100 text-lg-center text-md-center text-sm-center text-center mb-3 text-center">
              <img src="/images/payment-cards.png" />
            </div>
            <div className="d-block w-100 text-lg-center text-md-center text-sm-center text-center text-center">
             
              <span className="text-white fw-light  text-13 d-inline-block align-text-top">
              <i className="bg-success rounded-circle fa fa-check text-white text-13 p-1 payment_icon"> </i>
                Payment Secured 
              </span>
            </div>
          </div>
          <div className="d-lg-block d-md-block d-sm-block d-none w-100 mb-2 text-center">
            <img
              src="https://tikkets.com/images/logo-white.png"
              height="40px"
              className="w-auto"
            />
          </div>
          <div className="d-lg-block d-md-block d-sm-block d-none w-100 mb-4 text-center">
            <p className="text-white text-14 m-0 fw-normal text-center">
             
              Tickets for every event 
            </p>
          </div>
          <div className="d-block w-100 mb-2 text-center">
            <p className="text-white text-14 m-0 fw-normal text-uppercase text-center">
              
              FOLLOW US 
            </p>
          </div>
          <div className="d-flex w-100 align-items-center justify-content-lg-start justify-content-md-start justify-content-sm-center justify-content-center">
            <a href="" className="me-3">
              <img src="/images/icon-fb.svg" height="18" />
            </a>
            <a href="" className="me-3">
              <img src="/images/icon-insta.svg" height="18px" />
            </a>
            <a href="" className="me-3">
              <img src="/images/icon-x.svg" height="18px" />
            </a>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 col-12 mt-lg-0 mt-md-0 mt-sm-0 mt-5">
          <div className="row mb-lg-5 mb-md-5 mb-sm-5 mb-0">
            <div className="col-lg-7 col-md-7 col-sm-7 col-12">
              <div className="d-block mb-4 text-center">
                <h5 className="m-0 text-muted text-14 text-uppercase text-center">
                   
                  More 
                </h5>
              </div>
              <div className="d-block w-100">
                <ul className="list-style-none p-0 m-0 text-center">


                <li>
                  <Link to="/" className="text-color-e7e7e7 text-14 fw-normal">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/faq"className="text-color-e7e7e7 text-14 fw-normal">
                    Faq
                  </Link>
                </li>
                <li>
                  <Link to="/Contact" className="text-color-e7e7e7 text-14 fw-normal">
                  Contact
                  </Link>
                </li>
                {pages.length > 0 && (
                  pages.map((page) => (
                    <li key={page.id}>
                      <Link className="text-color-e7e7e7 text-14 fw-normal" to={`/page/${page.slug}`}>
                        {page.title}
                      </Link>
                    </li>
                  ))
                )}
 
                </ul>
              </div>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-5 col-12 mt-lg-0 mt-md-0 mt-sm-0 mt-3">
              <div className="d-lg-block d-md-block d-sm-block d-none">
                <div className="d-block w-100 text-lg-center text-md-center text-sm-center text-center mb-3">
                  <img src="/images/kanoo-logo-red.png" />
                </div>
                <div className="d-block w-100 text-lg-center text-md-center text-sm-center text-center mb-3">   
                  <img src="/images/payment-cards.png" />
                </div>
                <div className="d-block w-100 text-lg-center text-md-center text-sm-center text-center">
                  
                  <span className="text-white fw-light   text-13 d-inline-block align-text-top">
                  <i className="bg-success rounded-circle fa fa-check text-white text-13 p-1 payment_icon">  </i>
                    Payment Secured 
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="d-block mb-4">
                <h5 className="m-0 text-muted text-14 text-uppercase">
                  {" "}
                  Contact Us{" "}
                </h5>
              </div>
              <div className="d-block w-100">
                <ul className="list-style-none p-0 m-0">
                  <li>
                    <a href="" className="text-color-e7e7e7 text-14 fw-normal">
                      {" "}
                      +1 (001) 981-76-17{" "}
                    </a>
                  </li>
                  <li>
                    <a href="" className="text-color-e7e7e7 text-14 fw-normal">
                      {" "}
                      info@tikkets.com{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="d-block">
                <h5 className="m-0 text-muted text-13 fw-light">
                  {" "}
                  Tikkets.com by Kanoo{" "}
                </h5>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-lg-0 mt-md-0 mt-sm-0 mt-2">
              <div className="d-block">
                <h5 className="m-0 text-muted text-13 fw-light">
                  {" "}
                  © 2024 All rights reserved{" "}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

    </> : 
    <>
      <footer className="bg-color-101828"  style={{marginTop: "12rem"}}>
  <div className="d-block w-100 py-4">
    <div className="container">
      <div className="row">
        <div className="col-lg-8 col-md-8 col-sm-12 col-12">
          <div className="d-block mb-4">
            <h5 className="m-0 text-muted text-14 text-uppercase text-start">
              By the way
            </h5>
          </div>
          <div className="d-block w-100 mb-4">
            <h3 className="text-white text-32 mob-text-26 fw-normal m-0 text-start">
              Subscribe for our <br />
              newsletters
            </h3>
          </div>
          <div className="d-block w-100 mb-4">
            <form className="subscribe-footer-form border-color-silver d-flex border rounded-6 align-items-center justify-content-start">
              <input
                type="email"
                placeholder="Email"
                name=""
                className="w-100 px-3 py-2 text-white text-15 bg-transparent border-0 emailinput"
              />
              <button className="bg-transparent border-0 shadow-none pe-3">
                <i className="fa fa-arrow-right text-15 text-white"> </i>
              </button>
            </form>
          </div>
          <div className="d-block w-100 mb-2 text-start">
            <img
              src="https://tikkets.com/images/logo-white.png"
              height="40px"
              className="w-auto text-start"
            />
          </div>
          <div className="d-block w-100 mb-4 text-start">
            <p className="text-white text-14 m-0 fw-normal">
              Tickets for every event
            </p>
          </div>
          <div className="d-block w-100 mb-2 text-start">
            <p className="text-white text-14 m-0 fw-normal text-uppercase">
              FOLLOW US
            </p>
          </div>
          <div className="d-flex w-100 align-items-center justify-content-start">
            <a href="" className="me-3">
              <img src="/images/icon-fb.svg" height="18px" />
            </a>
            <a href="" className="me-3">
              <img src="/images/icon-insta.svg" height="18px" />
            </a>
            <a href="" className="me-3">
              <img src="/images/icon-x.svg" height="18px" />
            </a>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 col-12 mt-lg-0 mt-md-0 mt-sm-0 mt-5">
          <div className="row mb-5">
            <div className="col-lg-7 col-md-7 col-sm-7 col-12 text-start">
              <div className="d-block mb-4">
                <h5 className="m-0 text-muted text-14 text-uppercase">More</h5>
              </div>
              <div className="d-block w-100">
              <ul className="list-style-none p-0 m-0">
                <li>
                  <Link to="/" className="text-color-e7e7e7 text-14 fw-normal">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/faq"className="text-color-e7e7e7 text-14 fw-normal">
                    Faq
                  </Link>
                </li>
                <li>
                  <Link to="/Contact" className="text-color-e7e7e7 text-14 fw-normal">
                  Contact
                  </Link>
                </li>
                {pages.length > 0 && (
                  pages.map((page) => (
                    <li key={page.id}>
                      <Link className="text-color-e7e7e7 text-14 fw-normal" to={`/page/${page.slug}`}>
                        {page.title}
                      </Link>
                    </li>
                  ))
                )}
              </ul>

              </div>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-5 col-12 mt-lg-0 mt-md-0 mt-sm-0 mt-5">
              <div className="d-block w-100 text-lg-center text-md-center text-sm-center text-start mb-3">
                <img src="/images/kanoo-logo-red.png" />
              </div>
              <div className="d-block w-100 text-lg-center text-md-center text-sm-center text-start mb-3">
                <img src="/images/payment-cards.png" />
              </div>
              <div className="d-block w-100 text-lg-center text-md-center text-sm-center text-start">
                
                <span className="text-white fw-light   text-13 d-inline-block align-text-top">
                <i className="bg-success rounded-circle fa fa-check text-white text-13 p-1 payment_icon"></i>
                  Payment Secured
                </span>
              </div>
            </div>
          </div>
          <div className="row mb-4  ">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-start">
              <div className="d-block mb-4">
                <h5 className="m-0 text-muted text-14 text-uppercase">
                  Contact Us
                </h5>
              </div>
              <div className="d-block w-100">
                <ul className="list-style-none p-0 m-0">
                  <li>
                    <a href="" className="text-color-e7e7e7 text-14 fw-normal">
                      +1 (001) 981-76-17
                    </a>
                  </li>
                  <li>
                    <a href="" className="text-color-e7e7e7 text-14 fw-normal">
                      info@tikkets.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row mb-2 text-start ">
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="d-block">
                <h5 className="m-0 text-muted text-13 fw-light">
                  Tikkets.com by Kanoo
                </h5>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-lg-0 mt-md-0 mt-sm-0 mt-2">
              <div className="d-block">
                <h5 className="m-0 text-muted text-13 fw-light">
                  © 2024 All rights reserved
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer></>}
     

    </>
  );
}

export default FooterComponent;
