import React, { createContext, useContext, useState, useEffect } from 'react';

// Create a context
const OrdersContext = createContext();

// Create a provider component
export const OrdersProvider = ({ children }) => {
  const [ordersCount, setOrdersCount] = useState(() => {
    // Initialize orders count from localStorage, or default to 0 if not found
    const storedCount = localStorage.getItem('ordersCount');
    return storedCount ? parseInt(storedCount, 10) : 0;
  });

  // Function to update orders count
  const updateOrdersCount = (count) => {
    setOrdersCount(count);
  };

  useEffect(() => {
    // Store the updated orders count in localStorage
    localStorage.setItem('ordersCount', ordersCount);
  }, [ordersCount]);

  return (
    <OrdersContext.Provider value={{ ordersCount, updateOrdersCount }}>
      {children}
    </OrdersContext.Provider>
  );
};

// Custom hook to use orders context
export const useOrders = () => useContext(OrdersContext);
