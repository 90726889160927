import React, { useState, useEffect,useContext } from "react";
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Helmet } from "react-helmet";
// import Footerpage from '../../pages/Footerpages/footerpage.js';
import HeaderComponent from '../HeaderComponent/HeaderComponent.js';
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { fetchUserData, editUserProfile } from "../../services/profileService";
import FooterComponent from '../FooterComponent/FooterComponent.js';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { getCountryCallingCode } from 'react-phone-number-input/input';
import Swal from "sweetalert2";
import { ProfileContext } from '../../hooks/ProfileContext';
const validationSchema = Yup.object().shape({
  // bio: Yup.string().required('Bio is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  phone: Yup.string().required('Phone number is required'),
  // country: Yup.string().required('Country is required'),
  // image: Yup.mixed().test("fileSize", "File too large", (value) => {
  //   if (!value.length) return true; // Allow empty file input
  //   return value[0].size <= 1024 * 1024; // Max file size: 1MB
  // }).test("fileType", "Unsupported file type", (value) => {
  //   if (!value.length) return true; // Allow empty file input
  //   return ['image/jpeg', 'image/png'].includes(value[0].type); // Supported file types: JPEG, PNG
  // }),
});
 
const UserProfileComponent = () => {
  const { handleSubmit, control, setValue, register, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const defaultCountry = 'US';
  const [userData, setUserData] = useState({
    id: "",
    phone: "",
    bio: "",
    email: "",
    image: "",
    imagePath: "",
    country: "",
  });
  const { setImagePath } = useContext(ProfileContext);
  const [Countries, setCountries] = useState([]);
  const [defaultImagePath, setDefaultImagePath] = useState("");
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState('+1');
  const [buttonloading, setbuttonloading] = useState(false);
  const handleCountryChange = (country) => {
    if (country) {
      const countryCallingCode = getCountryCallingCode(country);
      setCountryCode('+' + countryCallingCode);
    } else {
      setCountryCode('');
    }
  };
  const fetchData = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const data = await fetchUserData(token);

    

      setCountries(data.countries);
      setUserData(data.user);
      if (data.user.image) {
        const fullImagePath = data.user.imagePath + data.user.image;
        setImagePath(fullImagePath);
      } else {
        setImagePath('/images/notlogin.png');
      }
      setDefaultImagePath(data.user.imagePath + data.user.image); // Set the default image path
    } catch (error) {
     
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    document.body.classList.add("header-bgms");

    return () => {
      document.body.classList.remove("header-bgms");
    };
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); // Change loading state to false after 2 seconds
    }, 2000); // 2 seconds delay

    return () => clearTimeout(timer); // Clear the timer if the component unmounts
  }, []);
  useEffect(() => {
    setValue('bio', userData.bio || '');
    setValue('email', userData.email || '');
    setValue('phone', userData.phone || '');
    setValue('country', userData.location || '');
    setValue('image', userData.image || '/frontend/images/notlogin.png');
  }, [userData, setValue]);

  const onSubmit = (data) => {
    const token = localStorage.getItem("authToken");
    const formData = new FormData();

    formData.append('bio', data.bio);
    formData.append('email', data.email);
    formData.append('phone', data.phone);
    formData.append('country', data.country);

    if (data.image.length > 0) {
      formData.append('image', data.image[0]);
    } else {
      formData.append('image', userData.image);
    }
    setbuttonloading(true);
    editUserProfile(token, formData)
      .then(() => {
        fetchData();  
         
        setbuttonloading(false);
        Swal.fire({
          title: 'Success',
          text: 'Profile updated successfully!',
          icon: 'success',
          confirmButtonText: 'OK'
      });
      })
      .catch((error) => {
        setError(error.message);
        setbuttonloading(false);
      });
  };

  return (
    <div>
      <Helmet>
        <link href="/css/bootstrap.min.css" rel="stylesheet" />
      </Helmet>
      {loading ? (
        <section className="pt-lg-5 pt-md-5 pt-3" style={{ background: '#f9fcff' }}>
          <div className="container3">
            <div className="block-element m-b-30">
            </div>
            <div className="block-element loader-mobile-screen" style={{ marginTop: '80px' }}>
              <LoadingSpinner />
            </div></div>
        </section>
      ) : (
        <>
          <HeaderComponent />
          <section className="pad-top-60 pad-bot-60" style={{ backgroundColor: "#f9fcff" }}>
            <div className="container">
              <div className="row">
                <div className="col-md-4 col-lg-4 col-sm-12 col-12">
                  <div className="edit-profile-wrapper">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="block-element2 text-right m-b-20"></div>
                      <div className="profile-image-details m-b-30 text-center">
                      <img 
                        src={userData.image ? (userData.imagePath + userData.image) : '/images/notlogin.png'} 
                        style={{ width: 38, height: 35, objectFit: "cover" }} 
                        alt="profile" 
                      />
                        <h4 className="col-black">{userData.name} {userData.last_name}</h4>
                        <span className="mt-2"> ID #{userData.user_id} </span>
                      </div>
                      <div className="profile-image-details m-b-30 text-center">
                        <input type="file" {...register('image')} />
                        {errors.image && <p>{errors.image.message}</p>}
                      </div>
                      <div className="form-field4 m-b-25">
                        <label>Bio </label>
                        <Controller
                          name="bio"
                          control={control}
                          defaultValue={userData.bio}
                          render={({ field }) => (
                            <textarea
                              className="field-style4"
                              placeholder="Add your bio"
                              {...field}
                            />
                          )}
                        />
                        {errors.bio && <p>{errors.bio.message}</p>}
                      </div>
                      <div className="form-field4 m-b-25">
                        <label>Email Address </label>
                        <Controller
                          name="email"
                          control={control}
                          defaultValue={userData.email}
                          render={({ field }) => (
                            <input
                              type="email"
                              placeholder="Enter email"
                              className="field-style4"
                              {...field}
                            />
                          )}
                        />
                        {errors.email && <p>{errors.email.message}</p>}
                      </div>
                      <div className="form-field4 m-b-25">
                        <label>Phone number</label>
                        <Controller
                          name="phone"
                          control={control}
                          render={({ field }) => (
                            <PhoneInput
                              className="field-style4"
                              placeholder="Enter Phone"
                              value={userData.phone || (countryCode + phoneNumber)} // Use userData.phone if countryCode + phoneNumber is null
                              defaultValue={userData.phone}
                              country={defaultCountry}
                              defaultCountry={defaultCountry}
                              onChange={(value) => {
                                if (value) {
                                  const phoneWithoutCountryCode = value.slice(countryCode.length);
                                  setPhoneNumber(phoneWithoutCountryCode);
                                  field.onChange(phoneWithoutCountryCode);
                                  setValue("phone", value);
                                }
                              }}
                              onCountryChange={handleCountryChange}
                            />
                          )}
                        />
                        <span className="text-red">{errors.phone && <p className="text-red"  >{errors.phone.message}</p>}</span>
                      </div>


                      <div className="form-field4 m-b-40">
                        <label>Location </label>
                        <Controller
                          name="country"
                          control={control}
                          render={({ field }) => (
                            <select id="country" className="field-style4" {...field}>
                              <option>select country</option>
                              {Countries.map(country => (
                                <option key={country.id} value={country.id}>
                                  {country.country}
                                </option>
                              ))}
                            </select>
                          )}
                        />
                        {errors.country && <p>{errors.country.message}</p>}
                      </div>
                      <div className="block-element2">
                        {/* <input
                          type="submit"
                          value="Save changes"
                          className="custom-btn6 block-element2"
                        /> */}
                        <button type="submit" className="custom-btn6 block-element2 text-white" disabled={buttonloading}>{buttonloading ? 'Updating...' : 'Save changes'}</button>
                           
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-md-8 col-lg-8 col-sm-12 col-12">
                  <div className="block-element content-block6">
                    <div className="block-element tabs-handler1 m-b-30">
                      <ul className="nav nav-tabs" role="tablist">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            data-toggle="tab"
                            href="#tabs-2"
                            role="tab"
                          >
                            <img src="" />
                            Favourites
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#tabs-1"
                            role="tab"
                          >
                            <img src="" />
                            Following
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="block-element">
                      <div className="tabs-content1">
                        <div className="tab-content">
                          <div className="tab-pane" id="tabs-1" role="tabpanel">
                            <div className="block-element">
                              <div className="profile-accounts-holder">
                                <p className="text-dark text-center">
                                  No Following Found.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane active"
                            id="tabs-2"
                            role="tabpanel"
                          >
                            <div className="block-element">
                              <div className="profile-accounts-holder">
                                <p className="text-dark text-center">
                                  No Favourite Found.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <FooterComponent />
        </>
      )}
    </div>
  );
}

export default UserProfileComponent;
