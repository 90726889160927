import React, { useEffect, useState, useRef, useContext } from "react";
import HeaderComponent from "../HeaderComponent/HeaderComponent.js";
import FooterComponent from "../FooterComponent/FooterComponent.js";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { fetchHomeData, fetchHomeDataReset } from "../../services/homeService";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { fetchEventData } from "../../services/EventTikketService";
import Swal from "sweetalert2";
import { useOrders } from "../../hooks/SettingContext";
import { Helmet } from "react-helmet";
import { ProfileContext } from "../../hooks/ProfileContext";
import { fetchUserData } from "../../services/profileService";
import EventComponent from "./EventComponent";
import TicketModelComponent from "./TicketModelComponent";
import CategoryFilterComponent from "./CategoryFilterComponent";
import PriceFilterComponent from "./PriceFilterComponent";
import CountriesList from "./CountriesList";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';

import Vanta from './Vanta';



  
function NewHomeComponent() {
 
  const [events, setEvents] = useState([]);
  const [blogs, setBlogs] = useState([]);
 
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isSearchCompleted, setIsSearchCompleted] = useState(false);

  const navigate = useNavigate();

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState("");
  const [selectedCountry, setSelectedCountry] = useState([]);

  const [Country, setCountry] = useState([]);
  const [from, setfrom] = useState("");
  const [to, setTo] = useState("");

  const [totalRecords, setTotal] = useState("");
  const [availableTickets, setAvailableTickets] = useState([]);
  const [evtData, setEvtData] = useState(null);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [prevPageUrl, setPrevPageUrl] = useState(null);
  const [itemsPerPage, setitemsPerPage] = useState(5);
  const [orders, setOrders] = useState([]);
  const [selectedcounties, setselectedcounties] = useState([]);
  const [countriesCount, setcountriesCount] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [serviceFee, setServiceFee] = useState(0);
  const [tickets, setTickets] = useState([]);
  const [countryOptions, setcountryOptions] = useState([]);
  const [allCategories, setAllcategories] = useState([]);
  const [event_type, setType] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const { ordersCount, updateOrdersCount } = useOrders();
  const [ShowModal, setshowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isTicketVisible, setIsTicketVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const { setImagePath } = useContext(ProfileContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedTickets, setSelectedTickets] = useState([]);
  const [SearchCountry, setSearch] = useState(0);
  const [minPrice, setMinPrice] = useState(1);
  const [maxPrice, setMaxPrice] = useState(1000);
  const [selectedCountryValues, setSelectedCountryValues] = useState([0]);

  const handleTicketSelection = (ticket, event) => {
    const ticketWithEventInfo = {
      ...ticket,
      eventName: event.name,
      image: event.imagePath + event.image,
      user_id: event.user_id,
      flag: event.country_flag,
      event_id: event.id,
      ticket_per_order: ticket.ticket_per_order,
      cateName: event.cate_name,
      quantity: 0,
      selected_price: 0,
    };

    setSelectedTickets((prevSelectedTickets) => {
      let updatedTickets;
      if (prevSelectedTickets.some((t) => t.id === ticket.id)) {
        updatedTickets = prevSelectedTickets.filter((t) => t.id !== ticket.id);
      } else {
        updatedTickets = [...prevSelectedTickets, ticketWithEventInfo];
      }

      // setTimeout(() => {
      //   console.log('setTimeout Run');
      //   console.log(updatedTickets);

      // }, 1000);

      if (updatedTickets.length > 0) {
        handleAddToCart(event.id, updatedTickets);
      }

      return updatedTickets;
    });
  };

  const handleAddToCart = (id, updatedTickets) => {
    const authToken = localStorage.getItem("authToken");

    if (!authToken) {

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please login before purchasing tickets",
        showCancelButton: true,
        confirmButtonText: "Login Now",
        cancelButtonText: "Close",
      }).then((result) => {
        if (result.isConfirmed) {
          // Navigate to the login page
          navigate('/user/login');
        }
      });
      return;
    }

    const isTicketSelected = updatedTickets.some(
      (ticket) => ticket.event_id === id
    );

    if (!isTicketSelected) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You have not selected tickets for this event.",
      });
      return;
    }

    // if (updatedTickets.length > 0) {
    //   setModalVisible(true);
    //   const itemsFromLocalStorage = JSON.parse(
    //     localStorage.getItem("items") || "[]"
    //   );
    //   if (itemsFromLocalStorage.length > 0) {
    //     localStorage.setItem("orders", JSON.stringify(itemsFromLocalStorage));
    //   }
    // } else {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Oops...",
    //     text: `You have not selected any tickets.`,
    //   });
    // }


    if (updatedTickets.length > 0) {
      setModalVisible(true);
  
      const loginUser = localStorage.getItem("userID");
      const cartDataFromLocalStorage = JSON.parse(localStorage.getItem("CartData")) || {};
    
      if (cartDataFromLocalStorage[loginUser] && cartDataFromLocalStorage[loginUser].length > 0) {
        const itemsFromLocalStorage = cartDataFromLocalStorage[loginUser];
  
        // Retrieve or initialize FakeOrder object
        const fakeOrderFromLocalStorage = JSON.parse(localStorage.getItem('CartData')) || {};
    
        // Update the FakeOrder data for the logged-in user
        fakeOrderFromLocalStorage[loginUser] = itemsFromLocalStorage;
    
        // Save the updated FakeOrder object back to localStorage
        localStorage.setItem('FakeOrder', JSON.stringify(fakeOrderFromLocalStorage));
    
        // Update 'orders' in localStorage for compatibility
        localStorage.setItem("orders", JSON.stringify(itemsFromLocalStorage));
      } 
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `You have not selected any tickets.`,
      });
    }


   

  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);


  useEffect(() => {
      
    const loginUser = localStorage.getItem("userID");
    const itemsJSON = localStorage.getItem("CartData");

    let totalQuantity = 0;
  
    if (itemsJSON) {
      const items = JSON.parse(itemsJSON);
  
      // Filter items by loginUser
      if (items[loginUser]) {
        items[loginUser].forEach((item) => {
          totalQuantity += item.quantity;
        });
      }
    }
  
    localStorage.setItem("ordersCount", totalQuantity);
    updateOrdersCount(totalQuantity); 

  }, []);
  const handleTicketClick = (ticket) => {
    setSelectedTickets(ticket);
    setshowModal(true);
  };

  const handleCloseModal = () => {
    setshowModal(false);
    setModalVisible(false);
    localStorage.removeItem("orders");
    localStorage.removeItem("FakeOrder");

    setSelectedTickets([]);
  };

  const [Mob_filter, setMob_filter] = useState(false);
  const handleMobfilter = () => {
    setMob_filter(true);
  };
  const handleMobfilterOff = () => {
    setMob_filter(false);
  };
  const [showModal, setShowModal] = useState(false);
  const bodyStyle = showModal ? { backgroundColor: "rgba(0, 0, 0, 0.5)" } : {};

  const openModal = () => {
    if (orders && orders.length > 0) {
      setShowModal(true);

      setSelectedTickets([]);
      setModalVisible(false);
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `You have not selected any tickets.`,
      });
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const [startDate, setStartDate] = useState(null);  
  const startDateInputRef = useRef(null);

  const handleStartDateChange = (date) => {
    setStartDate(date);  
  };
  
  const [endDate, setEndDate] = useState(null);
  const endDateInputRef = useRef(null);
  
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const [clickCount, setClickCount] = useState(0);
  const handleItemsPerPageChange = () => {
    // const div = document.getElementById("thisThat");

    // if (div) {
    //   div.scrollIntoView({ behavior: "smooth", block: "start" });
    // }
    const newValue = clickCount === 0 ? 10 : 20;

    setitemsPerPage(newValue);
    setClickCount(clickCount + 1);
  };

  const handleSearch = async () => {
    if (
      selectedCountry.length === 0 &&
      startDate === "" &&
      endDate === "" &&
      searchText === ""
    ) {
      return;
    }

    try {
      setIsTableLoading(true);
      setIsSearchCompleted(false);

      const data = await fetchHomeData(
        currentPage,
        itemsPerPage,
        selectedCountry.join(","),
        startDate,
        endDate,
        searchText,
        cate,
        event_type,
        sortOrder,
        minPrice,
        maxPrice
      );
      setEvents(data.events.data);
      setTotalPages(data.events.last_page);
      setPrevPageUrl(data.events.prev_page_url);
      setNextPageUrl(data.events.next_page_url);
      setfrom(data.events.from);
      setTo(data.events.to);
      setTotal(data.events.total);
      setAllcategories(data.categories);
      setBlogs(data.blog);
      setIsSearchCompleted(true);
      setcountriesCount(data.events.data.length);
      setselectedcounties(data.selectedcounties);
    } catch (error) {
      console.error("Error fetching home data:", error);
    } finally {
      setIsTableLoading(false);
    }
  };

  const [searchText, setSearchText] = useState("");
  const [Text, setText] = useState("");
  const inputRef = useRef(null);
  const clearInput = async () => {
    if (inputRef.current) inputRef.current.value = "";
    if (startDateInputRef.current) startDateInputRef.current.value = "";
    if (endDateInputRef.current) endDateInputRef.current.value = "";
    if (inputRef.current) inputRef.current.value = "";

    setcountriesCount(0);
    // setMaxPrice(5000);
    // setitemsPerPage(5);

    setSelectedCountry([]);
    setselectedcounties([]);
    setCountry([]);
    setEndDate(null);
    setStartDate(null);
    // setType('');
    setSearchText("");

    setIsTableLoading(false);
    setIsSearchCompleted(false);
    setSearchText("");
    handleClearFunction();
  };

  const handleClearFunction = async () => {
    try {
      setIsTableLoading(true);
      setIsSearchCompleted(false);

      const data = await fetchHomeDataReset(currentPage, itemsPerPage);
      setEvents(data.events.data);
      setTotalPages(data.events.last_page);
      setPrevPageUrl(data.events.prev_page_url);
      setNextPageUrl(data.events.next_page_url);
      setfrom(data.events.from);
      setTo(data.events.to);
      setTotal(data.events.total);
      setAllcategories(data.categories);
      setBlogs(data.blog);
      setIsSearchCompleted(false);
      setIsTableLoading(true);
      setcountriesCount(data.events.data.length);
      setselectedcounties(data.selectedcounties);
    } catch (error) {
      console.error("Error fetching home data:", error);
    } finally {
      setIsTableLoading(false);
    }
  };

  const handleChangeInput = (e) => setSearchText(e.target.value);
  const [cate, setCate] = useState("");

  const [selectedCategories, setSelectedCategories] = useState([]);

  const handleChangeCate = (selectedCategoryIds) => {
    setSelectedCategories(selectedCategoryIds);
    const selectedCategoriesString = selectedCategoryIds.join(",");

    setCate(selectedCategoriesString);
  };

  const [sortOrder, setSortOrder] = useState("");

  const handleChange = (event) => {
   

    const selectedValue = event.target.value;
    setSortOrder(selectedValue);
    setIsTableLoading(false);
  };
  const [show, setShow] = useState("show");
  const handleChangeMobile = (event) => {
  
    const selectedValue = event;
    setSortOrder(selectedValue);
    setIsTableLoading(false);
    setShow("");
    
  };
  const handleToggle = ( ) => {
    setShow("show");
  };

  const fetchDataHome = async () => {
    setMob_filter(false);
    if (isMobile) {
      setIsTicketVisible(false);
    }
    try {
      setIsTableLoading(true);
      const data = await fetchHomeData(
        currentPage,
        itemsPerPage,
        selectedCountry,
        startDate,
        endDate,
        searchText,
        cate,
        event_type,
        sortOrder,
        minPrice,
        maxPrice
      );

      setEvents(data.events.data);
      setTotalPages(data.events.last_page);
      setcountriesCount(data.events.data.length);
      setPrevPageUrl(data.events.prev_page_url);
      setNextPageUrl(data.events.next_page_url);
      setfrom(data.events.from);
      setTo(data.events.to);
      setTotal(data.events.total);
      setcountriesCount(data.events.data.length);
      setselectedcounties(data.selectedcounties);
      localStorage.removeItem("orders");
      localStorage.removeItem("FakeOrder");
      setBlogs(data.blog);
      fetchData();

      const countriesOptions = [
       
        ...data.currencies.map((currency) => ({
          value: currency.id,
          label: currency.country,
          country_flag: currency.country_flag,
          event_count: currency.event_count,
        })),
      ];

      setcountryOptions(countriesOptions);

      setAllcategories(data.categories);
    } catch (error) {
      console.error("Error fetching home data:", error);
    } finally {
      setIsTableLoading(false);
    }
  };
  useEffect(() => {
    fetchDataHome();
  }, [
    itemsPerPage,
    sortOrder,
    cate,
    event_type,
    minPrice,
    maxPrice,
    selectedCountry,
  ]);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const data = await fetchUserData(token);

      if (data.user.image) {
        const fullImagePath = data.user.imagePath + data.user.image;
        setImagePath(fullImagePath);
      } else {
        setImagePath("/images/notlogin.png");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // Calculate the subtotal (price without service fee)
    const subtotal = availableTickets.reduce(
      (accumulator, t) => accumulator + t.price * t.quantity,
      0
    );
    setSubtotal(subtotal);

    const fee = 0;
    setServiceFee(fee);

    const totalPrice = subtotal + fee;
    setTotalPrice(totalPrice);

    const availableitems =
      JSON.parse(localStorage.getItem("items")) || availableTickets;

    const quantities = availableitems.reduce((data, ticket) => {
      data[ticket.id] = ticket.quantity;
      return data;
    }, {});
    localStorage.setItem("ticketQuantities", JSON.stringify(quantities));
  }, [availableTickets]);
  useEffect(() => {
    localStorage.setItem("availableTickets", JSON.stringify(availableTickets));
    localStorage.setItem("totalPrice", totalPrice.toString());
  }, [availableTickets, totalPrice]);
  useEffect(() => {
    const savedTickets =
      JSON.parse(localStorage.getItem("availableTickets")) || availableTickets;
    const savedTotalPrice = parseFloat(
      localStorage.getItem("totalPrice") || totalPrice
    );
    setTickets(savedTickets);
    setTotalPrice(savedTotalPrice);
  }, []);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("items"));
    if (items && items.length > 0) {
      const itemsToUpdate = items.filter((item) => {
        const matchingTicket = availableTickets.find(
          (ticket) => ticket.id === item.id
        );
        return matchingTicket && matchingTicket.quantity !== item.quantity;
      });

      if (itemsToUpdate.length > 0) {
        setAvailableTickets((prevTickets) => {
          return prevTickets.map((ticket) => {
            const matchingItem = itemsToUpdate.find(
              (item) => item.id === ticket.id
            );
            if (matchingItem) {
              return { ...ticket, quantity: matchingItem.quantity };
            } else {
              return ticket;
            }
          });
        });
      }
    }
  }, []);

  const localStroagreLoad = () => {
    const items = JSON.parse(localStorage.getItem("items"));
    if (items && items.length > 0) {
      const itemsToUpdate = items.filter((item) => {
        const matchingTicket = availableTickets.find(
          (ticket) => ticket.id === item.id
        );
        return matchingTicket && matchingTicket.quantity !== item.quantity;
      });

      if (itemsToUpdate.length > 0) {
        setAvailableTickets((prevTickets) => {
          return prevTickets.map((ticket) => {
            const matchingItem = itemsToUpdate.find(
              (item) => item.id === ticket.id
            );
            if (matchingItem) {
              return { ...ticket, quantity: matchingItem.quantity };
            } else {
              return ticket;
            }
          });
        });
      }
    }
  };

  const [showFilters, setShowFilters] = useState(true);

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const [isFilterVisible, setIsFilterVisible] = useState(false);

  const toggle_Filter = () => {
    setIsFilterVisible(!isFilterVisible);
  };

  

  const handleContinue = () => {
    // if (locationStorage) {
    const loginUser = localStorage.getItem("userID");
    if (orders && orders.length > 0) {
      const ordersFromLocalStorage = JSON.parse(
        localStorage.getItem("orders") || "[]"
      );
 
      const allCartData = JSON.parse(localStorage.getItem('CartData')) || {};
      allCartData[localStorage.getItem("userID")] = ordersFromLocalStorage;
      localStorage.setItem('CartData', JSON.stringify(allCartData));
       
      localStorage.setItem("items", JSON.stringify(ordersFromLocalStorage));
      // const itemsJSON = localStorage.getItem("CartData");

      // let items = [];
      // if (itemsJSON) {
      //   items = JSON.parse(itemsJSON);
      // }

      // let totalQuantity = 0;

      // items.forEach((item) => {
      //   totalQuantity += item.quantity;
      // });

      // localStorage.setItem("ordersCount", totalQuantity);
      // updateOrdersCount(totalQuantity);
    
      const itemsJSON = localStorage.getItem("CartData");
  
      let totalQuantity = 0;
    
      if (itemsJSON) {
        const items = JSON.parse(itemsJSON);
    
        // Filter items by loginUser
        if (items[loginUser]) {
          items[loginUser].forEach((item) => {
            totalQuantity += item.quantity;
          });
        }
      }
    
      localStorage.setItem("ordersCount", totalQuantity);
      updateOrdersCount(totalQuantity); 


      setShowModal(false);
    }
  };
  const handleContinue_checkout = () => {
     
    const loginUser = localStorage.getItem("userID");

       

          if (!loginUser) {
              Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "User is not logged in.",
              });
              return;
          }
           
         

        if (orders && orders.length > 0) {
          const ordersFromLocalStorage = JSON.parse(
            localStorage.getItem("orders") || "[]"
          );
          const allCartData = JSON.parse(localStorage.getItem('CartData')) || {};
          allCartData[localStorage.getItem("userID")] = ordersFromLocalStorage;
          localStorage.setItem('CartData', JSON.stringify(allCartData));
           
          localStorage.setItem("items", JSON.stringify(ordersFromLocalStorage));
           
        
          const itemsJSON = localStorage.getItem("CartData");
      
          let totalQuantity = 0;
        
          if (itemsJSON) {
            const items = JSON.parse(itemsJSON);
        
            // Filter items by loginUser
            if (items[loginUser]) {
              items[loginUser].forEach((item) => {
                totalQuantity += item.quantity;
              });
            }
          }
        
          localStorage.setItem("ordersCount", totalQuantity);
          updateOrdersCount(totalQuantity); 
          navigate("/cart");
      };
  };
  // const handleContinue_checkout = () => {
  //   // if (locationStorage) {

  //   if (orders && orders.length > 0) {
  //     const ordersFromLocalStorage = JSON.parse(
  //       localStorage.getItem("orders") || "[]"
  //     );
  //     localStorage.setItem("items", JSON.stringify(ordersFromLocalStorage));

  //     // Retrieve orders from localStorage
  //     const loginUser = localStorage.getItem("userID");
  //     const itemsJSON = localStorage.getItem("CartData");
  
  //     let totalQuantity = 0;
    
  //     if (itemsJSON) {
  //       const items = JSON.parse(itemsJSON);
    
  //       // Filter items by loginUser
  //       if (items[loginUser]) {
  //         items[loginUser].forEach((item) => {
  //           totalQuantity += item.quantity;
  //         });
  //       }
  //     }
    
  //     localStorage.setItem("ordersCount", totalQuantity);
  //     updateOrdersCount(totalQuantity); 

  //     setShowModal(false);
  //     navigate("/cart");
  //   } else {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Oops...",
  //       text: `You have not selected any tickets.`,
  //     });
  //   }
  // };
  

  const [isOpen, setIsOpen] = useState(true);

  const toggleCountry = (value) => {
    if (value === "all") {
      if (selectedCountry.includes("all")) {
        setSelectedCountry([]);
      } else {
        setSelectedCountry(["all"]);
      }
      return;
    }

    const updatedSelectedCountry = selectedCountry.filter(
      (country) => country !== "all"
    );

    if (updatedSelectedCountry.includes(value)) {
      setSelectedCountry(
        updatedSelectedCountry.filter((country) => country !== value)
      );
    } else {
      setSelectedCountry([...updatedSelectedCountry, value]);
    }
  };

  const handleRemoveCountry = async (countryValue) => {
     
    setSelectedCountry((prevSelectedCountry) => {
      const newSelectedOptions = prevSelectedCountry.filter(
        (option) => option !== countryValue
      );
      if (newSelectedOptions.length === 0) {
        setSearch(1);
        setIsSearchCompleted(true);
        setIsTableLoading(true);
        clearInput();
        return [];
      }
      return newSelectedOptions;
    });

    setSelectedCountryValues((prevSelectedCountry) => {
      const newSelectedOptions = prevSelectedCountry.filter(
        (option) => option !== countryValue
      );
      if (newSelectedOptions.length === 0) {
        return [];
      }
      return newSelectedOptions;
    });
    setSearch(1);
  };

  useEffect(() => {
    if (SearchCountry === 1) {
      handleSearch();
      setSearch(0);
    }
  }, [SearchCountry]);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const UpcomingEvents = events.map((event, index) => {
    return (
      <>
        <EventComponent
          key={event.id}
          event={event}
          selectedTickets={selectedTickets}
          handleTicketSelection={handleTicketSelection}
          handleAddToCart={handleAddToCart}
          mainIndex={index}
          keyboardId={`keyboard-${index}`}
          // setisDisable={setisDisable}
          // isDisable={isDisable}
        />
      </>
    );
  });
  const [currentTab, setCurrentTab] = useState("eventsTab");
  const [checkedCountries, setCheckedCountries] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleCheckedCountry = (value) => {
    setCheckedCountries((prev) =>
      prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value]
    );
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const renderTabContentMobile = () => {
    switch (currentTab) {
      case "eventsTab":
        return (
          <div className="moble">
            <div className="form-group form-filter-grey form-filter-search form-filter-width4 position-relative d-flex align-items-center justify-content-start   rounded-4 mb-3">
              <input
                id="form_name6"
                className="form-control filter-5 border-0 float-input outline-0 shadow-none border-none"
                type="text"
                required
                onChange={handleChangeInput}
                ref={inputRef}
              />
              <label htmlFor="form_name6" className="float-label">
                Search Event Name...
                 
              </label>
            </div>
            <div className="d-flex w-100">
              <div className="form-group form-filter-grey  position-relative me-2  rounded-4 w-100">
 
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker onChange={handleStartDateChange} 
                renderInput={(params) => <TextField {...params} />}
                value={startDate} />
              </LocalizationProvider> 

                
                 

                {/* <input
                  id="form_name3"
                  className="form-control filter-2 border-0 float-input outline-0 shadow-none w-100 border-none"
                  type="date"
                  placeholder=""
                  onChange={handleStartDateChange}
                  ref={startDateInputRef}
                  required
                /> 
             
                <label htmlFor="form_name3" className="float-label">
                  Start Date
                </label> */}
              </div>
              <div className="form-group form-filter-grey  position-relative rounded-4 w-100 end_date">
                
            
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker onChange={handleEndDateChange} 
                renderInput={(params) => <TextField {...params} />}
                value={endDate} />
              </LocalizationProvider> 

                {/* <input
                  id="form_name4"
                  className="form-control filter-3 border-0 float-input outline-0 shadow-none w-100 border-none"
                  type="date"
                  placeholder=""
                  required
                  onChange={handleEndDateChange}
                  ref={endDateInputRef}
                />
                <label htmlFor="form_name4" className="float-label">
                  End Date
                   
                </label> */}
              </div>
            </div>
            
            
            <div className="mb-2">
              {!isTableLoading && !isSearchCompleted && (
                <button
                  className="custom-btn11 fw-normal text-16 w-100 d-block"
                  onClick={handleSearch}
                >
                  Search
                </button>
              )}
              {isTableLoading && (
                <button
                  className="custom-btn11 fw-normal text-16  w-100 d-block"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </button>
              )}
              {!isTableLoading && isSearchCompleted && (
                <button
                  className="custom-btn11 fw-normal text-16  w-100 d-block"
                  onClick={clearInput}
                >
                  Reset
                </button>
              )}
            </div>
          </div>
        );
      case "hotelsTab":
      case "transportTab":
      case "flightTab":
        return (
          <div className="d-block w-100 py-3 px-3">
            <h4 className="text-15 text-black m-0">
            Filters Coming Soon For {" "}
              {currentTab.charAt(0).toUpperCase() +
                currentTab.slice(1).replace("Tab", "")}
            </h4>
          </div>
        );
      default:
        return null;
    }
  };
  const renderTabContent = () => {
    switch (currentTab) {
      case "eventsTab":
        return (
          <div className="d-flex flex-wrap align-items-center  justify-content-start" style={{marginLeft:'10px'}}>
            {/* <div className="form-group form-filter-grey form-filter-width1 position-relative   rounded-4 me-2 mb-2">
          

                <div className="dropdown countries-select">
                    <button data-mdb-button-init data-mdb-ripple-init data-mdb-dropdown-init className="form-control filter-6 float-input outline-0 shadow-none dropdown-toggle" type="button" id="dropdownMenuButton12"
                    data-mdb-toggle="dropdown"  aria-haspopup="true" 
                    aria-expanded={true} 
                    onClick={handleToggleDropdown} >
                    Select Countries  
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton12">
                      {countryOptions.map(country => (
                        <li key={country.value}>
                          <a className="dropdown-item" href="#">
                            <div className="form-check d-flex justify-content-between align-items-center">
                              <div>
                                <input 
                                  className="form-check-input mt-2" 
                                  type="checkbox" 
                                  value={country.value} 
                                  id={country.value} 
                                  checked={selectedCountry.includes(country.value)} 
                                  onChange={() => toggleCountry(country.value)} 
                                />
                                <label className="form-check-label pt-1" htmlFor={country.value}>
                                  {country.label}
                                </label>
                              </div>
                              {country.country_flag && (
                                <img className="flg mt-2" src={country.country_flag} alt={`${country.label} flag`} style={{ marginLeft: '10px', width: '20px', height: '15px' }} />
                              )}
                            </div>
                          </a>
                        </li>
                      ))}
                    </ul>
                </div>


           

          </div>   */}
            <div className="form-group form-filter-grey form-filter-width2 position-relative me-2  rounded-4 mb-2 destopDate">
              {/* <input
                id="form_name3"
                className="form-control filter-2 border-0 float-input outline-0 shadow-none w-100"
                type="date"
                placeholder=""
                onChange={handleStartDateChange}
                ref={startDateInputRef}
                required
              />
              <label htmlFor="form_name3" className="float-label">
                Start Date
                 
              </label> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker onChange={handleStartDateChange} 
                renderInput={(params) => <TextField {...params} />}
                value={startDate}  />
              </LocalizationProvider> 
            </div>
            <div className="form-group form-filter-grey form-filter-width3 position-relative me-2   rounded-4 mb-2 destopDate">
            
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker onChange={handleEndDateChange} 
                renderInput={(params) => <TextField {...params} />}
                value={endDate} />
              </LocalizationProvider>  
              {/* <input
                id="form_name4"
                className="form-control filter-3 border-0 float-input outline-0 shadow-none w-100"
                type="date"
                placeholder=""
                required
                onChange={handleEndDateChange}
                ref={endDateInputRef}
              />
              <label htmlFor="form_name4" className="float-label">
                End Date
                 
              </label> */}
            </div>
            <div style={{width:'340px !important'}} id="search_input" className=" form-group form-filter-grey form-filter-search form-filter-width4 me-2 position-relative d-flex align-items-center justify-content-start   rounded-4 mb-2">
              <input
                id="form_name6"
                className="form-control filter-5 border-0 float-input outline-0 shadow-none w-100"
                type="text"
                required
                onChange={handleChangeInput}
                ref={inputRef}
              />
              <label htmlFor="form_name6" className="float-label">
                Search Event Name...
                 
              </label>
            </div>
            <div className="d-inline-block mb-2">
              {!isTableLoading && !isSearchCompleted && (
                <button
                  className="custom-btn11 fw-normal text-13 px-lg-5 px-md-5 px-sm-4 px-4"
                  onClick={handleSearch}
                >
                  Search
                </button>
              )}
              {isTableLoading && (
                <button
                  className="custom-btn11 fw-normal text-13 px-lg-5 px-md-5 px-sm-4 px-4"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </button>
              )}
              {!isTableLoading && isSearchCompleted && (
                <button
                  className="custom-btn11 fw-normal text-13 px-lg-5 px-md-5 px-sm-4 px-4"
                  onClick={clearInput}
                >
                  Reset
                </button>
              )}
            </div>
          </div>
        );
      case "hotelsTab":
      case "transportTab":
      case "flightTab":
        return (
          <div className="d-block w-100 py-3 px-3">
            <h4 className="text-15 text-black m-0">
            Filters Coming Soon For {" "}
              {currentTab.charAt(0).toUpperCase() +
                currentTab.slice(1).replace("Tab", "")}
            </h4>
          </div>
        );
      default:
        return null;
    }
  };

  // return(

  //   <Vanta/>
  // );
  return (
    <>

      
      <Helmet>
        <link href="/todayCss/bootstrap.min.css" rel="stylesheet" />
        <link href="/todayCss/animate.css" rel="stylesheet" />
        <link href="/todayCss/custom.css" rel="stylesheet" />
        <link href="/filterCss/custom.css" rel="stylesheet" />
        <link href="/todayCss/slick-theme.css" rel="stylesheet" />
        <link href="/todayCss/slick.css" rel="stylesheet" />
      </Helmet>
      {loading ? (
        <section className="pad-top-20 pad-bot-80">
          <div className="container3">
            <div className="block-element m-b-30"></div>
            <div
              className="block-element loader-mobile-screen"
              style={{ marginTop: "80px" }}
            >
              <LoadingSpinner />
            </div>
          </div>
        </section>
      ) : (
        <>

          <HeaderComponent />
       
          <div className="" style={bodyStyle}>
            <section className=" bg-silver2">
              <div className="container">
                <div className="d-block w-100 py-2">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-inline-block d-none">
                      {/* <Link to="/">
                        <h3 className="mt-0 mb-2 pt-0 pb-1 text-black text-29 mob-text-25 poppins-700">
                          Booking Search
                        </h3>
                      </Link> */}

                      {/* <h5 className="text-black text-15 d-flex align-items-center justify-content-start">
                        <span> Home </span>
                        <i className="fa fa-solid fa-circle text-6 text-muted ms-2 me-2"></i>
                        <span> Booking Search </span>
                      </h5> */}
                    </div>
                    <div className="d-lg-none d-md-none d-inline-block">
                      <button
                        id="toggle-filter"
                        className="bg-green2 col-green poppins-600 border-0 px-3 py-1 rounded-3"
                        onClick={toggleFilters}
                      >
                        Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className=" bg-silver2">
              <div className="container">

              {isMobile ?  <>
              
                <div
                    className="d-block w-100 mb-5 moble"
                    style={{ 
                      backgroundImage: 'url(../images/texture-bg.jpg)',
                      backgroundSize: 'cover',
                      padding: '40px 15px',
                      borderRadius: '20px',
                      backgroundRepeat: 'repeat',
                    }}
                  >
                  <div className="row justify-content-center">
                    <div className="col-lg-10 col-md-10 col-sm-12 col-12">
                      <div className="d-block w-100 top-menu-bar">
                        <div
                          className="nav nav-tabs border-0 custom-tabs4"
                          id="nav-tab2"
                          role="tablist"
                        >
                          <button
                            className={`nav-link  ${
                              currentTab === "eventsTab" ? "active" : ""
                            } bg-white text-black text-11 pl-2 pr-1 pt-3`}
                            onClick={() => setCurrentTab("eventsTab")}
                            style={{

                              borderTopLeftRadius: '10px', // Adjust the value as needed
                              borderTopRightRadius: '0',
                              borderBottomRightRadius: '0',
                              borderBottomLeftRadius: '0'

                            }}
                          >
                            <i className="fa fa-calendar text-10 me-1">
                              
                            </i> 
                            Events
                          </button>
                          <button
                            className={`nav-link rounded-0  ${
                              currentTab === "hotelsTab" ? "active" : ""
                            } bg-white text-black text-11 pl-2 pr-1 pt-3`}
                            onClick={() => setCurrentTab("hotelsTab")}
                          >
                            <i className="fa fa-bed text-10 me-1"> </i> Hotels
                          </button>
                          <button
                            className={`nav-link  rounded-0  ${
                              currentTab === "transportTab" ? "active" : ""
                            } bg-white text-black text-11 pl-2 pr-1 pt-3`}
                            onClick={() => setCurrentTab("transportTab")}
                          >
                            <i className="fa fa-bus text-10 me-1"> </i> Buses
                          </button>
                          <button
                            className={`nav-link  ${
                              currentTab === "flightTab" ? "active" : ""
                            } bg-white text-black text-11 pl-2 pr-1 pt-3`}
                            onClick={() => setCurrentTab("flightTab")}
                            style={{

                              borderTopLeftRadius: '0', // Adjust the value as needed
                              borderTopRightRadius: '10px',
                              borderBottomRightRadius: '0',
                              borderBottomLeftRadius: '0'
 
                            }}
                          >
                            <i className="fa fa-plane text-10 me-1"> </i> Flights 
                          </button>
                        </div>
                      </div>

                      {isMobile ? (
                    <div
                      className="bg-white px-2 pb-2 pt-3 d-md-none d-lg-none "
                      style={{
                        borderTopLeftRadius: '0', // Adjust the value as needed
                        borderTopRightRadius: '0',
                        borderBottomRightRadius: '10px',
                        borderBottomLeftRadius: '10px',
                      }}
                    >
                      <div  
                        className={`bg-white px-2 pb-2 pt-3 d-md-none d-lg-none ${showFilters ? 'd-block' : 'd-none'}`}
                        >
                    <div className="tab-content" id="nav-tabContent">
                      {renderTabContentMobile()}
                    </div>
            </div>
        </div>
      ) : (
      //  className="bg-white px-2 pb-2 pt-3 d-sm-none d-md-block d-lg-block shadow-normal"

      
      <div  
      className="bg-white px-2 pb-2 pt-3 d-sm-none d-md-block d-lg-block shadow-normal"
      style={{

        borderTopLeftRadius: '0', // Adjust the value as needed
        borderTopRightRadius: '10px',
        borderBottomRightRadius: '10px',
        borderBottomLeftRadius: '10px'

      }}
      >
        <div className="tab-content" id="nav-tabContent">
          {renderTabContent()}
        </div>
      </div>
           
      )}
      
                    </div>
                    {/* <div className="col-lg-8 col-md-8 col-sm-12 col-10">
                      {isMobile ? (
                      <div className={`bg-white px-2 pb-2 pt-3 shadow-normal rounded-3 ${showFilters ? "d-block" : "d-none"}`}>
                        <div className="d-flex flex-wrap align-items-center justify-content-lg-center justify-content-md-center justify-content-start">
                            <div className="form-group form-filter-grey form-filter-width1 position-relative   rounded-4 me-2 mb-2">
          
                                <div className="dropdown countries-select">
                                    <button data-mdb-button-init data-mdb-ripple-init data-mdb-dropdown-init className="form-control filter-6 float-input outline-0 shadow-none dropdown-toggle" type="button" id="dropdownMenuButton111"
                                    data-mdb-toggle="dropdown"  aria-haspopup="true" 
                                    aria-expanded={true} 
                                    onClick={handleToggleDropdown} >
                                 Select Countries  
                                    </button>
                                    <ul className="dropdown-menu d-block" aria-labelledby="dropdownMenuButton111">
                                      {countryOptions.map(country => (
                                        <li key={country.value}>
                                          <a className="dropdown-item" href="#">
                                            <div className="form-check d-flex justify-content-between align-items-center">
                                              <div>
                                                <input 
                                                  className="form-check-input mt-2" 
                                                  type="checkbox" 
                                                  value={country.value} 
                                                  id={country.value} 
                                                  checked={selectedCountry.includes(country.value)} 
                                                  onChange={() => toggleCountry(country.value)} 
                                                />
                                                <label className="form-check-label pt-1" htmlFor={country.value}>
                                                  {country.label}
                                                </label>
                                              </div>
                                              {country.country_flag && (
                                                <img className="flg mt-2" src={country.country_flag} alt={`${country.label} flag`} style={{ marginLeft: '10px', width: '20px', height: '15px' }} />
                                              )}
                                            </div>
                                          </a>
                                        </li>
                                      ))}
                                    </ul>
                                </div>
 
                          </div>  
                          <div className="form-group form-filter-grey form-filter-width2 position-relative me-2  rounded-4 mb-2">
                            <input
                              id="form_name3"
                              className="form-control filter-2 border-0 float-input outline-0 shadow-none"
                              type="date"
                              placeholder=""
                              onChange={handleStartDateChange}
                              ref={startDateInputRef}
                              required
                            />
                            <label htmlFor="form_name3" className="float-label">
                              Start Date<span className="gl-form-asterisk" />
                            </label>
                          </div>
                          <div className="form-group form-filter-grey form-filter-width3 position-relative me-2   rounded-4 mb-2">
                            <input
                              id="form_name4"
                              className="form-control filter-3 border-0 float-input outline-0 shadow-none"
                              type="date"
                              placeholder=""
                              required
                              onChange={handleEndDateChange}
                              ref={endDateInputRef}
                            />
                            <label htmlFor="form_name4" className="float-label">
                              End Date<span className="gl-form-asterisk" />
                            </label>
                          </div>
                          <div className="form-group form-filter-grey form-filter-search form-filter-width4 me-2 position-relative d-flex align-items-center justify-content-start   rounded-4 mb-2">
                            <input
                              id="form_name6"
                              className="form-control filter-5 border-0 float-input outline-0 shadow-none"
                              type="text"
                              required
                              onChange={handleChangeInput}
                              ref={inputRef}
                            />
                            <label htmlFor="form_name6" className="float-label">
                            Search Event Name...<span className="gl-form-asterisk" />
                            </label>
                          </div>
                          <div className="d-inline-block mb-2">
                          
                            {!isTableLoading && !isSearchCompleted && (
                                <button
                                  className="custom-btn11 fw-normal text-13 px-lg-5 px-md-5 px-sm-4 px-4"
                                  onClick={handleSearch}
                                >
                                  Search
                                </button>
                              )}  
                                                    {isTableLoading && (
                                <button
                                  className="custom-btn11 fw-normal text-13 px-lg-5 px-md-5 px-sm-4 px-4"
                                  disabled
                                >
                                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button>
                              )}
                               {!isTableLoading && isSearchCompleted && (
                                <button
                                  className="custom-btn11 fw-normal text-13 px-lg-5 px-md-5 px-sm-4 px-4"
                                  onClick={clearInput}
                                >
                                  Reset
                                </button>
                              )}
                          </div>
                         
                        </div>
                      </div>
                         ) : null
                      } 
                      <div className="bg-white px-2 pb-2 pt-3 shadow-normal rounded-3" id="HidemobSwc">
                        <div className="d-flex flex-wrap align-items-center justify-content-lg-center justify-content-md-center justify-content-start">
                          <div className="form-group form-filter-grey form-filter-width1 position-relative   rounded-4 me-2 mb-2">
                          
 
                                <div className="dropdown countries-select">
                                    <button data-mdb-button-init data-mdb-ripple-init data-mdb-dropdown-init className="form-control filter-6 float-input outline-0 shadow-none dropdown-toggle" type="button" id="dropdownMenuButton12"
                                    data-mdb-toggle="dropdown"  aria-haspopup="true" 
                                    aria-expanded={true} 
                                    onClick={handleToggleDropdown} >
                                    Select Countries  
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton12">
                                      {countryOptions.map(country => (
                                        <li key={country.value}>
                                          <a className="dropdown-item" href="#">
                                            <div className="form-check d-flex justify-content-between align-items-center">
                                              <div>
                                                <input 
                                                  className="form-check-input mt-2" 
                                                  type="checkbox" 
                                                  value={country.value} 
                                                  id={country.value} 
                                                  checked={selectedCountry.includes(country.value)} 
                                                  onChange={() => toggleCountry(country.value)} 
                                                />
                                                <label className="form-check-label pt-1" htmlFor={country.value}>
                                                  {country.label}
                                                </label>
                                              </div>
                                              {country.country_flag && (
                                                <img className="flg mt-2" src={country.country_flag} alt={`${country.label} flag`} style={{ marginLeft: '10px', width: '20px', height: '15px' }} />
                                              )}
                                            </div>
                                          </a>
                                        </li>
                                      ))}
                                    </ul>
                                </div>


                           

                          </div>  
                          <div className="form-group form-filter-grey form-filter-width2 position-relative me-2  rounded-4 mb-2">
                            <input
                              id="form_name3"
                              className="form-control filter-2 border-0 float-input outline-0 shadow-none"
                              type="date"
                              placeholder=""
                              onChange={handleStartDateChange}
                              ref={startDateInputRef}
                              required
                            />
                            <label htmlFor="form_name3" className="float-label">
                              Start Date<span className="gl-form-asterisk" />
                            </label>
                          </div>
                          <div className="form-group form-filter-grey form-filter-width3 position-relative me-2   rounded-4 mb-2">
                            <input
                              id="form_name4"
                              className="form-control filter-3 border-0 float-input outline-0 shadow-none"
                              type="date"
                              placeholder=""
                              required
                              onChange={handleEndDateChange}
                              ref={endDateInputRef}
                            />
                            <label htmlFor="form_name4" className="float-label">
                              End Date<span className="gl-form-asterisk" />
                            </label>
                          </div>
                          <div className="form-group form-filter-grey form-filter-search form-filter-width4 me-2 position-relative d-flex align-items-center justify-content-start   rounded-4 mb-2">
                            <input
                              id="form_name6"
                              className="form-control filter-5 border-0 float-input outline-0 shadow-none"
                              type="text"
                              required
                              onChange={handleChangeInput}
                              ref={inputRef}
                            />
                            <label htmlFor="form_name6" className="float-label">
                            Search Event Name...<span className="gl-form-asterisk" />
                            </label>
                          </div>
                          <div className="d-inline-block mb-2">
                          
                            {!isTableLoading && !isSearchCompleted && (
                                <button
                                  className="custom-btn11 fw-normal text-13 px-lg-5 px-md-5 px-sm-4 px-4"
                                  onClick={handleSearch}
                                >
                                  Search
                                </button>
                              )}  
                                                    {isTableLoading && (
                                <button
                                  className="custom-btn11 fw-normal text-13 px-lg-5 px-md-5 px-sm-4 px-4"
                                  disabled
                                >
                                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button>
                              )}
                               {!isTableLoading && isSearchCompleted && (
                                <button
                                  className="custom-btn11 fw-normal text-13 px-lg-5 px-md-5 px-sm-4 px-4"
                                  onClick={clearInput}
                                >
                                  Reset
                                </button>
                              )}
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div className="mt-3">
                    <div className="d-block w-100">
                      <div className="d-lg-none d-md-none w-100 bg-white rounded-4 py-2 px-2  d-block w-100 shadow-sm">
                        <div className="d-flex w-100 align-items-center justify-content-between">
                          <div className="d-inline-block">
                            <button
                              id="filter-mobile-toggle"
                              className="d-flex align-items-center justify-content-start bg-white border-0"
                              onClick={handleMobfilter}
                            >
                              <img
                                src="images/filter-icon2.png"
                                className="me-1"
                              />
                              <span className="text-16 text-black poppins-500">
                                Filter
                              </span>
                            </button>
                          </div>
                          <div className="d-inline-block">
                             <div className="dropdown">
                              <button onClick={handleToggle} className="ddd dropdown-toggle d-flex align-items-center justify-content-start bg-white border-0" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                              <img src="images/sort-icon1.png" className="me-1"  />
                              <span className="text-16 text-black poppins-500"> Sort </span>
                              </button>
                              
                              <ul className={`dropdown-menu ddddddddd ${show ? 'show' : ''}`} aria-labelledby="dropdownMenuButton1">

                                <li>
                                  <a className="dropdown-item text-14" onClick={() => handleChangeMobile('all')}>
                                    All Events
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item text-14" onClick={() => handleChangeMobile('featured')}>
                                  Featured
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item text-14" onClick={() => handleChangeMobile('newtoold')}>
                                    New to Old
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item text-14" onClick={() => handleChangeMobile('newtoold')}>
                                    Old to New
                                  </a>
                                </li>
                              </ul>
                              </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`d-block w-100 filter-mobile-bar ${
                          Mob_filter ? "open-filter-mobile" : ""
                        }`}
                      >
                        <div className="d-block w-100 filter-mobile-bar-wrapper">
                          <div className="d-lg-none d-md-none w-100 mb-3">
                            <div className="d-flex w-100 align-items-center justify-content-between">
                              <h4 className="m-0 p-0 col-blue3 poppins-600 text-20">
                                <img src="images/filter-icon3.png" />
                                Filters
                              </h4>
                              <button
                                id="filter-mobile-toggle-close"
                                className="border-0 bg-transparent text-18"
                                onClick={handleMobfilterOff}
                              >
                                <i className="fa fa-times"> </i>
                              </button>
                            </div>
                          </div>
                          <PriceFilterComponent
                            totalRecords={totalRecords}
                            to={to}
                            from={from}
                            setEvents={setEvents}
                            setType={setType}
                            setMinPrice={setMinPrice}
                            setMaxPrice={setMaxPrice}
                          />

                          <CountriesList
                            countries={countryOptions}
                            totalRecords={totalRecords}
                            
                            setSelectedCountry={setSelectedCountry}
                            selectedCountry={selectedCountry}
                            setSelectedCountryValues={setSelectedCountryValues}
                            selectedCountryValues={selectedCountryValues}
                          />
                          <div className="d-block w-100 mb-4">
                            <div className="d-block w-100 mb-3">
                              <h4 className="text-black text-18 m-0 poppins-600">
                                Categories
                              </h4>
                            </div>
                            <div className="d-block w-100 bg-white px-3 py-3 rounded-2 shadow-normal">
                              <div className="d-block w-100 mb-2">
                                <h4 className="text-black poppins-600 mt-0 mb-1 text-15">
                                  Event Categories
                                </h4>
                                <p className="text-muted poppins-400 text-12 m-0">
                                  
                                </p>
                              </div>
                              <div className="d-block w-100">
                                {allCategories.map((category) => (
                                  <CategoryFilterComponent
                                    category={category}
                                    
                                    onChangeCate={handleChangeCate}
                                    selectedCategories={selectedCategories}
                                  />
                                ))}
                              </div>
                            </div>
                          </div>
                          {/* Categories Filter Ends Here */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </> : 
                <>
                 <div
                    className="d-block w-100 mb-5"
                    style={{
                      backgroundImage: 'url(../images/texture-bg.jpg)',
                      backgroundSize: 'cover',
                      padding: '75px 20px',
                      borderRadius: '20px',
                      backgroundRepeat: 'repeat',
                    }}
                  >
                  <div className="row justify-content-center">
                    <div className="col-lg-10 col-md-10 col-sm-12 col-12">
                      <div className="d-block w-100">
                        <div
                          className="nav nav-tabs border-0 custom-tabs4"
                          id="nav-tab2"
                          role="tablist"
                        >
                          <button
                            className={`nav-link  ${
                              currentTab === "eventsTab" ? "active" : ""
                            } bg-white text-black text-14`}
                            onClick={() => setCurrentTab("eventsTab")}
                            style={{

                              borderTopLeftRadius: '10px', // Adjust the value as needed
                              borderTopRightRadius: '0',
                              borderBottomRightRadius: '0',
                              borderBottomLeftRadius: '0'

                            }}
                          >
                            <i className="fa fa-calendar text-16 me-2">
                              
                            </i> 
                            Events
                          </button>
                          <button
                            className={`nav-link rounded-0  ${
                              currentTab === "hotelsTab" ? "active" : ""
                            } bg-white text-black text-14`}
                            onClick={() => setCurrentTab("hotelsTab")}
                          >
                            <i className="fa fa-bed text-16 me-2"> </i> Hotels
                          </button>
                          <button
                            className={`nav-link  rounded-0  ${
                              currentTab === "transportTab" ? "active" : ""
                            } bg-white text-black text-14`}
                            onClick={() => setCurrentTab("transportTab")}
                          >
                            <i className="fa fa-bus text-16 me-2"> </i>  Buses
                          </button>
                          <button
                            className={`nav-link  ${
                              currentTab === "flightTab" ? "active" : ""
                            } bg-white text-black text-14`}
                            onClick={() => setCurrentTab("flightTab")}
                            style={{

                              borderTopLeftRadius: '0', // Adjust the value as needed
                              borderTopRightRadius: '10px',
                              borderBottomRightRadius: '0',
                              borderBottomLeftRadius: '0'
 
                            }}
                          >
                            <i className="fa fa-plane text-16 me-2"> </i> Flights
                          </button>
                        </div>
                      </div>

                      {isMobile ? (
                    <div
                      className="bg-white px-2 pb-2 pt-3 d-md-none d-lg-none shadow-normal"
                      style={{
                        borderTopLeftRadius: '0', // Adjust the value as needed
                        borderTopRightRadius: '10px',
                        borderBottomRightRadius: '10px',
                        borderBottomLeftRadius: '10px',
                      }}
                    >
                      <div  
                        className={`bg-white px-2 pb-2 pt-3 d-md-none d-lg-none shadow-normal ${showFilters ? 'd-block' : 'd-none'}`}
                        style={{

                          borderTopLeftRadius: '0', // Adjust the value as needed
                          borderTopRightRadius: '10px',
                          borderBottomRightRadius: '10px',
                          borderBottomLeftRadius: '10px'

                        }}
                        >
                    <div className="tab-content" id="nav-tabContent">
                      {renderTabContent()}
                    </div>
                    
            </div>
        </div>
      ) : (
      //  className="bg-white px-2 pb-2 pt-3 d-sm-none d-md-block d-lg-block shadow-normal"

      
      <div  
      className="bg-white px-2 pb-2 pt-3 d-sm-none d-md-block d-lg-block shadow-normal"
      style={{

        borderTopLeftRadius: '0', // Adjust the value as needed
        borderTopRightRadius: '10px',
        borderBottomRightRadius: '10px',
        borderBottomLeftRadius: '10px'

      }}
      >
        <div className="tab-content" id="nav-tabContent">
          {renderTabContent()}
        </div>
      </div>
           
      )}
      
                    </div>
                    {/* <div className="col-lg-8 col-md-8 col-sm-12 col-10">
                      {isMobile ? (
                      <div className={`bg-white px-2 pb-2 pt-3 shadow-normal rounded-3 ${showFilters ? "d-block" : "d-none"}`}>
                        <div className="d-flex flex-wrap align-items-center justify-content-lg-center justify-content-md-center justify-content-start">
                            <div className="form-group form-filter-grey form-filter-width1 position-relative   rounded-4 me-2 mb-2">
          
                                <div className="dropdown countries-select">
                                    <button data-mdb-button-init data-mdb-ripple-init data-mdb-dropdown-init className="form-control filter-6 float-input outline-0 shadow-none dropdown-toggle" type="button" id="dropdownMenuButton111"
                                    data-mdb-toggle="dropdown"  aria-haspopup="true" 
                                    aria-expanded={true} 
                                    onClick={handleToggleDropdown} >
                                 Select Countries  
                                    </button>
                                    <ul className="dropdown-menu d-block" aria-labelledby="dropdownMenuButton111">
                                      {countryOptions.map(country => (
                                        <li key={country.value}>
                                          <a className="dropdown-item" href="#">
                                            <div className="form-check d-flex justify-content-between align-items-center">
                                              <div>
                                                <input 
                                                  className="form-check-input mt-2" 
                                                  type="checkbox" 
                                                  value={country.value} 
                                                  id={country.value} 
                                                  checked={selectedCountry.includes(country.value)} 
                                                  onChange={() => toggleCountry(country.value)} 
                                                />
                                                <label className="form-check-label pt-1" htmlFor={country.value}>
                                                  {country.label}
                                                </label>
                                              </div>
                                              {country.country_flag && (
                                                <img className="flg mt-2" src={country.country_flag} alt={`${country.label} flag`} style={{ marginLeft: '10px', width: '20px', height: '15px' }} />
                                              )}
                                            </div>
                                          </a>
                                        </li>
                                      ))}
                                    </ul>
                                </div>
 
                          </div>  
                          <div className="form-group form-filter-grey form-filter-width2 position-relative me-2  rounded-4 mb-2">
                            <input
                              id="form_name3"
                              className="form-control filter-2 border-0 float-input outline-0 shadow-none"
                              type="date"
                              placeholder=""
                              onChange={handleStartDateChange}
                              ref={startDateInputRef}
                              required
                            />
                            <label htmlFor="form_name3" className="float-label">
                              Start Date<span className="gl-form-asterisk" />
                            </label>
                          </div>
                          <div className="form-group form-filter-grey form-filter-width3 position-relative me-2   rounded-4 mb-2">
                            <input
                              id="form_name4"
                              className="form-control filter-3 border-0 float-input outline-0 shadow-none"
                              type="date"
                              placeholder=""
                              required
                              onChange={handleEndDateChange}
                              ref={endDateInputRef}
                            />
                            <label htmlFor="form_name4" className="float-label">
                              End Date<span className="gl-form-asterisk" />
                            </label>
                          </div>
                          <div className="form-group form-filter-grey form-filter-search form-filter-width4 me-2 position-relative d-flex align-items-center justify-content-start   rounded-4 mb-2">
                            <input
                              id="form_name6"
                              className="form-control filter-5 border-0 float-input outline-0 shadow-none"
                              type="text"
                              required
                              onChange={handleChangeInput}
                              ref={inputRef}
                            />
                            <label htmlFor="form_name6" className="float-label">
                            Search Event Name...<span className="gl-form-asterisk" />
                            </label>
                          </div>
                          <div className="d-inline-block mb-2">
                          
                            {!isTableLoading && !isSearchCompleted && (
                                <button
                                  className="custom-btn11 fw-normal text-13 px-lg-5 px-md-5 px-sm-4 px-4"
                                  onClick={handleSearch}
                                >
                                  Search
                                </button>
                              )}  
                                                    {isTableLoading && (
                                <button
                                  className="custom-btn11 fw-normal text-13 px-lg-5 px-md-5 px-sm-4 px-4"
                                  disabled
                                >
                                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button>
                              )}
                               {!isTableLoading && isSearchCompleted && (
                                <button
                                  className="custom-btn11 fw-normal text-13 px-lg-5 px-md-5 px-sm-4 px-4"
                                  onClick={clearInput}
                                >
                                  Reset
                                </button>
                              )}
                          </div>
                         
                        </div>
                      </div>
                         ) : null
                      } 
                      <div className="bg-white px-2 pb-2 pt-3 shadow-normal rounded-3" id="HidemobSwc">
                        <div className="d-flex flex-wrap align-items-center justify-content-lg-center justify-content-md-center justify-content-start">
                          <div className="form-group form-filter-grey form-filter-width1 position-relative   rounded-4 me-2 mb-2">
                          
 
                                <div className="dropdown countries-select">
                                    <button data-mdb-button-init data-mdb-ripple-init data-mdb-dropdown-init className="form-control filter-6 float-input outline-0 shadow-none dropdown-toggle" type="button" id="dropdownMenuButton12"
                                    data-mdb-toggle="dropdown"  aria-haspopup="true" 
                                    aria-expanded={true} 
                                    onClick={handleToggleDropdown} >
                                    Select Countries  
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton12">
                                      {countryOptions.map(country => (
                                        <li key={country.value}>
                                          <a className="dropdown-item" href="#">
                                            <div className="form-check d-flex justify-content-between align-items-center">
                                              <div>
                                                <input 
                                                  className="form-check-input mt-2" 
                                                  type="checkbox" 
                                                  value={country.value} 
                                                  id={country.value} 
                                                  checked={selectedCountry.includes(country.value)} 
                                                  onChange={() => toggleCountry(country.value)} 
                                                />
                                                <label className="form-check-label pt-1" htmlFor={country.value}>
                                                  {country.label}
                                                </label>
                                              </div>
                                              {country.country_flag && (
                                                <img className="flg mt-2" src={country.country_flag} alt={`${country.label} flag`} style={{ marginLeft: '10px', width: '20px', height: '15px' }} />
                                              )}
                                            </div>
                                          </a>
                                        </li>
                                      ))}
                                    </ul>
                                </div>


                           

                          </div>  
                          <div className="form-group form-filter-grey form-filter-width2 position-relative me-2  rounded-4 mb-2">
                            <input
                              id="form_name3"
                              className="form-control filter-2 border-0 float-input outline-0 shadow-none"
                              type="date"
                              placeholder=""
                              onChange={handleStartDateChange}
                              ref={startDateInputRef}
                              required
                            />
                            <label htmlFor="form_name3" className="float-label">
                              Start Date<span className="gl-form-asterisk" />
                            </label>
                          </div>
                          <div className="form-group form-filter-grey form-filter-width3 position-relative me-2   rounded-4 mb-2">
                            <input
                              id="form_name4"
                              className="form-control filter-3 border-0 float-input outline-0 shadow-none"
                              type="date"
                              placeholder=""
                              required
                              onChange={handleEndDateChange}
                              ref={endDateInputRef}
                            />
                            <label htmlFor="form_name4" className="float-label">
                              End Date<span className="gl-form-asterisk" />
                            </label>
                          </div>
                          <div className="form-group form-filter-grey form-filter-search form-filter-width4 me-2 position-relative d-flex align-items-center justify-content-start   rounded-4 mb-2">
                            <input
                              id="form_name6"
                              className="form-control filter-5 border-0 float-input outline-0 shadow-none"
                              type="text"
                              required
                              onChange={handleChangeInput}
                              ref={inputRef}
                            />
                            <label htmlFor="form_name6" className="float-label">
                            Search Event Name...<span className="gl-form-asterisk" />
                            </label>
                          </div>
                          <div className="d-inline-block mb-2">
                          
                            {!isTableLoading && !isSearchCompleted && (
                                <button
                                  className="custom-btn11 fw-normal text-13 px-lg-5 px-md-5 px-sm-4 px-4"
                                  onClick={handleSearch}
                                >
                                  Search
                                </button>
                              )}  
                                                    {isTableLoading && (
                                <button
                                  className="custom-btn11 fw-normal text-13 px-lg-5 px-md-5 px-sm-4 px-4"
                                  disabled
                                >
                                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button>
                              )}
                               {!isTableLoading && isSearchCompleted && (
                                <button
                                  className="custom-btn11 fw-normal text-13 px-lg-5 px-md-5 px-sm-4 px-4"
                                  onClick={clearInput}
                                >
                                  Reset
                                </button>
                              )}
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
                </>
                }

             
                <div
                  className="d-flex align-items-start justify-content-start flex-wrap"
                  id="thisThat"
                >
                  <div className="booking-search-checkout-left pe-lg-4 pe-md-4 pe-0 " >
                    <div className="d-block w-100">
                      <div className="d-lg-none d-md-none   w-100 bg-white rounded-2 py-2 px-2  d-block w-100 shadow-sm mb-4 " id="customClass">
                        <div className="d-flex w-100 align-items-center justify-content-between">
                          <div className="d-inline-block">
                            <button
                              id="filter-mobile-toggle"
                              className="d-flex align-items-center justify-content-start bg-white border-0"
                              onClick={handleMobfilter}
                            >
                              <img
                                src="images/filter-icon2.png"
                                className="me-1"
                              />
                              <span className="text-16 text-black poppins-500">
                                Filter
                              </span>
                            </button>
                          </div>
                          <div className="d-inline-block">
                             <div className="dropdown">
                              <button onClick={handleToggle} className="ddd dropdown-toggle d-flex align-items-center justify-content-start bg-white border-0" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                              <img src="images/sort-icon1.png" className="me-1"  />
                              <span className="text-16 text-black poppins-500"> Sort </span>
                              </button>
                              
                              <ul className={`dropdown-menu ddddddddd ${show ? 'show' : ''}`} aria-labelledby="dropdownMenuButton1">

                                <li>
                                  <a className="dropdown-item text-14" onClick={() => handleChangeMobile('all')}>
                                    All Events
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item text-14" onClick={() => handleChangeMobile('featured')}>
                                  Featured
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item text-14" onClick={() => handleChangeMobile('newtoold')}>
                                    New to Old
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item text-14" onClick={() => handleChangeMobile('newtoold')}>
                                    Old to New
                                  </a>
                                </li>
                              </ul>
                              </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`d-block w-100 filter-mobile-bar ${
                          Mob_filter ? "open-filter-mobile" : ""
                        }`}
                      >
                        <div className="d-block w-100 filter-mobile-bar-wrapper">
                          <div className="d-lg-none d-md-none w-100 mb-3">
                            <div className="d-flex w-100 align-items-center justify-content-between">
                              <h4 className="m-0 p-0 col-blue3 poppins-600 text-20">
                                <img src="images/filter-icon3.png" />
                                Filters
                              </h4>
                              <button
                                id="filter-mobile-toggle-close"
                                className="border-0 bg-transparent text-18"
                                onClick={handleMobfilterOff}
                              >
                                <i className="fa fa-times"> </i>
                              </button>
                            </div>
                          </div>
                          {/* <PriceFilterComponent
                            totalRecords={totalRecords}
                            to={to}
                            from={from}
                            setEvents={setEvents}
                            setType={setType}
                            setMinPrice={setMinPrice}
                            setMaxPrice={setMaxPrice}
                          /> */}

                          <CountriesList
                            countries={countryOptions}
                            setSelectedCountry={setSelectedCountry}
                            selectedCountry={selectedCountry}
                            totalRecords={totalRecords}
                            setSelectedCountryValues={setSelectedCountryValues}
                            selectedCountryValues={selectedCountryValues}
                          />
                          <div className="d-block w-100 mb-4">
                            <div className="d-block w-100 mb-3">
                              <h4 className="text-black text-18 m-0 poppins-600">
                                Categories
                              </h4>
                            </div>
                            <div className="d-block w-100 bg-white px-3 py-3 rounded-2 shadow-normal">
                              <div className="d-block w-100 mb-2">
                                <h4 className="text-black poppins-600 mt-0 mb-1 text-15">
                                  Event Categories
                                </h4>
                              </div>
                              <div className="d-block w-100">
                                {allCategories.map((category) => (
                                  <CategoryFilterComponent
                                    category={category}
                                    onChangeCate={handleChangeCate}
                                    selectedCategories={selectedCategories}
                                  />
                                ))}
                              </div>
                            </div>
                          </div>
                          <PriceFilterComponent
                            totalRecords={totalRecords}
                            to={to}
                            from={from}
                            setEvents={setEvents}
                            setType={setType}
                            setMinPrice={setMinPrice}
                            setMaxPrice={setMaxPrice}
                          />
                           
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="booking-search-checkout-right ps-lg-3 ps-md-3 ps-0  pe-lg-3 pe-md-3 pe-0 mt-0">
                    <div className="d-block w-100">
                      <div className="d-block w-100 mb-3">
                        <h3 className="text-black poppins-600 text-24 m-0 mob-text-16">
                          {/* {selectedcounties && selectedcounties.length > 0 ? `${selectedcounties.length} Countries` : 'All Countries'} : {totalRecords} search results found */}
                        </h3>
                      </div>
                      <div className="d-block w-100 mb-3">
                        <div className="row">
                          <div className="col-lg-9 col-md-9 col-sm-12 col-12">
                            <div className="d-flex w-100 align-items-center justify-content-start flex-wrap">
                              {selectedcounties &&
                                selectedcounties.length > 0 &&
                                selectedcounties.map((country, index) => (
                                  <div
                                    key={index}
                                    className="d-flex align-items-center justify-content-start mb-2 me-3 px-2 py-2 border border-color-blue rounded-2"
                                  >
                                    <img
                                      src={country.flag}
                                      alt={`${country.name} flag`}
                                      id="FlagImages"
                                      className="w-auto flg"
                                    />
                                    <span className="col-blue3 mx-2 d-inline-block">
                                      {country.name}
                                    </span>
                                    <button
                                      className="p-0 border-0 bg-transparent text-14 poppins-500 text-muted awann"
                                      onClick={() =>
                                        handleRemoveCountry(country.id)
                                      }
                                    >
                                      <i className="fa fa-times"> </i>
                                    </button>
                                  </div>
                                ))}
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                            <div className="d-lg-block d-md-block d-none  mt-lg-0 mt-md-0 mt-3">
                              <div className="form-group position-relative me-2 border border-color-silver rounded-4 mb-2">
                                <select
                                  id="form_name2"
                                  className="form-control text-14 border-0 float-input outline-0 shadow-none"
                                  name="sortOrder"
                                  onChange={handleChange}
                                >
                                  <option value="all">All Events</option>
                                  <option value="featured">Featured</option>
                                  {/* <option value="lowToHigh">Prices: Low to High</option>
                                  <option value="highToLow">Prices: High to Low</option> */}
                                  <option value="newtoold">New to Old</option>
                                  <option value="oldtonew">Old to New</option>
                                </select>
                                <label
                                  for="form_name2"
                                  className="float-label"
                                  id="Sortfloat"
                                >
                                  Sort By
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-block w-100 mb-3">
                        <h3 className="text-black poppins-600 text-20 m-0 ">
                          Popular Events
                        </h3>
                      </div>

                      {isTableLoading ? (
                        <LoadingSpinner />
                      ) : (
                        <>
                          {UpcomingEvents.length > 0 ? (
                            <>
                              {UpcomingEvents}

                              {totalRecords !== itemsPerPage &&
                                to !== totalRecords && (
                                  <div className="d-flex w-100 align-items-center justify-content-center">
                                    <button
                                      className="custom-btn10"
                                      onClick={handleItemsPerPageChange}
                                    >
                                      Load more Results
                                    </button>
                                  </div>
                                )}
                            </>
                          ) : (
                            <>
                              <div>
                                
                                  <img
                                    className="d-block m-auto "
                                    src="/images/eventnotfound.png"
                                  />
                                </div>
 
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <TicketModelComponent
              modalVisible={modalVisible}
              selectedTickets={selectedTickets}
              handleClose={handleCloseModal}
              evtData={evtData}
              setOrders={setOrders}
              setAvailableTickets={setAvailableTickets}
              ordersCount={updateOrdersCount}
              updateOrdersCount={updateOrdersCount}
              availableTickets={availableTickets}
              orders={orders}
              setSelectedTickets={setSelectedTickets}
              openModal={openModal}
            />

            {/* <TikketsBlog isLoading={isLoading} blogrender={blogrender} /> */}

            {showModal && (
              <div
                className={`modal fade modal-open  show ${
                  showModal ? "d-block modal-open" : ""
                }`}
                id="staticBackdrop"
                style={{
                  display: "block",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                }} // Black backdrop
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabindex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog  ">
                  <div className="modal-content">
                    <div className="modal-body p-0">
                      <div className="d-block w-100 bg-white rounded-3 pb-3 pt-3">
                        <div className="row justify-content-center position-relative m-0">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-12 p-0 ">
                            <div className="d-block w-100 text-center mb-4  ps-3 pe-3">
                              <h3 className="text-black text-26 poppins-700 mob-text-20">
                                Great Job!
                              </h3>
                            </div>
                            <div className="d-block w-100 text-center mb-4  ps-3 pe-3">
                              <img
                                className="custom-image2 popupimg"
                                src="/images/thankyou-graphics.png"
                              />
                            </div>
                            <div className="d-block w-100 text-center  ps-3 pe-3">
                              <h5 className="text-black poppins-500 text-15 mb-3">
                                Thanks for placing order :
                              </h5>
                              <h3 className="text-black poppins-600 text-17 mb-3 mob-text-17">
                                Your Items are now in your Cart
                              </h3>
                              <h5 className="col-green poppins-500 text-15 mb-2 mt-0 d-none">
                                01dc1370-3df6-11eb-b378-0242ac130002
                              </h5>
                              <h5 className="text-black poppins-500 text-15 mb-3 mt-0">
                                If you have any question or queries then fell to
                                get in contact us.
                              </h5>
                              <h4 className="text-black poppins-600 text-16 mb-3 mt-0">
                                All the best,
                              </h4>
                            </div>
                            <div className="d-block w-100 mt-4 mb-3 pb-2 position-relative">
                              <img
                                src="/images/line-1.png"
                                className="position-relative z-index99 line-img1"
                              />
                              <span className="d-block w-100 line-1 position-absolute top-0 start-0 mt-3"></span>
                            </div>
                            <div className=" align-items-center justify-content-between flex-wrap  pl-3 pr-3 container">
                              <button
                                className="custom-btn2 w-100 mb-3"
                                onClick={handleContinue}
                              >
                                <span onClick={handleContinue}>
                                  Continue Shopping
                                </span>
                              </button>
                              <button
                                className="custom-btn3 w-100"
                                onClick={handleContinue_checkout}
                              >
                                Checkout
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <FooterComponent />
        </>
      )}
    </>
  );
}
export default NewHomeComponent;
