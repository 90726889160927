import React, { useState, useEffect,useContext } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from '../services/authService';
import Swal from "sweetalert2";
import * as yup from "yup";
import { Helmet } from "react-helmet";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";

 

function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const [Isloading, setloading] = useState(true);
  const navigate = useNavigate();
  
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [forgotPasswordLoading, setForgotPasswordLoading] = useState(false);

 
  const handleForgotPasswordSubmit = async (event) => {
    event.preventDefault();
    setForgotPasswordLoading(true);

    const { success, message } = await forgotPassword(forgotPasswordEmail);
    if (success) {
      Swal.fire("Success", message, "success");
     
    } else {
      Swal.fire("Error", message, "error");
    }

    setForgotPasswordLoading(false);
  };
   
 
  

  useEffect(() => {
    const timer = setTimeout(() => {
      setloading(false);  
    }, 2000);  

    return () => clearTimeout(timer);  
    
  }, []);

  
  return (
    <div>
      <Helmet>
        <link href="/style/bootstrap.min.css" rel="stylesheet" />
        <link href="/style/animate.css" rel="stylesheet" />
        <link href="/style/custom.css" rel="stylesheet" />
        <link href="/style/slick-theme.css" rel="stylesheet" />
        <link href="/style/slick.css" rel="stylesheet" />
      </Helmet>
      {Isloading ? (
        <section className="pad-top-20 pad-bot-80">
          <div className="container3">
            <div className="block-element m-b-30"></div>
            <div className="block-element loader-mobile-screen" style={{ marginTop: "80px" }}>
              <LoadingSpinner />
            </div>
          </div>
        </section>
      ) : (
        <>
          <section className="all-layout">
            <div className="register-wrapper m-0 d-flex justify-content-start flex-wrap h-100-vh">
              <div className="register-left-layout bg-silver1 h-100-vh overflow-auto">
                <div className="d-block w-100 ps-3 pe-3 pt-3 pb-3 mb-5" id="loginContainer">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-inline-block">
                      <Link to="/">
                        <img src="/images/tikkets-dark-logo.png" />
                      </Link>
                    </div>
                    <div className="d-inline-block d-lg-none d-md-none">
                      <button className="text-black bg-transparent border-0 poppins-500">
                        Need help?
                      </button>
                    </div>
                  </div>
                </div>
                <div className="d-block w-100 text-center mb-4">
                  <h4 className="text-15 poppins-600 mt-0 mb-3">
                  Forget Password
                  </h4>
                  <p className="text-dark-cts poppins-600">Want to go back? <Link to='/user/login'  id="UserAccountAbc" >Sign in</Link></p>
                </div>
                <div className="d-block w-100 ps-lg-5 pe-lg-5 ps-md-4 pe-md-4 ps-3 pe-3">
                  <form onSubmit={handleForgotPasswordSubmit}>
                    <div className="row justify-content-center">
                      <div className="col-lg-12 col-m-12 col-sm-12 col-12 mb-3">
                        <div className="form-field1">
                        <input
                            type="email"
                            value={forgotPasswordEmail}
                            onChange={(e) => setForgotPasswordEmail(e.target.value)}
                            placeholder="Email address"
                            className="field-style1"
                            required
                        />
                        </div>
                      </div>
                      <div className="col-lg-12 col-m-12 col-sm-12 col-12 mb-3">
                        <div className="d-block w-100">
                          <button className="custom-btn1 w-100 rounded-3" disabled={forgotPasswordLoading}>
                            {forgotPasswordLoading ? (
                              <div className="spinner-border text-light" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              "Reset Password"
                            )}
                          </button>
                        </div>
                      </div>
                      <div className="col-lg-12 col-m-12 col-sm-12 col-12 mb-3">
                        <div className="d-block w-100">
                          <p className="text-center col-grey1 m-0 text-14 poppins-500 lh-normal">
                      
                            By signing up, I agree to{" "}
                            <a href className="col-black1">
                        
                              Terms of Use{" "}
                            </a>{" "}
                            and{" "}
                            <a href className="col-black1">
                        
                              Privacy Policy.{" "}
                            </a>{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="register-right-layout h-100 d-lg-block d-md-block d-sm-none d-none">
                <div className="d-flex w-100 align-items-center justify-content-center h-100 flex-column">
                  <div className="d-block w-100 text-center">
                    <h4 className="small-width1 text-26 poppins-700 lh-base">
                
                      Manage your Events experience more effectively with Tickets.com
                    </h4>
                  </div>
                  <div className="d-block w-50 text-center">
                    <img className="custom-image1" src="/images/register-graphics.png" />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </div>
  );
}

export default ResetPassword;
