import React from 'react';
import { Modal } from "react-bootstrap";
import "./Register.css"
import { Link } from 'react-router-dom';

function ForgotPassword({
  showForgotPasswordModal,
  handleForgotPasswordClose,
  handleForgotPasswordSubmit,
  forgotPasswordLoading,
  forgotPasswordEmail,
  setForgotPasswordEmail,
  handleSwitchToLoginAndClose
}) {
  
  return (
    <>
    <Modal show={showForgotPasswordModal} onHide={handleForgotPasswordClose} className="register">
    <Modal.Header id="modelHeaderDesgn" closeButton={false}>
    <button type="button" id="modelHeaderButton" className="custom-close" onClick={handleForgotPasswordClose}>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-x"
      >
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
      </svg>
     </button>
  </Modal.Header>
      <Modal.Body>
        <form className="registration-form" onSubmit={handleForgotPasswordSubmit}>
          <h2>
            Forget Password <img className="auth-logo" src="/images/logo-black.png" alt="image-2" />
          </h2>
          <p>User Account</p>
          <p>Enter your email to reset your password.</p> 
          <p className="text-dark-cts poppins-600">Want to go back? <Link  id="UserAccountAbc" onClick={handleSwitchToLoginAndClose} >Sign in</Link></p>
          <input
            type="email"
            value={forgotPasswordEmail}
            onChange={(e) => setForgotPasswordEmail(e.target.value)}
            placeholder="Email address"
            className="field-style1"
            required
          />
          <button type="submit" disabled={forgotPasswordLoading}>
            {forgotPasswordLoading ? (
              <div className="spinner-border text-light" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              "Reset Password"
            )}
          </button>

          <p className="terms">
            <span className="text-muted">
              By signing in, I agree to
              <a href="#">Terms of Use</a> and
              <a href="#">Privacy Policy</a>.
            </span>
          </p>
          <div className="by-signing-up-container1">
            Copyright © 2023 Made with ❤️
            <a
              className="by-kanoo"
              href="https://kanoopays.com/"
              target="_blank" rel="noreferrer" 
            >
              <span className="by-kanoo1">By Kanoo.</span>
            </a>
          </div>
        </form>
      </Modal.Body>
    </Modal>
    </>
  );
}

export default ForgotPassword;