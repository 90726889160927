import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { fetchLoggedInUser } from "../../services/EventTikketService";
import { fetchOrganizerProfile, fetchFavoriteState } from '../../services/profileService';
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import HeaderComponent from '../HeaderComponent/HeaderComponent.js';
import FooterComponent from '../FooterComponent/FooterComponent.js';
import { Helmet } from "react-helmet";
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";


function OrganizersProfileComponent() {
   const { id } = useParams();
  const [userfollow, setUserfollow] = useState(null);
  const [isFollow, setIsFollow] = useState(0);
  const [followText, setFollowText] = useState('');
  const [userEvents, setUserEvents] = useState([]);
  const [userFollow, setUserFollow] = useState(null);
  const [userorg, setUserorg] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isIdMatching, setIsIdMatching] = useState(false);
  const isFetchingRef = useRef(false);
  const isStatusRef = useRef(false);
  const [pageLoading, setPageLoading] = useState(true);
  const navigate = useNavigate();
 
  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
  const fetchData = async () => {
    if (!isStatusRef.current) {
      isStatusRef.current = true;
    try {
      const userFollow = await fetchLoggedInUser(authToken);
      setUserFollow(userFollow);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching user data:', error);
      setIsLoading(false);
      isStatusRef.current = false;
    }
  }
  };
    fetchData();
  }, []);
 
  const handleFavoriteButtonClick = async () => {
    const authToken = localStorage.getItem('authToken');

    if (!authToken) {
      Swal.fire({
        title: "Please log in",
        text: "You need to log in to perform this action.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((loggedIn) => {
        if (loggedIn) {
          navigate("/user/login");
        }
      });
    } else {
      setIsLoading(true);
      try {
        const data = await fetchFavoriteState(id, authToken);

        setIsFollow(data.follow); // Update isFollow state directly with the fetched data
        setFollowText(data.follow === 0 ? 'Follow' : 'Unfollow'); // Update followText based on fetched data

        // Show success message based on fetched data
        Swal.fire({
          icon: 'success',
          title: data.follow === 1 ? 'Followed' : 'Unfollowed',
          text: data.follow === 1 ? 'You are now following this event' : 'You have unfollowed this event',
          showCancelButton: false,
          confirmButtonText: 'OK',
        });

        // Update local storage
        localStorage.setItem('followState', data.follow);
        localStorage.setItem('followText', data.follow === 1 ? 'Follow' : 'Unfollow');
      } catch (error) {
        console.error('Error fetching favorite state:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to fetch favorite state from the server',
          showCancelButton: false,
          confirmButtonText: 'OK',
        });
      } finally {
        setIsLoading(false);
      }
    }
  };
  const storedfollowState = localStorage.getItem('followState');
  const storedfollowTEXT = localStorage.getItem('followText');

  useEffect(() => {
    setFollowText(storedfollowTEXT);
      setIsFollow(storedfollowState);
  }, [storedfollowState,storedfollowTEXT]);

   
  useEffect(() => {
    const timer = setTimeout(() => {
      setPageLoading(false); // Change loading state to false after 2 seconds
    }, 2000); // 2 seconds delay

    return () => clearTimeout(timer); // Clear the timer if the component unmounts
  }, []);
  useEffect(() => {
    const fetchDataProfile = async () => {
      if (!isFetchingRef.current) {
        isFetchingRef.current = true;
        try {
          const { userEvents, userOrg } = await fetchOrganizerProfile(id);
          setUserEvents(userEvents);
          setUserorg(userOrg);
          setLoading(false);
        } catch (error) {
          console.error(error);
          setLoading(false);
          isFetchingRef.current = false;
        }
      }
    };

    fetchDataProfile();

  }, [id]);

  const rendereduserorg = userorg.map((item, index) => {
    return (

      <div key={index}>
        <div className="profile-image-details m-b-30 text-center">
          <img src={item.imagePath} alt="defaultuser" />
          <h4 className="col-black"> {item.name}</h4>
          <span className="mt-2"> ID#{item.id} </span>
        </div>
        <div className="form-field4 m-b-15">
          <label>Bio </label>
          <p className="text-dark">{item.bio}</p>
        </div>
        <div className="form-field4 m-b-15">
          <label> Email Address </label>
          <p className="text-dark">{item.email}</p>
        </div>
        <div className="form-field4 m-b-15">
          <label> Phone number </label>
          <p className="text-dark">{item.phone}</p>
        </div>
        <div className="form-field4 m-b-15">
          <label> Location </label>
          <p className="text-dark">{item.location}</p>
        </div>
        <div className="block-element2 m-t-25">

          <button className="custom-btn6 block-element2 text-center"
            onClick={handleFavoriteButtonClick}>
            <i className="mr-2 fa-solid fa-user-plus" />
             {followText}
           </button>
        </div>
      </div>
    );
  });


  return (
    <div>
       
    <Helmet>
    <link href="/frontend/css/bootstrap.min.css" rel="stylesheet" />
  </Helmet> 
      {pageLoading ? (
        <section className="pt-lg-5 pt-md-5 pt-3 bg-silver2">
          <div className="container3">
            <div className="block-element m-b-30">
            </div>
            <div className="block-element loader-mobile-screen"   style={{ marginTop: '80px' }}>
              <LoadingSpinner />
            </div></div>
        </section>
      ) : (
        <>
        <HeaderComponent/>
      <section
        className="pad-top-60 pad-bot-60"
        
      >
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-lg-4 col-sm-12 col-12">
              <div className="edit-profile-wrapper">
                <div className="block-element2 text-center m-b-20">
                  <p
                    style={{
                      color: "red !important",
                      fontWeight: "bold",
                      fontSize: 22,
                    }}
                  >
                    Organizer Profile
                  </p>
                </div>
                {rendereduserorg}
              </div>
            </div>

            <div className="col-md-8 col-lg-8 col-sm-12 col-12">
              <div className="block-element content-block2 pt-3">
                <div className="block-element featured-posts-title">
                  <div className="sec-head2">
                    <h3 className="col-black"> Events</h3>
                  </div>
                </div>
                <div className="block-element">
                  <div className="row arrows-1">
                    {userEvents.map((event) => (
                      <div className="col-md-6 col-lg-6 col-sm-6 col-12">
                        <div className="music-event-block">
                          <div className="fav-section">
                            <button
                              style={{ position: "absolute", right: "10%" }}
                              type="button"
                              className="btn p-0"
                            >


                            </button>
                          </div>
                          <Link to={`/event/${event.id}/${event.name
                            .replace(/\s+/g, "-")
                            .toLowerCase()}`}>
                            <img
                              src={`${event.imagePath}${event.image}`}
                              style={{
                                objectFit: "cover",
                                maxHeight: 206,
                                borderRadius: 4,
                              }}
                              alt="imagePath" />
                          </Link>
                          <Link to={event.eventUrl}>
                            <h4 style={{ color: "black" }}>{event.name} </h4>
                            <div style={{ maxWidth: "100%" }}>
                              <p className="col-grey6 descriptionText">
                                <div className="orgdes" dangerouslySetInnerHTML={{ __html: event.description }} />
                              </p>
                            </div>
                            <div className="event-detail">
                              <span className="Op">Category: </span>
                              <span
                                className="archive-text1 col-pink1"
                                style={{ fontSize: 15 }}
                              >

                                {event.category.name}
                              </span>
                            </div>
                          </Link>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterComponent  />
      </>)}
    </div>
  );
}

export default OrganizersProfileComponent;
