import { API_ENDPOINTS } from './api';


export async function FetchChildTicketData(id, authToken, setItems, setorderID, setUpcomingTicketsOnlyChild, setIsdata,setIsChildLoading) {
    try {
        
        const response = await fetch(API_ENDPOINTS.FETCH_CHILD_TIKKET + id, {
        method: "get",
        headers: { "Authorization": `Bearer ${authToken}` }
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const json = await response.json();
  
      // Uncomment and implement these functions if needed
      // setUpcomingTickets(json.data);
      // setPastTickets(json.ticket.past);
  
  
  
      setUpcomingTicketsOnlyChild(json.data);
      setIsChildLoading(false);
     
      if (json.data) {
        setIsdata('yes');
      }
  
      // Replace 'otherStateValue' with the appropriate value from the JSON response
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
export async function FetchChild_tranfer_TicketData(id, authToken,setTransferEvent,setTransferTicket,setFunctionRun) {
    try {
        
        const response = await fetch(API_ENDPOINTS.FETCH_CHILD_TIKKET + id, {
        method: "get",
        headers: { "Authorization": `Bearer ${authToken}` }
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const json = await response.json();
      

      localStorage.setItem('transfer_ticket', JSON.stringify(json.data.ticket));
      localStorage.setItem('transfer_event', JSON.stringify(json.data.event));
      setFunctionRun(true); 
      setTransferEvent(json.data.event);
      setTransferTicket(json.data.ticket); 
      
   
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  export async function User_Search(authToken, searchQuery, setUsers,setfrom,setTo,setTotal,setPrevPageUrl,setNextPageUrl, currentPage, itemsPerPage,page,  
  ) {
    try {
      
      const response = await fetch(API_ENDPOINTS.UserSearchFind, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${authToken}`,
          "Content-Type": "application/json" // Specify content type as JSON
        },
        body: JSON.stringify({ search: searchQuery,'page':page,'itemsPerPage':itemsPerPage }) // Include searchQuery in the request body
      });
       
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const json = await response.json();
    
      if (json.success) {
        if (json.appusers.data === 0) {
          setUsers([]);
           
        
        } else {
          setUsers(json.appusers.data);
          setPrevPageUrl(json.appusers.prev_page_url);
          setNextPageUrl(json.appusers.next_page_url);
          setfrom(json.appusers.from);
          setTo(json.appusers.to);
          setTotal(json.appusers.total);

        }
      } else {
         
        console.error("Request was not successful:", json.message);
      }
    
      
    } catch (error) {
   
      console.error('Error fetching data:', error);
    }
  }
  export async function transfer_ticket(authToken,
    transfer_order_id,
    selectedTicket,
    user_id) {
    try {
      
      const response = await fetch(API_ENDPOINTS.transferTicket, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${authToken}`,
          "Content-Type": "application/json" // Specify content type as JSON
        },
        body: JSON.stringify({ order_id: transfer_order_id,'transfer_to':user_id,'selectedTicket':selectedTicket }) // Include searchQuery in the request body
      });
       
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const json = await response.json();
    
       return json;
    
      
    } catch (error) {
   
      console.error('Error fetching data:', error);
    }
  }
  
  
  export const handleSubmitFunction = async (orderID, items, authToken, navigate,isMobile) => {
    localStorage.removeItem('apiResponseTicket');
  
    const jsonString = JSON.stringify({
      orderId: orderID,
      ticket_child_id: JSON.stringify(items),
    });
  
    try {
        const response = await fetch(API_ENDPOINTS.PRINT_TIKKET, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${authToken}`
        },
        body: jsonString,
      });
  
      if (response.ok) {
        const responseData = await response.json();
        const jsonString = JSON.stringify(responseData);
        localStorage.setItem('apiResponseTicket', jsonString);
        //navigate("/order-ticket-print");
        if (isMobile) {
          navigate("/order-ticket-print")
        }else{
          window.open('/order-ticket-print', '_blank');
        }
       

      } else {
        console.error('API request failed with status:', response.status);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };
  export async function getMyTicketsDATA(
    authToken, 
    setItems, 
    setorderID, 
    setIsLoading, 
    setUpcomingTickets, 
    setIsdata, 
    setUpcomingTicketsChild,
    setPastTickets, 
    status, 
    itemsPerPage, 
    currentPage, 
    setPrevPageUrl, 
    setNextPageUrl, 
    setfrom, 
    setTo, 
    setTotal, 
    setEventLoading, 
    setAll_count,
    setExpired, 
    setValid, 
    setScanned, 
    setUpcomingTicketsOnlyChild, 
    selectedCountry, 
    startDate, 
    endDate, 
    searchText, 
    cate,
    
  ) {
     
    setItems([]);
    setorderID('');
    setUpcomingTicketsChild([]);
    setUpcomingTicketsOnlyChild();
 
 
    try {
      setEventLoading(true);
       
      const params = new URLSearchParams({
        status: status || '',
        itemsPerPage: itemsPerPage || '',
        page: currentPage || '',
        country: selectedCountry || '',
        startDate: startDate || '',
        endDate: endDate || '',
        name: searchText || '',
        category: cate || '',
        status: status || '',
    });

    // Fetch data from the API
    const response = await fetch(`${API_ENDPOINTS.MY_TIKKET}?${params.toString()}`, {

        method: "get",
        headers: { "Authorization": `Bearer ${authToken}` }
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const json = await response.json();
      
     
    setIsLoading(false);
 
    setEventLoading(false);
    setUpcomingTickets(json.ticket.upcoming.data);
    setUpcomingTicketsChild(json.ticket.upcoming.data);
    setAll_count(json.event_data.all_count);
    setExpired(json.event_data.expired);
    setValid(json.event_data.valid);
    setScanned(json.event_data.scanned);  
    setPrevPageUrl(json.ticket.upcoming.prev_page_url);
    setNextPageUrl(json.ticket.upcoming.next_page_url);
    setfrom(json.ticket.upcoming.from);
    setTo(json.ticket.upcoming.to);
    setTotal(json.ticket.upcoming.total);
    setEventLoading(false);
  if(json.ticket){

 
    
    if (json.ticket.upcoming.data.length > 0) {
      setIsdata('yes');
    }
    
    setPastTickets(json.ticket.past);
  }else{
    setUpcomingTickets([]);
    setUpcomingTicketsChild([]);
    setPastTickets([]);
  }
      
       
  
      
      // Replace 'otherStateValue' with the appropriate value from the JSON response
    } catch (error) {
      setUpcomingTickets([]);
      setUpcomingTicketsChild([]);
      setPastTickets([]);
      console.error('Error fetching data:', error);
      setIsLoading(false);
      setEventLoading(false);
    }
  }
  export async function SearchMyTicketsDATA(
    authToken, 
    setItems, 
    setorderID, 
    setIsLoading, 
    setUpcomingTickets, 
    setIsdata, 
    setUpcomingTicketsChild,
    setPastTickets, 
    itemsPerPage, 
    currentPage, 
    setPrevPageUrl, 
    setNextPageUrl, 
    setfrom, 
    setTo, 
    setTotal, 
    setEventLoading, 
    setAll_count,
    setExpired, 
    setValid, 
    setScanned, 
    setUpcomingTicketsOnlyChild, 
    selectedCountry, 
    startDate, 
    endDate, 
    searchText, 
    cate,
    sort_Order,
    status
  ) {
     
    setItems([]);
    setorderID('');
    setUpcomingTicketsChild([]);
    setUpcomingTicketsOnlyChild();
 
 
    try {
      setEventLoading(true);
       
      const params = new URLSearchParams({
        itemsPerPage: itemsPerPage || '',
        page: currentPage || '',
        startDate: startDate || '',
        endDate: endDate || '',
        name: searchText || '',
        category: cate || '',
        sort_Order: sort_Order || '',
        status: status || '',
    });

    // Fetch data from the API
    const response = await fetch(`${API_ENDPOINTS.MY_TIKKET}?${params.toString()}`, {

        method: "get",
        headers: { "Authorization": `Bearer ${authToken}` }
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const json = await response.json();
       
     
    setIsLoading(false);
 
    setEventLoading(false);
    setUpcomingTickets(json.ticket.upcoming.data);
    setUpcomingTicketsChild(json.ticket.upcoming.data);
    setAll_count(json.event_data.all_count);
    setExpired(json.event_data.expired);
    setValid(json.event_data.valid);
    setScanned(json.event_data.scanned);  
  
    setPrevPageUrl(json.ticket.upcoming.prev_page_url);
    setNextPageUrl(json.ticket.upcoming.next_page_url);
    setfrom(json.ticket.upcoming.from);
    setTo(json.ticket.upcoming.to);
    setTotal(json.ticket.upcoming.total);
    setEventLoading(false);
  if(json.ticket){

 
    
    if (json.ticket.upcoming.data.length > 0) {
      setIsdata('yes');
    }
    
    setPastTickets(json.ticket.past);
  }else{
    setUpcomingTickets([]);
    setUpcomingTicketsChild([]);
    setPastTickets([]);
  }
      
       
  
      
      // Replace 'otherStateValue' with the appropriate value from the JSON response
    } catch (error) {
      setUpcomingTickets([]);
      setUpcomingTicketsChild([]);
      setPastTickets([]);
      console.error('Error fetching data:', error);
      setIsLoading(false);
      setEventLoading(false);
    }
  }
  export async function getMyTicketsDATAReset(page,status, itemsPerPage,setUpcomingTickets,setPrevPageUrl,setNextPageUrl,setfrom,setTo,setTotal,setAll_count,setValid,setExpired ) {
    try {
      const authToken = localStorage.getItem("authToken");
      const response = await fetch(`${API_ENDPOINTS.MY_TIKKET}?page=${page}&itemsPerPage=${itemsPerPage}&status=${status}`, {

        method: "get",
        headers: { "Authorization": `Bearer ${authToken}` }
      });
      if (!response.ok) { 
        throw new Error("Network response was not ok");  
      }
      const data = await response.json();
      
      setUpcomingTickets(data.ticket.upcoming.data);
      setPrevPageUrl(data.ticket.upcoming.prev_page_url);
      setNextPageUrl(data.ticket.upcoming.next_page_url);
      setfrom(data.ticket.upcoming.from);
      setAll_count(data.event_data.all_count);
      setExpired(data.event_data.expired);
      setValid(data.event_data.valid);
      setTo(data.ticket.upcoming.to);
      setTotal(data.ticket.upcoming.total);
      return data.data;
    } catch (error) {
      console.error("Error fetching home data:", error);   
      throw error;
    }
  }
  