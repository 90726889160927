import axios from "axios";
import { API_ENDPOINTS } from "./api";

export const login = async (email, password) => {
  try {
    const response = await axios.post(API_ENDPOINTS.LOGIN, {
      email,
      password
    });

    // Check if the response includes the token and role
    if (response.data && response.data.token && response.data.role) {
      localStorage.setItem("authToken", response.data.token);
      localStorage.setItem("role", response.data.role);
      localStorage.setItem("login_userId", response.data.id);
      localStorage.setItem("permissions", JSON.stringify(response.data.permissions));
      localStorage.setItem("login_user", JSON.stringify(response.data.user));
    } else {
      throw new Error("Authentication token or role is missing in the response");
    }
    return response.data;
  } catch (error) {
    console.error("Login error:", error);
    // Rethrow the error for further handling (e.g., showing an error message in the UI)
    throw error;
  }
};
export const ORGlogin = async (email, password) => {
  try {
    const response = await axios.post(API_ENDPOINTS.ORGLOGIN, {
      email,
      password
    });

    // Check if the response includes the token and role
    if (response.data && response.data.token && response.data.role) {
      localStorage.setItem("authToken", response.data.token);
      localStorage.setItem("role", response.data.role);
      localStorage.setItem("permissions", JSON.stringify(response.data.permissions));
    } else {
      throw new Error("Authentication token or role is missing in the response");
    }
    return response.data;
  } catch (error) {
    console.error("Login error:", error);
    // Rethrow the error for further handling (e.g., showing an error message in the UI)
    throw error;
  }
};

export const registerUser = async (formData) => {
  try {
    const response = await axios.post(API_ENDPOINTS.REGISTER, formData);
    return response.data;
  } catch (error) {
    console.error("Registration error:", error);
    throw error; // Rethrow the error to handle it in the calling function
  }
};
export const ORGregisterUser = async (formData) => {
  try {
    const response = await axios.post(API_ENDPOINTS.ORG_REGISTER, formData);
    return response.data;
  } catch (error) {
    console.error("Registration error:", error);
    throw error; // Rethrow the error to handle it in the calling function
  }
};

export const forgetPassword = async (email) => {
  try {
    const response = await axios.post(API_ENDPOINTS.forget_Password, {
      email
    });
    return response.data;
  } catch (error) {
    console.error("scanner error:", error);
    // Return the error message or response data
    throw error.response ? error.response.data : error.message || "An unknown error occurred";
  }
};

export const forgotPassword = async (email) => {
  try {
    const response = await fetch(`${API_ENDPOINTS.FORGET_PASSWORD}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ email })
    });

    const result = await response.json();
    if (response.ok) {
      return { success: true, message: result.msg };
    } else {
      return { success: false, message: result.msg || "Failed to send password reset link" };
    }
  } catch (error) {
    console.error("Forgot Password failed:", error);
    return { success: false, message: "An error occurred. Please try again later." };
  }
};
