import { API_ENDPOINTS } from './api';


export const fetchBlogData = async (page) => {
  try {
    const response = await fetch(`${API_ENDPOINTS.BLOG}?page=${page}&perPage=10`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching blog data:", error);
    throw error;
  }
};
export const fetchBlogDetail = (id, title, setBlogData, setIsLoading) => {
  fetch(
    `${API_ENDPOINTS.BLOG_DETAILS}/${id}/${title}`
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((data) => {
      const blogdetialArray = data.blog;
      setBlogData(blogdetialArray);
      setIsLoading(false);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    });
};