import { React, useEffect, useState } from "react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
export default function MyticketsSwiper({ onChangeCate,setStatus,allCount, expiredCount, validCount, scannedCount }) {
  const [status, setagainStatus] = useState("valid");
 
  const handleCategoryClick = (id) => {
    
    onChangeCate(id);
    setStatus(id);
    setagainStatus(id);
  };
 
  return (
    <>


<div className="row gap-2 m-0 bg-white brd-rd-cu">
        <div className="col-md-12 col-lg-12 col-sm-12 col-12 d-flex bg-dimgrey events-cats-links">
          <div className="w-100 d-flex">
            <Swiper
              modules={[Navigation, A11y]} 
              spaceBetween={10}
              slidesPerView={3}
              navigation
              onSwiper={(swiper) => console.log(swiper)}
              onSlideChange={() => console.log("slide change")}
            >
              
                
            <SwiperSlide  >
              <Link
                
                className= {`px-2 ${
                  status === "valid" ? "active" : ""
                }`}
                onClick={() => handleCategoryClick("valid")}
              >
                <div className="d-flex gap-2">
                <div className={`py-1 px-2 border-radius-2 bg-light-8 badge-cu-table`}>
                    <p className="m-0 text-cyan">
                        
                    {validCount}
                    </p>
                  </div>
                  <p className="m-0 text-charcoal d-flex align-items-center text-badge-cu">
                  Valid
                  </p>
                  <p></p>
                </div>
              </Link>
            </SwiperSlide>


            <SwiperSlide  >
              <Link
                
                className= {`px-2 d-none ${
                  status === "transferred" ? "active" : ""
                }`}
                onClick={() => handleCategoryClick("transferred")}
              >
                <div className="d-flex gap-2">
                <div className={`py-1 px-2 border-radius-2 bg-light-2 badge-cu-table`}>
                    <p className="m-0 text-cyan">
                        
                    {validCount}
                    </p>
                  </div>
                  <p className="m-0 text-charcoal d-flex align-items-center text-badge-cu">
                  Transferred
                  </p>
                  <p></p>
                </div>
              </Link>
            </SwiperSlide>


              <SwiperSlide  >
                <Link
                  
                  className= {`px-2 d-none ${
                    status === "scanned" ? "active" : ""
                  }`}
                  onClick={() => handleCategoryClick("scanned")}
                >
                  <div className="d-flex gap-2">
                  <div className={`py-1 px-2 border-radius-2 bg-light-9 badge-cu-table`}>
                      <p className="m-0 text-cyan">
                          
                      
                      {scannedCount} 
                      </p>
                    </div>
                    <p className="m-0 text-charcoal d-flex align-items-center text-badge-cu">
                    Scanned
                    </p>
                    <p></p>
                  </div>
                </Link>
              </SwiperSlide>



                <SwiperSlide  >
                  <Link
                    
                    className= {`px-2 ${
                      status === "expired" ? "active" : ""
                    }`}
                    onClick={() => handleCategoryClick("expired")}
                  >
                    <div className="d-flex gap-2">
                    <div className={`py-1 px-2 border-radius-2 bg-light-2 badge-cu-table`}>
                        <p className="m-0 text-cyan">
                           
                       
                        {expiredCount}
                        </p>
                      </div>
                      <p className="m-0 text-charcoal d-flex align-items-center text-badge-cu">
                      Expired
                      </p>
                      <p></p>
                    </div>
                  </Link>
                </SwiperSlide>
                <SwiperSlide  >
                  <Link
                      className= {`px-2 ${
                        status === "all" ? "active" : ""
                      }`}
                      onClick={() => handleCategoryClick("all")}
                    >
                    <div className="d-flex gap-2">
                    <div className={`py-1 px-2 border-radius-2 bg-light-2 badge-cu-table`}>
                        <p className="m-0 text-cyan col-blue2">
                           
                        {allCount}
                        </p>
                      </div>
                      <p className="m-0 text-charcoal d-flex align-items-center text-badge-cu">
                      All
                      </p>
                      <p></p>
                    </div>
                  </Link>
                </SwiperSlide>


            

            </Swiper>
          </div>
        </div>
      </div>


{/* <div className="d-flex slick-slider1 align-items-center justify-content-start ">
                 
<Swiper
              modules={[Navigation, A11y]} 
              spaceBetween={10}
              slidesPerView={3}
              navigation
              onSwiper={(swiper) => console.log(swiper)}
              onSlideChange={() => console.log("slide change")}
            >

             
                <SwiperSlide> 
                  
                 <button
                     id="cateall"
                     className={`px-2 py-1 text-14 d-flex align-items-center justify-content-start border-0 bg-transparent me-2 ${
                      status === "all" ? "active" : ""
                    }`}

                     onClick={() => handleCategoryClick("all")}
                   >
                      <b className="bg-blue2 col-blue2 text-13 px-2 py-1 me-2 rounded-2 text-center d-inline-block">
       
                       {allCount}
                       </b>
                     All
                   </button>
                </SwiperSlide>
             
              * <SwiperSlide>
                <button
                     id="cate23"
                     className={`px-2 py-1 text-14 d-flex align-items-center justify-content-start border-0 bg-transparent me-2 ${status === "valid" ? "active" : ""}`}
                     onClick={() => handleCategoryClick("valid")}
                   >

                         <b className="bg-green2 col-green2 text-13 px-2 py-1 me-2 rounded-2 text-center d-inline-block">
                             
                           {validCount}
                           </b>
                           Valid

                      </button>
                </SwiperSlide>  
             
                 <SwiperSlide>
                <button
                     id="cate22"
                     className={`px-2 py-1 d-none text-14 d-flex align-items-center justify-content-start border-0 bg-transparent me-2 ${status === "transferred" ? "active" : ""}`}
                     onClick={() =>
                       handleCategoryClick("transferred")
                     }
                   >

                       <b className="bg-brown2 col-brown2 text-13 px-2 py-1 me-2 rounded-2 text-center d-inline-block">

                      4
                       </b>

                       Transferred
                   </button>
                </SwiperSlide>
             
                <SwiperSlide>
                  
               
                <button
                     id="cate21"
                     className={`px-2 py-1 text-14 d-flex align-items-center justify-content-start border-0 bg-transparent me-2 ${status === "scanned" ? "active" : ""}`}
                     onClick={() => handleCategoryClick("scanned")}
                   >
                     
                       <b className="bg-blue2 col-blue2 text-13 px-2 py-1 me-2 rounded-2 text-center d-inline-block">

                       {scannedCount} 
                       </b>
                       Scanned
                      
                   </button>
                </SwiperSlide>

                <SwiperSlide>
                <button
                     id="cate15"
                     className={`px-2 py-1 text-14 d-flex align-items-center justify-content-start border-0 bg-transparent me-2 ${status === "expired" ? "active" : ""}`}
                     onClick={() => handleCategoryClick("expired")}
                   >

                             <b className="bg-red2 col-red2 text-13 px-2 py-1 me-2 rounded-2 text-center d-inline-block">

                         {expiredCount}
                               </b>
                               Expired
                   </button>
                </SwiperSlide>  
            
            </Swiper>

                 
                 
               
   
   </div> */}

      
    </>
  );
}
