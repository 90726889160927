import React from 'react';
import { Link } from 'react-router-dom';

const NotLogin = ({ setTicketNotselect,isMobile}) => {
  const handleClose = () => {
    setTicketNotselect(false);
  };

  return (
    <>
      <div
  className="modal fade modal fade show d-block modal modal-open modall-standard-design"
  id="exampleModal"
  tabIndex={-1}
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
<div className={`modal-dialog  modal-dialog-centered ${isMobile ? "mx-3" : ""}`}>

    <div className={`modal-content notlogin mx-auto  ${isMobile ? "mx-3" : ""}`} style={{ borderRadius: 25 }}>
      <div className="ms-auto pt-3 pe-3">
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        />
      </div>
      <div className="modal-body">
        <div className="text-center px-1">
          <i
            className="fa fa-exclamation-triangle fs-1"
            style={{
              color: "#F5403F",
              backgroundColor: "#F9ECEC",
              padding: 20,
              borderRadius: "100%"
            }}
          />
          <h3 className="fw-bold pt-2">Oops</h3>
          <p style={{ fontSize: 20 }}>You have not selected any tickets</p>
          
          <span className="btn btn-primary w-100 fw-medium fs-6 py-2 mt-1 mb-2" onClick={handleClose}>Close</span>
        </div>
      </div>
    </div>
  </div>
</div>
</>

  

 
  );
};

export default NotLogin;
